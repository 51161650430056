<template>
  <div>
    <div class="rel-grid-container">
      <div ></div>
      <div v-if="analyticsFetched && template_region.id==0">
        <Tip :tipNumber="10"></Tip>
        <form v-on:submit.prevent="updateAnalytics">
          <b-table class="table-hover-new" small bordered :items="analytics" :fields="fields" :tbody-tr-class="rowClass" v-on:row-clicked="getAnalyticTitle">
            <template v-slot:cell(value)="data" >
              <b-form-input v-if="data.item.type==0 || !permissions.organization.is_parent" v-model="data.item.value" class="form-box-input anal-value" :disabled="data.item.isParent===1"></b-form-input>
              <div v-if="data.item.type==1 && permissions.organization.is_parent " class="anal-value">{{$t('analytics.set_value')}}</div>
            </template>
            
            <template v-slot:cell(delete)="data">
              <b-button v-if="!data.item.isParent && (data.item.type==0 || (permissions.organization.is_parent && data.item.type==1))" class="trash-button" variant="blue" v-on:click.stop="showDeleteAnalytic(data.item)"></b-button>
            </template>
          </b-table>
          <br />
          <b-alert variant="success" dismissible :show="showSuccess" @dismissed="showSuccess=false">
          {{ $t('analytics.success_text') }}
          </b-alert>
          <b-alert variant="danger" dismissible :show="showGeneralAlert" @dismissed="showGeneralAlert=false">
          {{ $t('analytics.general_alert') }}
          </b-alert>
          <div class="submit-box-2">
            <b-button class="action-button" v-on:click="showAddAnalytic=true;" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
            <b-button v-if="!onboard" class="action-button" v-on:click="back" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button v-if="!onboard" class="action-button" type="submit" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
          </div>
        </form>
      </div>
      <div v-if="analyticsFetched && template_region.id>0" id="managed-by-note">
          {{ $t('global.managed_by', {region: template_region.name}) }}
        </div>
      <b-modal centered v-model="showAddAnalytic" :title="$t('analytics.new_title')" >
        <div id="anal-add">
          <span class="form-box-name">{{$t('analytics.title_text')}}</span><b-form-input v-model="title" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" id="anal-title" class="form-box-input "></b-form-input>
          <div id="anal-canedit">
            <b-form-checkbox v-if="permissions.organization.id>1 && permissions.organization.is_parent"  v-model="type" value="1" unchecked-value="0">
              {{$t('analytics.can_edit')}}
            </b-form-checkbox>
          </div>
        </div>
        <b-alert variant="danger" dismissible :show="showAlert" @dismissed="showAlert=false">
          {{ $t('analytics.alert_Text') }}
          </b-alert>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="action-button" v-on:click="showAddAnalytic = false;showAlert = false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="action-button"  v-on:click="addAnalytic" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
        </div>
      </b-modal>
      <b-modal centered v-model="showEditAnalytic" >
        <div class="grid-fit-2column">
          <span class="form-box-name" v-if="editAnalytic && editAnalytic.language && $i18n.locale!=editAnalytic.language">{{$t('analytics.title_text')}} ({{ editAnalytic.language }})</span>
          <b-form-input v-if="editAnalytic && editAnalytic.language && $i18n.locale!=editAnalytic.language" v-model="editAnalytic.name" :disabled="true" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" id="anal-title"></b-form-input>
          <span class="form-box-name">{{$t('analytics.title_text')}} ({{ $i18n? $i18n.locale : '' }})</span><b-form-input v-model="title" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" id="anal-title"></b-form-input>
        </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="action-button" v-on:click="showEditAnalytic = false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="action-button"  v-on:click="setAnalyticTitle" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
        </div>
      </b-modal>

      <b-modal id="modal-center" v-model="showDelete" centered :title="$t('analytics.delete_title')">
          <p class="my-4" v-html="$t('analytics.delete_text')"></p>
          
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="showDelete=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" v-on:click="deleteAnalytic" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          </div>
        </b-modal>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped lang="scss">
#anal-add{
  display:grid;
}

#anal-canedit{
  grid-column: 2/4;
}
#anal-title{
  max-width: 300px;
}
#managed-by-note{
  text-align: center;font-size: 30px;
}
.anal-value{
  max-width: 300px;text-align:right;
}
  .trash-button {
    width: 26px;
    height: 30px;
    background: url("../assets/icon-delete.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display:block;
    margin:auto;
    
    filter: $VUE_APP_COLOUR_MAIN_FILTER;
    background-color: transparent !important;
    border: none !important;
  }
  .edit-button {
    width: 30px;
    height: 30px;
    background: url("../assets/icon-Edit.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: block;
    margin: auto;
  }
</style>
<script>
  import router from "../router"
    export default {
    name: "Analytics",
    props:{
      onboard: Boolean,
    },
    watch: {
      '$i18n.locale': {
        handler: function () {
          this.fields[0].label = this.$t('analytics.analytics_item');
          this.fields[1].label = this.$t('analytics.value_text');
          this.fields[2].label = this.$t('analytics.delete_header');
          this.getAnalytics();
        },
        deep: true
      }
    },
      data() {
        return {
          showGeneralAlert: false,
          showEditAnalytic: false,
          editAnalytic: null,
          showSuccess: false,
          showAlert: false,
          analyticsFetched: false,
          template_region: {id: 0, name: ""},
          showDelete: false,
          toDelete: null,
          showAddAnalytic : false,
          saveVariant: "blue",
          title: "",
          type: 0,
          fields: [
            {
              key: 'name',
              label: this.$t('analytics.analytics_item'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
            { key: 'value',
              label: this.$t('analytics.value_text'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            },
             { key: 'delete',
              label: this.$t('analytics.delete_header'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff", "text-align":"center" }
            },
          ],
          analytics: []
        }
      },
    methods: {
      rowClass(item) {
        if (!item) return
        if (item.isParent || (item.type>0 && !this.permissions.organization.is_parent)) return 'grey-row'
        return 'clickable'
      },
      deleteAnalytic() {
        if(this.toDelete!= null){
          this.$http.post("/api/organization/analytics/delete", this.toDelete).then((response) => {
          if (response.data.success == true) {
            var index = this.analytics.indexOf(this.toDelete);
            if (index > -1) {
              this.analytics.splice(index, 1);
            }
          }
          this.showDelete = false;
        }).catch(() => {
          this.showDelete = false;
        });
        }else{
          this.showDelete = false;
        }
      },
      showDeleteAnalytic(a){
        this.toDelete= a;
        this.showDelete = true;
      },
      getAnalytics() {
        this.$http.post("/api/organization/analytics/get", { }).then((response) => {
          if (response.data.success) {
            if(response.data.template_region){
                this.template_region = response.data.template_region;
            }else{
              this.analytics = response.data.analytics;
            }
          }
          this.analyticsFetched = true;
        }).catch(() => {
        });
      },
      getAnalyticTitle(anal){
        if (anal.isParent || (anal.type>0 && !this.permissions.organization.is_parent)){
          return;
        }
        this.editAnalytic = {analytic_id: anal.id, language: "", title: ""};
        this.title = anal.name? anal.name : "";
        this.showEditAnalytic = true;
        this.$http.post("/api/organization/analytics/title/get", {id: anal.id }).then((response) => {
          if (response.data.success) {
            this.editAnalytic = response.data.title;
          }
        }).catch(() => {
        });
      },
      setAnalyticTitle(){
        
        for(var anal of this.analytics){
          if(this.editAnalytic.analytic_id == anal.id){
            if (anal.isParent || (anal.type>0 && !this.permissions.organization.is_parent)){
              break;
            }
            anal.name = this.title;
            break;
          }
        }
        this.showEditAnalytic = false;
        this.title = "";
      },
      updateAnalytics() {
        this.showSuccess = false;
        this.showGeneralAlert = false;
        if(this.analytics.filter(anal => !anal.name && !(anal.isParent || (anal.type>0 && !this.permissions.organization.is_parent))).length>0){
          this.showGeneralAlert = true;
          return;
        }
        this.$http.post("/api/organization/analytics/update", {analytics: this.analytics }).then((response) => {
          if (response.data.success === true) {
            this.saveVariant = 'success';
            this.showSuccess = true;
            if(!this.onboard){
              this.showAlert = true;
              //this.back();
            }
          } else {
            this.saveVariant = 'danger';
          }
        }).catch((errors) => {
          console.log(errors);
          this.$bvToast.show('serverError');
        });
      },
      back() {
        if (this.permissions.organization.is_parent) {
          router.push("/admin/regiondash");
        } else {
          router.push("/admin/dashboard");
        }
      },
      addAnalytic() {
        this.showAlert = false;
        var t = this.title.split(" ").join("_");
        this.$http.post("/api/organization/analytics/new", {value: "0", value_title: t, name: this.title, type: this.type}).then((response) => {
          if (response.data.success === true) {
            this.saveVariant = 'success';
            this.analytics.push({
              id: response.data.id,
              organization_id: this.analytics.length > 0 ? this.analytics[0].organization_id : this.permissions.organization.id,
              value_title: t,
              type: this.type,
              disable: 0,
              value: "0",
              isParent: 0,
              name: this.title
            });
            this.showAddAnalytic = false;
            this.title = "";
          }else{
            this.showAlert = true;
          }
          
        }).catch((errors) => {
          this.showAddAnalytic = false;
          this.title = "";
          this.$bvToast.show('serverError');
        });
      }
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-analytics"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-analytics"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
      this.getAnalytics();
    }
  }
</script>
