<template>
  <div>
    <div class="rel-grid-container">
      <div ></div>
      <div v-if="agendaFetched && template_region.id==0">
        <div id="top-select-box">
          <div class="auto-margin">
            <div class="agenda-section-title">{{$t('slides.meeting_time')}} - <b>{{totalTime}}</b></div>
            <div id="section-selector">
              <span class="form-box-name">{{$t('slides.show_section')}} </span>
              <b-form-select required v-model="permissions.sectionFilterId" :options="section_select" value-field="id" text-field="name" class="mb-3 " />
            </div>
          </div>
          <div class="fit-content" v-if="organization_id==0">
            <span class="form-box-title">{{$t('slides.section_text')}}</span>
            <div class="form-box agenda-button-wrapper">
              <b-button class="action-button" v-if="permissions.organization.is_parent" v-on:click="showCopyPopupSection" variant="clear-blue"><div class="copy-button"></div><span>{{$t('global.button_copy')}}</span></b-button>
              <b-button class="action-button" v-on:click="newSection" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
            </div>
          </div>
          <div class="fit-content" v-if="organization_id==0">
            <span class="form-box-title">{{$t('slides.slide_text')}}</span>
            <div class="form-box agenda-button-wrapper">
              <b-button :disabled="permissions.sectionFilterId==0" class="action-button" v-on:click="showCopyPopup" variant="clear-blue"><div class="copy-button"></div><span>{{$t('global.button_copy')}}</span></b-button>
              <b-button :disabled="permissions.sectionFilterId==0" class="action-button" v-on:click="newSlide" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
              <div v-if="permissions.sectionFilterId==0" id="select-slide-note">{{$t('slides.slide_select_note')}}</div>
            </div>
          </div>
        </div>
        <Tip :tipNumber="3"></Tip>
        <div id="select-options">
          <div></div>
          <b-form-checkbox v-on:change="disabledSlidesChange" v-model="permissions.showDisabledSlides">
            {{$t('slides.disabled_slides')}}
          </b-form-checkbox>
          <b-form-checkbox v-model="permissions.getFiles">
            {{$t('slides.show_files')}}
          </b-form-checkbox>
          <b-form-checkbox v-model="permissions.getSlides">
            {{$t('slides.show_slides')}}
          </b-form-checkbox>
        </div>
        <SetupTip v-if="!permissions.organization.is_parent" :tipNumber="5"/>
        <SetupTip v-if="permissions.organization.is_parent" :tipNumber="12"/>
        <table class="table b-table table-bordered table-sm">
          <thead>
            <tr>
              <th class="tableHeader center-text">{{$t('slides.time_header')}} </th>
              <th class="tableHeader">{{$t('slides.section_header')}} </th>
              <th v-if="permissions.getSlides" class="tableHeader">{{$t('slides.slide_header')}} </th>
              <th v-if="permissions.getSlides" class="tableHeader">{{$t('slides.after_header')}} </th>
              <th class="tableHeader center-text">{{$t('slides.duration_header')}} </th>
            </tr>
          </thead>
          <draggable v-for="section in sections" tag="tbody" :key="section.id" :list="section.slides" item-key="id" group="slides" handle=".slide-move" @end="endDragSlide" @start="startDragSlide" >
            <template #item="{element}">
            <tr v-if="permissions.getSlides || element==section.slides[0]" v-bind:id="'slide_'+element.id+'_'+section.section_id" class="dragndrop slide-row"
                v-bind:class="{disabledClass : section.disabled, lastRow: element == (permissions.getSlides? section.slides[section.slides.length-1] : section.slides[0]) && section != sections[sections.length-1] && !moving, 'slide-move': organization_id==0 && element.isParent ==0 && element.id>0}">
              <td >
                <div v-if="element == section.slides[0]"  class="section-time-column">
                  {{start != null ? section.start_time: ""}}
                </div>
              </td>
              <td @mouseenter="section.hover=true;" @mouseleave="section.hover=false;" v-bind:class="{ 'striped-color': section.isParent==1,'td-hover-applied': section.hover ==true,}" v-on:click="editSection(section)"> <!--'td-hover-new' : slide == section.slides[0] && organization_id ==0,-->
                <div v-if="element == section.slides[0]" :id="'section_'+section.section_id"  class="slide-description-column">
                  <div class="fit-height rank-controls" >
                    <b-button v-if="section != sections[0] && section.isParent == 0 && organization_id == 0" id="sectionUpButton" variant="blue" v-on:click.stop="sectionUp(section)" v-b-popover.hover="$t('slides.section_up')" ></b-button>
                    <b-button v-if="section != sections[sections.length-1] && section.isParent == 0 && organization_id == 0" id="sectionDownButton" variant="blue" v-on:click.stop="sectionDown(section)" v-b-popover.hover="$t('slides.section_down')"></b-button>
                  </div>
                  <b class="section-name">{{section.name && section.name.length>0? section.name : $t('global.no_translation') }}</b>
                  <img class="image-colour-shift slide-icon" v-b-popover.hover.top="$t('slides.autoplay_tooltip')" v-if="section.auto_play==0 && !section.disabled" src="../assets/tiny-NoAutoPlay.png" />
                  <img  class="image-colour-shift slide-icon" v-b-popover.hover.top="$t('slides.looped_tooltip')" v-if="section.looped>0 && !section.disabled" src="../assets/tiny-SectionLoop.png" />
                  <img v-if="section.preparer && section.preparer.length>0 && section.preparer != ',' && !section.disabled " :style="{cursor: organization_id==0? 'pointer' : ''}" class="image-colour-shift slide-icon" v-on:click.stop="goToFiles(section)"  v-b-popover.hover.top="preparePopup(section)" src="../assets/tiny-SectionPrepare.png" />
                  <img  class="image-colour-shift slide-icon" v-if="section.force_time!=null && !section.disabled" v-b-popover.hover.top="$t('slides.autostart_tooltip')+' '+section.force_time" src="../assets/tiny-SectionAutostart.png" />
                  <div v-if="permissions.getFiles && section.files && section.files.length>0" class="section-files">
                    <div v-for="f in section.files" :key="f.num">
                      <b-button v-on:click.stop="goToFileControl(f.hash,f.type,f.link)" variant="outline-success" class="curtail-text file-button thick-border">{{f.type == 2? f.link : f.description}}</b-button>
                    </div>
                  </div>
                </div>
              </td>
              <td v-if="permissions.getSlides" v-bind:id="'slide_'+element.id+'_'+section.section_id+'pop'" v-on:click="editSlide(element)" :style="{'cursor': element.id>0? 'pointer' : 'auto'}" v-bind:class="{'td-hover-new' : element.name && element.name.length>0  && organization_id ==0 , disabledClass : element.disabled,'striped-color': element.isParent==1}" >
                <div class="grid-fit-3column">
                  <div v-if="element.id>0" v-on:click.stop="toggleSlide(element)" v-b-popover.hover.left="!(element.isParent==0 || element.child_edit==1)? $t('slides.always_visible') : (element.disabled==0 ? $t('slides.slide_visible') : $t('slides.slide_hidden'))" class="visibility-slide " :class="{'slide-hidden': element.disabled==1 && (element.isParent==0 || element.child_edit==1), 'slide-shown': element.disabled==0 && (element.isParent==0 || element.child_edit==1), 'slide-always-shown': !(element.isParent==0 || element.child_edit==1), 'image-colour-shift' : (element.isParent==0 || element.child_edit==1)}"></div>
                  <span class="auto-margin" v-html="element.name && element.name.length>0? element.name : $t('global.no_translation')"></span>
                  <img class="image-colour-shift slide-icon" v-b-popover.hover.top="$t('slides.selected_tooltip')" v-if="[1,3,7,8,10,12,14,18,20].indexOf(element.type) !== -1" src="../assets/tiny-ControllerSelect.png" />
                </div>
                <b-popover v-if="element.id>0" :ref="'slide_'+element.id+'_'+section.section_id+'pop'" :target="'slide_'+element.id+'_'+section.section_id+'pop'" triggers="hover focus" @show="onPopoverShow(element, section)">
                  <img v-if="element.thumbnail && element.thumbnail.length>0" :src="element.thumbnail" class="slide-image" />
                  <span v-if="!(element.thumbnail && element.thumbnail.length>0)">{{$t('slides.no_preview')}} </span>
                </b-popover>
                <div v-if="permissions.getFiles && element.files && element.files.length>0" >
                  <div  v-for="f in element.files"  :key="f.num">
                    <b-button v-on:click.stop="goToFileControl(f.hash,f.type,f.link)" variant="outline-success" class="curtail-text file-button thick-border" >{{f.type == 2? f.link : f.description}}</b-button>
                  </div>
                </div>
                <div v-if="permissions.getFiles && element.speaker" class="speaker-description">
                  <span class="speaker-name">{{element.speaker.name}}:</span>  
                  <b-button v-if="element.speaker.bio && element.speaker.bio.length> 10" v-on:click.stop="showBio(element.speaker)" variant="outline-success" class="curtail-text file-button thick-border" >{{$t('slides.bio_text')}}</b-button>
                  <b-button v-if="element.speaker.url && element.speaker.url.length>0" v-on:click.stop="goToFileControl(element.speaker.hash, element.speaker.type, element.speaker.url)" variant="outline-success" class="curtail-text file-button thick-border" >{{$t('slides.presentation_text')}}</b-button>
                </div>
              </td>
              <td v-if="permissions.getSlides" :class="{disabledClass : element.disabled}">{{ element.then_action== 1? $t('slides.replay_text')+" "+element.replay_times+"X, "+element.pauseSeconds+" "+$t('slides.secs_slide') : (element.then_action==2? $t('slides.next_slide')+" > "+element.pauseSeconds+" "+$t('slides.secs_text') : (element.then_action==3? $t('slides.wait_controller') : "")) }}</td>
              <td class="section-time">{{element == section.slides[0]? (("0"+Math.floor(section.target_duration/60)).substr(-2))+":"+("0"+(section.target_duration%60)).substr(-2) : ""}} 
              </td>
            </tr>
          </template>
          </draggable>
        </table>
        <div v-if="permissions.access && organization_id == 0 && permissions.getSlides" id="drag-note">{{$t('slides.drag_order')}}</div>
        
      </div>
      <div v-if="agendaFetched && template_region.id>0" id="managed-by-note">
        {{ $t('global.managed_by', {region: template_region.name}) }}
      </div>
      <b-modal id="slide-modal" centered :title="$t('slides.copy_slide_title')" v-model="showCopyModal">
        <span class="form-box-title">{{$t('slides.copy_from')}}</span>
        <div class="form-box copy-selection-box">
          <span v-if="permissions.organization.is_parent || permissions.organization.rst" class="form-box-name">{{$t('slides.organization_label')}}</span><b-form-select v-if="permissions.organization.is_parent || permissions.organization.rst"  v-model="copyOid" :options="copy_organizations" value-field="id" text-field="name" class="copy-select-margin form-box-input" />
          <span class="form-box-name" >{{$t('slides.section_label')}}</span><b-form-select  v-model="copySid" :options="copy_sections" value-field="id" text-field="name" class="copy-select-margin form-box-input" />
          <span class="form-box-name" >{{$t('slides.slide_label')}}</span><b-form-select  v-model="copyId" :options="copy_slides" value-field="id" text-field="name" html-field="name" class="copy-select-margin form-box-input" />
        </div>
        <Tip :tipNumber="4"></Tip>
        <div v-if="showAlert">
          <b-alert variant="warning"
                   dismissible
                   :show="showAlert"
                   @dismissed="showAlert=false">
            {{alertText}}
          </b-alert>
        </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button v-if="showReplace" class="action-button" v-on:click="copySlide(true)" variant="clear-blue"><div class="replace-button"></div><span>{{$t('global.button_replace')}}</span></b-button>
          <b-button class="action-button" v-on:click="copySlide(false)" variant="clear-blue"><div class="copy-button"></div><span>{{$t('global.button_copy')}}</span></b-button>
          <b-button class="action-button" v-on:click="showCopyModal=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
        </div>
      </b-modal>
      <b-modal id="slide-modal" centered :title="$t('slides.copy_section_title')" v-model="showCopyModalSection">
        <span class="form-box-title">{{$t('slides.copy_from')}}</span>
        <div class="form-box copy-selection-box">
          <span v-if="permissions.organization.is_parent" class="form-box-name">{{$t('slides.region_text')}}</span><b-form-select v-if="permissions.organization.is_parent"  v-model="copyOid" :options="copy_organizations" value-field="id" text-field="name" class="copy-select-margin form-box-input" />
          <span v-if="permissions.organization.is_parent" class="form-box-name">{{$t('slides.chapter_optional')}}</span><b-form-select v-if="permissions.organization.is_parent" v-model="copyCid" :options="copy_chapters" value-field="id" text-field="name" class="copy-select-margin form-box-input" />
          <span class="form-box-name" >{{copyCid>0? $t('slides.chapter_section') : $t('slides.regional_section')}}</span><b-form-select  v-model="copySid" :options="copy_sections" value-field="id" text-field="name" class="copy-select-margin form-box-input" />
        </div>
        <div v-if="showAlert">
          <b-alert variant="warning"
                   dismissible
                   :show="showAlert"
                   @dismissed="showAlert=false">
            {{alertText}}
          </b-alert>
        </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button v-if="showReplace" class="action-button" v-on:click="copySection(true)" variant="clear-blue"><div class="replace-button"></div><span>{{$t('global.button_replace')}}</span></b-button>
          <b-button class="action-button" v-on:click="copySection(false)" variant="clear-blue"><div class="copy-button"></div><span>{{$t('global.button_copy')}}</span></b-button>
          <b-button class="action-button" v-on:click="showCopyModalSection=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
        </div> 
      </b-modal>
      <b-modal centered v-model="showBioModal">
        <div slot="modal-header" class="w-100" id="bio-modal-header">
          <b-button id="bio-modal-name" class="agenda-width-button " variant="dark">{{$t('slides.speaker_bio')}}: {{current_speaker.name}}</b-button>
        <b-button  class="auto-margin action-button" v-on:click="showBioModal= false;" variant="clear-blue"><div class="close-button"></div><span>{{$t('global.button_close')}}</span></b-button>
        </div>
        <div id="bio-modal-text">
          <pre class="script-text" v-html="current_speaker.bio"></pre>
        </div>
        <div slot="modal-footer" class="submit-box-2">
      </div>
      </b-modal>
      <div class="div-space"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.section-time{
  text-align:center;
}
.speaker-name{
  grid-column: 1/3
}
.slide-image{
  width:150px;height:auto;
}
.section-files{
  grid-column: 1/6;grid-row: 2;
}
.slide-row{
  line-height:30px;
}
.rank-controls{
  width:23px;display:grid;
}
.section-name{
  max-width:200px;
}
#managed-by-note{
  text-align: center;font-size: 30px;
}
.copy-selection-box{
  grid-template-columns: 25% 25% 50%;
}
.agenda-button-wrapper{
  grid-template-columns: repeat(2,max-content);
}
#top-select-box{
  display: grid;grid-template-columns: repeat(3,auto);
}
.copy-select-margin{
  margin: 5px;
}
#select-slide-note{
  grid-column:1/3; text-align: center;font-size:10px;
}
.slide-icon{
  height: 16px;margin: 7px 5px;
}
.visibility-slide{
  height: 18px;
  width:25px;
   background-size: 100% 100%;
  background-repeat: no-repeat;
  vertical-align: middle;
    margin-right: 5px;
    display:inline-block;
    margin: auto 5px auto 0px;
}
.slide-hidden{
  background-image: url("../assets/icon-SlideHidden.png");
}
.slide-shown{
  background-image: url("../assets/icon-SlideVisible.png");
}
.slide-always-shown{
  background-image: url("../assets/icon-SlideAlwaysVisible.png");
}
.agenda-width-button {
    width: 100%;
    font-size: 14px;
    text-align: left;
  }
.agenda-section-title{
  text-align: center;
    font-size: 25px;
    margin-bottom: 10px;
}
#bio-modal-text{
  height:100%; overflow: auto; font-size:20px;
}
#bio-modal-name{
  margin-right: auto;width: 400px;font-size: 20px;
}
#bio-modal-header{
  display: grid;grid-template-columns: minmax(250px, 90%) max-content max-content;
}
#drag-note{
  float: right;font-size: 10px;
}
.speaker-description{
  display:grid;grid-template-columns:  max-content max-content;
}
.slide-description-column{
  line-height: 30px; display: grid;grid-auto-flow: column;grid-template-columns:repeat(5,max-content);
}
.section-time-column{
  width:100px;text-align: center;margin: auto;
}
#select-options{
  display:grid;grid-template-columns: auto max-content max-content max-content;grid-gap:20px;
}

#section-selector{
  display:grid; grid-template-columns:auto auto; width: 400px;margin:auto;
}

.highlight-wrapper{
    border-radius: 10px;
    width: 200px;
    margin: auto;
    margin-top: 25px;
}

.file-button{
  max-width: 500px;
    margin: 2px;
    height: 30px;
    padding: 0px 10px;
}

  #sectionDownButton {
    background-image: url("../assets/Icon-Dn-dim.png");
    border: none;
    width: 16px;
    height: 14px;
    border: none;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-color: rgba(0,0,0,0);
    padding: 0px;
  }
    #sectionDownButton:hover {
      filter: $VUE_APP_COLOUR_MAIN_FILTER;
    }
    #sectionUpButton {
      background-image: url("../assets/Icon-Up-dim.png");
      border: none;
      width: 16px;
      height: 14px;
      border: none;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-color: rgba(0,0,0,0);
      padding: 0px;
    }
      #sectionUpButton:hover {
       filter: $VUE_APP_COLOUR_MAIN_FILTER;
      }

      #sectionUpButton.active {
        border: none;
      }

    #sectionDownButton.active {
      border: none;
    }

  .lastRow {
    border-bottom: $VUE_APP_COLOUR_MAIN solid 3px;
  }
  .tbody-stripe-even tr:nth-of-type(even) {
    background-color: rgba(0,0,0,.05);
  }
  .tbody-stripe-odd tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,.05);
  }
    .striped-color {
        background-color: rgba(0,0,0,.05);
    }
 
</style>
<script>
  import router from "../router"
  
  
    export default {
    name: "Agenda",
    watch: {
      'permissions.getFiles': {
        handler: function (v) {
          if(v){
            if (this.permissions.sectionFilterId > 0) {
              this.getSectionFiles(this.permissions.sectionFilterId);
            } else {
              this.getAllFiles();
            }
          }
        },
        deep: true
      },
      'permissions.sectionFilterId': {
        handler: function () {
          if (this.permissions.sectionFilterId > 0) {
            this.getSlides();
          } else {
            this.getAllSlides();
          }
        },
        deep: true
      },
      'copyOid': {
        handler: function () {
          this.copyCid = 0;
          this.getChapters();
          this.getCopySections();
        },
        deep: true
      },
      'copyCid': {
        handler: function () {
          this.getCopySections();
        },
        deep: true
      },
      'copySid': {
        handler: function () {
          this.getCopySlides();
        },
        deep: true
      },
      'copyId': {
        handler: function () {
          this.checkCopySlide();
        },
        deep: true
      },
      
      '$i18n.locale': {
        handler: function () {
          var self = this;
          this.organization_id = this.$route.params.id ? this.$route.params.id : 0;
          this.populateVariables(function () {
            self.getSections();
            if (self.permissions.sectionFilterId > 0) {
              self.getSlides();
            } else {
              self.getAllSlides();
            }
          });
        },
        deep: true
      }
    },
      data() {
        return {
          time: 0,
          agendaFetched: false,
          template_region: {id: 0, name: ""},
          copyCid: 0,
          copy_chapters: [],
          showPPTImagesSuccess: false,
          importAsObjects: 1,
          importObject: {pages: "", allPages: 1, file: null},
          showPPTModal: false,
          moving: false,
          tags: [],
          organization_id: 0,
          sections: [],
          section_select: [],
          copy_sections: [],
          copy_slides: [],
          copy_organizations: [],
          copyOid: 0,
          copySid: 0,
          copyId: 0,
          showCopyModal: false,
          from_default: "own",
          copy_options: [{ id: "own", name: this.$t('slides.self_option') }, { id: "default", name: "" }],
          showAlert: false,
          alertText: this.$t('slides.alert_text'),
          showReplace: false,
          totalTime: "",
          showCopyModalSection: false,
          variable_map: [],
          current_speaker: {name: '', bio: ''},
          showBioModal: false,
          previews: [],
          imageTest: "",
          showImportAlert: false,
          fontOptions: new Set(["Arial","Georgia","Times","Verdana","Tahoma","Raleway",'Arial Black']),
        }
    },
    methods: {
      toggleSlide(slide){
        if(!(slide.isParent==0 || slide.child_edit==1)){
          return;
        }
        slide.disabled = 1-slide.disabled;
        this.$http.post("/api/organization/slide/disable", { slide_id: slide.id, disabled: slide.disabled}).then(() => {
        }).catch((errors) => {
          console.log(errors);
        });
      },
      preparePopup(section){
        if(!section.preparer_names){
            this.$http.post("/api/organization/section/preparer/names"+(this.permissions.organization.is_parent? "/roles" : ""), { section_id: section.section_id, id: this.organization_id }).then((response) => {
              if (response.data.success) {
                this.$set(section, "preparer_names", response.data.names.join(","));
              }
            }).catch((errors) => {
              console.log(errors);
            });
        }
        return {
          html: true,
          content: () => {
            return this.$t('slides.section_prepared_by')+':<br/> '+ section.preparer_names+(this.organization_id==0 && this.permissions.organization.is_parent==0? '<br/>'+this.$t('slides.click_to_view') : '')
          }
        }
      },
      goToFiles(section) {
        if (section.preparer_hash && this.organization_id==0 && this.permissions.organization.is_parent==0) {
          this.$http.post("/api/organization/section/gethash", { id: section.id}).then((response) => {
            if (response.data.success) {
              router.push("/admin/prepare/" + response.data.preparer_hash);
            }
          }).catch((errors) => {
            console.log(errors);
          });
        }
      },
      onPopoverShow(slide, section) {
        if (!slide.gotThumbnail) {
          this.$http.post("/api/meeting/slide/get/thumbnail", { slide_id: slide.id, id: this.organization_id }).then((response) => {
            if (response.data.success) {
              this.$set(slide, "thumbnail", response.data.thumbnail);
              this.$set(slide, "gotThumbnail", true);
              if(this.$refs['slide_'+slide.id+'_'+section.section_id+'pop'][0]){
                this.$refs['slide_'+slide.id+'_'+section.section_id+'pop'][0].updateContent();
              }
            }
          }).catch((errors) => {
            console.log(errors);
          });
        }
      },
      newSection() {
        router.push("/admin/section/0");
      },
      checkCopySlide() {
        this.showAlert = false;
        this.showReplace = false;
        if (this.copyId > 0) {
          this.$http.post("/api/organization/slide/copy/check", { id: this.copyId, section_id: this.permissions.sectionFilterId}).then((response) => {
            if (response.data.success && response.data.replace === true) {
              this.showAlert = true;
              this.showReplace = true;
            } 
          }).catch(() => {
          });
        }
      },
      copySlide(replace) {
        if(this.copyId>0){
            var ranks = [];
            var j = 0;
            var lastParent = this.permissions.organization.is_parent? "" : String.fromCharCode(31);
            var toSid = this.permissions.sectionFilterId;
            var sIndex = 0;
            for (var i = 0; i < this.sections.length; i++) {
              if (this.sections[i].section_id == toSid) {
                sIndex = i;
                break;
              }
            }
            for (i = 0; i < this.sections[sIndex].slides.length; i++) {
              if (this.sections[sIndex].slides[i].isParent == 0) {
                ranks.push({ id: this.sections[sIndex].slides[i].id, rank: lastParent + String.fromCharCode(Math.max(Math.min((j + 33), 126), 33)) });
                j++;
              } else {
                lastParent = this.sections[sIndex].slides[i].rank;
                j = 0;
              }
            }
            var rank = lastParent + String.fromCharCode(Math.max(Math.min((j + 33), 126), 33));
            this.$http.post("/api/organization/slide/copy", { id: this.copyId, section_id: this.permissions.sectionFilterId, replace: replace, rank:rank }).then((response) => {
              if (response.data.success === true) {
                this.showCopyModal = false;
                this.getSlides();
              } else {
                this.showCopyModal = false;
              }
            }).catch(() => {
            });
        }
      },
      checkCopySection() {
        this.showAlert = false;
        this.showReplace = false;
        if (this.copySid > 0) {
          this.$http.post("/api/organization/section/copy/check", { id: this.copySid }).then((response) => {
            if (response.data.success && response.data.replace === true) {
              this.showAlert = true;
              this.showReplace = true;
            }
          }).catch(() => {
          });
        }
      },
      copySection(replace) {
        if (this.copySid > 0) {
          var ranks = [];
            var lastParent = this.permissions.organization.is_parent? "" : String.fromCharCode(31);
            var j = 0;
            for (var i = 0; i < this.sections.length; i++) {
              if (this.sections[i].isParent == 0) {
                ranks.push({ id: this.sections[i].section_id, rank: lastParent + String.fromCharCode(Math.max(Math.min((j + 33), 126), 33)) });
                j++;
              } else {
                lastParent = this.sections[i].rank;
                j = 0;
              }
            }
            var rank = lastParent + String.fromCharCode(Math.max(Math.min((j + 33), 126), 33));
          this.$http.post("/api/organization/section/copy", { section_id: this.copySid, replace: replace, rank: rank }).then((response) => {
            if (response.data.success === true) {
              this.showCopyModalSection = false;
              var self = this;
              setTimeout(function () {
                self.getSections();
                self.getAllSlides();
              }, 500);
            } else {
              this.showCopyModalSection = false;
            }
          }).catch(() => {
          });
        }
      },
          getSlides() {
            this.$http.post("/api/organization/slides", { section_id: this.permissions.sectionFilterId, getDisabled: this.permissions.showDisabledSlides, id: this.organization_id }).then((response) => {
              this.sections = [];
              if (response.data.success) {
                if(response.data.template_region){
                  this.template_region = response.data.template_region;
                }else{
                    response.data.section.slides = response.data.slides;
                    this.sections.push(response.data.section);
                    this.populateSlideNameVariables(this.sections[0]);
                    if(this.permissions.getFiles){
                      this.getSectionFiles(this.permissions.sectionFilterId);
                    }
                    this.scrollToSlide();
                  }
                this.agendaFetched = true;
              } 
              }).catch(() => {
              });
          },
          getAllSlides() {
            if (this.permissions.sectionFilterId == 0) {
              this.$http.post("/api/organization/slides/all", { getDisabled: this.permissions.showDisabledSlides, id: this.organization_id }).then((response) => {
                if (response.data.success) {
                  if(response.data.template_region){
                      this.template_region = response.data.template_region;
                    }else{
                      if (response.data.sections) {
                        this.sections = response.data.sections;
                        this.start = response.data.start;
                        this.makeTableRows();
                      } else {
                        this.sections = [];
                      }
                      if(this.permissions.getFiles){
                        this.getAllFiles();
                      }
                      this.scrollToSlide();
                    }
                  this.agendaFetched = true;
                }
              }).catch(() => {
              });
            }
      },
      scrollToSlide(){
        var self = this;
        setTimeout(function(){
          if(self.permissions.agenda_back && self.permissions.agenda_back.length>0){
            var d = document.getElementById(self.permissions.agenda_back);
            self.permissions.agenda_back = "";
            if(d){
              d.scrollIntoView({block: "center"});
            }
          }
        })
      },
      populateVariables(callback) {
        if (this.organization_id == 0) {
          this.$http.post("/api/organization/slide/get/variables", {}).then((response) => {
            if (response.data.success) {
              this.variable_map = response.data.variable_map;
            }
            callback();
          }).catch(() => {
            callback();
          });
        } else {
          callback();
        }
      },
      disabledSlidesChange() {
        //this.permissions.showDisabledSlides = !this.permissions.showDisabledSlides;
        if (this.permissions.sectionFilterId > 0) {
          this.getSlides();
        } else {
          this.getAllSlides();
        }
      },
      getSections() {
        this.$http.post("/api/meeting/sections/select", { id: this.organization_id }).then((response) => {
          if (response.data.success) {
            for(var i =0; i<response.data.sections.length;i++){
                if(!response.data.sections[i].name || response.data.sections[i].name.length==0){
                  response.data.sections[i].name = this.$t('global.no_translation');
                }
            }
            this.section_select = response.data.sections
            this.section_select.unshift({ id: 0, name: this.$t('slides.all_sections')})
          }
        }).catch(() => {
        });
      },
          newSlide() {
            router.push("/admin/slide/"+this.permissions.sectionFilterId+"/0");
          },
          editSlide(item) {
            if (!item.filler && this.organization_id == 0 && item.allow_editing!=2) {
              this.permissions.agenda_back = "slide_"+item.id+"_"+item.section_id;
              router.push("/admin/slide/" + item.section_id + "/" + item.id );
            }
      },
      editSection(item) {
        if (this.organization_id == 0) {
          this.permissions.agenda_back = "section_"+item.section_id;
          router.push("/admin/section/" + item.section_id);
        }
      },
      startDragSlide(event) {
        event.item.style.color = "#ff0000";
        this.moving = true;
      },
          
      endDragSlide(event) {
        this.moving = false;
            var ranks = [];
            var j = 0;
            var lastParent = this.permissions.organization.is_parent? "" : String.fromCharCode(31);
            var movedSid = event.clone.id.split("_").slice(-1)[0];
            var movedId = event.clone.id.split("_").slice(-2)[0];
            event.item.style.color = "";
            var toSid = event.to.children.length > 0 ? event.to.children[0].id.split("_").slice(-1)[0] : 0;
            var sIndex = 0;
            for (var i = 0; i < this.sections.length; i++) {
              if (this.sections[i].section_id == toSid) {
                sIndex = i;
                break;
              }
            }
            if(this.permissions.sectionFilterId==0){
              this.makeTableRows();
            }
            if (movedSid != toSid) {
              this.$http.post("/api/organization/slides/change/section", { section_id: toSid, id: movedId }).then((response) => {
                if (response.data.success) {
                    for (var i = 0; i < this.sections[sIndex].slides.length; i++) {
                      if (this.sections[sIndex].slides[i].isParent == 0) {
                        ranks.push({ id: this.sections[sIndex].slides[i].id, rank: lastParent + String.fromCharCode(Math.max(Math.min((j + 33), 126), 33)) });
                        j++;
                      } else {
                        lastParent = this.sections[sIndex].slides[i].rank;
                        j = 0;
                      }
                    }
                  this.$http.post("/api/organization/slides/rank", { section_id: toSid, ranks: ranks }).then(() => {
                  }).catch(() => {
                  });
                }
              }).catch(() => {
              });
            } else {
              for (i = 0; i < this.sections[sIndex].slides.length; i++) {
                if (this.sections[sIndex].slides[i].isParent == 0) {
                  ranks.push({ id: this.sections[sIndex].slides[i].id, rank: lastParent + String.fromCharCode(Math.max(Math.min((j + 33), 126), 33)) });
                  j++;
                } else {
                  lastParent = this.sections[sIndex].slides[i].rank;
                  j = 0;
                }
              }
              this.$http.post("/api/organization/slides/rank", { section_id: toSid, ranks: ranks }).then(() => {
              }).catch(() => {
              });
            }
          },

          populateSlideNameVariables(section){
            var self = this;
            for (let slide of section.slides) {
              this.variable_map.forEach(function (item) {
                if(item.id == "{Current_Time}"){
                  item.value = self.$d(new Date(), 'time12') ;
                }else if(item.id == "{Current_Date}"){
                  item.value = self.$d(new Date(), 'short') ;
                }
                var reg = new RegExp('(<span data-type="mention" class="mention" data-id="'+item.id+'.*?<\/span>)', 'g');
                if (slide.name) {
                  if(slide.name){
                    slide.name = slide.name.replaceAll(reg, (item.value && item.value.length>0? item.value.replaceAll("$", "$$$$"): ""));
                  }
                }
              });
            }
          },
      makeTableRows() {
        var t = 0;
        for (var i = 0; i < this.sections.length; i++) {
          this.$set(this.sections[i], 'hover', false);
          if (!this.sections[i].disabled) {
            var datetime = new Date(new Date().toDateString() + " " + this.start + " (EST)");
            datetime.setSeconds(datetime.getSeconds() + t);
            this.$set(this.sections[i], 'start_time', this.$d(datetime, 'time12l'));
            if(this.permissions.organization.is_parent && this.sections[i].start_time){
              this.sections[i].start_time = this.sections[i].start_time.slice(0,-2);
            }
            t += this.sections[i].target_duration;
          }
          if (this.sections[i].slides.length == 0) {
            this.sections[i].slides.push({
              id: 0, name: "--", pauseSeconds: 0, then_action: -1, replay_times: -1,
              disabled: false, section_id: 0, filler: true, isParent: this.sections[i].isParent
            });
          }
          if (this.organization_id == 0) {
            this.populateSlideNameVariables(this.sections[i]);
          }
          if (this.sections[i].slides.length == 2) {
            if (this.sections[i].slides[0].id == 0) {
              this.sections[i].slides = [this.sections[i].slides[1]];
            }
            if (this.sections[i].slides.length == 2 && this.sections[i].slides[1].id == 0) {
              this.sections[i].slides = [this.sections[i].slides[0]];
            }
          }
        }
        var h = Math.floor(t / 3600);
        var m = Math.floor((t % 3600) / 60);
        var s = (t % 60);
        this.totalTime = h + ":" + (m < 10 ? "0" : "") + m + ":" + (s < 10 ? "0" : "") + s;
      },
      showCopyPopup() {
        this.showAlert = false;
        this.showReplace = false;
        this.showCopyModal = true;
        this.copySid = 0;
        this.copyId = 0;
       
        if(this.permissions.organization.is_parent || this.permissions.organization.rst){
          this.getRegions();
        }else{
           this.getCopySections();
        }
        this.copy_options = [{ id: "own", name: this.permissions.organization.name }];
        if(!this.permissions.organization.is_parent){
          this.copy_options.push({ id: "default", name: this.permissions.organization.parent_name });
        }else{
          this.copy_options.push({ id: "children", name: this.$t('slides.children_option')  });
        }
      },
      showCopyPopupSection() {
        this.showCopyModalSection = true;
        this.copySid = 0;
        if(this.permissions.organization.is_parent){
          this.getRegions();
        }else{
           this.getCopySections();
        }
        this.copy_options = [{ id: "own", name: this.permissions.organization.name }];
        if(!this.permissions.organization.is_parent){
          this.copy_options.push({ id: "default", name: this.permissions.organization.parent_name });
        }else{
          this.copy_options.push({ id: "children", name: this.$t('slides.children_option')  });
        }
      },
      getCopySlides() {
        if(!this.copySid || this.copySid == 0){
          return;
        }
        var par = {  section_id: this.copySid };
        var url = "/api/meeting/slides/select/bysect";
        if((this.permissions.organization.is_parent || this.permissions.organization.rst) && this.copyOid >0){
          par = { org: this.copyOid, section_id: this.copySid };
          url = "/api/meeting/slides/select/bysectorg";
        }
        this.$http.post(url, par).then((response) => {
          if (response.data.success){
            for(var i =0; i<response.data.slides.length;i++){
                  if(!response.data.slides[i].name || response.data.slides[i].name.length==0){
                    response.data.slides[i].name = this.$t('global.no_translation');
                  }
              }
            this.copy_slides = response.data.slides;
          }
        }).catch(() => {
        });
      },
      getCopySections() {
        var par = { };
        var url = "/api/meeting/sections/select";
        if((this.permissions.organization.is_parent || this.permissions.organization.rst) && this.copyOid >0){
          par = {org : this.copyCid>0? this.copyCid : this.copyOid};
          url = "/api/meeting/sections/select/byorg";
        }
        this.$http.post(url, par).then((response) => {
          if (response.data.success) {
            for(var i =0; i<response.data.sections.length;i++){
                  if(!response.data.sections[i].name || response.data.sections[i].name.length==0){
                    response.data.sections[i].name = this.$t('global.no_translation');
                  }
              }
            this.copy_sections = response.data.sections
            if (this.copy_sections.length > 0) {
              this.copySid = this.copy_sections[0].section_id;
            }
          }
        }).catch(() => {
        });
      },
      sectionUp(section) {
        var changed = false;
        for (var i = 0; i < this.sections.length; i++) {
          if (i != 0 && this.sections[i] == section) {
            var temp = this.sections[i - 1];
            this.sections[i - 1] = section;
            this.sections[i] = temp;
            changed = true;
            break;
          }
        }
        if (changed) {
          this.rankSections();
        }
      },
      sectionDown(section) {
        var changed = false;
        for (var i = 0; i < this.sections.length; i++) {
          if (i != this.sections.length - 1 && this.sections[i] == section) {
            var temp = this.sections[i + 1];
            this.sections[i + 1] = section;
            this.sections[i] = temp;
            changed = true;
            break;
          }
        }
        if (changed) {
          this.rankSections();
        }
      },
      rankSections() {
        var ranks = [];
            var lastParent = this.permissions.organization.is_parent? "" : String.fromCharCode(31);
            var j = 0;
            var t = 0;
            for (var i = 0; i < this.sections.length; i++) {
              if (!this.sections[i].disabled) {
                var datetime = new Date(new Date().toDateString() + " " + this.start + " (EST)");
                datetime.setSeconds(datetime.getSeconds() + t);
                this.$set(this.sections[i], 'start_time', this.$d(datetime, 'time12l'));
                if(this.permissions.organization.is_parent && this.sections[i].start_time){
                  this.sections[i].start_time = this.sections[i].start_time.slice(0,-2);
                }
                t += this.sections[i].target_duration;
              }
              if (this.sections[i].isParent == 0) {
                ranks.push({ id: this.sections[i].section_id, rank: lastParent + String.fromCharCode(Math.max(Math.min((j + 33), 126), 33)) });
                j++;
              } else {
                lastParent = this.sections[i].rank;
                j = 0;
              }
            }

        this.$http.post("/api/organization/sections/rank", { ranks: ranks }).then(() => {
          this.getSections();
        }).catch(() => {
        });
      },
      getSectionFiles(section_id){
        this.$http.post("/api/organization/files/section", {section_id: section_id, id: this.organization_id}).then((response) => {
          if(response.data.success){
            this.setFiles(response.data);
          }
        }).catch((errors) => {
          console.log(errors);
        });
      },
      getAllFiles() {
        this.$http.post("/api/organization/files/all", {id: this.organization_id}).then((response) => {
          if(response.data.success){
            this.setFiles(response.data);
          }
        }).catch((errors) => {
          console.log(errors);
        });
      },
      goToFileControl(hash, type, url){
      if(type == 2){
        url = (url.startsWith("http") ? "" : "http://") + url;
        window.open(url, "_blank");
      }else if (type == 1){
        window.open("/#/filecontrol/"+hash, "_blank");
      }
    },
      getRegions() {
        this.$http.post("/api/regions/get/all", {}).then((response) => {
          if(response.data.success){
            this.copy_organizations = response.data.regions;
            this.$set(this, 'copyOid', this.permissions.organization.id);
          }
        }).catch((errors) => {
          console.log(errors);
        });
      },
      getChapters(){
        this.$http.post("/api/chapters/get/all", {id: this.copyOid}).then((response) => {
          if(response.data.success){
            this.copy_chapters = response.data.children;
          }
        }).catch((errors) => {
          console.log(errors);
        });
      },
      setFiles(d){
        for(var i=0; i<this.sections.length; i++){
          this.$set(this.sections[i], 'files', []);
          for(var y=0; y<d.sectionFiles.length; y++){
            if(this.sections[i].id === d.sectionFiles[y].section_id){
              d.sectionFiles[y].num = this.sections[i].files.length+1;
              this.sections[i].files.push(d.sectionFiles[y]);
            }
          }
          for(var j=0; j<this.sections[i].slides.length; j++){
            this.$set(this.sections[i].slides[j], 'files', []);
            for(var x=0;x< d.slideFiles.length; x++){
              if(this.sections[i].slides[j].id === d.slideFiles[x].slide_id){
                d.slideFiles[x].num = this.sections[i].slides[j].files.length+1;
                this.sections[i].slides[j].files.push(d.slideFiles[x]);
              }
            }
            if(this.sections[i].slides[j].speakerNum>0){
              this.$set(this.sections[i].slides[j], 'speaker', d.speakerFiles[this.sections[i].slides[j].speakerNum-1]);
            }
          }
        }
      },
      openLink(l){
        window.open(l);
      },
      showBio(speaker){
        this.current_speaker = speaker;
        this.showBioModal = true;
      },
    },
    destroyed() {
      
      this.removeClass(document.getElementById("menu-agenda"), "active-menu");
      this.removeClass(document.getElementById("menu-meeting"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-agenda"), "active-menu");
      this.addClass(document.getElementById("menu-meeting"), "active-menu");
      var self = this;
      this.organization_id = this.$route.params.id ? this.$route.params.id : 0;
      this.populateVariables(function () {
        self.getSections();
        if (self.permissions.sectionFilterId > 0) {
          self.getSlides();
        } else {
          self.getAllSlides();
        }
      });
    }
  }
</script>
