<template>
  <div>
    <Header :useruid="this.$route.params.useruid" :title="$t('powerteams.manage_powerteams')" :showDate="true"></Header>
    <div class="rel-grid-container">
      <div ></div>
      <div >
          <table class="table b-table table-hover-new table-bordered table-sm">
          <thead>
            <tr>
              <th class="tableHeader">{{$t('powerteams.powerteam_header')}}</th>
              <th class="tableHeader">{{$t('powerteams.members_header')}}</th>
              <th class="tableHeader center-text">{{$t('powerteams.activity_header')}}</th>
              <th class="tableHeader center-text">{{$t('powerteams.files_header')}}</th>
            </tr>
          </thead>
          <draggable  tag="tbody" v-model="powerteams" item-key="id"  @end="endDragCat" @start="startDragCat">
            <template #item="{element}">
              <tr v-bind:class="{dragndrop: true}" class="clickable" v-on:click="editPowerteam(element)">
                <td>{{ element.title }}</td>
                <td><span v-html="element.assigned"> </span></td>
                <td class="center-text">{{ element.has_activity==0? '' : $t('global.yes_text') }}</td>
                <td class="center-text">{{ element.files && element.files>0? element.files : '' }}</td>
              </tr>
          </template>
          </draggable>
        </table>
        <div id="drag-note">*{{$t('global.drag_order')}}</div>
        <div class="submit-box-2">
          <b-button v-if="permissions.access || useruid.length>0" class="action-button" v-on:click="newPowerteam" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
        </div>
      </div>
      <div class="div-space"></div>
    </div>
    <Footer :useruid="this.$route.params.useruid"></Footer>
  </div>
</template>
<style scoped>
#drag-note{
  float: right;font-size: 10px;
}
</style>
<script>
  import router from "../router"
  import draggable from "vuedraggable";
    export default {
    name: "Powerteams",
    components: {
      draggable
    },
    data() {
      return {
            powerteams: [],
            useruid: '',
            moving: false,
          
      }
    },
    methods: {
        startDragCat(){
        this.moving = true;
      },
      endDragCat() {
        this.moving = false;
        var ranks = [];
        for (var i = 0; i < this.powerteams.length; i++) {
          ranks.push({ id: this.powerteams[i].id, rank: i });
        }
        this.$http.post("/api/organization/powerteams/rank"+(this.useruid==''? "" : "/byuseruid"), { ranks: ranks,useruid: this.useruid }).then(() => {
        }).catch(() => {});
      },
       rowClass(item) {
            if (!item) return
            //if (item.disabled==1) return 'disabledClass'
          },
      getPowerteams() {
        this.$http.post("/api/organization/powerteams"+(this.useruid==''? "" : "/byuseruid"), { useruid: this.useruid}).then((response) => {
          if (response.data.success) {
            this.powerteams = response.data.powerteams;
          }
        }).catch(() => {});
      },
      newPowerteam() {
        if (this.permissions.access || this.useruid.length>0) {
          if(this.useruid==''){
            router.push("/admin/powerteams/0");
          }else{
            router.push("/powerteams/0/"+this.useruid);
          }
        }
      },
      editPowerteam(item) {
        if (this.permissions.access || this.useruid.length>0) {
           if(this.useruid==''){
            router.push("/admin/powerteams/" + item.id);
           }else{
             router.push("/powerteams/"+ item.id+"/"+this.useruid);
           }
        }
      },
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-powerteams"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      if(this.$route.params.useruid){
        this.useruid = this.$route.params.useruid;
      }
      this.addClass(document.getElementById("menu-powerteams"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
      this.getPowerteams();
    }
  }
</script>
