<template>
  <div class="full-height">
    <div id="region-members-page-wrapper" class="rel-grid-container">
      <div ></div>
      <div id="region-members-main-wrapper">
       <div id="role-select" class="fit-width">
         <div class="fit-width grid-fit-2column auto-margin">
          <span class="form-box-name">{{$t('members.show_chapter')}} </span>
          <b-form-select required v-model="permissions.filterRoleId" :options="chapters" value-field="id" text-field="name" class="mb-3 input-vmargins" />
         </div>

          <div id="region-members-filter-box" class="fit-width">
              <span class="form-box-name">{{$t('members.name_header')}}</span>
            <b-form-input v-model="membersFilter.name" type="text" class="medium-input" />
            <span class="form-box-name region-members-filter-input">{{$t('members.company_header')}}</span>
            <b-form-input v-model="membersFilter.category" type="text" class="medium-input" />
            <span class="form-box-name region-members-filter-input">{{$t('members.city_header')}}</span>
            <b-form-input v-model="membersFilter.city" type="text" class="medium-input" />
            <b-button class="action-button" @click="clearFilters()" variant="clear-blue">
            <div class="cancel-button"></div>
            <span>
            {{$t('organizations.clear_filters')}}
            </span>
          </b-button>
          </div>

        </div>
        <b-table class="table-hover-new" id="region-members-table" small bordered :per-page="perPage" v-on:row-clicked="editMember" @filtered="onFiltered" :filter="membersFilter" :filter-function="tableFilter" :current-page="currentPage" :items="members" :fields="fields" :tbody-tr-class="rowClass" >
            <template v-slot:cell(select)="data">
                <b-form-checkbox  class="fit-width auto-margin" v-model="data.item.move">
                </b-form-checkbox>
            </template>
            <template v-slot:cell(roles)="data">
               {{data.item.roles? (data.item.roles + (data.item.sub_role.length>0? ", "+$t('member.sub_role')+": "+data.item.sub_role : '')) : ''}}
            </template>
          </b-table>
          <b-pagination  v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0" ></b-pagination>
      </div>
      <div class="submit-box-2">
        <b-button v-if="members.filter(v => v.move).length>0" class="action-button" v-on:click="showMove" variant="clear-blue"><div class="move-button"></div><span>{{$t('global.button_move')}}</span></b-button>
        <b-button v-if="permissions.filterRoleId!=0" class="action-button" v-on:click="newMember" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
      </div>
      <div class="div-space"></div>
    </div>
    <b-modal centered v-model="showMoveModal" :title="$t('members.move_member')">
          <div class="left-text">
            <span class="form-box-name" >{{$t('region.move_to_chapter')}}:</span><b-form-select  id="chapters-select" v-model="move_chapter" :options="startup_chapters" value-field="id" text-field="name"/>
            <div v-if="move_chapter>0">
              {{isCorporateConnections? $t('members.move_members_confirm'): $t('members.move_confirm')}}:<br/>
              <div v-for="applicant in members.filter(v => v.move)" :key="applicant.id">{{applicant.name}}</div>
            </div>
          </div>
          <div slot="modal-footer" class="fit-content footer-button-style">
            <b-button class="action-button" v-on:click="showMoveModal = false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" v-if="move_chapter>0" v-on:click="moveApplicants" variant="clear-blue"><div class="save-button"></div><span>{{$t('region.button_move')}}</span></b-button>
          </div>
        </b-modal>
    <div></div>
    </div>
</template>
<style scoped>
#chapters-select{
  margin: 5px;
}
#region-members-table{
  margin-bottom:0px;cursor:pointer;
}
.region-members-filter-input{
  margin-left: 20px;
}
#region-members-filter-box{
  display:grid; grid-template-columns: repeat(7,max-content);margin:auto;
}
#region-members-main-wrapper{
  margin-bottom:20px;
}
#region-members-page-wrapper{
  top:20px; display: grid;grid-template-rows: auto max-content auto;height:100%;
}
#preview-members{
   max-height:500px; overflow: auto;
 }
#role-select{
  display:grid; margin:auto;
}
#members-text{
  text-align: center;font-size: 20px;padding: 10px;font-weight:bold;
}
</style>
<script>
  import router from "../router"
    export default {
    name: "RegionMembers",
     props:{
      onboard: Boolean,
    },
    watch: {
      'permissions.filterRoleId': {
        handler: function () {
          this.getMembers();
        },
        deep: true
      },
      '$i18n.locale': {
        handler: function () {
          if(this.roles.length>0){
            this.roles[0].name = this.$t('members.all_chapters');
          }
        },
        deep: true
      },
      'isCorporateConnections': {
        handler: function () {
          this.fields[4].label = this.isCorporateConnections? this.$t('members.company_header') : this.$t('members.category_header'); 
          this.fields[4].key = this.isCorporateConnections? 'company' : 'category'; 
        },
        deep: true
      },
    },
    data() {
      return {
        membersFilter: {name: '', category: '', city: ''},
        perPage: 20,
        currentPage:1,
        totalRows:0,
        selectedMember: null,
        showLoginAs: false,
        members: [],
        showUpload: false,
        showAlert: false,
        saveVariant: "",
        alertText: "",
        showSuccess: false,
        members_num: 0,
        chapters: [],
        fields: [
          { key: 'select',
            label: this.$t('members.move_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'org_name',
            label: this.$t('members.organization_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
          { key: 'name',
            label: this.$t('members.name_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
          { key: 'city',
            label: this.$t('members.city_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'category',
            label: this.$t('members.category_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          }
        ],
        showMoveModal: false,
        startup_chapters: [],
        move_chapter: 0

      }
    },
    methods: {
      moveApplicants(){
        var applicants = this.members.filter(v => v.move);
        this.$http.post("/api/organization/applicants/parent/move", { applicants: applicants, to_chapter: this.move_chapter }).then((response) => {
           this.showMoveModal = false;
            if (response.data.success) {
            var name = "";
            for(var i=0; i<this.startup_chapters.length;i++){
               if(this.startup_chapters[i].id== this.move_chapter){
                name = this.startup_chapters[i].name;
                break;
               }
             }
             for(var i=0; i<this.members.length;i++){
               if(this.members[i].move){
                 this.members[i].move=false;
                 this.members[i].organization = this.move_chapter;
                 this.members[i].org_name = name;
               }
             }

             this.members.sort(function(a, b) {
               if(a.org_name == b.org_name){
                 var textA = a.name.toUpperCase();
                var textB = b.name.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
               }
                var textA = a.org_name.toUpperCase();
                var textB = b.org_name.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
            }
          }).catch((errors) => {
             this.showMoveModal = false;
            console.log(errors);
          });
      },
      showMove(){
       
          this.showMoveModal = true;
      },
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      clearFilters(){
        this.membersFilter= {name: '', category: '', city: ''};
      },
      tableFilter(row, filter){
        /*if(row.organization== filter || filter==0){
          return true;
        }*/
        var cat_comp = this.isCorporateConnections? filter.category.length==0 || row.company.toLocaleLowerCase().includes(filter.category.toLocaleLowerCase()) : filter.category.length==0 || row.category.toLocaleLowerCase().includes(filter.category.toLocaleLowerCase());
        if((filter.name.length==0 || row.name.toLocaleLowerCase().includes(filter.name.toLocaleLowerCase())) &&
          (cat_comp) &&
          (filter.city.length==0 || row.city.toLocaleLowerCase().includes(filter.city.toLocaleLowerCase()))){
          return true;
        }
        return false;
      },
      onPopoverShow(member) {
        if (!member.gotThumbnail) {
          this.$http.post("/api/member/get/photo", { id: member.id }).then((response) => {
            if (response.data.success) {
              this.$set(member, "thumbnail", response.data.photo);
              this.$set(member, "gotThumbnail", true);
            }
          }).catch((errors) => {
            console.log(errors);
          });
        }
      },
      getMembers() {
        this.$http.post("/api/region/children/members",{org_id:this.permissions.filterRoleId }).then((response) => {
          for (var i = 0; i < response.data.members.length; i++) {
              response.data.members.move = false;
            }
            this.members = response.data.members;
            this.totalRows = response.data.members.length;
        }).catch((errors) => {console.log(errors);});
      },
      newMember() {
        if (this.permissions.filterRoleId>0) {
          router.push("/admin/member/0/"+this.permissions.filterRoleId);
        }
      },
      editMember(item) {
        router.push("/admin/member/" + item.id+"/"+item.organization);
      },
      importPage() {
        router.push("/admin/import");
      },
      getChildren() {
        this.$http.post("/api/organization/get/children/select", {}).then((response) => {
          if (response.data.success) {
            this.chapters = response.data.children.filter(function () {
                  return true;
                });;
            this.chapters.unshift({id: 0, name: this.$t('members.all_chapters')});

            
            this.startup_chapters = response.data.children.filter(function () {
                  return true;
                });
            this.startup_chapters.unshift({id: 0, name: this.$t('region.select_chapter')});
          }
        }).catch(() => { });
      },
      rowClass(item) {
          if (!item) return
          if (item.disabled==1) return 'disabledClass';
        },
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-members"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-members"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
      this.getChildren();
      this.getMembers();
      this.fields[4].label = this.isCorporateConnections? this.$t('members.company_header') : this.$t('members.category_header'); 
      this.fields[4].key = this.isCorporateConnections? 'company' : 'category'; 
    }
    }
</script>
