<template>
  <div>
    <div class="rel-grid-container">
      <div ></div>
      <Tip :tipNumber="21"></Tip>
      <div>
        <b-table small bordered :items="emails" :fields="fields">
          <template v-slot:cell(status)="data">
              <div :style="{color: data.item.sent==1? 'green' : 'red'}"><!-- v-b-popover.hover.top="data.item.popup"-->
                {{data.item.sent==1? $t('emails.delivered_text') : $t('emails.failed_text')}}</div>
          </template>
        </b-table>
      </div>
      <div class="submit-box-2">
        <b-button v-if="emailNum>0" class="action-button" v-on:click="previousPage" variant="clear-blue"><div class="back-button"></div><span>{{$t('global.button_previous')}}</span></b-button>
        <b-button v-if="emails.length==50" class="action-button" v-on:click="nextPage" variant="clear-blue"><div class="next-button"></div><span>{{$t('global.button_next')}}</span></b-button>
      </div>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>

</style>
<script>
    export default {
    name: "emails",
    watch: {
      
      '$i18n.locale': {
        handler: function () {
          this.fields[0].label = this.$t('emails.time_header');
          this.fields[1].label = this.isCorporateConnections? this.$t('emails.recipient_header') :  this.$t('emails.email_header');
          this.fields[2].label = this.$t('emails.action_header');
          this.getEmails();
        },
        deep: true
      }
    },
      data() {
        return {
          fields: [
             { key: 'time_occurred',
              label: this.$t('emails.time_header'),
              sortable: true,
              thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff", "width": "200px", "text-align": "center" },
              tdClass: "actions-centre"
            },
             
            { key: 'emails',
              label: this.isCorporateConnections? this.$t('emails.recipient_header') :  this.$t('emails.email_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
             { key: 'action',
              label: this.$t('emails.action_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            },
            /* { key: 'viewed',
              label: this.$t('emails.last_viewed'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            }
            ,*/
           { key: 'status',
              label: this.$t('emails.status_header'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            }
          ],
          bounced: {},
          emails: [],
          emailNum: 0,
          orgId: 0,
          organizations: [],
          email_actions : {1: this.$t('emails.email_action1'), 2: this.$t('emails.email_action2'),
                        3: this.$t('emails.email_action3'), 4: this.$t('emails.email_action4'),
                        5: this.$t('emails.email_action5'), 6: this.$t('emails.email_action6'), 
                        7: this.$t('emails.email_action7'), 8: this.$t('emails.email_action8'), 
                        9: this.$t('emails.email_action9'), 10: this.$t('emails.email_action10'),
                        11: this.$t('emails.email_action11'),12: this.$t('emails.email_action12'),
                        13: this.$t('emails.email_action13'),14: this.$t('emails.email_action14'),
                        15: this.$t('emails.email_action15'),16: this.$t('emails.email_action16')
                        ,17: this.$t('emails.email_action17'),18: this.$t('emails.email_action18')
                        ,19: this.$t('emails.email_action19'),20: this.$t('emails.email_action20')
                        ,21: this.$t('emails.email_action21'),22: this.$t('emails.email_action22')
                        ,23: this.$t('emails.email_action23'),24: this.$t('emails.email_action24'),
                        25: this.$t('emails.email_action25'),26: this.$t('emails.email_action26'),
                        27: this.$t('emails.email_action27'),28: this.$t('emails.email_action28'),
                        29: this.$t('emails.email_action29'),30: this.$t('emails.email_action30')}
        }
      },
    methods: {
      nextPage() {
        this.emailNum += 50;
        this.getEmails();
      },
      previousPage() {
        this.emailNum = Math.max(0, this.emailNum - 50);
        this.getEmails();
      },
      getBlocks() {
        this.bounced = {};
          this.$http.post("/api/organization/get/emails/blocks", {}).then((response) => {
            if (response.data.success) {
              this.bounced = response.data.bounced;
            }
            this.getEmails();
          }).catch((error) => {
            console.log(error);
          });
      },
      getEmails() {
          this.$http.post("/api/organization/get/emails", { emailNum: this.emailNum }).then((response) => {
            if (response.data.success) {
               for (var i = 0; i < response.data.emails.length; i++) {
                  /*if([8,11,16,14].includes(response.data.emails[i].action)){
                    response.data.emails[i].viewed = this.$t('emails.not_tracked');
                  }else if(response.data.emails[i].viewed == null){
                    response.data.emails[i].viewed = "";
                  }*/

                  response.data.emails[i].action = this.email_actions[response.data.emails[i].action];

                  /*if(this.bounced[response.data.emails[i].emails]){
                    var matched = false;
                    for(var g=0; g<this.bounced[response.data.emails[i].emails].length; g++){
                      var bounce = this.bounced[response.data.emails[i].emails][g];
                      var t1 = new Date(response.data.emails[i].utc_time).getTime()/1000;
                      var t2 = new Date(bounce["BouncedAt"]).getTime()/1000;
                      if(Math.abs(t1-t2)<10){
                        response.data.emails[i].status = bounce["Name"];
                        response.data.emails[i].popup = bounce["Description"]+"\n"+bounce["Details"];
                        response.data.emails[i].status_id = bounce["TypeCode"];
                        matched = true;
                        break;
                      }
                    }
                    if(!matched){
                      response.data.emails[i].status = this.$t('emails.delivered_text');
                      response.data.emails[i].popup = "";
                      response.data.emails[i].status_id = 0;
                    }
                  }else{
                    response.data.emails[i].status = this.$t('emails.delivered_text');
                    response.data.emails[i].popup = "";
                    response.data.emails[i].status_id = 0;
                  }*/
                  //var g = this.blocked.filter(v => v.email ==  response.data.emails[i].emails);
                  //response.data.emails[i].status = g.length>0? g[0].status : 0;
              }
              this.emails = response.data.emails;
            }
          }).catch(() => {
          });
      },
      getOrganizations() {
        this.$http.post("/api/organization/get/children", {}).then((response) => {
          this.organizations = response.data.children;
          if (this.organizations.length > 0) {
            this.emailNum = 0;
            this.orgId = 0;
            this.organizations.unshift({ id: 0, name: this.$t('emails.all_chapters') })
          } else {
            this.organizations.unshift({ id: -1, name: this.$t('emails.no_chapters') })
          }
          
        }).catch(() => {
        });
      }
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-emails"), "active-menu");
      this.removeClass(document.getElementById("menu-admin"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-emails"), "active-menu");
      this.addClass(document.getElementById("menu-admin"), "active-menu");
      this.fields[1].label = this.isCorporateConnections? this.$t('emails.recipient_header') :  this.$t('emails.email_header');
      //this.getBlocks();
      this.getEmails();
    }
    }
</script>
