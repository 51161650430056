<template>
  <div id="menu" class="user" > 
    <b-toast id="serverError" variant="danger" solid :title="$t('global.server_error')">
      {{$t('global.server_error_message')}}
    </b-toast>
    <div class="top-home-bar">
      <div id="home-logo" class="fit-content pre-format" >
        <div v-on:click="toggleFullScreen" id="fullscreen-overlay" ><div class="fit-content">{{$t('global.fullscreen')}}</div></div>
      </div>
      <div id="home-general-info">
        <span v-if="windowWidth>1015" id="home-chapter-name" >{{permissions.organization.name}}</span>
        <span v-if="windowWidth>1015" id="home-user-name" class="curtail-text"> {{permissions.user.name}}</span>
      </div>
      <div></div>
        <b-navbar-nav v-if="!isCorporateConnections" id="menu-dropdown" class="ml-auto ">
          <b-nav-item v-show="permissions.organization.id != 1" v-on:click="goToPage(permissions.organization.is_parent? '/admin/regiondash' : '/admin/dashboard' )" right>
            <div id="menu-home" class="menu-button2"><b-img :src="getImgUrl('nav-Home-Gray.png')" /><span v-if="windowWidth>850">{{$t('home.home_text')}}</span></div>
          </b-nav-item>
          <div @mouseover="openDropdown('chapterdropdown')" @mouseleave="closeDropdown('chapterdropdown')">
            <b-nav-item-dropdown id="chapterdropdown" right no-caret v-show="permissions.organization.id != 1">
            <template v-slot:button-content>
              <div id="menu-chapter" class="menu-button2" ><b-img :src="getImgUrl(permissions.organization.is_parent? 'nav-Region-Gray.png':'nav-Chapter-Gray.png')" /><span v-if="windowWidth>850">{{permissions.organization.is_parent? $t('home.region_text') : $t(permissions.organization.rst? 'home.rst_text' : 'home.chapter_text')}}</span></div>
            </template>
            <b-dropdown-item v-on:click="goToPage('/admin/regional')" v-show="permissions.organization.is_parent"><div id="menu-regional" class="menu-button2"><b-img :src="getImgUrl('nav-Settings-Gray.png')" /><span>{{$t('home.settings_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/chapters')" v-show="permissions.organization.is_parent"><div id="menu-chapters" class="menu-button2"><b-img :src="getImgUrl('nav-Chapter-Gray.png')" /><span>{{$t('home.chapters_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/general')" v-show="!permissions.organization.is_parent"><div id="menu-general" class="menu-button2"><b-img :src="getImgUrl('nav-Settings-Gray.png')" /><span>{{$t('home.settings_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/contacts')" v-show="permissions.organization.is_parent || permissions.organization.rst"><div id="menu-contacts" class="menu-button2"><b-img :src="getImgUrl('nav-Members-Gray.png')" /><span>{{$t('home.contacts_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/topics')" v-show="permissions.organization.rst"><div id="menu-topics" class="menu-button2"><b-img :src="getImgUrl('nav-Topics-Gray.png')" /><span>{{$t('home.topics_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/roles')" v-show="permissions.organization.official"><div  id="menu-roles" class="menu-button2"><b-img :src="getImgUrl('nav-Roles-Gray.png')" /><span>{{$t('home.roles_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/members')" v-show="!permissions.organization.is_parent && !permissions.organization.rst"><div id="menu-members" class="menu-button2"><b-img :src="getImgUrl('nav-Members-Gray.png')" /><span>{{permissions.organization.official? $t('home.members_text') : $t('home.applicants_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/recognition')" v-show="!permissions.organization.is_parent && permissions.organization.official"><div id="menu-recognition" class="menu-button2"><b-img :src="getImgUrl('nav-Recognition-Gray.png')" /><span>{{$t('home.recognition_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/goals')" v-show="!permissions.organization.is_parent && permissions.organization.official"><div id="menu-goals" class="menu-button2"><b-img :src="getImgUrl('nav-Goals-Gray.png')" /><span>{{$t('home.goals_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/analytics')" v-show="permissions.organization.is_parent"><div  id="menu-analytics" class="menu-button2"><b-img :src="getImgUrl('nav-Values-Gray.png')" /><span>{{$t('home.analytics_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/categories')" v-show="!permissions.organization.is_parent && !permissions.organization.rst"><div id="menu-categories" class="menu-button2"><b-img :src="getImgUrl('nav-Categories-Gray.png')" /><span>{{$t('home.categories_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/questions')" v-show="!permissions.organization.is_parent && !permissions.organization.official"><div id="menu-questions" class="menu-button2"><b-img :src="getImgUrl('nav-Categories-Gray.png')" /><span>{{$t('home.questions_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/performance')" v-show="!permissions.organization.is_parent && permissions.organization.official"><div id="menu-performance" class="menu-button2"><b-img :src="getImgUrl('nav-Performance-Gray.png')" /><span>{{$t('home.performance_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/events')" v-show="permissions.organization.official"><div  id="menu-events" class="menu-button2"><b-img :src="getImgUrl('nav-Events-Gray.png')" /><span>{{$t('home.events_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/meetingdates')" v-show="!permissions.organization.is_parent && !permissions.organization.official"><div  id="menu-dates" class="menu-button2"><b-img :src="getImgUrl('nav-Events-Gray.png')" /><span>{{$t('home.dates_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/regionmembers')" v-show="permissions.organization.is_parent && !permissions.organization.official && !permissions.organization.rst"><div  id="menu-dates" class="menu-button2"><b-img :src="getImgUrl('nav-Members-Gray.png')" /><span>{{$t('home.applicants_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/powerteams')" v-show="!permissions.organization.is_parent && permissions.organization.official && (permissions.organization.id == 3 || permissions.organization.allow_powerteams==1)"><div  id="menu-powerteams" class="menu-button2"><b-img :src="getImgUrl('nav-PowerTeams-Gray.png')" /><span>{{$t('home.powerteams_text')}}</span></div></b-dropdown-item>
          </b-nav-item-dropdown>
          </div>
          
            <div @mouseover="openDropdown('meetingdropdown')" @mouseleave="closeDropdown('meetingdropdown')">
           <b-nav-item-dropdown id="meetingdropdown"   right no-caret v-show="permissions.organization.id != 1">
            <template v-slot:button-content>
              <div id="menu-meeting" class="menu-button2" ><b-img :src="getImgUrl('nav-Meeting-Gray.png')" /><span v-if="windowWidth>850">{{permissions.organization.is_parent? $t('home.meetings_text') : $t('home.meeting_text')}}</span></div>
            </template>
            <b-dropdown-item v-on:click="goToPage('/admin/agenda')"><div id="menu-agenda" class="menu-button2"><b-img :src="getImgUrl('nav-Agenda-Gray.png')" /><span>{{$t('home.agenda_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/scripts')"><div id="menu-scripts" class="menu-button2"><b-img :src="getImgUrl('nav-Script-Gray.png')" /><span>{{$t('home.script_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/schedule')" v-show="!permissions.organization.is_parent && !permissions.organization.rst"><div id="menu-speakers" class="menu-button2"><b-img :src="getImgUrl('nav-Speakers-Gray.png')" /><span>{{$t('home.speakers_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/attendance')" v-show="!permissions.organization.is_parent"><div id="menu-attendance" class="menu-button2"><b-img :src="getImgUrl('nav-Attendance-Gray.png')" /><span>{{$t('home.attendance_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/corevalues')" v-show="!permissions.organization.is_parent && !permissions.organization.rst"><div id="menu-corevalues" class="menu-button2"><b-img :src="getImgUrl('nav-CoreValue-Gray.png')" /><span>{{$t('home.core_value')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/quotes')" v-show="!permissions.organization.is_parent"><div id="menu-quotes" class="menu-button2"><b-img :src="getImgUrl('nav-Quotes-Gray.png')" /><span>{{$t('home.quote_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item  v-on:click="goToPage('/admin/newsletter')" v-show="!permissions.organization.is_parent || permissions.organization.official" ><div id="menu-newsletter" class="menu-button2"><b-img :src="getImgUrl('nav-Newsletter-Gray.png')" /><span>{{$t('home.newsletter_text')}}</span></div></b-dropdown-item>
          </b-nav-item-dropdown>
            </div>

          <b-nav-item v-show="permissions.organization.id == 1" v-on:click="goToPage('/admin/regions')" right>
            <div id="menu-regions" class="menu-button2"><b-img :src="getImgUrl('nav-Region-Gray.png')" /><span v-if="windowWidth>850">{{$t('home.regions_text')}}</span></div>
          </b-nav-item>
           <div @mouseover="openDropdown('admindropdown')" @mouseleave="closeDropdown('admindropdown')" >
          <b-nav-item-dropdown  id="admindropdown" right no-caret>
            <template v-slot:button-content>
              <div id="menu-admin" class="menu-button2" ><b-img :src="getImgUrl('nav-Admin-Gray.png')" /><span v-if="windowWidth>850">{{$t('home.admin_text')}}</span></div>
            </template>
            <b-dropdown-item v-on:click="goToPage('/admin/analytics')" v-show="permissions.organization.id == 1 && permissions.country"><div  id="menu-analytics" class="menu-button2"><b-img :src="getImgUrl('nav-Values-Gray.png')" /><span>{{$t('home.analytics_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/settings')" v-show="permissions.organization.id == 1 && !permissions.country"><div id="menu-settings" class="menu-button2"><b-img :src="getImgUrl('nav-Settings-Gray.png')" /><span>{{$t('home.settings_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/emaillogs')" v-show="!permissions.organization.is_parent"><div id="menu-emails" class="menu-button2"><b-img :src="getImgUrl('nav-Logs-Gray.png')" /><span>{{$t('home.emails_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/blocked')" v-show="permissions.organization.id != 1"><div id="menu-bounced" class="menu-button2"><b-img :src="getImgUrl('nav-BlockEmails-gray.png')" /><span>{{$t('home.blocked_emails')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/translations')" v-show="permissions.organization.id != 1 && languageOptions.length>1 && permissions.organization.language_admin==1"><div id="menu-translations" class="menu-button2"><b-img :src="getImgUrl('nav-Globe-Gray.png')" /><span>{{$t('home.language_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/logs')" v-show="permissions.organization.is_parent && permissions.organization.id != 1"><div id="menu-userlog" class="menu-button2"><b-img :src="getImgUrl('nav-Logs-Gray.png')" /><span>{{$t('home.userlog_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/countries')" v-show="permissions.organization.id == 1 && !permissions.country"><div id="menu-countries" class="menu-button2"><b-img :src="getImgUrl('nav-Countries-Gray.png')" /><span>{{$t('home.countries_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/languages')" v-show="permissions.organization.id == 1 && !permissions.country"><div id="menu-languages" class="menu-button2"><b-img :src="getImgUrl('nav-Globe-Gray.png')" /><span>{{$t('home.languages_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/tips')" v-show="permissions.organization.id == 1 && !permissions.country"><div id="menu-tips" class="menu-button2"><b-img :src="getImgUrl('nav-Tip-Gray.png')" /><span>{{$t('home.tips_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/onboard-tips')" v-show="permissions.organization.id == 1 && !permissions.country"><div id="menu-onboardtips" class="menu-button2"><b-img :src="getImgUrl('nav-Tip-Gray.png')" /><span>{{$t('home.onboardtips_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/notifications')" v-show="!permissions.organization.is_parent && permissions.organization.official"><div id="menu-notifications" class="menu-button2"><b-img :src="getImgUrl('nav-Emails-Gray.png')" /><span>{{$t('home.notifications_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="resetHelpTips" v-show="permissions.organization.id != 1 && permissions.organization.official"><div id="menu-tips" class="menu-button2"><b-img :src="getImgUrl('nav-Tip-Gray.png')" /><span>{{$t('home.reset_tips')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="resetSetup" v-show="permissions.organization.id != 1 && permissions.organization.onboard==0 && permissions.organization.official"><div id="menu-setup" class="menu-button2"><b-img :src="getImgUrl('nav-Tip-Gray.png')" /><span>{{$t('home.reset_setup')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/contacttypes')" v-show="permissions.organization.id == 1  && !permissions.country"><div  id="menu-contacttypes" class="menu-button2"><b-img :src="getImgUrl('nav-Roles-Gray.png')" /><span>{{$t('home.contacttypes_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/mediatypes')" v-show="permissions.organization.id == 1  && !permissions.country"><div  id="menu-mediatypes" class="menu-button2"><b-img :src="getImgUrl('nav-Admin-Gray.png')" /><span>{{$t('home.mediatypes_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/status')" v-show="permissions.organization.id == 1 && !permissions.country"><div  id="menu-status" class="menu-button2"><b-img :src="getImgUrl('nav-Admin-Gray.png')" /><span>{{$t('home.status_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/regionimport')" v-show="permissions.organization.is_parent && false"><div  id="menu-region-import" class="menu-button2"><b-img :src="getImgUrl('nav-Attendance-Gray.png')" /><span>{{$t('home.region_import_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/adminimport')" v-show="permissions.organization.id == 1 && !permissions.country"><div id="menu-members" class="menu-button2"><b-img :src="getImgUrl('nav-Settings-Gray.png')" /><span>{{$t('home.import_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/slidecopy')" v-show="permissions.organization.id == 1 && !permissions.country"><div id="menu-copyslide" class="menu-button2"><b-img :src="getImgUrl('nav-Deliver.png')" /><span>{{$t('home.copy_slide')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/slide-ops')" v-show="(permissions.organization.id == 1 && !permissions.country) || permissions.organization.is_parent"><div id="menu-copy-ops" class="menu-button2"><b-img :src="getImgUrl('nav-Admin-Gray.png')" /><span>{{$t('home.slide_backgrounds')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/movechapters')" v-show="permissions.organization.id == 1 && !permissions.country"><div id="menu-move" class="menu-button2"><b-img :src="getImgUrl('nav-Move-Gray.png')" /><span>{{$t('home.move_chapter')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/sitetranslations')" v-show="permissions.organization.id == 1 && !permissions.country"><div id="menu-sitetranslations" class="menu-button2"><b-img :src="getImgUrl('nav-Globe-Gray.png')" /><span>{{$t('home.language_admin')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/access')" v-show="permissions.organization.is_parent && permissions.organization.id != 1"><div id="menu-access" class="menu-button2"><b-img :src="getImgUrl('nav-Attendance-Gray.png')" /><span>{{$t('home.access_admin')}}</span></div></b-dropdown-item>
          </b-nav-item-dropdown>
          </div>
        </b-navbar-nav>
        <b-navbar-nav v-if="isCorporateConnections" id="menu-dropdown" class="ml-auto ">
          <b-nav-item v-show="permissions.organization.id != 1" v-on:click="goToPage(permissions.organization.is_parent? '/admin/regiondash' : '/admin/dashboard' )" right>
            <div id="menu-home" class="menu-button2"><b-img :src="getImgUrl('nav-Home-Gray.png')" /><span v-if="windowWidth>850">{{$t('home.home_text')}}</span></div>
          </b-nav-item>
          <div @mouseover="openDropdown('chapterdropdown')" @mouseleave="closeDropdown('chapterdropdown')">
            <b-nav-item-dropdown id="chapterdropdown" right no-caret v-show="permissions.organization.id != 1">
            <template v-slot:button-content>
              <div id="menu-chapter" class="menu-button2" ><b-img :src="getImgUrl(permissions.organization.is_parent? 'nav-Region-Gray.png':'nav-Chapter-Gray.png')" /><span v-if="windowWidth>850">{{permissions.organization.is_parent? $t('home.region_text') : $t(permissions.organization.rst? 'home.rst_text' : 'home.chapter_text')}}</span></div>
            </template>
            <b-dropdown-item v-on:click="goToPage('/admin/regional')" v-show="permissions.organization.is_parent"><div id="menu-regional" class="menu-button2"><b-img :src="getImgUrl('nav-Settings-Gray.png')" /><span>{{$t('home.settings_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/chapters')" v-show="permissions.organization.is_parent"><div id="menu-chapters" class="menu-button2"><b-img :src="getImgUrl('nav-Chapter-Gray.png')" /><span>{{$t('home.chapters_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/contacts')" v-show="permissions.organization.is_parent"><div id="menu-contacts" class="menu-button2"><b-img :src="getImgUrl('nav-Members-Gray.png')" /><span>{{$t('home.contacts_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/general')" v-show="!permissions.organization.is_parent"><div id="menu-general" class="menu-button2"><b-img :src="getImgUrl('nav-Settings-Gray.png')" /><span>{{$t('home.settings_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/members')" v-show="!permissions.organization.is_parent"><div id="menu-members" class="menu-button2"><b-img :src="getImgUrl('nav-Members-Gray.png')" /><span>{{permissions.organization.official? $t('home.members_text') : $t('home.applicants_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/events')" ><div  id="menu-events" class="menu-button2"><b-img :src="getImgUrl('nav-Events-Gray.png')" /><span>{{$t('home.events_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item  v-on:click="goToPage('/admin/newsletter')" ><div id="menu-newsletter" class="menu-button2"><b-img :src="getImgUrl('nav-Newsletter-Gray.png')" /><span>{{$t('home.newsletter_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item  v-on:click="goToPage(permissions.organization.is_parent? '/admin/regionasks' : '/admin/asks')" ><div id="menu-asks" class="menu-button2"><b-img :src="getImgUrl('nav-Topics-Gray.png')" /><span>{{$t('home.asks_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/regionmembers')" v-show="permissions.organization.is_parent"><div  id="menu-dates" class="menu-button2"><b-img :src="getImgUrl('nav-Members-Gray.png')" /><span>{{$t('home.move_members')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/engagement-report')"><div  id="menu-engagement" class="menu-button2"><b-img :src="getImgUrl('nav-Logs-Gray.png')" /><span>{{$t('home.engagement_report')}}</span></div></b-dropdown-item>
          </b-nav-item-dropdown>
          </div>
          
            <div @mouseover="openDropdown('meetingdropdown')" @mouseleave="closeDropdown('meetingdropdown')">
           <b-nav-item-dropdown id="meetingdropdown"   right no-caret v-show="permissions.organization.id != 1">
            <template v-slot:button-content>
              <div id="menu-meeting" class="menu-button2" ><b-img :src="getImgUrl('nav-Meeting-Gray.png')" /><span v-if="windowWidth>850">{{permissions.organization.is_parent? $t('home.meetings_text') : $t('home.meeting_text')}}</span></div>
            </template>
            <b-dropdown-item v-on:click="goToPage('/admin/meetingdates')"  ><div id="menu-dates" class="menu-button2"><b-img :src="getImgUrl('nav-Events-Gray.png')" /><span>{{$t('home.schedule_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/agenda')"><div id="menu-agenda" class="menu-button2"><b-img :src="getImgUrl('nav-Agenda-Gray.png')" /><span>{{$t('home.agenda_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/scripts')" ><div id="menu-scripts" class="menu-button2"><b-img :src="getImgUrl('nav-Script-Gray.png')" /><span>{{$t('home.script_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/roles')" v-show="permissions.organization.is_parent"><div  id="menu-roles" class="menu-button2"><b-img :src="getImgUrl('nav-Roles-Gray.png')" /><span>{{$t('home.roles_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/analytics')" v-show="permissions.organization.is_parent"><div  id="menu-analytics" class="menu-button2"><b-img :src="getImgUrl('nav-Values-Gray.png')" /><span>{{$t('home.analytics_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/schedule')" ><div id="menu-speakers" class="menu-button2"><b-img :src="getImgUrl('nav-Speakers-Gray.png')" /><span>{{$t('home.speakers_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/attendance')" v-show="!permissions.organization.is_parent"><div id="menu-attendance" class="menu-button2"><b-img :src="getImgUrl('nav-Attendance-Gray.png')" /><span>{{$t('home.attendance_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/questions')" v-show="!permissions.organization.is_parent"><div id="menu-questions" class="menu-button2"><b-img :src="getImgUrl('nav-Categories-Gray.png')" /><span>{{$t('home.questions_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/quotes')" ><div id="menu-quotes" class="menu-button2"><b-img :src="getImgUrl('nav-Quotes-Gray.png')" /><span>{{$t('home.quote_text')}}</span></div></b-dropdown-item>
            </b-nav-item-dropdown>
            </div>

          <b-nav-item v-show="permissions.organization.id == 1" v-on:click="goToPage('/admin/regions')" right>
            <div id="menu-regions" class="menu-button2"><b-img :src="getImgUrl('nav-Region-Gray.png')" /><span v-if="windowWidth>850">{{$t('home.regions_text')}}</span></div>
          </b-nav-item>
           <div @mouseover="openDropdown('admindropdown')" @mouseleave="closeDropdown('admindropdown')" >
          <b-nav-item-dropdown  id="admindropdown" right no-caret>
            <template v-slot:button-content>
              <div id="menu-admin" class="menu-button2" ><b-img :src="getImgUrl('nav-Admin-Gray.png')" /><span v-if="windowWidth>850">{{$t('home.admin_text')}}</span></div>
            </template>
            <b-dropdown-item v-on:click="goToPage('/admin/analytics')" v-show="permissions.organization.id == 1 && permissions.country"><div  id="menu-analytics" class="menu-button2"><b-img :src="getImgUrl('nav-Values-Gray.png')" /><span>{{$t('home.analytics_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/settings')" v-show="permissions.organization.id == 1 && !permissions.country"><div id="menu-settings" class="menu-button2"><b-img :src="getImgUrl('nav-Settings-Gray.png')" /><span>{{$t('home.settings_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/emaillogs')" v-show="!permissions.organization.is_parent"><div id="menu-emails" class="menu-button2"><b-img :src="getImgUrl('nav-Logs-Gray.png')" /><span>{{$t('home.message_log')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/blocked')" v-show="permissions.organization.id != 1"><div id="menu-bounced" class="menu-button2"><b-img :src="getImgUrl('nav-BlockEmails-gray.png')" /><span>{{$t('home.blocked_emails')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/translations')" v-show="permissions.organization.id != 1 && languageOptions.length>1 && (permissions.organization.is_parent || permissions.organization.language_admin==1)"><div id="menu-translations" class="menu-button2"><b-img :src="getImgUrl('nav-Globe-Gray.png')" /><span>{{$t('home.language_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/logs')" v-show="permissions.organization.is_parent && permissions.organization.id != 1"><div id="menu-userlog" class="menu-button2"><b-img :src="getImgUrl('nav-Logs-Gray.png')" /><span>{{$t('home.userlog_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/countries')" v-show="permissions.organization.id == 1 && !permissions.country"><div id="menu-countries" class="menu-button2"><b-img :src="getImgUrl('nav-Countries-Gray.png')" /><span>{{$t('home.countries_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/languages')" v-show="permissions.organization.id == 1 && !permissions.country"><div id="menu-languages" class="menu-button2"><b-img :src="getImgUrl('nav-Globe-Gray.png')" /><span>{{$t('home.languages_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/tips')" v-show="permissions.organization.id == 1 && !permissions.country"><div id="menu-tips" class="menu-button2"><b-img :src="getImgUrl('nav-Tip-Gray.png')" /><span>{{$t('home.tips_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/onboard-tips')" v-show="permissions.organization.id == 1 && !permissions.country"><div id="menu-onboardtips" class="menu-button2"><b-img :src="getImgUrl('nav-Tip-Gray.png')" /><span>{{$t('home.onboardtips_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/notifications')" v-show="!permissions.organization.is_parent && permissions.organization.official"><div id="menu-notifications" class="menu-button2"><b-img :src="getImgUrl('nav-Emails-Gray.png')" /><span>{{$t('home.notifications_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="resetHelpTips" v-show="permissions.organization.id != 1 && permissions.organization.official"><div id="menu-tips" class="menu-button2"><b-img :src="getImgUrl('nav-Tip-Gray.png')" /><span>{{$t('home.reset_tips')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="resetSetup" v-show="permissions.organization.id != 1 && permissions.organization.onboard==0 && permissions.organization.official"><div id="menu-setup" class="menu-button2"><b-img :src="getImgUrl('nav-Tip-Gray.png')" /><span>{{$t('home.reset_setup')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/contacttypes')" v-show="permissions.organization.id == 1  && !permissions.country"><div  id="menu-contacttypes" class="menu-button2"><b-img :src="getImgUrl('nav-Roles-Gray.png')" /><span>{{$t('home.contacttypes_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/mediatypes')" v-show="permissions.organization.id == 1  && !permissions.country"><div  id="menu-mediatypes" class="menu-button2"><b-img :src="getImgUrl('nav-Admin-Gray.png')" /><span>{{$t('home.mediatypes_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/status')" v-show="permissions.organization.id == 1 && !permissions.country"><div  id="menu-status" class="menu-button2"><b-img :src="getImgUrl('nav-Admin-Gray.png')" /><span>{{$t('home.status_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/regionimport')" v-show="permissions.organization.is_parent && false"><div  id="menu-region-import" class="menu-button2"><b-img :src="getImgUrl('nav-Attendance-Gray.png')" /><span>{{$t('home.region_import_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/adminimport')" v-show="permissions.organization.id == 1 && !permissions.country"><div id="menu-members" class="menu-button2"><b-img :src="getImgUrl('nav-Settings-Gray.png')" /><span>{{$t('home.import_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/slidecopy')" v-show="permissions.organization.id == 1 && !permissions.country"><div id="menu-copyslide" class="menu-button2"><b-img :src="getImgUrl('nav-Deliver.png')" /><span>{{$t('home.copy_slide')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/regional-slidecopy')" v-show="permissions.organization.is_parent && permissions.organization.id != 1"><div id="menu-copyslide" class="menu-button2"><b-img :src="getImgUrl('nav-Deliver.png')" /><span>{{$t('home.copy_slide')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/movechapters')" v-show="permissions.organization.id == 1 && !permissions.country"><div id="menu-move" class="menu-button2"><b-img :src="getImgUrl('nav-Move-Gray.png')" /><span>{{$t('home.move_chapter')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/sitetranslations')" v-show="permissions.organization.id == 1 && !permissions.country"><div id="menu-sitetranslations" class="menu-button2"><b-img :src="getImgUrl('nav-Globe-Gray.png')" /><span>{{$t('home.language_text')}}</span></div></b-dropdown-item>
            <b-dropdown-item v-on:click="goToPage('/admin/assistants')" v-show="!permissions.organization.is_parent && permissions.admin==1"><div id="menu-assistants" class="menu-button2"><b-img :src="getImgUrl('nav-Topics-Gray.png')" /><span>{{$t('home.assistants_text')}}</span></div></b-dropdown-item>
          </b-nav-item-dropdown>
          </div>
        </b-navbar-nav>
      <div id="home-header-buttons">
        <div class="clickable image-colour-shift" id="portal-option-button" v-on:click="showHelp"><b-img class="home-button-iamge" :src="getImgUrl('icon-Help.png')" /></div>
        <div class="clickable" v-if="languageOptions.length>1 && (allowOptions || permissions.organization.id==1)"  id="portal-option-button" v-on:click="showOptions=true;"><b-img class="home-button-iamge" :src="getImgUrl('icon-NavSettings-Gray.png')" /></div>
        <div class="clickable third-column"  id="portal-exit-button" v-on:click="logout"><b-img :src="getImgUrl('nav-Close-Gray.png')" /></div>
      </div>
    </div>
    <b-modal centered v-model="showOptions" :title="$t('controller.options_text')">
            <div class="center-text">
              <div class="option-title">{{$t('controller.language_text')}}</div>
              <div  class="fit-content header-language-options">
                <b-button v-for="language in languageOptions" :key="language.id"  :class="{'action-button' : true, 'active-flag': $i18n.locale == language.abbrev}" class="language-flag"  v-on:click="loadLanguageAsync(language.abbrev)" variant="clear-blue" ><img :src="getImgUrl('flags/'+language.flag+'.png')" v-bind:alt="language.flag+' flag'"></b-button>
              </div>
            </div>
            <div slot="modal-footer" class="fit-content footer-button-style">
              <b-button class="action-button" v-on:click="showOptions = false;" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
            </div>
          </b-modal>
          <b-modal :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true" centered v-model="showLoginstatusModal" :title="$t(loggedOut? 'home.logged_out' : 'home.user_changed')">
            <div class="left-text" v-html="$t(loggedOut? 'home.logged_out_text' : 'home.user_changed_text')">
            </div>
            <div slot="modal-footer" class="fit-content footer-button-style">
              <b-button class="action-button" v-on:click="reroute" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
            </div>
          </b-modal>
    <router-view class="admin-view"></router-view>
  </div>
</template>   
<style scoped lang="scss">
.admin-view{
  overflow-x: auto;
  height: calc(100% - 50px);
}
#home-general-info{
  display:grid;grid-template-rows: auto auto;text-align: left;
}
#home-user-name{
  color:#000;font-size:14px;padding-left: 10px;white-space: nowrap;    line-height: 20px;
}

.home-button-iamge{
  height:25px;
}
#home-header-buttons{
  display:grid; grid-template-columns:repeat(3,auto);margin-left:20px;
}
 #home-exit-button:hover img{
    filter: $VUE_APP_COLOUR_MAIN_FILTER;
}
#logged-text{
  padding: 0px;
    min-width: 200px;
    padding-top: 15px;
}
#log-text{
  color: #aaa;font-size: 12px;margin-top:6px;line-height:12px;
}
  #menu {
    font-family: Avenir,Helvetica,Arial,sans-serif;
  }
  .nav-link{
    height:100%;
  }
  .menu-text {
    position: relative;
    top: 42px;
    font-size: 7px;
    text-align: center;
    font-family: Arial,monospace;
    color: #acacac;
  }
  .menu-button {
    width: 64px;
    height:61px;
    background-size: 60px ;
    background-repeat: no-repeat;
    cursor: pointer;
    background-position-x: 2px;
  }
  .menu-button:hover .menu-text{
    color: #000000;
  }
    .menu-button .nav-link {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .menu-button2{
      display: grid;
      grid-template-columns: auto auto;
      grid-column-gap: 7px;
      max-height: 25px;
    }

    .menu-button2 >span{
      color: $VUE_APP_MENU_TEXT;
      font-weight: bold;
      line-height: 30px;
    }

    #menu-dropdown{
      display: grid;
    grid-auto-flow: column;
    margin:3px;
    }
  
 
</style>
<script>  
    import router from "../router"    
    export default {    
    name: "Home",
    data() {
      return {
        pages: [],
        totalLanguages: [],
        languageOptions: [],
        windowWidth: 1000,
    showOptions: false,
    allowOptions: false,
    
    showLoginstatusModal: false,
    loggedOut: false,
      }
    },
    methods: {
      showHelp(){
        window.open(this.permissions.help_url? this.permissions.help_url : "https://help.bniplus.com/", "_blank", 'location=no,status=no,titlebar=no,toolbar=no,menubar=no');
    },
      
      
      resetHelpTips(){
        this.setCookie("tips", 0);
        this.$bvToast.toast(this.$t('home.tips_reset_message'), {
          title: this.$t('home.tips_reset'),
          variant: 'success',
          solid: true
        });
      },
      resetSetup(){
        this.$http.post("/api/organization/onboard/update", {onboard: 1}).then((response) => {
          if (response.data.success === true) {
            this.permissions.organization.onboard = 1;
            this.$bvToast.toast(this.$t('home.setup_reset_message'), {
              title: this.$t('home.setup_reset'),
              variant: 'success',
              solid: true
            });
          }
        }).catch((errors) => {
          console.log(errors)
        });
      },
      selectLanguage(language){
        this.selectedLanguage = language;
        this.loadLanguageAsync(language.abbrev);
      },
      getLanguages(){
        this.$http.post("/api/organization/languages", {}).then((response) => {
          if (response.data.success === true) {
            this.languageOptions = [];
            var languageFound = false;
            for(var i=0; i<response.data.languages.length; i++){
              if(this.$i18n && this.$i18n.locale == response.data.languages[i].abbrev){
                languageFound = true;
              }
                this.languageOptions.push(response.data.languages[i]);
            }
            if(!languageFound && response.data.languages.length>0){
              this.loadLanguageAsync(response.data.languages[0].abbrev);
            }
          }
        }).catch((errors) => {
          console.log(errors)
        });
      },
      logout() {
        for(var i=0; i<this.pages.length;i++){
          if(this.pages[i]){
            this.pages[i].close();
          }
        }
        this.closeDropdowns();
        this.signout();
      },
      openDropdown(id){
        var el = document.getElementById(id).getElementsByClassName("dropdown-menu")[0];
        if(el){
          this.addClass(el, 'show');
        }
        
      },
      closeDropdown(id){
        var el = document.getElementById(id).getElementsByClassName("dropdown-menu")[0];
        if(el){
          this.removeClass(el, 'show');
        }
      },
      closeDropdowns(){
        var els = document.getElementsByClassName("dropdown-menu")
        for(var i =0; i<els.length; i++){
          this.removeClass(els[i], "show");
        }
      },
      goToPage(relpath){
          this.closeDropdowns();
          router.push(relpath);
      },
      onResize() {
        if(document.getElementById("menu")){
          this.windowWidth=document.getElementById("menu").parentElement.offsetWidth;
        }
      },
      reroute(){
        this.showLoginstatusModal= false;
        if(this.loggedOut){
          router.push("/login");
        }else if(this.permissions.organization.id == 1){
          router.push("/admin/regions");
        }else if(this.permissions.organization.is_parent){
          router.push("/admin/regiondash");
        }else {
          router.push("/admin/dashboard");
        }
      }
    },
    created(){
      window.addEventListener("resize", this.onResize);
      this.$root.$on('allowOptions', (allow) => {
        this.allowOptions = allow;
      });
    },
    destroyed() {
      window.removeEventListener("resize", this.onResize);
    },
    mounted() {
      if (this.detectIE() >= 0) {
        router.push("/login");
        return;
      }
      
      var self = this;
      window.addEventListener("focus", function(event) {
        var id = self.permissions.user.id;
        self.genericAuthenticate("/api/auth", "", function(){
          if(id != self.permissions.user.id && id!=0 && self.permissions.user.id != 0){
            self.loggedOut = false;
            self.showLoginstatusModal = true;
          }
        },
        function(){
          self.loggedOut = true;
          self.showLoginstatusModal = true;
        });
      }, false);
      this.onResize();
      this.authenticate();
      this.getLanguages();
      this.$root.$on('orgChange', (name) => {
        this.orgName = name;
        this.permissions.organization.name = name;
        if(this.$t){
          document.title = this.$t('global.document_title_admin') +" "+ this.permissions.organization.name;
        }
      });
      this.$root.$on('userChange', (name) => {
        this.getLanguages();
      });
      this.$root.$on('newPage', (page) => {
        this.pages.push(page);
      });
    }    
  }
</script>
