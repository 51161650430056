<template>
  <div>
    <div>
      <b-form-radio-group id="email-radio-options" class="fit-width auto-margin" v-if="permissions.organization.id!=1" v-model="email.to_leadership" :options="sendOptions">
        </b-form-radio-group>
        <div id="email-info-form-box" class="form-box grid-fitfirst-2column">
            <span class="form-box-name">{{$t('sendemail.subject_text')}}</span>
            <b-form-input v-model="email.subject" type="text" class="email-input" maxlength="255" />
            <span class="form-box-name">{{$t('sendemail.name_text')}}</span>
            <b-form-input v-model="email.name" type="text" class="email-input" maxlength="255" />
            <span class="form-box-name">{{$t('sendemail.email_text')}}</span>
            <b-form-input v-model="email.email" type="text" class="email-input" maxlength="255" />
            <span class="form-box-name column-span2 auto-margin">{{$t('sendemail.body_text')}}</span>
            <base-editor v-model="email.body" />
        </div>
    </div>
    <b-alert variant="danger" dismissible :show="showAlert" @dismissed="showAlert=false">
        {{alertText}}
    </b-alert>
    <div class="submit-box-2">
        <b-button class="action-button" v-on:click="clearEmail" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_clear')}}</span></b-button>
        <b-button class="action-button"  v-on:click="showSendConfirm=true;" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_send')}}</span></b-button>
    </div>
    <b-table id="emails-table" :per-page="perPage" :current-page="currentPage" v-if="sentEmails.length>0" class="table-hover-new" small bordered :items="sentEmails" :fields="fields" v-on:row-clicked="showEmail">
        <template v-slot:cell(date)="data" >
            {{$d(new Date(data.item.sent), 'short')}}
        </template>
    </b-table>
    <b-pagination v-if="sentEmails.length>perPage" id="emails-pagination" v-model="currentPage" :total-rows="sentEmails.length" :per-page="perPage" align="fill" size="sm" class="my-0" ></b-pagination>
    <b-modal centered v-model="showEmailModal" :title="preview.subject" >
        <div>
          <div>{{$t('sendemail.to_text')}}: {{preview.to_leadership==0? $t('sendemail.regional_contacts') : $t('sendemail.chapter_leadership')}}</div>
          <div>{{$t('sendemail.name_text')}}: {{preview.name}}</div>
          <div>{{$t('sendemail.email_text')}}: {{preview.reply}}</div>
        <div v-html="preview.body"></div>
        </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="action-button"  v-on:click="showEmailModal=false;" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
        </div>
      </b-modal>
    <b-modal centered v-model="showSendConfirm" :title="$t('sendemail.confirm_send')" >
        <div >
            {{permissions.organization.id==1 || email.to_leadership==1? $t('sendemail.confirm_message') : $t('sendemail.confirm_contacts_message')}}
        </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="action-button" v-on:click="showSendConfirm = false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="action-button"  v-on:click="sendEmail" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_send')}}</span></b-button>
        </div>
      </b-modal>
      <div class="div-space"></div>
  </div>
</template>
<style scoped>
#email-radio-options{
  margin-top: 20px;
}
#email-info-form-box{
  width: 75%; max-width:800px;margin: auto;margin-top: 50px;
}
#email-input{
  width:300px;
}
#emails-pagination{
  max-width:800px;margin:auto;
}
#emails-table{
  width: 75%; max-width:800px;margin: auto;margin-top: 50px; cursor:pointer;
}
</style>
<script>
export default {
    name: "SendEmail",
    watch: {
      '$i18n.locale': {
        handler: function () {
         this.sendOptions= [{value: 0, text: this.$t('sendemail.regional_contacts')},{value: 1, text: this.$t('sendemail.chapter_leadership')}];
         this.fields= [
            {
              key: 'date',
              label: this.$t('sendemail.date_text'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
            { key: 'subject',
              label: this.$t('sendemail.subject_text'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            }
          ];
        },
        deep: true
      }
    },
      data() {
        return {
          sendOptions: [{value: 0, text: this.$t('sendemail.regional_contacts')},{value: 1, text: this.$t('sendemail.chapter_leadership')}],
            showEmailModal: false,
            preview: {subject: "", body: ""},
            showAlert: false,
            alertText: "",
          showSendConfirm: false,
          email: {subject: "", body: "", file: null, filename: "", email: "", name: "", to_leadership: 0},
            sentEmails: [],
            fields: [
            {
              key: 'date',
              label: this.$t('sendemail.date_text'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
            { key: 'subject',
              label: this.$t('sendemail.subject_text'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            }
          ],
         perPage: 20,
        currentPage:1,
        totalRows:0,
        }
      },
    methods: {
        showEmail(item) {
            this.preview = item;
            this.showEmailModal = true;
      },
        clearEmail(){
            this.email = {subject: "", body: "", file: null, filename: "", email: "", name: "", to_leadership: 0};
        },
        getSentEmails(){
            this.$http.post("/api/superadmin/sentemails/get", {}).then((response) => {
                if (response.data.success === true) {
                    this.sentEmails = response.data.sentemails;
                }
            }).catch(() => { });
        },
        sendEmail(){
            this.showAlert = false;
            if(this.email.subject.length == 0 || this.email.body.length<8){
                this.alertText = this.$t('sendemail.enter_fields');
                this.showAlert = true;
            }
            let formData = new FormData();
            formData.append("file", this.email.file);
            formData.append("subject", this.email.subject);
             formData.append("to_leadership", this.email.to_leadership);
            formData.append("name", this.email.name);
            formData.append("email", this.email.email);
            formData.append("body", this.email.body);
            this.$http.post("/api/superadmin/sendemail", formData, {
                headers: {"Content-Type": "multipart/form-data"}
            }).then((response) => {
               if(response.data.success){
                   this.showSendConfirm = false;
                   this.getSentEmails();
                   this.clearEmail();
                   this.$bvToast.toast(this.$t('sendemail.email_sent'), {
                    variant: 'success',
                    solid: true
                    });
               }
            }).catch(() => {
            });
        },
        openDialog() {
            document.getElementById('fileid').click();
          },
        setFile(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length || !this.checkFileSize(files[0]))
            return;
            this.email.file = files[0];
            this.email.filename = files[0].name;
      },
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-sendemail"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-sendemail"), "active-menu");
      this.getSentEmails();
    }
}
</script>
