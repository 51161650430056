<template>
    <div>
      <div class="rel-grid-container">
        <div ></div>
        <div>
          <table class="table b-table table-hover-new table-bordered table-sm">
            <thead>
              <tr>
                <th class="tableHeader">{{$t('contacttype.english_name')}}</th>
                <th class="tableHeader">{{$t('contacttype.name_text')}}</th>
              </tr>
            </thead>
            <draggable tag="tbody" v-model="mediatypes" item-key="id" @end="endDrag">
              <template #item="{element}">
                <tr class="dragndrop clickable"  v-on:click="editMediatype(element)">
                  <td >{{ element.name_en }}</td>
                  <td>{{ element.name }}</td>
                </tr>
              </template>
            </draggable>
          </table>
            <div id="drag-note">{{$t('contacttype.drag_order')}}</div>
        </div>
        <div class="submit-box-2">
          <b-button v-if="permissions.access" class="action-button" v-on:click="newMediatype" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
        </div>
        <div class="div-space"></div>
      </div>
      <b-modal centered v-model="showTypeEdit" :title="selectedType.id>0? $t('contacttype.type_edit') :$t('contacttype.type_new') " >
          <div id="media-type-info-wrapper">
            <span class="form-box-name">{{$t('contacttype.name_text')}}</span><b-form-input v-model="selectedType.name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input medium-width-input"></b-form-input>
           <b-form-checkbox class="media-type-checbox" v-model="selectedType.chapter_usable" >
              {{$t('contacttype.chapter_usable')}}
            </b-form-checkbox>
            <b-form-checkbox class="media-type-checbox" v-model="selectedType.region_usable">
              {{$t('contacttype.region_usable')}}
            </b-form-checkbox>
            <ImageSelector id="media-type-image-selector" ref="imageSelector" :imageObject="selectedType" :maxWidth="500" :maxHeight="500"/>
          </div>
          <div slot="modal-footer" class="submit-box-2">
              <b-button class="action-button" v-on:click="deleteType" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
            <b-button class="action-button" v-on:click="showTypeEdit = false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button"  v-on:click="updateType" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
          </div>
        </b-modal>
    </div>
  </template>
<style scoped>
#media-type-info-wrapper{
  display:grid;
}
.media-type-checbox{
  grid-column:2/4;
}
  #media-type-image-selector{
  grid-column: 1/3;margin-left: 50px;margin-top: 20px;
  }
</style>
  <script>
      export default {
        name: "MediaTypes",
        data() {
          return {
            mediatypes: [],
            selectedType: {id: 0, name: "", image: "", photo: "", chapter_usable: false, region_usable: false},
            showTypeEdit: false
          }
        },
        watch: {
        '$i18n.locale': {
          handler: function () {
            this.getMediaTypes();
          }
        }
      },
      methods: {
       getMediaTypes(){
          this.$http.post("/api/media/types/get/edit", {}).then((response) => {
            for(var type of response.data.types){
              type.chapter_usable = type.chapter_usable==1;
              type.region_usable = type.region_usable==1;
            }
            this.mediatypes = response.data.types;
            
          }).catch(() => {
          });
        },
        deleteType(){
          this.$http.post("/api/media/types/delete", {id: this.selectedType.id}).then((response) => {
              this.showTypeEdit = false;
              if(response.data.success){
                  this.getMediaTypes();
              }
              
          }).catch(() => {
              this.showTypeEdit = false;
          });
        },
        updateType(){
          this.$http.post("/api/media/types/update", this.selectedType).then((response) => {
              this.showTypeEdit = false;
             if(response.data.success){
                 
                  this.getMediaTypes();
              }
          }).catch(() => {
              this.showTypeEdit = false;
          });
        },
        newMediatype() {
            this.selectedType = {id: 0, name: "", image: "", photo: "", chapter_usable: false, region_usable: false};
          this.showTypeEdit = true;
        },
        editMediatype(item) {
          this.selectedType = JSON.parse(JSON.stringify(item));
          this.selectedType.photo = this.selectedType.image;
          this.showTypeEdit = true;
        },
         endDrag() {
          var ranks = [];
          for (var i = 0; i < this.mediatypes.length; i++) {
            ranks.push({ id: this.mediatypes[i].id, rank: i });
          }
          this.$http.post("/api/media/types/rank", { ranks: ranks}).then(() => {}).catch(() => {});
        },
      },
      destroyed() {
        this.removeClass(document.getElementById("menu-mediatypes"), "active-menu");
        this.removeClass(document.getElementById("menu-admin"), "active-menu");
      },
      mounted() {
        this.addClass(document.getElementById("menu-mediatypes"), "active-menu");
        this.addClass(document.getElementById("menu-admin"), "active-menu");
        this.getMediaTypes();
      }
      }
  </script>
  