<template>
    <div>
      <div class="rel-grid-container">
        <div ></div>
        
        <div >
          <Tip :tipNumber="28"></Tip>
            <b-form-checkbox v-on:change="getCoreValueSpeakers" id="previous-speakers-checkbox" v-model="permissions.previousSpeakers">
              {{$t('corevalue.show_previous')}}
            </b-form-checkbox>
        <b-table class="table-hover-new" small bordered :items="speakers" :fields="fields" v-on:row-clicked="editCoreValueSpeaker">
            <template v-slot:cell(core_value_id)="data" >
              {{ coreOptions[data.item.core_value_id] }}
            </template>
          </b-table>
          <div id="new-button-wrapper" class="fit-width">
            <b-button class="action-button" v-on:click="newCoreValueSpeaker" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
          </div>
        </div> 
        <div class="div-space"></div>
      </div>
    </div>
  </template>
  <style scoped>
  #previous-speakers-checkbox{
    float: right;
    }
    #new-button-wrapper{
  margin: 5px auto;
}
  </style>
  <script>
import router from "../router"
export default {
    name: "CoreValueSpeakers",
    data() {
    return {
        speakers: [],
        fields: [
            {
              key: 'member_name',
              label: this.$t('corevalue.name_header'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
            { key: 'core_value_id',
              label: this.$t('corevalue.core_value'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            },
             { key: 'day',
              label: this.$t('corevalue.date_header'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff", "text-align":"center" },
              tdClass: "actions-centre"
            },
          ],
          coreOptions:["",this.$t('newsletter.core_option_1'),this.$t('newsletter.core_option_2'),
          this.$t('newsletter.core_option_3'),this.$t('newsletter.core_option_4'),
          this.$t('newsletter.core_option_5'),this.$t('newsletter.core_option_6'),
          this.$t('newsletter.core_option_7'),this.$t('newsletter.core_option_8'),
          this.$t('newsletter.core_option_9'),this.$t('newsletter.core_option_10'),
          this.$t('newsletter.core_option_11'),this.$t('newsletter.core_option_12'),
            ],
    }
    },
    methods: {
        getCoreValueSpeakers() {
            this.$http.post("/api/organization/corevaluespeakers", {previousSpeakers: this.permissions.previousSpeakers}).then((response) => {
            if (response.data.success) {
                this.speakers = response.data.speakers;
            }
            }).catch((error) => {
            console.log(JSON.stringify(error));
            });
        },
        newCoreValueSpeaker() {
            router.push("/admin/corevalues/0/");
        },
        editCoreValueSpeaker(item) {
            router.push("/admin/corevalues/" + item.id+"/");
        },
    },
    destroyed() {
        this.removeClass(document.getElementById("menu-corevalues"), "active-menu");
        this.removeClass(document.getElementById("menu-meeting"), "active-menu");
    },
    mounted() {
        this.addClass(document.getElementById("menu-corevalues"), "active-menu");
        this.addClass(document.getElementById("menu-meeting"), "active-menu");
        this.getCoreValueSpeakers();
    }
}
  </script>
  