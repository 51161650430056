<template>
    <div>
      <div class="rel-grid-container">
        <div ></div>
        <div >
            <b-table class="table-hover-new" small bordered :items="alerts" :fields="fields">
                <template v-slot:cell(action)="data">
                    <b-button variant="blue" v-on:click="runAction(data.item)">Run Action</b-button>
                    <b-button variant="blue" v-on:click="hideAlert(data.item)">Mark Completed</b-button>
                </template>
            </b-table>
            <b-button variant="blue" v-on:click="selectJSONFile">SYNC with json</b-button>
            <b-form-file ref="file-input" id="jsonfile" accept=".json" @change="onFileChangeJSON($event)" plain hidden></b-form-file>
        </div> 
        <div class="div-space"></div>
      </div>
    </div>
  </template>
  <style scoped>
    
  </style>
  <script>
      export default {
      name: "Sync",
      data() {
        return {
            fields: [
            {
              key: 'type',
              label: this.$t('analytics.analytics_item'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
            { key: 'name',
              label: this.$t('analytics.value_text'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            },
             { key: 'action',
              label: this.$t('analytics.delete_header'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff", "text-align":"center" }
            },
          ],
           alerts: []
        }
      },
      methods: {
        hideAlert(alert){
            this.$http.post("/api/hidealert", {alert: alert}).then((response) => {
                if (response.data.success) {
                    this.alerts = this.alerts.filter(v => v.id != alert.id);
                }
            }).catch(() => {
            });
        },
        runAction(alert){
            this.$http.post("/api/runalert", {alert: alert}).then((response) => {
                if (response.data.success) {
                    this.$bvToast.toast(this.$t('global.exceeds_maxsize', {max_size: '100MB'}), {
                        title: "Action completed",
                        variant: 'sucess',
                        solid: true
                    });
                    this.alerts = this.alerts.filter(v => v.id != alert.id);
                }
            }).catch(() => {
            });
        },
        getAlerts(){
            this.$http.post("/api/getalerts", {}).then((response) => {
                if (response.data.success) {
                    this.alerts = response.data.alerts;
                }
            }).catch(() => {
            });
        },
        getMemberSyncs(){
            this.$http.post("/api/membersyncs/get", {}).then((response) => {
                if (response.data.success) {
                    this.syncs = response.data.syncs;
                }
            }).catch(() => {
            });
        },
        getChapterSyncs(){
            this.$http.post("/api/chaptersyncs/get", {}).then((response) => {
                if (response.data.success) {
                    this.syncs = response.data.syncs;
                }
            }).catch(() => {
            });
        },
        selectJSONFile(){
            document.getElementById('jsonfile').click();
        },
        onFileChangeJSON(e){
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length || !this.checkFileSize(files[0]))
                return;
            this.syncFromJSON(files[0]);
        },
        syncFromJSON(file){
            this.$refs['file-input'].reset();
            let formData = new FormData();
            formData.append("file", file);
            this.$http.post("/api/sync/json", formData, {headers: {"Content-Type": "multipart/form-data"}}).then((response) => {
                if (response.data.success == true) {
                    this.$bvToast.toast("Success", {
                        variant: 'success',
                        solid: true
                    });
                } 
            }).catch((errors) => {
                console.log(errors)
            });
        }
      },
      destroyed() {
        this.removeClass(document.getElementById("menu-status"), "active-menu");
        this.removeClass(document.getElementById("menu-admin"), "active-menu");
      },
      mounted() {
        this.addClass(document.getElementById("menu-status"), "active-menu");
        this.addClass(document.getElementById("menu-admin"), "active-menu");
        this.getAlerts();
      }
      }
  </script>
  