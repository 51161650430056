<template>
  <div>
    <div>{{$d(current_date, 'day_text')}}</div>
    <div>
      <div ></div>
      <div id="bios-box">
        <br />
        <table class="table b-table table-bordered table-sm">
          <tbody>
            <tr v-for="speaker in speakers" :key="speaker.num">
              <td><div>{{$t('bios.speaker_text')}} #{{speaker.num}}: <b>{{speaker.name}}</b></div>
                  <pre class="script-text" v-html="speaker.bio"></pre>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<style scoped>
#bios-box{
  width: 50%; margin: auto;
}
</style>
<script>
  export default {
  name: "Bios",
    data() {
      return {
        speakers: [],
        day: null,
        org: 0,
        current_date: new Date() 
      }
    },
      methods: {
        getSpeakers() {
          if (this.$route.params.day && this.$route.params.org) {
            this.day = this.$route.params.day;
            this.org = this.$route.params.org;
            this.current_date = new Date("" + this.day.split("-").join("/")+" 00:01");
            this.$http.post("/api/organization/speakers/day", { date: this.day, org: this.org, getImages: false }).then((response) => {
              if (response.data.success) {
                this.speakers = response.data.speakers;
              }
            }).catch((errors) => {
              console.log(errors);
            });
          } 
        },
    },
    mounted() {
      this.getSpeakers();
    }
    }
</script>
