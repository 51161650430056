<template>
    <div>
      <div class="rel-grid-container">
        <div >
            <span class="form-box-title form-box-title-fix">{{$t('slide.backgrounds_library')}}</span>
            <div  class="form-box" id="backgrounds-form-box">
              <div id="backgrounds-wrapper">
                <div v-for="background in backgrounds" :key="background.id" v-on:click="editBackground(background.id)" class="clickable slide-background">
                    <img class="slide-background-image" :src="background.photo_small" />
                </div>
              </div>
                <b-button v-on:click="newBackground" class="action-button auto-margin" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
            </div>
        </div> 
        <div v-if="permissions.organization.id != 1">
            <span class="form-box-title form-box-title-fix">{{$t('slide.backgrounds_library')}}</span>
            <div  class="form-box" id="slide-ops-form-box">
              <div id="slide-ops-filter-box" class="fit-width">
                <span class="form-box-name" >{{$t('slide.slide_header')}}</span>
                <b-form-input  v-model="slidesFilter.slide" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')"></b-form-input>
                <span id="section-filter-name" class="form-box-name">{{$t('slide.section_header')}}</span>
                <b-form-input  v-model="slidesFilter.section" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')"></b-form-input>
                <b-button  v-on:click="clearFilters" class="action-button" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_clear')}}</span></b-button>
            </div>
            <b-table class="table-hover-new" small bordered :items="slides" :fields="fields" :per-page="perPage" @filtered="onFiltered" :filter="slidesFilter" :filter-function="tableFilter" :current-page="currentPage" >
                <template v-slot:cell(selected)="data">
                    <b-form-checkbox  v-model="data.item.selected" class="auto-margin">
                    </b-form-checkbox>
                </template>
            </b-table>
            <b-pagination v-show="totalRows>perPage"  v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0" ></b-pagination>
            <div class="submit-box-2">
                <multiselect id="background-select" v-model="backgroundOpId" :options="selectBackgrounds" class="blueselect" :noOptionsText="$t('global.empty_list')" openDirection="top">
                  <template v-slot:singlelabel="{ value }">
                    <div class="multiselect-single-label">
                      <img :src="value.photo_small" class="background-select-image" />
                    </div>
                  </template>
                    <template v-slot:option="{ option }">
                      <img :src="option.photo_small" class="background-select-image"  />
                    </template>
                </multiselect>
                    
                <b-button id="ops-update-button" v-on:click="setBackgroundsBatch" :disabled="!(slides.filter(v => v.selected).length>0 && backgroundOpId>0)" class="action-button" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_update')}}</span></b-button>
            </div>
            </div>
            </div>
        <div class="div-space"></div>
      </div>
      <b-modal id="slide-modal" centered  v-model="showBackgroundEdit" :title="$t('global.set_image')">
          <div>
            <ImageSelector ref="backgroundSelector" :imageObject="background" :maxWidth="1920" :maxHeight="1080" :ratio="16.0/9.0" :embedded="true" />
            <b-form-checkbox  v-model="background.disabled" id="ops-slide-disabled">
              {{$t('slide.disabled_background')}}
            </b-form-checkbox>
            <div v-if="background.id>0 && background.num>0" id="update-warning-note">{{$t('slide.background_in_use', {num: background.num})}}</div>
            <div v-if="background.id>0 && background.num>0" id="update-warning-note">{{$t('slide.background_update_warning')}}</div>
          </div>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="deleteBackground" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
            <b-button class="action-button" v-on:click="showBackgroundEdit=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" v-on:click="cropAndSave" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
          </div>
        </b-modal>
    </div>
  </template>
  
  <style scoped>
  #section-filter-name{
    margin-left:20px;
  }
  .slide-background-image{
    width:100%;height:auto;border:1px lightgray solid;
  }
  .slide-background{
    width:200px;margin:5px;
  }
  #backgrounds-wrapper{
    display:flex; flex-wrap: wrap; width:100%;
  }
  .form-box-title-fix{
    top:13px;
  }
  #backgrounds-form-box{
    grid-template-columns: auto;width: 80%;margin:auto;min-width:600px;grid-gap:10px;
  }
  #slide-ops-form-box{
    grid-template-columns: auto;width: 80%;margin:auto;grid-gap:10px;min-width:600px;
  }
  #slide-ops-filter-box{
    display:grid; grid-template-columns: repeat(5,max-content); margin: auto;margin-bottom:20px;
  }
  #ops-update-button{
    margin: auto 10px;
  }
  .select-image{
    width:50px;height:50px;
  }
  .background-select-image{
    height:100px;width:auto;border:1px lightgray solid;
  }
  #ops-slide-disabled{
    margin:auto; margin-top:20px;
  }
  #update-warning-note{
    color:red;margin-top:30px;font-size:20px;
  }
  #background-select{
    width:300px;height:150px;
  }
  </style>
  <script>
    export default {
      name: "SlideOps",
      data() {
      return {
            slidesFilter: {section: "", slide: ""},
            perPage: 20,
            currentPage:1,
            totalRows:0,
            backgroundOpId: 0,
            showBackgroundEdit: false,
            backgrounds: [],
            background: {id: 0, photo: "", photo_small: "", num: 0},
            selectBackgrounds: [],
            slides: [],
            fields: [{
                key: 'selected',
                label: this.$t('slide.select_header'),
                thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff", "text-align": "center"},
                tdClass: "actions-centre"
              },
              { key: 'section',
                label: this.$t('slide.section_header'),
                thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" , "text-align": "center"},
                tdClass: "actions-centre"
              },
               { key: 'slide',
                label: this.$t('slide.slide_header'),
                thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" , "text-align": "center"},
                tdClass: "actions-centre"
              },
            ],
      }
      },
      methods: {
        onFiltered(filteredItems) {
          this.totalRows = filteredItems.length;
          this.currentPage = 1;
        },
        clearFilters(){
          this.slidesFilter= {section: "", slide: ""};
        },
        tableFilter(row, filter){
          if(((filter.section.length==0 || row.section.toLocaleLowerCase().includes(filter.section.toLocaleLowerCase())) && (filter.slide.length==0 || row.slide.toLocaleLowerCase().includes(filter.slide.toLocaleLowerCase()))) || row.selected){
            return true;
          }
          return false;
        },
        newBackground(){
            this.background = {id: 0, photo: "", photo_small: "", disabled: false};
            this.showBackgroundEdit = true;
        },
          editBackground(id) {
              this.$http.post("/api/organization/photos/get", {id: id}).then((response) => {
                  if (response.data.success) {
                    response.data.background.disabled = response.data.background.disabled==1;
                    this.background = response.data.background;
                    this.showBackgroundEdit = true;
                  }
              }).catch(() => {});
          },
          cropAndSave(){
            this.$refs.backgroundSelector.cropImage(this.saveBackground);
          },
          saveBackground(){
            this.$http.post("/api/organization/photos/update", this.background).then((response) => {
                  if (response.data.success) {
                    this.getBackgrounds();
                    this.getOwnBackgrounds();
                    this.background = {id: 0, phtoo: "", photo_small: "", disabled: false};
                    this.showBackgroundEdit = false;
                  }
              }).catch(() => {});
          },
          deleteBackground(){
            this.$http.post("/api/organization/photos/delete", {id : this.background.id}).then((response) => {
                  if (response.data.success) {
                    this.backgrounds = this.backgrounds.filter(b => b.id != this.background.id);
                    this.getBackgrounds();
                    this.background = {id: 0, phtoo: "", photo_small: "", disabled: false};
                    this.showBackgroundEdit = false;
                  }
              }).catch(() => {});
          },
          getOwnBackgrounds() {
              this.$http.post("/api/organization/photos/own", {}).then((response) => {
                  if (response.data.success) {
                      this.backgrounds = response.data.backgrounds;
                  }
              }).catch(() => {});
          },
          getBackgrounds() {
              this.$http.post("/api/organization/photos", {}).then((response) => {
                  if (response.data.success) {
                    for(let background of response.data.backgrounds){
                        background.html = "<div class='select-image'><img src='"+background.photo_small+"' /></div>";
                        background.text = background.photo_small;
                        background.value = background.id;
                    }
                      this.selectBackgrounds = response.data.backgrounds;
                  }
              }).catch(() => {});
          },
          getSlides() {
              this.$http.post("/api/meeting/slides/select/own", {}).then((response) => {
                  if (response.data.success) {
                      this.slides = response.data.slides;
                      this.totalRows = this.slides.length;
                  }
              }).catch(() => {});
          },
          setBackgroundsBatch() {
                var slide_ids = this.slides.filter(v => v.selected).map(s => s.id);
                if(slide_ids.length>0 && this.backgroundOpId>0){
                    this.$http.post("/api/organization/slides/batch-backgrounds", {slide_ids: slide_ids, photo_id: this.backgroundOpId}).then((response) => {
                        if (response.data.success) {
                          this.clearFilters();
                            this.$bvToast.toast(this.$t('slide.background_updated'), {
                            variant: 'success',
                            solid: true
                            });
                            for(let slide of this.slides){
                                slide.selected = false;
                            }
                        }
                    }).catch(() => {});
                }
          },
      },
      destroyed() {
        this.unsetMenu("admin","slide-ops");
      },
      mounted() {
        this.setMenu("admin","slide-ops");
        this.getSlides();
        this.getBackgrounds();
        this.getOwnBackgrounds();
      }
    }
  </script>
  