<template>
  <div>
    <div class="rel-grid-container" >
      <Tip :tipNumber="25"></Tip>
      <div id="top-div">
        <div>
          <b-button v-show="multipleMembers" :disabled="!loaded" v-on:click="updateMember(previousMember)" id="previous-member-button" class="action-button fit-width" variant="clear-blue"><div class="back-button"></div><span>{{$t('global.button_previous')}}</span></b-button>
        </div>
        <div>
          <b-button v-show="multipleMembers" :disabled="!loaded" v-on:click="updateMember(nextMember)" id="next-member-button" class="action-button fit-width" variant="clear-blue"><div class="next-button"></div><span>{{$t('global.button_next')}}</span></b-button>
        </div>
      </div>
      <form v-on:submit.prevent="checkAndUpdateMember">
        <div id="big-box">
          <div>
            <div>
              <span class="form-box-title">{{is_parent? $t('member.contact_info') : (official? $t('member.member_info') : $t('member.applicant_info'))}}</span>
              <div class="form-box grid-fit-2column">
                <span id="member-name" class="form-box-name">{{$t('member.name_text')}}</span><b-form-input v-model="member.name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input medium-width-input" required></b-form-input>
                <b-form-checkbox class="second-column" v-model="member.disabled" value="1" unchecked-value="0">
                  {{$t('member.disabled_text')}}
                </b-form-checkbox>
                <span v-if="!is_parent" class="form-box-name first-column">{{ $t('member.category_text')}}</span><b-form-input v-if="!is_parent" v-model="member.category" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input>
                <span v-if="!is_parent && !official" class="form-box-name first-column">{{ $t('member.city_text')}}</span><b-form-input v-if="!is_parent && !official" v-model="member.city" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input>
                <span v-if="is_parent" class="form-box-name first-column">{{ $t('member.contact_type')}}</span>
                <multiselect v-if="is_parent" v-model="member.contact_types" :options="typeOptions" label="name" track-by="name" class="blueselect contact-select" :noOptionsText="$t('global.empty_list')"  mode="tags" valueProp="id" :close-on-select="false" :searchable="true">
                </multiselect>
                <span class="form-box-name" s>{{$t('member.company_text')}}</span><b-form-input v-model="member.company" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input>
                <span class="form-box-name" v-if="isCorporateConnections">{{$t('member.job_title')}}</span><b-form-input v-if="isCorporateConnections" v-model="member.job_title" type="text" class="form-box-input"></b-form-input>
                <span class="form-box-name">{{$t('member.phone_text')}}</span>
                <b-form-input v-model="member.phone" type="tel" class="form-box-input medium-width-input"></b-form-input>
                <span class="form-box-name" v-if="isCorporateConnections">{{$t('member.mobile_phone')}}</span>
                <b-form-input v-if="isCorporateConnections" v-model="member.mobile_phone" type="tel" class="form-box-input medium-width-input"></b-form-input>
                <span class="form-box-name">{{$t('member.email_text')}}</span><b-form-input v-model="member.email" type="email" class="form-box-input" name="whateveremail" autocomplete="new-email"></b-form-input>
                <!--<span class="form-box-name first-column" v-if="isCorporateConnections">{{$t('member.preferred_contact')}}</span>
                <b-form-select v-if="isCorporateConnections" class="form-box-input medium-width-input"  v-model="member.preferred_contact" :options="contactMethods"/>-->
                <span class="form-box-name">{{$t('member.website_text')}}</span><b-form-input v-model="member.website" type="text" class="form-box-input" maxlength="255"></b-form-input>
                <span class="form-box-name">{{$t('member.preferred_language')}}</span>
                <b-form-select class="form-box-input" v-model="member.preferred_language" :options="languageOptions" value-field="abbrev" text-field="name"/>
                <span class="form-box-name" v-if="isCorporateConnections">{{$t('member.linkedin_url')}}</span><b-form-input v-if="isCorporateConnections" v-model="member.linkedin_url" type="text" class="form-box-input"></b-form-input>
                <span class="form-box-name" v-if="isCorporateConnections">{{$t('member.member_presence')}}</span>
                <multiselect v-if="isCorporateConnections" v-model="presence_types" :options="presenceTypeOptions" label="text" track-by="text" class="blueselect contact-select" :noOptionsText="$t('global.empty_list')"  mode="tags" valueProp="value" :close-on-select="false" :searchable="true">
                </multiselect>
                <span class="form-box-name" v-if="official">{{$t('member.member_since')}}</span>
                <div v-if="official" class="grid-fit-2column" id="member-since"><b-form-select  v-model="member.since_year" :options="yearOptions"  /><b-form-select  v-model="member.since_month" :options="monthOptions" /></div>
                <span class="form-box-name first-column" v-if="isCorporateConnections">{{$t('member.sponsor_text')}}</span>
                <b-form-select v-if="isCorporateConnections" class="form-box-input medium-width-input"  v-model="member.sponsoring_member_id" :options="allMembers" value-field="id" text-field="name" />
                 <span v-if="!is_parent && use_taglines" class="form-box-name first-column">{{$t('memberphoto.tag_line')}}</span>
                <div v-if="!is_parent && use_taglines" class="form-box-input">
                    <b-form-textarea v-model="member.tagline" :rows="2" :maxlength="tagline_chars"  />
                    <div class="page-note" :style="{'color': member.tagline.length>=tagline_chars? 'red' : ''}">{{tag_count}}</div>
                </div>
                <span v-if="!is_parent && official" class="form-box-name  first-column">{{$t('memberphoto.weekly_ask')}}</span>
                <div v-if="!is_parent && official" class="form-box-input">
                    <b-form-textarea v-model="member.ask" :rows="2" maxlength="120"  />
                    <div class="page-note" :style="{'color': member.ask.length>=120? 'red' : ''}">{{char_count}}</div>
                </div>
                <span v-if="isCorporateConnections" class="form-box-name  first-column">{{$t('member.member_introduction')}}</span>
                <b-form-textarea v-if="isCorporateConnections" v-model="member.introduction" :rows="2" maxlength="255" class="form-box-input" />
              </div>
            </div>
            <div v-if="member.roles.length>0 && !is_parent && official" >
              <span class="form-box-title">{{$t('member.role_info')}}</span>
              <div class="form-box grid-fit-2column">
                <span class="form-box-name">{{$t('member.sub_role')}}</span><b-form-input v-model="member.sub_role" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input medium-width-input"></b-form-input>
                <span class="form-box-name">{{$t('member.sub_description')}}</span><b-form-textarea v-model="member.role_description" id="sub-role-description" placeholder="" class="form-box-input" :rows="2" :max-rows="2" />
              </div>
            </div>
            <div v-if="official || (allowPhotos==1 || is_parent)">
              <span class="form-box-title">{{is_parent? $t('member.contact_media') :$t('member.member_photo')}}</span>
              <div class="form-box grid-auto-1column">
                <div id="member-photo-box">
                  <span class="form-box-name auto-margin" >{{$t('member.photo_text')}}</span><ImageSelector class="member-image-select" ref="imageSelector" :imageObject="member" :ratio="isCorporateConnections? 1 : 0.6157" :maxWidth="665" :maxHeight="1080" :clearButton="true" :minText="true" :circular="true"/>
                  <span class="form-box-name auto-margin" >{{$t('member.logo_text')}}</span><ImageSelector v-if="allowLogos" class="member-image-select" ref="imageSelector" :imageObject="member" :imageField="'logo'" :maxWidth="500" :maxHeight="500" :smallImageField="'logo'" :clearButton="true" :minText="true"/>
                  <b-button v-if="member.id>0 && originalEmail.length>0" id="photo-request-button" class="action-button" v-on:click="requestPhotoByEmail()" variant="blue">{{$t('member.request_photo')}}</b-button>
                </div>
              </div>
            </div>
          </div>
          <div id="in-box-2" v-if="official">
            <div v-if="!is_parent">
              <span class="form-box-title">{{$t('member.member_roles')}}</span>
              <div id="member-roles-form-box" class="form-box">
                <b-form-checkbox-group stacked v-model="member.roles" :options="roles" value-field="id" text-field="name">
                </b-form-checkbox-group>
              </div>
            </div>
            <div v-if="allow_logins"> 
              <span class="form-box-title">{{$t('member.app_permissions')}}</span>
              <div class="form-box grid-auto-2column">
                <div id="member-access">
                <b-form-checkbox  v-model="member.access" value="1" unchecked-value="0" :disabled="leadership">
                  {{$t('member.access_permission')}}
                </b-form-checkbox>
              </div>
                <span v-if="leadership" id="leadership-note">{{$t('member.leadership_role')}}</span>
                <span v-if="permissions.admin==1" id="member-has-pass">{{member.has_pass? $t('member.pass_set') : $t('member.no_pass')}}</span>
                <b-button v-if="permissions.admin==1" class="action-button auto-margin" v-on:click="showSetPass=true;"  variant="clear-blue"><div class="login-button"></div><span>{{$t('member.set_pass')}}</span></b-button>
                <b-button v-if="permissions.admin==1 && originalEmail.length>0" id="send-reset-button" class="action-button" v-on:click="sendReset" variant="clear-blue"><div class="email-button"></div><span>{{$t('member.send_reset')}}</span></b-button>
              </div>
            </div>
          </div>

          <div id="member-alert">
            <b-alert variant="danger"
                     dismissible
                     :show="showRoleAlert"
                     @dismissed="showRoleAlert=false">
              {{alertText}}
            </b-alert>
          </div>
          <div id="member-buttons" class="submit-box-2">
            <b-button v-if="member.id>0" class="action-button" v-on:click="showDelete=true;" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
            <b-button class="action-button" v-on:click="back" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button :disabled="!loaded" class="action-button" type="submit" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
          </div>
          <b-modal id="modal-center" v-model="showDelete" centered :title="$t('member.delete_title')">
            <p class="my-4">{{$t('member.delete_text')}}</p>
            <div slot="modal-footer" class="submit-box-2">
              <b-button class="action-button" v-on:click="showDelete=false" :disabled="deleting" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
              <b-button class="action-button" v-on:click="deleteMember" :disabled="deleting" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
            </div>
          </b-modal>
          <b-modal id="modal-center" v-model="showLeadershipRemoved" centered>
            <div slot="modal-header">
            </div>
            <p class="my-4">{{$t('member.leadership_removed1')}}</p>
            <p class="my-4">{{$t('member.leadership_removed2')}}</p>
            <div slot="modal-footer" class="submit-box-2">
              <b-button class="action-button" v-on:click="showLeadershipRemoved=false;" variant="clear-blue"><span>{{$t('member.keep_access')}}</span></b-button>
              <b-button class="action-button" v-on:click="member.access= 0; showLeadershipRemoved=false;" variant="clear-blue"><span>{{$t('member.remove_access')}}</span></b-button>
            </div>
          </b-modal>
          <b-modal id="modal-center" v-model="showAccessGranted" centered>
            <div slot="modal-header">
            </div>
            <p class="my-4">{{is_parent? $t('member.contact_granted') : $t('member.access_granted')}}</p>
            <div slot="modal-footer" class="submit-box-2">
              <b-button class="action-button" v-on:click="showAccessGranted=false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
              <b-button class="action-button" v-on:click="sendAccessEmail=true;initAccess=true;showAccessGranted=false;updateMember();" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
            </div>
          </b-modal>
          <b-modal id="modal-center" :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true" v-model="showSetPass" centered :title="$t('member.set_pass_title')" >
            <div class="grid-auto-2column">
                <span class="form-box-name">{{$tc('member.password_text',(permissions.admin && member.has_pass==1)? 1 : 0)}}</span>
                <b-form-input v-model="member.password" type="password" :state="member.password.length==0? null : (member.password.length>=8)" class="form-box-input" name="whatever" autocomplete="new-password"></b-form-input>
                <span class="form-box-name" >{{$t('member.password_retype')}}</span>
                <b-form-input v-model="member.retype" :state="member.password.length==0? null : (member.password===member.retype && member.retype.length>0)" type="password" class="form-box-input" name="whatevera" autocomplete="new-password-again"></b-form-input>
                <span class="password-note">*{{$t('global.password_requirement')}}</span>
                <span class="password-note">*{{$t('member.set_pass_note')}}</span>
            </div>
            <b-alert class="fit-width auto-margin"  variant="danger" dismissible :show="passAlert" @dismissed="passAlert=false">
              {{alertPassText}}
            </b-alert>
            <div slot="modal-footer" class="submit-box-2">
              <b-button class="action-button" v-on:click="member.password='';member.retype='';showSetPass=false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
              <b-button class="action-button" v-on:click="checkPasses" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
            </div>
          </b-modal>
        </div>
      </form>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
#member-buttons{
  margin-top:30px;
}
#member-alert{
  grid-column: 1; grid-column-end: 3;
}
#leadership-note{
  font-size: 12px;text-align:center;color:#555;margin-top:5px;grid-column:1/3;
}
#member-access{
  color: black;grid-column:1/3;
}
#member-roles-form-box{
  grid-template-columns: auto;height: auto;
}
#photo-request-button{
  margin:  15px auto;grid-column: 1/3;
}
.member-image-select{
  margin-left:10px
}
#sub-role-description{
  margin-bottom: 10px;
}
#member-has-pass{
  font-size: 12px;text-align:center;margin:auto;
}
#send-reset-button{
  margin:auto;grid-column: 1/3;margin-top: 20px;
}
#member-since{
  grid-gap: 20px;
}
#member-name{
  white-space: nowrap;
}
#previous-member-button{
  margin-top: 2px;margin: 0px;
}
#next-member-button{
  width:93px; margin-right: 0px;margin-left: auto;margin-top: 2px;
}
.password-note{
  font-size: 12px;text-align:left;color:#aaa;grid-column: 2/3;
}
#top-div{
  display: grid;
    grid-template-columns: auto auto;
    width: 900px;
    margin: auto;
}
.contact-select{
  grid-column: 2/4;max-width: 375px;margin-bottom: 10px;
}
#member-photo {
    max-height: 100px;
    max-width: 200px;
    border: 1px gray solid;
  }

  #member-photo-box {
    display: grid;
    grid-template-columns: max-content auto;
    margin: auto;
    width: fit-content;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    vertical-align: middle;
    grid-row-gap: 10px;
  }
  
  #in-box-2 {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 2;
    display: grid;
  }
  #big-box {
    display: grid;
    grid-template-columns: 65% 35%;
    width: 900px;
    margin: auto;
  }
</style>
<script>
    
  import router from "../router"
  
    export default {
    name: "Members",
     watch:{
        'member.roles': {
          handler: function () {
            var initLeader = this.leadership;
            for(var i=0; i<this.member.roles.length;i++){
              for(var j=0; j<this.roles.length;j++){
                if(this.member.roles[i] == this.roles[j].id && this.roles[j].leadership == 1){
                  this.member.access = 1;
                  this.leadership = true;
                  return;
                }
              }
            }
            this.leadership = false;
            if(initLeader && !this.loading){
              this.showLeadershipRemoved = true;
            }
          },
          deep: true
        },
      },
    computed: {
      create_login: function () {
        return Math.min(this.member.access, 1);
      },
      char_count: function () {
        var n = this.member.ask.length;
        return (n < 120? n+"/120 "+this.$t('memberphoto.char_count_1') : this.$t('memberphoto.char_count_2'));
      },
      tag_count: function () {
        var n = this.member.tagline.length;
        return (n < this.tagline_chars? n+"/"+this.tagline_chars+" "+this.$t('memberphoto.char_count_1') : this.$t('memberphoto.char_count_2'));
      },
      yearOptions: function(){
        var now = new Date().getUTCFullYear();
        var years = [{value: 0, text: this.$t('member.year_since')}];
        for (var i=0; i<100;i++){
          years.push({value:now-i, text: now-i});
        }
        return years;
      }
    },
      data() {
        return {
          originalEmail:"",
          deleting: false,
          languageOptions: [],
          contactMethods: [{value:0, text: this.$t("member.sms_mobile")},{value:1, text: this.$t("member.email_link")},{value:2, text: this.$t("member.sms_email")}],
          loaded: false,
          allow_logins: false,
          allMembers: [],
          is_parent: false,
          official: false,
          org_id: 0,
          passAlert: false,
          alertPassText: false,
          sendAccessEmail : false,
          showLeadershipRemoved: false,
          showAccessGranted: false,
          initAccess: false,
          preferred_countries: ['CA', 'US'],
          showDelete: false,
          saveVariant : 'blue',
          roles: [],
          member: { id: 0, access: 0, roles: [], city: "", presence: 0, job_title: "", linkedin_url: "", sponsoring_member_id: 0, preferred_contact: 0, preferred_language: '',
            run_permission: 0, has_pass: 0, category: "", company: "", email: "", photo: null, photo_small: null, retype:"", website: "",
            disabled: 0, password: "", name: "", sub_role: "", role_description: "", phone: "", mobile_phone: "", logo: null, contact_type: "", contact_types: [],ask: "",tagline:"", introduction:""
          },
          showRoleAlert: false,
          alertText: "",
          typeOptions: [],
          leadership: false,
          showSetPass: false,
          use_taglines: false,
          tagline_chars: 65,
          multipleMembers: false,
          allowPhotos: false,
          allowLogos: false,
          presence_types: [],
          presenceTypeOptions: [{value: 1, text: this.$t('member.global_text')},{value: 2, text: this.$t('member.national_text')},{value: 4, text: this.$t('member.regional_text')},],
          monthOptions: [{value: 0, text: this.$t('member.month_since')},{value: 1, text: this.$t('global.january')},{value: 2, text: this.$t('global.february')},{value: 3, text: this.$t('global.march')},
          {value: 4, text: this.$t('global.april')},{value: 5, text: this.$t('global.may')},{value: 6, text: this.$t('global.june')},
          {value: 7, text: this.$t('global.july')},{value: 8, text: this.$t('global.august')},{value: 9, text: this.$t('global.september')},
          {value: 10, text: this.$t('global.october')},{value: 11, text: this.$t('global.november')},{value: 12, text: this.$t('global.december')}],
        }
      },
    methods: {
      nextMember(){
        this.$http.post("/api/member/next/get", {filterRoleId: this.permissions.filterRoleId, current_id : this.member.id}).then((response) => {
         if(response.data.success){
           this.member.id = response.data.id;
           this.getMember();
         }
        }).catch(() => {});
      },
      previousMember(){
        this.$http.post("/api/member/previous/get", {filterRoleId: this.permissions.filterRoleId, current_id : this.member.id}).then((response) => {
         if(response.data.success){
           this.member.id = response.data.id;
           this.getMember();
         }
        }).catch(() => {});
      },
      getFilterRoleNumber(){
        this.$http.post("/api/member/role/number/get", {filterRoleId: this.permissions.filterRoleId}).then((response) => {
         if(response.data.success){
           this.multipleMembers = response.data.number>1;
         }
        }).catch(() => {});
      },
      sendReset(){
        this.$http.post("/api/password/reset/byid", {id: this.member.id}).then((response) => {
         if(response.data.success){
           this.$bvToast.toast(this.$t('member.reset_sent')+" "+this.member.email, {
              variant: 'success',
              solid: true
            });
         }
        }).catch(() => {
        });
      },
      checkPasses(){
        this.passAlert = false;
        if(this.member.password.length<8){
          this.alertPassText = this.$t("reset.alert_length");
          this.passAlert = true;
          return;
        }
        if(this.member.password != this.member.retype){
          this.alertPassText = this.$t("reset.alert_match");
          this.passAlert = true;
          return;
        }
        this.showSetPass = false;
      },
      getContactTypes(){
        this.$http.post("/api/contact/types/get", {}).then((response) => {
          this.typeOptions = response.data.types;
          this.getMember();
        }).catch(() => {
        });
      },
          getMember() {
            this.loaded = false;
            this.loading = true;
            var self = this;
            if (this.member.id > 0) {
              this.$http.post("/api/member/get", this.member).then((response) => {
                if (response.data.success) {
                  this.initAccess = response.data.member.access;
                  response.data.member.contact_types = [];
                  response.data.member.since_year = 0;
                    response.data.member.since_month = 0;
                    this.presence_types = [];
                    for(var i=0; i<this.presenceTypeOptions.length;i++){
                      if((this.presenceTypeOptions[i].value & response.data.member.presence) !=0){
                        this.presence_types.push(this.presenceTypeOptions[i].value);
                      }
                    }
                  if(response.data.member.member_since && response.data.member.member_since.length>1){
                    var ls = response.data.member.member_since.split("-");
                    response.data.member.since_year = ls[0]==""? 0 : parseInt(ls[0]);
                    response.data.member.since_month =  ls.length<2 || ls[1]==""? 0 : parseInt(ls[1]);
                  }
                  if(response.data.member.contact_type && response.data.member.contact_type.length>0){
                    response.data.member.contact_types = response.data.member.contact_type.split(",");
                  }else{
                    response.data.member.contact_type = "";
                    response.data.member.contact_types = [];
                  }
                  this.originalEmail = response.data.member.email? response.data.member.email : '';
                  this.member = response.data.member;
                  this.$set(this.member, 'password', "");
                  this.$set(this.member, 'retype', "");
                  this.getMemberMedia();
                  this.$nextTick(function(){
                    self.loading = false;
                  });
                }
              }).catch((errors) => {
                console.log(errors);
                this.$nextTick(function(){
                    self.loading = false;
                  });});
            }else{
              this.loaded = true;
            }
          },
          getMemberMedia(){
            if (this.member.id > 0) {
              this.$http.post("/api/member/get/media", {id: this.member.id}).then((response) => {
                if (response.data.success) {
                  this.$set(this.member, 'photo', response.data.photo);
                  this.$set(this.member, 'photo_small', response.data.photo_small);
                  this.$set(this.member, 'logo', response.data.logo);
                }
                this.loaded = true;
              }).catch(() => {this.loaded = true;});
            }else{
              this.loaded = true;
            }
          },
          getRoles() {
            this.$http.post("/api/organization/roles", {}).then((response) => {
              if (response.data.success) {
                this.roles = response.data.roles;
              }
            }).catch(() => {});
          },
          getAllowPhotos() {
            this.$http.post("/api/organization/get/allowphotos", {}).then((response) => {
              if (response.data.success) {
                this.allowPhotos = response.data.allowPhotos;
                this.allowLogos = response.data.allowLogos;
              }
            }).catch(() => {});
          },
          checkAndUpdateMember(){
            if(this.member.password.length>0 || this.member.access == 1 || this.member.has_pass==1 || this.is_parent){
              if(this.member.email == ""){
                this.showRoleAlert = true;
                this.alertText = this.$t('member.email_required');
                this.loaded = true;
                return;
              }
              this.checkEmailAccount(this.updateMember);
            }else{
              this.updateMember();
            }
          },
          checkEmailAccount(onSuccess){
            this.$http.post("/api/member/check/account", this.member).then((response) => {
              if (response.data.success && response.data.account) {
                this.showRoleAlert = true;
                this.alertText = this.$t('member.account_error') +": " +response.data.org+" > "+response.data.name;
                this.member.access = 0;
                this.member.password = "";
                this.member.retype = "";
              }else{
                onSuccess();
              }
            }).catch(() => {
             
            });
          },
          updateMember(callback) {
            this.loaded = false;
            this.showRoleAlert = false;

            if(this.member.password.length>0){
              if(this.member.password != this.member.retype){
                this.showRoleAlert = true;
                this.alertText = this.$t('member.password_mismatch');
                this.loaded = true;
                return;
              }
              else if(this.member.email == ""){
                this.showRoleAlert = true;
                this.alertText = this.$t('member.email_required');
                this.loaded = true;
                return;
              }
            }

            if(this.official){
              this.member.access==0;
            }

            if(this.member.access==1){
              if(this.member.email == ""){
                this.showRoleAlert = true;
                this.alertText = this.$t('member.email_required');
                this.loaded = true;
                return;
              }
            }
            if(this.member.contact_types && this.member.contact_types.length>0){
              this.member.contact_type = this.member.contact_types.join(",");
            }else{
              this.member.contact_type = "";
            }
            

            if(!this.initAccess && this.member.access == 1 && !this.member.has_pass){
              this.showAccessGranted = true;
              this.loaded = true;
              return;
            }else if(!this.initAccess && this.member.access == 1){
              this.sendAccessEmail = true;
            }
            this.member.presence = 0;
            for(var i=0; i<this.presence_types.length;i++){
              this.member.presence+= this.presence_types[i];
            }

            this.member.tagline = this.member.tagline.substr(0,this.tagline_chars);
            this.member.member_since = this.member.since_year+"-"+this.member.since_month;
            this.member.org_id = this.org_id;
            this.$http.post("/api/member/update", this.member).then((response) => {
              if (response.data.error) {
                if (response.data.error.errno == 1) {
                  var role_conflicts = response.data.error.role_conflicts;
                  this.showRoleAlert = true;
                  this.alertText = this.$t('member.role_error') + ": ";
                  for (var i = 0; i < role_conflicts.length; i++) {
                    this.alertText += role_conflicts[i].name + ", ";
                  }
                  this.alertText = this.alertText.slice(0, this.alertText.length - 2);
                } else if(response.data.error.errno == 1062){
                  this.showRoleAlert = true;
                  this.alertText = this.$t('member.email_error') +": " +response.data.error.conflict.org+" > "+response.data.error.conflict.name;
                }
                this.saveVariant = 'danger';
                this.loaded = true;
              }
              else if (response.data.success === true) {
                this.saveVariant = 'success';
                if(!(this.member.id>0)){
                  this.member.id = response.data.member_id;
                }
                if(this.sendAccessEmail){
                  this.$http.post("/api/member/access/granted", {id: this.member.id}).then((response) => {
                    if (response.data.success === true) {
                      if(callback){
                        callback();
                      }else{
                        this.back();
                      }
                      
                    }
                  }).catch(() => {this.loaded = true;});
                }else{
                  if(callback){
                    callback();
                  }else{
                    this.back();
                  }
                }
              } else {
                this.saveVariant = 'danger';
                this.loaded = true;
              }
            }).catch((errors) => {
              console.log(errors);
              this.loaded = true;
              this.$bvToast.show('serverError');
              });
          },
          deleteMember() {
            this.deleting = true;
            this.$http.post("/api/member/delete", this.member).then((response) => {
              this.deleting = false;
              if (response.data.success === true) {
                this.back();
              }
            }).catch(() => {this.deleting = false;});
          },
          getUseTaglines(){
            this.$http.post("/api/region/get/usetaglines", {}).then((response) => {
              if (response.data.success === true) {
                this.use_taglines = response.data.use_taglines;
                this.tagline_chars = response.data.tagline_chars;
              }
            }).catch(() => {});
          },
          getAllMembers(){
            this.$http.post("/api/members/all/select", {}).then((response) => {
              if (response.data.success === true) {
                this.allMembers = response.data.members;
                this.allMembers.unshift({id: 0, name: this.$t('member.select_member_contact')});
              }
            }).catch(() => {});
          },
          back() {
            window.history.length > 1? router.go(-1) : router.push("/admin/members");
          },
      requestPhotoByEmail() {
        this.$http.post("/api/member/photo/request/email", this.member).then((response) => {
          if (response.data.success === true) {
            this.$bvToast.toast(this.$t('memberphoto.link_sent')+" "+this.originalEmail, {
              title: this.$t('memberphoto.link_sent_title'),
              variant: 'success',
              solid: true
            });
          }else{
            this.$bvToast.toast(response.data.error, {
              title: this.$t('memberphoto.link_not_sent'),
              variant: 'warning',
              solid: true
            });
          }
        }).catch(() => { });
      },
      getOrgInfo(){
        this.org_id = this.$route.params.org? this.$route.params.org : 0;
        this.$http.post("/api/member/orginfo", {id: this.org_id}).then((response) => {
          if (response.data.success === true) {
            this.is_parent = response.data.is_parent;
            this.allow_logins = response.data.allow_logins;
            this.official = response.data.official;
          }else{
            this.is_parent = false;
            this.allow_logins = false;
            this.official = false;
          }
        }).catch(() => { });
      },

      getLanguages(){
          this.$http.post("/api/organization/languages", {}).then((response) => {
            if (response.data.success === true) {
                this.languageOptions = response.data.languages;
            }
            this.$http.post("/api/organization/get/language", {}).then((response) => {
              if (response.data.success === true) {
                for(var language of this.languageOptions){
                  if(language.abbrev == response.data.language){
                    this.languageOptions.unshift({abbrev: '', name: this.$t('member.default_language')+" ("+language.name+")"})
                    break;
                  }
                }
              }
            }).catch((errors) => {
              console.log(errors)
            });
          }).catch((errors) => {
          console.log(errors)
          });
      },
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-members"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-members"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
      if(this.isCorporateConnections){
        this.getAllMembers();
      }
      this.getLanguages();
      this.getOrgInfo();
      this.getUseTaglines();
      this.getRoles();
      this.getFilterRoleNumber();
      this.member.id = this.$route.params.id;
      this.getContactTypes();
      this.getAllowPhotos();
    }
    }
</script>
