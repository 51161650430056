<template>
  <div>
    <div class="rel-grid-container">
      <div ></div>
      <div >
        <b-table class="table-hover-new clickable" small bordered :items="tips" :fields="fields" v-on:row-clicked="editTip">
          <template v-slot:cell(content)="data">
            <pre class="pre-format" v-html="data.item.content"></pre>
          </template>
          <template v-slot:cell(url)="data">
              {{data.item.url? "YES" : ""}}
          </template>
        </b-table>
        <b-modal id="slide-modal-2" centered v-model="showTipEdit" :title="$t('tips.edit_tip')">
            <div>
              <span class="form-box-title">{{$t('tips.edit_tip')}}</span>
              <div class="form-box" >
                  <span class="form-box-name">{{$t('tips.title_header')}}</span><b-form-input v-model="currentTip.title" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input>
                <span class="form-box-name">{{$t('tips.width_header')}}</span><b-form-input v-model="currentTip.width" type="number" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input>
                <span class="form-box-name">{{$t('tips.help_url')}}</span><b-form-input v-model="currentTip.url" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input>
               <b-form-checkbox v-model="currentTip.disabled" id="tip-disabled-checkbox" class="form-box-input left-text">
                {{$t('tips.disabled_text')}}
                </b-form-checkbox>
                <base-editor id="tip-editor" v-model="currentTip.content" />
            </div>
            <div :style="{width: currentTip.width+'px'}" class="auto-margin">
                <div id="tip-info-box" class="left-text">
                    <b-alert id="tip-alert" :show="true" variant="light">
                    <div id="tip-inner-wrapper">
                      <img id="tip-icon" src="../../assets/icon-Tip.png" /><span id="tip-title">{{currentTip.title}}</span>
                      <span id="tip-content" class="tip-text pre-format column-span2" v-html="currentTip.content"></span>
                    </div> 
                    </b-alert>
                </div>

            </div>
            </div>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="updateTip" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
          </div>
        </b-modal>
      </div> 
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
#tip-editor{
  grid-column: 1/4;
}
#tip-content{
  font-size: 14px;
}
#tip-title{
  line-height: 40px;margin-left: 10px;
}
#tip-icon{
  height:30px;
}
#tip-inner-wrapper{
  display:grid; grid-template-columns: 30px auto;
}
#tip-alert{
  border:1px solid #999;
}
#tip-info-box{
  margin-top: 20px;
}
#tip-disabled-checkbox{
  margin-bottom:20px;
}
  
</style>
<script>
  export default {
    name: "Tip",
    watch:{
        '$i18n.locale': { 
        handler: function () {
            this.getTips();
        },
        deep: true
        }
    },
    data() {
      return {
          showTipEdit: false,
        tips: [],
        moving:false,
        currentTip: {},
        currentTipIndex: -1,
        fields: [
            {
              key: 'tag',
              label: this.$t('tips.tag_header'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
            { key: 'title',
              label: this.$t('tips.title_header'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            },
            
             { key: 'width',
              label: this.$t('tips.width_header'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff", "text-align":"center" },
              tdClass: "actions-centre"
            },
            { key: 'url',
              label: this.$t('tips.help_url'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff","text-align":"center" },
              tdClass: "actions-centre"
            },
             { key: 'content',
              label: this.$t('tips.content_header'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            },
            
          ],
      }
    },
    methods: {
        resetTip(n){
            this.$http.post("/api/superadmin/tips/reset", {n: n}).then(() => {
            }).catch(() => {
            });
        },
        updateTip(){
            this.$http.post("/api/superadmin/tips/update", this.currentTip).then((response) => {
                this.showTipEdit = false;
                if (response.data.success) {
                    this.tips[this.currentTipIndex] = this.currentTip;
                    this.currentTip = {}
                }
            }).catch(() => {
                this.showTipEdit = false;
            });

        },
      getTips() {
        this.$http.post("/api/superadmin/tips", {}).then((response) => {
          if (response.data.success) {
            for(var tip of response.data.tips){
              top.disabled = tip.disabled==1;
            }
            this.tips = response.data.tips;
          }
        }).catch(() => {
        });
      },
      editTip(item,index) {
        if(item.id == null){
          this.currentTip = {tag: item.tag, id: 0, tip_number: item.tip_number,width: 650,title: "",content: "",onboard: 0,url: "",language: this.$i18n.locale != undefined? this.$i18n.locale : "en",disabled: 0};
        }else{
          this.currentTip = item;
        }
        this.currentTipIndex = index;
        this.showTipEdit = true;
      }
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-tips"), "active-menu");
      this.removeClass(document.getElementById("menu-admin"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-tips"), "active-menu");
      this.addClass(document.getElementById("menu-admin"), "active-menu");
      this.getTips();
    }
    }
</script>
