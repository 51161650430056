<template>
  <div id="public-container" :style="{'grid-template-rows': isCorporateConnections? 'auto 120px max-content auto' : 'auto max-content auto'}">
      <div></div>
      <div v-if="isCorporateConnections"><img id="cc-banner" src="../assets/public-cc-banner.png" /></div>
      <div id="public-wrapper">
        <div id="organization-name" :style="{'text-align': isCorporateConnections? 'right' : 'center' }">
            {{member.organization_name}}
        </div>
        <div id="info-body" :style="{'grid-template-columns': isCorporateConnections? 'auto auto 80px' : 'auto auto', 'padding-top': isCorporateConnections? '0' : '40px'}" class="fit-width ">
            <div v-if="isCorporateConnections" id="cc-member-photo"><img class='member-corner-image'  src='../assets/Picture-CC-backYellow.png'/>
                <div class='circular-member member-graphic' :style="{'background-image': 'url('+member.photo+')'}"></div>
                <div id="public-member-since" v-if="member.since_year" >{{$t('member.member_since')}}<br/><b>{{ member.since_year }}</b></div>
            </div>
            <img v-if="!isCorporateConnections" style="width: 150px;margin: 0 20px;" :src="member.photo"/>
            <div id="member-info-box">
                <div class="member-name" :style="{'color': isCorporateConnections? '#FFD700' : '#CF2030'}">{{member.name}}</div>
                <div id="member-company">{{member.company}}</div>
                <div><b>{{member.category}}</b></div>
                <div><a :href="'mailto:'+member.email">{{member.email}}</a></div>
                <div v-if="member.phone"><a :href="'tel:'+member.phone">{{member.phone}}</a></div>
                <div v-if="!isCorporateConnections"><a :href='(member.website.startsWith("http") ? "" : "http://") + member.website' target="_blank">{{member.website}}</a></div>
            </div>
            <div id="member-linkedin"><div></div><img v-if="member.linkedin_url && member.linkedin_url.length>0" id="public-linkedin-image" class="clickable" v-on:click="openLinkedin(member);" src="../assets/LinkedIn.png"/><div></div></div>
        </div>
        <div v-if="isCorporateConnections" class="cc-block-header fit-width">{{$t('member.looking_for')}}</div>
        <div class="cc-block-text">
           {{member.ask}}
        </div>
        <div v-if="isCorporateConnections" class="cc-block-header fit-width">{{$t('member.how_introduce')}}</div>
        <div v-if="isCorporateConnections" class="cc-block-text" >{{member.introduction}}</div>
      </div>
      <div></div>
  </div>
</template>
<style scoped>
#public-member-since{
  text-align: center;
    margin: 20px;
}
#public-linkedin-image{
  width:80px;height:80px;
}
#member-linkedin{
  display: grid; grid-template-rows: auto max-content auto;
}
#member-company{
  font-size: 35px;
}
#member-info-box{
  font-size: 30px;min-width: 400px;text-align: left;line-height: 35px;grid-gap: 5px;display: grid;
}
#cc-member-photo{
  height: 250px;width: 250px;position: relative;top: 0px;
}
#member-photo{
  width: 150px;margin: 0 20px;
}
#public-wrapper{
  max-width: 900px; margin: auto;
}
#cc-banner{
  margin:auto;display:block;width:900px;
}
.cc-block-header{
  background-color: #424242;
  color: #fff;
  padding: 10px 50px;
  width: fit-content;
  font-size: 22px;
  margin: 20px 0;
}
.cc-block-text{
  color: rgb(119, 119, 119);
  font-size: 20px;
  margin-left: 100px;
}
#info-body{
  display:grid;grid-gap: 25px;margin:auto;
  padding-bottom: 60px;
}
#organization-name{
  font-size: 40px;font-weight: bold;
}
#public-container{
  display:grid;  height: 100%; 
}
.member-name{
  font-size: 40px;font-weight:bold;margin-bottom:10px;
}
</style>
<script>
    export default {
    name: "Public",
      data() {
        return {
            member: {name: "", company: '', category: '', phone: '',email: '', website: '', ask: '', photo: '', organization_name: '', introduction: '', since_year: ''}
        }
      },
    methods: {
      openLinkedin(m){
            window.open((m.linkedin_url.startsWith("http") ? "" : "http://") + m.linkedin_url);
        },
      getMember(){
          var hash = this.$route.params.h;
          this.$http.post("/api/organization/member/get/bypublichash", {hash: hash}).then((response) => {
            response.data.member.since_year = (response.data.member.member_since? response.data.member.member_since.split("-")[0] : '');
            this.member = response.data.member;
        }).catch(() => {});
      }
    },
    mounted() {
        this.getMember();
    }
    }
</script>
