<template>
    <div>
      <div class="rel-grid-container">
        <div class="submit-box-2">
          <span class="form-box-name">At Least</span>
          <b-form-select v-model="degree" :options="degreeOptions" class="mb-3 " />
        </div>
        <div class="submit-box-2">
          <b-button v-if="errorNum>0" class="action-button previous-button" v-on:click="previousPage" variant="clear-blue"><div class="back-button"></div><span>{{$t('global.button_back')}}</span></b-button>
          <b-button v-if="errors.length==25" class="action-button" v-on:click="nextPage" variant="clear-blue"><div class="next-button"></div><span>{{$t('global.button_next')}}</span></b-button>
        </div>
        <div>
          <b-table small bordered :items="errors" :fields="fields">
            <template v-slot:cell(occurred)="data" >
                <span class="no-wrap-text">{{data.item.occurred? $d(new Date("" + data.item.occurred), 'long') : ""}}</span>
            </template>
            <template v-slot:cell(degree)="data" >
              {{ degreeOptions.filter(v => v.value == data.item.degree).length? degreeOptions.filter(v => v.value == data.item.degree)[0].text : "" }}
            </template>
            <template v-slot:cell(description)="data" >
              <div class="fixed-size">
                {{data.item.description? data.item.description : ""}}
              </div>
            </template>
          </b-table>
        </div>
        <div ></div>
        <div class="div-space"></div>
      </div>
    </div>
  </template>
  <style scoped>
  .fixed-size{
    max-width:800px;
    max-height: 200px;
     overflow: auto !important;
  }
  </style>
  <script>
      export default {
      name: "Errorlog",
      watch: {
      'degree': {
        handler: function (v) {
          this.getErrorlogs();
        },
      },
    },
        data() {
          return {
            degreeOptions: [{value: 0, text: "INFO"},{value: 1, text: "WARNING"},{value: 2, text: "ERROR"}],
            fields: [
            { key: 'id',
                label: "ID",
                thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff", "text-align": "center" },
                tdClass: "actions-centre"
              },
                { key: 'occurred',
                label: "Time",
                thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff", "width": "200px", "text-align": "center" },
                tdClass: "actions-centre"
              },
              { key: 'degree',
                label: "Level",
                thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" },
              },
               { key: 'stack',
                label: "Error Stack",
                thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" },
              },
              { key: 'description',
                label: "Description",
                thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
              },
              /* { key: 'resolved',
                label: "Resolved",
                sortable: true,
                thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
              }*/
            ],
            errors: [],
            errorNum: 0,
            degree: 0
          }
        },
      methods: {
        nextPage() {
          this.errorNum += 25;
          this.getErrorlogs();
        },
        previousPage() {
          this.errorNum = Math.max(0, this.errorNum - 25);
          this.getErrorlogs();
        },
        getErrorlogs() {
            this.erorrs = [];
            this.$http.post("/api/get/errorlogs", { errorNum: this.errorNum, degree: this.degree }).then((response) => {
              if (response.data.success) {
                this.errors = response.data.errors;
              }
            }).catch(() => {
            });
        },
      },
      mounted() {
        this.getErrorlogs();
      }
      }
  </script>
  