<template>
  <div class="full-height">
    <div id="import-page-wrapper" class="rel-grid-container valign-grid">
      <div ></div>
      <div id="import-buttons-wrapper">
      <div v-if="showAlert">
        <b-alert variant="danger" dismissible :show="showAlert"  @dismissed="showAlert=false">
          {{alertText}}
        </b-alert>
      </div>
      <div v-if="showSuccess">
        <b-alert variant="success" dismissible :show="showSuccess"  @dismissed="showSuccess=false">
          {{$t('members.import_success')}}
        </b-alert>
      </div>
      <div v-if="showUpload">
        <b-alert class="alert-line-height" variant="warning" :show="showUpload">
          {{$t('preparer.uploading_text')}}
          <b-spinner class="alert-spinner" label="Uploading" variant="warning"></b-spinner>
        </b-alert>
      </div>
      <div >
        <span class="form-box-title">IMPORT</span>
        <div class="form-box grid-fit-4column" >
          <b-button v-if="permissions.access" :disabled="showUpload" class="action-button" v-on:click="showImportRegions=true" variant="clear-blue"><div class="import-button"></div><span>{{$t('import.import_regions')}}</span></b-button>
          <b-button v-if="permissions.access" :disabled="showUpload" class="action-button" v-on:click="showImportChapters=true" variant="clear-blue"><div class="import-button"></div><span>{{$t('members.import_chapters')}}</span></b-button>
          <b-button v-if="permissions.access" :disabled="showUpload" class="action-button" v-on:click="showImportAllMembers=true" variant="clear-blue"><div class="import-button"></div><span>{{$t('members.import_members')}}</span></b-button>
         <!-- <b-button v-if="permissions.access" :disabled="showUpload" class="action-button" v-on:click="showImportAllRoles=true" variant="clear-blue"><div class="import-button"></div><span>{{$t('members.import_roles')}}</span></b-button>-->
        </div>
        <span class="form-box-title">DELETE</span>
        <div class="form-box" >
          <b-button v-if="permissions.access" :disabled="showUpload" class="action-button" v-on:click="showDeleteInactiveRegions=true" variant="clear-blue"><div class="delete-button"></div><span>DELETE INACTIVE REGIONS</span></b-button>
          <b-button v-if="permissions.access" :disabled="showUpload" class="action-button" v-on:click="showDeleteInactiveChapters=true" variant="clear-blue"><div class="delete-button"></div><span>DELETE INACTIVE CHAPTERS</span></b-button>
        </div>
        
        <span class="form-box-title">EXPORT</span>
        <div class="form-box" > 
          <b-button class="action-button" v-on:click="exportMembers" :disabled="showUpload" variant="clear-blue"><div class="import-button"></div><span>{{$t('attendance.export_members')}}</span></b-button>
          <div v-if="exportFile.filename.length>0 && showSuccess" class="fit-width auto-margin"> 
                <b-button class="action-button" v-on:click="downloadAttendanceExport" variant="clear-blue" ><div class="import-button"></div><span>{{$t('attendance.download_export')}}</span></b-button>
                <a id="export-file" :download="exportFile.filename" :href="exportFile.content" hidden></a>
              </div>
          </div>
      </div>

      <div class="div-space"></div>

    </div>
    <div></div>
    <b-modal centered  v-model="showDeleteInactiveRegions" :title="$t('members.chapters_import_title')">
         <div>
           This will delete all regions marked as inactive (have no chapters which are active). Are you sure?
           <div v-if="showUpload">
            <b-alert class="alert-line-height" variant="warning" :show="showUpload">
              Deleting, please wait.
              <b-spinner class="alert-spinner" label="Uploading" variant="warning"></b-spinner>
            </b-alert>
          </div>
         </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="action-button" v-on:click="deleteInactiveRegions" :disabled="showUpload" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_yes')}}</span></b-button>
          <b-button class="action-button" v-on:click="showDeleteInactiveRegions=false" :disabled="showUpload" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
        </div>
    </b-modal>
    <b-modal centered  v-model="showDeleteInactiveChapters" :title="$t('members.chapters_import_title')">
         <div>
           This will delete all chapters without a meeting in the last month. Are you sure?
           <div v-if="showUpload">
            <b-alert class="alert-line-height" variant="warning" :show="showUpload">
              Deleting, please wait.
              <b-spinner class="alert-spinner" label="Uploading" variant="warning"></b-spinner>
            </b-alert>
          </div>
         </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="action-button" v-on:click="deleteInactiveChapters" :disabled="showUpload" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_yes')}}</span></b-button>
          <b-button class="action-button" v-on:click="showDeleteInactiveChapters=false" :disabled="showUpload" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
        </div>
    </b-modal>
    <b-modal centered  v-model="showImportRegions" :title="$t('members.chapters_import_title')">
         <div>
           <div class="import-modal-inner-wrapper">
            <div class="form-box grid-auto-2column">
              <b-button variant="blue" v-on:click="openDialog" class="medium-input">{{$t('import.select_file')}}</b-button>
              <b-form-file ref="file-input"  id='fileid' accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="onFileChange" plain hidden></b-form-file>
              <b-form-checkbox v-model="only_new_regions">
              Only New
            </b-form-checkbox>
            <b-form-checkbox v-model="core_regions" class="second-column">
              Import as Core
            </b-form-checkbox>
            </div>
            Required Fields: `region id`, `region name`
          </div>
           <div v-if="showUpload">
            <b-alert class="alert-line-height" variant="warning" :show="showUpload">
              {{$t('import.importing_message')}}
              <b-spinner class="alert-spinner" label="Uploading" variant="warning"></b-spinner>
            </b-alert>
          </div>
         </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="action-button" v-on:click="showImportRegions=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
        </div>
    </b-modal>
    <b-modal centered id="slide-modal-4" v-model="showRegionPreviewImport" :title="$t('import.import_title')">
        <div id="preview-members">
          <b-table class="table-hover-new" small bordered :fields="regionFields" :items="previewRegions">
            <template v-slot:cell(import)="data">
              <b-form-checkbox v-model="data.item.import" @change="checkCanImport(data.item)" class="auto-margin">
            </b-form-checkbox>
            </template>
            <template v-slot:cell(id)="data">
              <b-form-select  v-model="data.item.id" :options="regions" value-field="id" text-field="name" class="copy-select-margin form-box-input" />
            </template>
          </b-table>
        </div>
        <b-alert class="alert-line-height" variant="warning" :show="copy_region>0">
            Copying over regions is an long operation, and thus you are limited to importing 10 regions at a time.
        </b-alert>
        <div slot="modal-footer" >
          <div class="grid-auto-4column">
            <span class="form-box-name">{{$t('import.region_country')}} </span>
            <b-form-select  v-model="regionImportCountry" :options="countries" value-field="id" text-field="name" class="copy-select-margin" />
            <span  class="form-box-name">Template Region Content</span><b-form-select v-model="template_region" :disabled="copy_region>0" :options="officialRegions" class="mb-3" value-field="id" text-field="name"/>
            <span  class="form-box-name">Copy Region Content</span><b-form-select v-model="copy_region" :disabled="template_region>0" :options="officialCopyRegions" class="mb-3" value-field="id" text-field="name"/>
            <b-form-checkbox v-model="override_names" class="auto-margin">
            {{$t('import.override_names')}}
            </b-form-checkbox>
            <b-form-checkbox v-model="override_bni_ids" class="auto-margin">
              {{$t('import.override_bni_ids')}}
            </b-form-checkbox>
            
        </div>
        <div v-if="showUpload">
            <b-alert class="alert-line-height" variant="warning" :show="showUpload">
              Creating regions. Please wait...
              <b-spinner class="alert-spinner" label="Uploading" variant="warning"></b-spinner>
            </b-alert>
          </div>
        <div class="submit-box-2">
          <b-button class="action-button" v-on:click="cancelImport" :disabled="showUpload" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="action-button" v-on:click="importRegionData" :disabled="regionImportCountry=='' || showUpload" variant="clear-blue"><div class="import-button"></div><span>{{$t('global.button_import')}}</span></b-button>
        </div>
        </div>
      </b-modal>

      <b-modal centered  v-model="showImportChapters" :title="$t('members.chapters_import_title')">
         <div>
           <div class="import-modal-inner-wrapper">
            <div class="form-box grid-auto-2column import-options">
              <b-button variant="blue" v-on:click="openDialog3" class="medium-input">{{$t('import.select_file')}}</b-button>
              <b-form-file ref="file-input"  id='fileid3' accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="onFileChange3" plain hidden></b-form-file>
              <b-form-checkbox v-model="no_meeting" class="second-column">
                  Only update chapters with no meetings
                </b-form-checkbox>
              <b-form-checkbox v-model="only_new_chapters" class="second-column">
              Only New
            </b-form-checkbox>
            </div>
            Required Fields: `chapter name`, `region id`, `chapter id`
            Optional Fields: `meeting_day`, `meeting_time`, `meeting_location`, `meeting_address1`, `meeting_address2`, `meeting_city`, `meeting_country`, `meeting_postcode`
          </div>
           <div v-if="showUpload">
            <b-alert class="alert-line-height" variant="warning" :show="showUpload">
              {{$t('import.importing_message')}}
              <b-spinner class="alert-spinner" label="Uploading" variant="warning"></b-spinner>
            </b-alert>
          </div>
         </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="action-button" v-on:click="showImportChapters=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
        </div>
    </b-modal>
      <b-modal centered id="slide-modal-4" v-model="showChapterPreviewImport" :title="$t('import.import_title')">
        <div id="preview-members">
          <b-table class="table-hover-new" small bordered :items="previewChapters">
            <template v-slot:cell(import)="data">
              <b-form-checkbox v-if="data.item.can_import" v-model="data.item.import" class="auto-margin">
            </b-form-checkbox>
            </template>
          </b-table>
        </div>
        <div v-if="showUpload">
            <b-alert class="alert-line-height" variant="warning" :show="showUpload">
              Creating chapters. Please wait...
              <b-spinner class="alert-spinner" label="Uploading" variant="warning"></b-spinner>
            </b-alert>
          </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="action-button" v-on:click="cancelImport" :disabled="showUpload" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="action-button" v-on:click="importChapterData" :disabled="showUpload" variant="clear-blue"><div class="import-button"></div><span>{{$t('global.button_import')}}</span></b-button>
        </div>
      </b-modal>

      <b-modal centered  v-model="showImportAllMembers" :title="$t('members.import_title')">
         <div>
           <div class="import-modal-inner-wrapper">
            <div class="form-box grid-auto-2column">
              <b-button variant="blue" v-on:click="openDialog4" class="medium-input">{{$t('import.select_file')}}</b-button>
              <b-form-file ref="file-input"  id='fileid4' accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="onFileChange4" plain hidden></b-form-file>
              <div id="only-new-checkbox-wrapper">
                <b-form-checkbox v-model="no_meeting" class="second-column">
                  Only update chapters with no meetings
                </b-form-checkbox>
                <b-form-checkbox v-model="only_new" class="second-column">
                  {{$t('import.only_new')}}
                </b-form-checkbox>
                <b-form-checkbox v-model="import_emails" class="second-column">
                  Import emails/phone numbers
                </b-form-checkbox>
              </div>
            </div>
            Required Fields: `member name`, `membership id`, `chapter id`
            Optional Fields: `company`, `profession`, `phone number`, `city`, `website`, `start date`, `email`, `preferred language`, `roles`
          </div>
           <div v-if="showUpload">
            <b-alert class="alert-line-height" variant="warning" :show="showUpload">
              {{$t('import.importing_message')}}
              <b-spinner class="alert-spinner" label="Uploading" variant="warning"></b-spinner>
            </b-alert>
          </div>
         </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="action-button" v-on:click="showImportAllMembers=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
        </div>
    </b-modal>
    <b-modal centered  v-model="showImportAllRoles" :title="$t('members.import_roles')">
         <div>
           <div sclass="import-modal-inner-wrapper">
            <div class="form-box grid-auto-2column">
              <b-button variant="blue" v-on:click="openDialog5" class="medium-input">{{$t('import.select_file')}}</b-button>
              <b-form-file ref="file-input"  id='fileid5' accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="onFileChange5" plain hidden></b-form-file>
              
            </div>
          </div>
           <div v-if="showUpload">
            <b-alert class="alert-line-height" variant="warning" :show="showUpload">
              {{$t('import.importing_message')}}
              <b-spinner class="alert-spinner" label="Uploading" variant="warning"></b-spinner>
            </b-alert>
          </div>
         </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="action-button" v-on:click="showImportAllRoles=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
        </div>
    </b-modal>
      <b-modal centered id="slide-modal-4" v-model="showAllMembersPreviewImport" :title="$t('import.import_title')">
        <div id="preview-members">
          <b-alert class="alert-line-height" variant="info" :show="only_roles">
              {{$t('import.roles_only_note')}}
            </b-alert>
             <table class="table b-table table-bordered table-sm">
          <thead>
            <tr>
              <th class="tableHeader center-text">Chapter id</th>
              <th class="tableHeader center-text">Name </th>
              <th class="tableHeader center-text field-enabled" v-for="field in optionalFields" :key="field.header">{{field.field}}<!--<b-form-checkbox v-model="field.enabled" value="1" unchecked-value="0" class="auto-margin">{{field.header}}</b-form-checkbox>-->
              </th>
              <th class="tableHeader center-text">Import</th>
            </tr>
          </thead>
            <tbody>
              <tr v-for="member in previewMembers.slice((currentPage-1)*perPage, (currentPage)*perPage-1)" :key="member" :class="{'table-danger': member._rowVariant=='danger', 'table-success': member._rowVariant=='success', 'table-warning': member._rowVariant=='warning'}">
                <td>{{member.organization}}</td>
                <td>{{member.name}}</td>
                <td v-for="field in optionalFields" :key="field.header" class="field-break">{{member[field.field]? member[field.field] : ""}}</td>
                <td>
                  <b-form-checkbox v-if="member.can_import" v-model="member.import" class="auto-margin"></b-form-checkbox>
                </td>
              </tr>
            </tbody>
          </table>
          <b-pagination  v-model="currentPage" :total-rows="previewMembers.length" :per-page="perPage" align="fill" size="sm" class="my-0" ></b-pagination>
        </div>
         <div>
            Total Importable Members: {{previewMembers.filter(v => v.can_import).length}}
          </div>
          <div>
            Unfound Roles: {{notRoles.length==0? 'None' : notRoles.join(',')}}
          </div>
          <div v-if="showUpload">
            <b-alert class="alert-line-height" variant="warning" :show="showUpload">
              Creating members. Please wait...
              <b-spinner class="alert-spinner" label="Uploading" variant="warning"></b-spinner>
            </b-alert>
          </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="action-button" v-on:click="cancelImport" :disabled="showUpload" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="action-button" v-on:click="importAllMembersData" :disabled="showUpload" variant="clear-blue"><div class="import-button"></div><span>{{$t('global.button_import')}}</span></b-button>
        </div>
      </b-modal>
    </div>
    </div>
</template>
<style scoped>
.import-options{
  grid-gap:10px;
}
.field-break{
  word-break: break-all;
}
.field-enabled{
  white-space: pre;
}
#only-new-checkbox-wrapper{
  margin: auto;padding:20px;
}
.import-modal-inner-wrapper{
  max-width: 600px;margin: auto;
}
#import-buttons-wrapper{
  margin-bottom:20px;
}
#import-page-wrapper{
  top:20px;height:100%;
}
#preview-members{
   max-height:500px; overflow: auto;margin-bottom:20px;
 }
#role-select{
  display:grid; grid-template-columns:auto auto; width: 400px;margin:auto;
}
</style>
<script>
  import router from "@/router"
    export default {
    name: "AdminImport",
     props:{
      onboard: Boolean,
    },
    watch: {
      'permissions.filterRoleId': {
        handler: function () {
          this.getMembers();
        },
        deep: true
      },
      '$i18n.locale': {
        handler: function () {
          if(this.roles.length>0){
            this.roles[0].name = this.$t('members.all_chapters');
          }
        },
        deep: true
      },
      'template_region': {
        handler: function () {
          this.copy_region = 0;
        },
        deep: true
      },
      'copy_region':{
        handler: function () {
          if(this.copy_region>0 && this.only_new_regions){
            var x = 0;
            for(var region of this.previewRegions){
              region.import = x<10;
              x++;
            }
          }else if (this.copy_region>0 && !this.only_new_regions){
            for(var region of this.previewRegions){
              region.import = false;
            }
          }else{
            for(var region of this.previewRegions){
              region.import = true;
            }
          }
        },
        deep: true
      },
    },
    data() {
      return {
        override_names: false,
        override_bni_ids: false,
        core_regions: false,
        import_emails: false,
        template_region: 0,
        copy_region: 0,
        officialRegions: [],
        officialCopyRegions: [],
        no_meeting: true,
        only_new_chapters: true,
        only_new_regions: true,
        showDeleteInactiveRegions: false,
        showDeleteInactiveChapters: false,
        countries: [],
        regionImportCountry: '',
        regions: [],
        regionFields: [
          { key: 'import',
            label: this.$t('import.import_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'name',
            label: this.$t('import.name_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
          { key: 'bni_id',
            label: this.$t('import.bni_id_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
          { key: 'id',
            label: this.$t('import.region_id_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
        ],
        previewRegions: [],
        showRegionPreviewImport: false,
        showImportRegions: false,
        optionalFields: [],
        showAllMembersPreviewImport: false,
        only_new: true,
        showImportAllRoles: false,
        only_roles: false,
        notRoles: [],
        showImportAllMembers: false,
        showImportAllPreviewImport: false,
        showImportChapters: false,
        showChapterPreviewImport: false,
        previewChapters: [],
        perPage: 20,
        currentPage:1,
        totalRows:0,
        selectedMember: null,
        showLoginAs: false,
        members: [],
        showUpload: false,
        showAlert: false,
        saveVariant: "",
        alertText: "",
        showSuccess: false,
        members_num: 0,
        chapters: [],
        fields: [
           { key: 'org_name',
            label: this.$t('members.organization_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
          { key: 'name',
            label: this.$t('members.name_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'category',
            label: this.$t('members.category_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'company',
            label: this.$t('members.company_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'roles',
            label: this.$t('members.role_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" },
            tdClass: 'breakAll'
          }
        ],
        previewRoleFields: [
           { key: 'chapter_name',
            label: this.$t('import.chapter_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
          { key: 'name',
            label: this.$t('import.name_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'role',
            label: this.$t('import.role_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'import',
              label: this.$t('events.import_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff", 'text-align': 'center'},
            }
        ],
        previewFields: [
           { key: 'chapter_name',
            label: this.$t('import.chapter_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
          { key: 'name',
            label: this.$t('import.name_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'category',
            label: this.$t('import.category_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'company',
            label: this.$t('import.company_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'email',
            label: this.$t('import.email_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" },
            tdClass: 'breakAll'
          },
           { key: 'phone',
            label: this.$t('import.phone_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
            { key: 'website',
            label: this.$t('import.website_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" },
            tdClass: 'breakAll'
          },
           { key: 'job_title',
            label: this.$t('import.job_title'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'linkedin_url',
            label: this.$t('import.linkedin_url'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'preferred_language',
            label: this.$t('import.preferred_language'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'import',
              label: this.$t('events.import_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff", 'text-align': 'center'},
            }
        ],
          showImport: false,
          previewMembers: [],
          showRoleImport: false,
          showImportRoleMembers: false,
          exportFile: {filename: '', content: ''},
      }
    },
    methods: {
      deleteInactiveRegions(){
        this.showUpload = true;
        this.$http.post("/api/superadmin/delete/inactive/regions", {}).then((response) => {
          this.showDeleteInactiveRegions = false;
          this.showUpload = false;
          if (response.data.success === true) {
          }else{
            this.showAlert = true;
          }
        }).catch(() => { this.showUpload = false;});
      },
      deleteInactiveChapters(){
        this.showUpload = true;
        this.$http.post("/api/superadmin/delete/inactive/chapters", {}).then((response) => {
          this.showDeleteInactiveChapters = false;
          this.showUpload = false;
          if (response.data.success === true) {
          }else{
            this.showAlert = true;
          }
        }).catch(() => { this.showUpload = false;});
      },
      downloadAttendanceExport(){
        document.getElementById("export-file").click();
      },
      exportMembers(){
        this.$http.post("/api/superadmin/members/export", {}).then((response) => {
          if (response.data.success === true) {
            this.exportFile = response.data.file;
            this.showSuccess = true;
          }else{
            this.showAlert = true;
          }
        }).catch(() => { });
      },
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      tableFilter(row, filter){
        if(row.organization== filter || filter==0){
          return true;
        }
        return false;
      },
      onPopoverShow(member) {
        if (!member.gotThumbnail) {
          this.$http.post("/api/member/get/photo", { id: member.id }).then((response) => {
            if (response.data.success) {
              this.$set(member, "thumbnail", response.data.photo);
              this.$set(member, "gotThumbnail", true);
            }
          }).catch((errors) => {
            console.log(errors);
          });
        }
      },
      getMembers() {
        this.$http.post("/api/region/children/members",{}).then((response) => {
            this.members = response.data.members;
            this.totalRows = response.data.members.length;
        }).catch((errors) => {console.log(errors);});
      },
      editMember(item) {
        if (item.shadow==1) {
          router.push("/admin/member/" + item.id);
        }
      },
      importPage() {
        router.push("/admin/import");
      },
      getChildren() {
        this.$http.post("/api/organization/get/children/select", {}).then((response) => {
          if (response.data.success) {
            this.chapters = response.data.children;
            this.chapters.unshift({id: 0, name: this.$t('members.all_chapters')});
          }
        }).catch(() => { });
      },
      importAllMembersData(){
        var vs = this.previewMembers.filter(g => g.import);
          if(vs.length>0){
            this.showUpload = true;
            this.$http.post("/api/region/allmembers/import/all", { members: vs, only_roles: this.only_roles,optionalFields: this.optionalFields}).then((response) => {
              this.$set(this, 'showAllMembersPreviewImport', false);
              this.showUpload = false;
              if (response.data.success === true) {
                this.$bvToast.toast('Imported/Updated '+response.data.rows+" members", {
                  title: "Success",
                  variant: 'success',
                  solid: true
                });
              }
          }).catch(() => {this.showAllMembersPreviewImport = false;this.showUpload = false;});
        }else{
          this.showAllMembersPreviewImport = false;
        } 
      },
      importAllMembers() {
        this.showImportAllMembers = false;
        this.showImportAllRoles = false;
        this.showUpload = true;
        this.showAlert = false;
        this.alertText = this.$t('import.alert_text');
        this.saveVariant = "";
        this.$refs['file-input'].reset();
        let formData = new FormData();
        formData.append("file", this.file);
        formData.append('only_roles', this.only_roles);
        formData.append('only_new', this.only_roles? false : this.only_new);
        formData.append('only_new_chapters', this.no_meeting)
        this.$http.post("/api/region/allmembers/import/excel", formData, {headers: {"Content-Type": "multipart/form-data"}}).then((response) => {
          this.showImport = false;
          this.showUpload = false;
          this.optionalFields = response.data.fields;
          this.saveVariant = "blue";
          if (response.data.success == true) {
            this.previewMembers = response.data.members;
            if(this.only_new){
              this.previewMembers = this.previewMembers.filter(v=> v.can_import)
            }
            if(!this.import_emails){
              for(var member of this.previewMembers){
                member.email = "";
                member.phone = "";
              }
            }
            this.notRoles = response.data.notRoles;
            this.showAllMembersPreviewImport = true;
            this.only_roles = response.data.only_roles;
          } else {
            this.showAlert = true;
            this.alertText = response.data.error;
          }
        }).catch((errors) => {
          this.showImport = false;
          this.showUpload = false;
          this.saveVariant = "blue";
          this.showAlert = true;
          this.alertText = JSON.stringify(errors);
        });
      },
      importChapterData(){
        var vs = this.previewChapters.filter(g => g.import);
          if(vs.length>0){
            this.showUpload = true;
            this.$http.post("/api/region/chapters/import/all", { chapters: vs}).then((response) => {
              this.showUpload = false;
            if (response.data.success === true) {
              this.showChapterPreviewImport = false;
              this.$bvToast.toast('Imported/Updated '+response.data.rows+" chapters", {
                title: "Success",
                variant: 'success',
                solid: true
              });
            }
          }).catch(() => {this.showUpload = false; });
        }else{
          this.showChapterPreviewImport = false;
        } 
      },
      importChapters() {
        this.showImportChapters = false;
        this.showUpload = true;
        this.showAlert = false;
        this.alertText = this.$t('import.alert_text');
        this.saveVariant = "";
        this.$refs['file-input'].reset();
        let formData = new FormData();
        formData.append("file", this.file);
        formData.append('only_new_chapters', this.no_meeting)
        this.$http.post("/api/region/chapters/import/excel", formData, {headers: {"Content-Type": "multipart/form-data"}}).then((response) => {
          this.showImport = false;
          this.showUpload = false;
          this.saveVariant = "blue";
          if (response.data.success == true) {
            this.previewChapters = response.data.chapters;
            if(this.only_new_chapters){
              this.previewChapters = this.previewChapters.filter( v=> !(v.id >0));
            }
            this.showChapterPreviewImport = true;
          } else {
            this.showAlert = true;
            this.alertText = response.data.error;
          }
        }).catch((errors) => {
          this.showImport = false;
          this.showUpload = false;
          this.saveVariant = "blue";
          this.showAlert = true;
          this.alertText = JSON.stringify(errors);
        });
      },
      checkCanImport(item){
        var x = this.previewRegions.filter(v => v.import).length
        if(x>10){
          item.import = false;
        }
      },
      importRegions(){
        this.showImportRegions = false;
        this.showUpload = true;
        this.showAlert = false;
        this.alertText = this.$t('import.alert_text');
        this.$refs['file-input'].reset();
        let formData = new FormData();
        formData.append("file", this.file);
        this.$http.post("/api/region/regions/import/excel", formData, {headers: {"Content-Type": "multipart/form-data"}}).then((response) => {
          this.showImport = false;
          this.showUpload = false;
          if (response.data.success == true) {
            this.previewRegions = response.data.regions;
            if(this.only_new_regions){
              this.previewRegions = this.previewRegions.filter( v=> !(v.id >0));
            }
            if(this.copy_region>0 && this.only_new_regions){
              var x = 0;
              for(var region of this.previewRegions){
                region.import = x<10;
                x++;
              }
            }else if (this.copy_region>0 && !this.only_new_regions){
              for(var region of this.previewRegions){
                region.import = false;
              }
            }
            this.showRegionPreviewImport = true;
          } else {
            this.showAlert = true;
            this.alertText = response.data.error;
          }
        }).catch((errors) => {
          this.showImport = false;
          this.showUpload = false;
          this.showAlert = true;
          this.alertText = JSON.stringify(errors);
        });
      },
      importRegionData(){
        var vs = this.previewRegions.filter(g => g.import);
          if(vs.length>0){
            this.showUpload = true;
            this.$http.post("/api/region/regions/import/all", { regions: vs, core_regions: this.core_regions, override_bni_ids: this.override_bni_ids, override_names: this.override_names, import_country: this.regionImportCountry, template_region: this.template_region, copy_region: this.copy_region}).then((response) => {
              this.showImport = false;
              this.showUpload = false;
              if (response.data.success === true) {
              
              this.showRegionPreviewImport = false;
              this.$bvToast.toast('Imported/Updated '+response.data.rows+" regions", {
                title: "Success",
                variant: 'success',
                solid: true
              });
            }
          }).catch((error) => { this.showImport = false;this.showUpload = false;this.showRegionPreviewImport = false;
            if(error.code === 'ECONNABORTED'){
              this.$bvToast.toast('The process is taking longer than expected. Please wait and check the regions of been imported before continuing.', {
                title: "Timeout",
                variant: 'WARNING',
                solid: true
              });
            }
          });
            
              
        }else{
          this.showRegionPreviewImport = false;
        } 
      },
      onFileChange(e) {
         var files = e.target.files || e.dataTransfer.files;
        if (!files.length || !this.checkFileSize(files[0]))
          return;
        this.file = files[0];
        //this.getMembers();
        this.importRegions();
      },
       onFileChange3(e) {
         var files = e.target.files || e.dataTransfer.files;
        if (!files.length || !this.checkFileSize(files[0]))
          return;
        this.file = files[0];
        //this.getMembers();
        this.importChapters();
      },
      onFileChange4(e) {
         var files = e.target.files || e.dataTransfer.files;
        if (!files.length || !this.checkFileSize(files[0]))
          return;
        this.file = files[0];
        //this.getMembers();
        this.only_roles = false;
        this.importAllMembers();
      },
      onFileChange5(e) {
         var files = e.target.files || e.dataTransfer.files;
        if (!files.length || !this.checkFileSize(files[0]))
          return;
        this.file = files[0];
        //this.getMembers();
        this.only_roles = true;
        this.importAllMembers();
      },
      cancelImport() {
        this.previewMembers = [];
        this.file = { name: "" };
        this.previewChapters = [];
        this.showChapterPreviewImport = false;
        this.showAllMembersPreviewImport = false;
        this.showRegionPreviewImport = false;
        this.previewRegions = [];
      },
      getOfficialRegions(){
            this.$http.post("/api/regions/official/get", {}).then((response) => {
              if (response.data.success === true) {
                this.officialRegions = JSON.parse(JSON.stringify(response.data.regions));
                this.officialRegions.unshift({id: 0, name: this.$t('region.no_template')})

                this.officialCopyRegions = JSON.parse(JSON.stringify(response.data.regions));
                this.officialCopyRegions.unshift({id: 0, name: "Do not copy region content."})
              } 
            }).catch(() => {});
          },
      openDialog() {
        document.getElementById('fileid').click();
      },
      openDialog3() {
        document.getElementById('fileid3').click();
      },
      openDialog4() {
        document.getElementById('fileid4').click();
      },
      openDialog5() {
        document.getElementById('fileid5').click();
      },
      rowClass(item) {
          if (!item) return
          //if(item.disabled==1 && item.shadow==1) return 'disabledClickClass';
          //if(item.shadow==1) return 'clickable'
          if (item.disabled==1) return 'disabledClass';
        },
        getRegions() {
        this.$http.post("/api/regions/get/all", {}).then((response) => {
          if(response.data.success){
            this.regions = response.data.regions;
            this.regions.unshift({id: 0, name: "Create Region"});
          }
        }).catch((errors) => {
          console.log(errors);
        });
      },
      getCountries() {
        this.$http.post("/api/superadmin/countries", {}).then((response) => {
          if (response.data.success) {
            this.countries = response.data.countries;
          }
        }).catch((e) => {console.log(e);
        });
      },
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-members"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-members"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
      this.getRegions();
      this.getCountries();
      this.getOfficialRegions();
      //this.getChildren();
      //this.getMembers();
    }
    }
</script>
