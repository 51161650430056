<template>
  <div>
    <Header :useruid="this.$route.params.useruid" :title="$t('newsletter.manage_newsletter')" :showDate="true"></Header>
    <div id="newsletter-page-wrapper" class="fit-width auto-margin">
      <div ></div>
      <Tip :tipNumber="19"></Tip>
      <div id="configure-layout-button-wrapper" class="grid-auto-2column">
        <b-button id="configure-layout-button" class="action-button" v-if="!previewing" v-on:click="showLayoutEdit=true;" variant="clear-blue"><div class="configure-button"></div><b>{{$t('general.configure_layout')}}</b></b-button>
      </div>
      <form v-on:submit.prevent="updateNewsletter" v-if="!previewing" class="fit-width auto-margin">
        <span class="form-box-title">{{$t('newsletter.newsletter_header') }} <b-form-select v-if="!is_parent" v-on:change="getCoreValueSpeaker" v-model="newsletter.send_date" :options="newsletter_dates" value-field="value" text-field="text"/></span>
        <draggable :disabled="true" tag="div" v-model="newsletterObjects" item-key="id" class="form-box grid-split-1column">
          <template #item="{element}">
            <div>
             <div class="newsletter-admin-box" v-if="(element.id == 3 && !isCorporateConnections) || (element.id==2 && isCorporateConnections)">
              <span class="form-box-name">{{isCorporateConnections? $t('newsletter.newsletter_blockc3') : $t('newsletter.newsletter_block3')}}</span>
              <div class="grid-fit-2column newsletter-grid-box">
                  <b-form-radio v-model="newsletter.custom_header" value="0">
                      {{$t('newsletter.use_default')}}
                  </b-form-radio>
                  <b-form-radio v-model="newsletter.custom_header" value="1">
                      {{$t('newsletter.use_custom')}}
                  </b-form-radio>
                  <div v-if="newsletter.custom_header==1" class="grid-auto-2column column-span3">
                      <ImageSelector :imageObject="newsletter" :ratio="isCorporateConnections? 1.392 : 2.83" :maxWidth="600" :maxHeight="300" :imageField="'header_image'" :smallImageField="'header_image'" :clearButton="true" :minText="true"/>
                  </div>
              </div>
            </div>
            <div class="newsletter-admin-box" v-if="element.id == 14 && !isCorporateConnections">
              <span  class="form-box-name">{{$t('newsletter.newsletter_block14')}}</span>
              <div class="grid-fit-3column newsletter-grid-box">
                 <span id="ask-complete">{{newsletter.asks_missing>0? $t('newsletter.asks_missing', {asks: newsletter.asks_missing}) : $t('newsletter.asks_complete')}}</span>
                  <b-button class="action-button auto-margin" v-if="newsletter.asks_missing>0" v-on:click="showReferralConfirm=true;" variant="clear-blue"><div class="email-button"></div><span>{{$t('newsletter.send_reminder')}}</span></b-button>
              </div>
            </div>
            <div class="newsletter-admin-box" v-if="element.id == 31">
              <span  class="form-box-name">{{$t('newsletter.newsletter_block31')}}</span>
              <div class="grid-fit-3column newsletter-grid-bo">
                 <span id="num-meetings">{{$t('newsletter.num_meetings')}}</span>
                  <b-form-select  v-model="newsletter.num_meetings" :options="[{value:1,text:'1'},{value:2,text:'2'},{value:3,text:'3'},{value:4,text:'4'}]" value-field="value" text-field="text"/>
              </div>
            </div>
            <div class="newsletter-admin-box" v-if="element.id == 13">
              <span  class="form-box-name">{{$t('newsletter.newsletter_block13')}}</span>
              <div class="grid-fit-3column newsletter-grid-box">
                  <span >{{ $t('newsletter.cat_order')}}</span>
                    <b-form-radio v-model="newsletter.categories_order" value="0">
                        {{$t('newsletter.cat_list')}}
                    </b-form-radio>
                    <b-form-radio v-model="newsletter.categories_order" value="1">
                        {{$t('newsletter.cat_random')}}
                    </b-form-radio>
                </div>
            </div>
            <div class="newsletter-admin-box" v-if="element.id == 4">
                <span class="form-box-name">{{$t('newsletter.newsletter_block4')}}</span>
              <div >
                <multiselect :max="2" v-model="newsletter.performance_select" :options="tracking_nums" :object="true" :placeholder="$t('general.select_periods')" label="text" track-by="text" class="blueselect" :noOptionsText="$t('global.empty_list')"  mode="tags" valueProp="value" :close-on-select="false" :searchable="true">
                </multiselect>
                <div v-show="no_data.length>0" >{{$t('newsletter.no_performance')+": "+(no_data.map(v => $t('general.tracking_option_'+v)).join(", "))}}</div>
              </div>
            </div>
            <div class="newsletter-admin-box" v-if="element.id == 17">
              <span  class="form-box-name">{{$t('newsletter.newsletter_block17')}}</span>
              <b-form-select v-if="!speaker.id"  v-model="newsletter.core_value" :options="coreOptions.filter(v => isCorporateConnections? v.value>7 : v.value<=7)" value-field="value" text-field="text"/>
                <b-form-select v-if="speaker.id>0" v-model="speaker.core_value_id" :options="coreOptions.filter(v => isCorporateConnections? v.value>7 : v.value<=7)" value-field="value" text-field="text"/>
                  <div v-if="speaker.member_name" class="second-column"><i>{{$t('newsletter.chosen_by', {name: speaker.member_name})}}</i></div>
            </div>
            <div class="newsletter-admin-box" v-if="element.id == 1">
              <span class="form-box-name">{{$t('newsletter.newsletter_block1')}}</span>
              <BaseEditor v-model="newsletter.top_message" :showLink="true" />
            </div>
            <div class="newsletter-admin-box" v-if="element.id == 7">
              <span class="form-box-name">{{$t('newsletter.newsletter_block7')}}</span>
              <BaseEditor v-model="newsletter.meeting_recap" :showLink="true"/>
              <b-form-checkbox v-model="newsletter.meeting_recap_pic" class="second-column" value="1" unchecked-value="0">
                  {{$t('newsletter.meting_recap_pic')}}
              </b-form-checkbox>
            </div>
            <div class="newsletter-admin-box" v-if="element.id == 9">
              <span  class="form-box-name">{{$t('newsletter.newsletter_block9')}}</span>
              <BaseEditor v-model="newsletter.news_notifications" :showLink="true"/>
            </div>
            <div class="newsletter-admin-box" v-if="element.id == 8">
              <span class="form-box-name">{{$t('newsletter.newsletter_block8')}}</span><b-form-input v-model="newsletter.visitors_note" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" ></b-form-input>
            </div>
            <div class="newsletter-admin-box" v-if="element.id == 15 && !isCorporateConnections">
              <span class="form-box-name">{{$t('newsletter.newsletter_block15')}}</span>
              <BaseEditor v-model="newsletter.closing_note" :showLink="true"/>
            </div>
            <div class="newsletter-admin-box" v-if="element.id == 16">
              <span class="form-box-name">{{$t('newsletter.newsletter_block16')}}</span>
              <BaseEditor v-model="newsletter.bottom_message" :showLink="true"/>
            </div>
            <div class="newsletter-admin-box" v-if="element.id == 20">
              <span class="form-box-name">{{$t('newsletter.newsletter_block20')}}</span><b-form-select class="auto-margin" v-model="newsletter.best_weekly" :options="members" value-field="id" text-field="name"/>
            </div>
            <div class="newsletter-admin-box" v-if="element.id == 21">
              <span class="form-box-name">{{$t('newsletter.newsletter_block21')}}</span>
              <BaseEditor v-model="newsletter.media_header" :content="mediaHeader" :showLink="true"/>
              <div v-if="media_links==0" class="valign-margin second-column">{{$t('newsletter.define_social_media')}} <b-button variant="link" id="chapter-settings" v-on:click="goToSettings">{{$t('newsletter.chapter_settings')}}</b-button></div>
            </div>
            <div class="newsletter-admin-box" v-if="element.id == 22 && !is_parent">
              <span class="form-box-name">{{$t('newsletter.newsletter_block22')}}</span>
              <div id="include-region">
                <b-form-checkbox v-model="newsletter.regional_events" value="1" unchecked-value="0">
                    {{$t('newsletter.include_regional')}}
                </b-form-checkbox>
              </div>
            </div>
            <div class="newsletter-admin-box" v-if="element.id == 25">
              <span class="form-box-name">{{$t('newsletter.newsletter_block25')}}</span>
              <BaseEditor v-model="newsletter.photo_description" :showLink="true"/>
              <div class="second-column">
                <img v-for="photo in galleryPhotos" :key="photo.number" :src="photo.link" class="gallery-image gallery-photo-image"/>
              </div>
              <div class="grid-auto-2column second-column">
                <i :style="{'font-weight': galleryPhotos.length>0? 'bold' : 'normal'}">{{$tc('newsletter.number_photos_selected', galleryPhotos.length)}}</i>
                <b-button class="action-button newsletter-select-button" v-on:click="showGalleryPhotos=true;" variant="blue"><span>{{$t('newsletter.select_photos')}}</span></b-button>
              </div>
            </div>
            <div class="newsletter-admin-box" v-if="element.id == 26">
              <span class="form-box-name">{{$t('newsletter.newsletter_block26')+" #"+element.custom_id}}</span>
              <b-form-input v-model="element.title" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" ></b-form-input>
            </div>
            <div class="newsletter-admin-box" v-if="element.id == 27">
              <span class="form-box-name">{{$t('newsletter.newsletter_block27')+" #"+element.custom_id}}</span>
              <BaseEditor v-model="element.content" :showLink="true"/>
            </div>
            <div class="newsletter-admin-box" v-if="element.id == 28">
              <span class="form-box-name">{{$t('newsletter.newsletter_block28')+" #"+element.custom_id}}</span>
              <b-form-input v-model="element.title" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" ></b-form-input>
              <div class="second-column">
                <div v-for="member in element.members" :key="member.id" class="member-name-company"><b>{{member.name}}</b>{{member.company? " - "+member.company: ""}}</div>
              </div>
              <div class="grid-auto-2column second-column">
                <i :style="{'font-weight':element.members && element.members.length>0? 'bold' : 'normal'}">{{$tc('newsletter.number_members_selected', element.members? element.members.length : 0)}}</i>
                <b-button class="action-button newsletter-select-button" v-on:click="openMemberSelection(element)" variant="blue"><span>{{$t('newsletter.select_members')}}</span></b-button>
              </div>
            </div>
          </div>
          </template>
        </draggable>
        <b-modal id="slide-modal" centered  v-model="showLayoutEdit" :title="$t('general.configure_layout')">
              <div class="grid-fitfirst-2column">
                <div class="grid-split-2column column-span2 center-text">
                  <div>{{$t('newsletter.newsletter_layout')}}</div>
                  <div>{{$t('newsletter.unset_objects')}}</div>
                  <draggable class="nLayout" tag="div" group="newsletter" v-model="newsletterObjects" item-key="id"  @end="endDrag" @start="startDrag">
                    <template #item="{element}">
                      <div :id="'unused_'+element.id+(element.custom_id>0?  '_'+element.custom_id : '')" :class="{'dragged-object' : (element.id+(element.custom_id>0?  '_'+element.custom_id : '')==currentDrag)}" class="nObject" :style="{'grid-column': 'span '+element.cspan, 'grid-row': 'span '+element.rspan}">
                        <span>{{isCorporateConnections && $t("newsletter.newsletter_blockc"+element.id)!="newsletter.newsletter_blockc"+element.id?  $t("newsletter.newsletter_blockc"+element.id) : $t("newsletter.newsletter_block"+element.id)+(element.custom_id? " #"+element.custom_id : '')}}</span>
                        <b-button v-if="(!is_parent || (isCorporateConnections && element.id==24)) && element.resizeable && (element.id==7 || element.id==22 || element.id==19 || element.id==24 || element.id==31? element.cspan>2 : element.cspan>1)" v-on:click.stop="element.cspan -=1;" class="resize-button fit-width layout-remove-button op-black-background  " variant="clear-blue"><img class="skinned-image" src="../assets/button-Remove.png" /></b-button>
                        <b-button v-if="(!is_parent || (isCorporateConnections && element.id==24)) && element.resizeable && element.cspan<3 && (element.id != 29 || element.cspan<2)" v-on:click.stop="element.cspan += 1;" class="resize-button fit-width op-black-background" variant="clear-blue"><img class="skinned-image" src="../assets/button-Add.png" /></b-button>
                      </div>
                    </template>
                  </draggable> 
                  <draggable id="unused-objects-grid" class="nLayout" tag="div" group="newsletter" v-model="unusedObjects"  item-key="id" @choose="dragChoose" @end="endDragUnused" @start="startDrag">
                    <template #item="{element}">
                      <div :id="'block_'+element.id+(element.custom_id>0?  '_'+element.custom_id : '')" :class="{'dragged-object' : (element.id+(element.custom_id>0?  '_'+element.custom_id : '')==currentDrag)}" class="nObject" :style="{'grid-column': 'span '+element.cspan, 'grid-row': 'span '+element.rspan}">
                        <span>{{isCorporateConnections && $t("newsletter.newsletter_blockc"+element.id)!="newsletter.newsletter_blockc"+element.id?  $t("newsletter.newsletter_blockc"+element.id) : $t("newsletter.newsletter_block"+element.id)}}</span>
                        <b-button v-if="(!is_parent || (isCorporateConnections && element.id==24)) && element.resizeable && (element.id==7 || element.id==22  || element.id==19 || element.id==24 || element.id==31? element.cspan>2 : element.cspan>1)" v-on:click.stop="element.cspan -=1;" class="resize-button layout-remove-button fit-width" variant="clear-blue"><img class="skinned-image" src="../assets/button-Remove.png" /></b-button>
                        <b-button v-if="(!is_parent || (isCorporateConnections && element.id==24)) && element.resizeable && element.cspan<3 && (element.id != 29 || element.cspan<2)" v-on:click.stop="element.cspan += 1;" class="resize-button  fit-width" variant="clear-blue"><img class="skinned-image" src="../assets/button-Add.png" /></b-button>
                      </div>
                    </template>
                  </draggable>
                  <div class="layout-note">*{{$t('newsletter.layout_note1')}}</div>
                  <div class="layout-note">*{{$t('newsletter.layout_note2')}}</div>
                  <div class="layout-note">*{{$t('newsletter.layout_note3')}}</div>
                </div>
              </div>
              <div slot="modal-footer" class="submit-box-2 grid-fitfirst-2column full-width">
                 <b-button class="action-button" v-on:click="showLayoutReset=true;" variant="clear-blue"><div class="reset-button"></div><span>{{$t('global.button_reset')}}</span></b-button>
                <b-button class="action-button auto-margin" v-on:click="showLayoutEdit=false" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
              </div>
         </b-modal>
         <b-modal id="slide-modal"  v-model="showMemberSelection" centered :title="$t('newsletter.member_group_selections')">
           <div v-if="selectedMemberObject" >
             <multiselect id="member-select" v-model="selectedMemberObject.members" :options="members.filter(v=>v.id>0)" :object="true" :placeholder="$t('global.select_members')" label="name" track-by="name" class="blueselect" :noOptionsText="$t('global.empty_list')"  mode="tags" valueProp="id" :close-on-select="false" :searchable="true">
              <template v-slot:option="{ option }">
                <div><span>{{ option.org_name }}</span> > <span>{{ option.name }}</span>  <span >{{ option.company? " - "+option.company: ""}}</span></div>
              </template>
              </multiselect>
              <div class="form-box grid-split-1column auto-margin select-members-form-box" v-if="selectedMemberObject.members && selectedMemberObject.members.length>0">
                <draggable  tag="div" v-model="selectedMemberObject.members" item-key="id">
                  <template #item="{element}">
                    <div class="select-member-name-company">
                      <div><b>{{element.name}}</b>{{element.company? " - "+element.company: ""}}</div>
                    </div>
                  </template>
              </draggable>
              <div class="drag-order">*{{$t('questions.drag_order')}}</div>
            </div>
           </div>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="showMemberSelection=false;" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
          </div>
        </b-modal>
         <b-modal id="slide-modal"  v-model="showGalleryPhotos" centered >
           <div slot="modal-header" class="full-width">
             <div id="photo-selection">{{$t('newsletter.photo_selection')}}</div>
            <b-button class="action-button auto-margin" v-on:click="openPhotoEdit((s => addPhotoToGallery(s)))" variant="blue"><span>{{$t('entry.upload_photo')}}</span></b-button>
          </div>
          <draggable  tag="div" v-model="galleryPhotos" item-key="number">
            <template #item="{element}">
              <div class="gallery-photo-info">
                <img :src="element.link" class="gallery-image" />
                <b-form-textarea v-model="element.description" pattern="[^<>]*" :title="$t('global.pattern_title')" class="gallery-photo-description" placeholder="" :rows="3" :max-rows="3" />
                <b-button class="trash-button" variant="blue" v-on:click="deletePhoto(element)"></b-button>
              </div>
            </template>
          </draggable>
          <div class="drag-order">*{{$t('questions.drag_order')}}</div>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="showGalleryPhotos=false;" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
          </div>
        </b-modal>
         <b-modal id="modal-center" v-model="showLayoutReset" centered :title="$t('newsletter.reset_title')">
          <div >
              {{$t('newsletter.reset_message')}}
          </div>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="showLayoutReset=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" v-on:click="resetLayout" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
          </div>
        </b-modal>
        <div id="newsletter-actions" class="submit-box-2 grid-auto-3column">
          <b-button class="action-button" v-on:click="preview(true)" variant="clear-blue"><div class="preview-button"></div><span>{{$t('global.button_preview')}}</span></b-button>
          <b-button class="action-button auto-margin" v-b-popover.hover.top="$t('newsletter.test_send_to')" v-on:click="showSendTestModal(true)" variant="clear-blue"><div class="email-button"></div><span>{{$t('newsletter.test_newsletter')}}</span></b-button>
          <b-button class="action-button"  v-on:click="showSendModal(true)" variant="clear-blue"><div class="email-button"></div><span>{{$t('newsletter.send_newsletter')}}</span></b-button>
          <b-button class="action-button second-column" type="submit" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
        </div>
        <b-modal id="modal-center" v-model="showReferralConfirm" centered :title="$t('newsletter.referral_email_title')">
          <div >
            {{$t('newsletter.referral_email_text',{asks: newsletter.asks_missing})}}
          </div>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="showReferralConfirm=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" v-on:click="sendAskRequestEmails" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
          </div>
        </b-modal>
        <b-modal id="modal-center" v-model="showNewsletterSend" centered :title="$t('newsletter.send_newsletter')">
          <div >
            <div v-if="confirm_step==0">{{$t('newsletter.empty_recap')}}</div>
            <div v-if="confirm_step==1"><span v-html="$t('newsletter.confirm_text', {date: $d(new Date(newsletter.send_date+' 00:00:01'), 'short')})"></span></div>
            <div v-if="confirm_step==1" id="date-note">*{{$t('newsletter.date_note')}}</div>
          </div>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="showNewsletterSend=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" v-on:click="confirmSend" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
          </div>
        </b-modal>
        <b-modal id="send-modal" v-model="showNewsletterRegionalSend" centered :title="$t('newsletter.send_newsletter')">
          <div >
            <div>{{$t('newsletter.regional_send_select')}}</div>
            <div class="fit-width left-text auto-margin">
                <b-form-checkbox v-model="regionalSendOptions.chapters">
                  {{$t('newsletter.chapters_text')}}
              </b-form-checkbox>
              <b-form-checkbox v-model="regionalSendOptions.startups">
                  {{$t('newsletter.startups_text')}}
              </b-form-checkbox>
            </div>
            <div v-html="$t('newsletter.members_to_send', {members: regionalMembers.chapters_members*(regionalSendOptions.chapters? 1 : 0)+regionalMembers.startups_members*(regionalSendOptions.startups? 1 : 0), chapters: regionalMembers.chapters*(regionalSendOptions.chapters? 1 : 0)+regionalMembers.startups*(regionalSendOptions.startups? 1 : 0), contacts: regionalMembers.contacts})"></div>
          </div>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="showNewsletterRegionalSend=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" v-on:click="confirmRegionalSend" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_yes')}}</span></b-button>
          </div>
        </b-modal>
        <b-modal id="modal-center" v-model="showTestNewsletter" centered :title="$t('newsletter.test_newsletter')">
          <div class="grid-auto-2column">
            <span class="form-box-name">{{$t('newsletter.test_recipient')}}</span>
            <b-form-input v-model="testEmail" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" :state="validEmail" @input="validateEmail"></b-form-input>
            <div class="column-span2" v-html="$t('newsletter.confirm_test')"></div>
          </div>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="showTestNewsletter=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" :disabled="!validEmail" v-on:click="confirmTest" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
          </div>
        </b-modal>
        <b-modal :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true" id="modal-center" v-model="showEventDisable" centered :title="$t('newsletter.disable_email')">
          <div v-html="emailDisableNote">
            </div>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="showEventDisable=false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_no')}}</span></b-button>
            <b-button class="action-button" v-on:click="runEmailDisabledCallback()" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_yes')}}</span></b-button>
          </div>
        </b-modal>
        <b-modal id="slide-modal" centered  v-model="showPhotoEdit" :title="$t('global.set_image')">
          <div>
            <ImageSelector ref="gallerySelector" :imageObject="imageEdit" :maxWidth="200" :maxHeight="200" :smallImageField="'photo'" :embedded="true"/>
          </div>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="showPhotoEdit=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" v-on:click="cropImage" variant="clear-blue"><div class="save-button"></div><span>{{$t('memberphoto.set_photo')}}</span></b-button>
          </div>
        </b-modal>
      </form>
      <b-button v-if="previewing" class="action-button newsletter-preview-button" v-on:click="previewing=false" variant="clear-blue"><div class="back-button"></div><span>{{$t('global.button_back')}}</span></b-button>
      <div v-if="previewing" class="div-space fit-width auto-margin" v-html="previewhtml"></div>
      <b-button v-if="previewing" class="action-button newsletter-preview-button" v-on:click="previewing=false" variant="clear-blue"><div class="back-button"></div><span>{{$t('global.button_back')}}</span></b-button>
      <div id="footer-spacer"></div>
    </div>
    <Footer :useruid="this.$route.params.useruid"></Footer>
  </div>
</template>

<style scoped>
#date-note{
  font-size:12px;
}
.gallery-photo-description{
  margin-bottom: 10px;
}
.gallery-photo-info{
  display:grid; grid-template-columns: 250px auto 35px;padding:10px; border: 1px solid gray;
}
#photo-selection{
  font-size: 20px;
}
.drag-order{
  text-align:right;font-size:14px;
}
.select-member-name-company{
  display:grid; grid-template-columns: auto 35px;padding:10px; border: 1px solid gray;padding: 5px;margin:2px;
}
.select-members-form-box{
  width:600px;
  margin-top: 10px !important;
}
#unused-objects-grid{
  grid-auto-flow: dense;
}
.op-black-background{
  background-color: rgba(255,255,255,0);
}
.layout-remove-button{
  left:0px;
}
.layout-remove-button{
  left:0px;background-color: rgba(255,255,255,0);
}
.member-name-company{
  border: 1px solid gray;padding: 5px;margin:2px;
}
.newsletter-select-button{
  grid-column:2; margin-left:auto;margin-top:5px;
}
.gallery-photo-image{
  margin: 5px;
}
#include-region{
  margin:auto 0;
}
#chapter-settings{
  padding:0px;
}
#configure-layout-button{
  margin-left: auto;margin-right: 0px;grid-column: 2; z-index: 100;
}
#configure-layout-button-wrapper{
  top: 30px;position: relative;
}
#newsletter-page-wrapper{
  max-width:800px;
}
#newsletter-actions{
  grid-auto-flow: inherit;
}
#footer-spacer{
  width:100%;height:50px;
}
.newsletter-preview-button{
  margin: 10px auto;
}
#ask-complete, #num-meetings{
  margin: auto;margin-left: 5px;color: #555;
}
.newsletter-grid-box{
  margin: 10px 0px;grid-gap: 20px;
}
.layout-note{
  grid-column: 1/3;text-align:left; font-size: 14px; color: #cccccc;padding-left:10px;
}
#member-select{
  grid-column:2;margin-bottom: 10px;
}
.dragged-object{
  border-color: red;
  border-width: 2px;
}
.gallery-image{
  width:auto;height:100px;
  margin:auto;
}
.newsletter-admin-box{
  display: grid;
  grid-template-columns: 165px 620px;
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px dashed #e9e9e9;
}
.blur-editor{
    border: 1px solid #ccc;
}
.blur-editor:focus{
    box-shadow: 0 0 2px 5px #bfdeff;
    border: 1px solid #62b9f5;
}
.tiptap-button{
    color:black;
    background-color: white;
    border:none;
    width: 40px;
    padding: 5px 0px;
}
.tiptap-button:hover{
    color: white;
    background-color: gray;
}
.is-active{
    background-color: lightgray;
}
 .menubar2{
    display: grid;
    grid-template-columns: repeat(7,auto);
    grid-gap: 5px;
 }
 
</style>
<script>


import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
    import draggable from "vuedraggable";
    
  import router from "../router"
    export default {
      
    name: "Newsletter",
   components: {
      VueCropper
      ,draggable
    },
      data() {
        return {
          speaker: {id:0, member_id: 0, core_value_id:0, date:null},
          testEmail: "",
          newsletter_dates: [],
          next_meeting: null,
          currentDrag: "",
          regionalMembers: {chapters: 0, startups: 0, chapters_members: 0, startups_members:0},
          regionalSendOptions: {chapters: true, startups: true},
          showNewsletterRegionalSend: false,
          selectedMemberObject: null,
          showMemberSelection: false,
          galleryPhotos: [],
          showGalleryPhotos: false,
          justSaved: false,
          is_parent: false,
          official: true,
          showLayoutReset: false,
          showLayoutEdit: false,

        tempObjects: [{id: 1, cspan: 3, rspan: 1, resizeable: false},{id: 2, cspan: 3, rspan: 1, resizeable: false},{id: 3,  cspan: 3, rspan: 1, resizeable: false},
        {id: 4, cspan: 1, rspan: 1, resizeable: false},{id: 5, cspan: 1, rspan: 1, resizeable: false},{id: 6, cspan: 1, rspan: 1, resizeable: false},
        {id: 7,  cspan: 3, rspan: 1, resizeable: true},{id: 8, cspan: 3, rspan: 1, resizeable: false}, {id: 9,  cspan: 3, rspan: 1, resizeable: true},
        {id: 10,  cspan: 1, rspan: 1, resizeable: false},{id: 11, cspan: 1, rspan: 1, resizeable: false},{id: 12, cspan: 1, rspan: 1, resizeable: false},{id: 13, cspan: 1, rspan: 1, resizeable: false},
        {id: 14,  cspan: 3, rspan: 1, resizeable: true},{id: 15,  cspan: 3, rspan: 1, resizeable: false},{id: 16,  cspan: 3, rspan: 1, resizeable: false},{id: 17,  cspan: 1, rspan: 1, resizeable: false}
        ,{id: 18,  cspan: 1, rspan: 1, resizeable: false},{id: 19,  cspan: 2, rspan: 1, resizeable: true},{id: 20,  cspan: 1, rspan: 1, resizeable: false},{id: 21,  cspan: 3, rspan: 1, resizeable: true},
        {id: 22,  cspan: 3, rspan: 1, resizeable: true},{id: 23,  cspan: 1, rspan: 1, resizeable: false},{id: 24,  cspan: 2, rspan: 1, resizeable: true},{id: 25,  cspan: 3, rspan: 1, resizeable: false},
        {id: 26,  cspan: 3, rspan: 1, resizeable: false, max: 8,custom_id:0},{id: 27,  cspan: 3, rspan: 1, resizeable: false, max: 12,custom_id:0},{id: 28,  cspan: 3, rspan: 1, resizeable:false, max: 8,custom_id:0}
        ,{id: 29,  cspan: 1, rspan: 1, resizeable:true},{id: 30,  cspan: 1, rspan: 1, resizeable:false},{id: 31,  cspan: 3, rspan: 1, resizeable:false}],
        officialObjects: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
        startupObjects: [1, 2, 3, 6, 7, 8, 9, 10, 13, 14, 15, 16, 17, 18, 19, 23],
        rstObjects: [1, 2, 3, 6, 7, 8, 9, 15, 16, 17, 18, 19, 24],
        regionalObjects: [1,2,3,21,22,16,25,26,27,28],
        ccObjects: [1,2,5,6,7,8,9,10,12,14,15,16,17,18,19,21,22,30],
        ccRegionalObjects: [1,2,16,17,21,22,24,25,26,27,28,30,31],
        member: [],
        unusedObjects: [],
        newsletterObjects: [],
          showNewsletterSend: false,
          confirm_step: 0,
           previewing: false,
            previewhtml: "",
            imgurl:'',
          newsletter: {send_date: null, num_meetings: 1,backup_blocks: 0, backup_date_formatted: null, photo_description: '', photo_ids: '', regional_events: false,best_weekly: 0,asks_missing: 0,meeting_recap_pic: true,categories_order: 0,core_value: 0,custom_header: 0, header_image: '',tracking_periods: 0, split_layout: [], performance_select: [],top_message: "Stay safe and follow Covid-19 restrictions in your region.",bottom_message: "Have a great week everyone!", meeting_recap: "", news_notifications: "",visitors_note: "If you invited a member, make sure you follow-up on their visit.",
          closing_note: '<p>Visit our website at:</p><p><a href="http://www.yourbnichapter.url">www.yourbnichapter.url</a></p><p></p><p>If you have any comments or feedback on our newsletter,</p><p>please email <a href="mailto: someone@yourbnichapter.url">someone@yourbnichapter.url</a></p><p></p><p></p>', layout: "1;3,2;3,3;3,4;1,17;1,5;1,7;2,6;1,8;3,10;1,11;1,13;1,14;3,15;3,16;3", media_header: 'Connect with us on one of our channels:'},
        command: null,
        imageEdit: {photo: "", data: "", preview: "", successFunc: null, fixed: true,maxwidth:0,maxheight:0},
        image_type: 0,
          showPhotoEdit: false,
        maxwidth: 0,
        useruid: "",
        tracking_nums: [],
        trackingOptions: [
          { value: 1, text: this.$t('general.tracking_option_1') },
          { value: 2, text: this.$t('general.tracking_option_2') },
          { value: 4, text: this.$t('general.tracking_option_4') },
          { value: 8, text: this.$t('general.tracking_option_8') },
          { value: 16, text: this.$t('general.tracking_option_16') },
          { value: 32, text: this.$t('general.tracking_option_32') },
          { value: 64, text: this.$t('general.tracking_option_64') },
        ],
        coreOptions:[
          { value: 1, text: this.$t('newsletter.core_option_1') },
          { value: 2, text: this.$t('newsletter.core_option_2') },
          { value: 3, text: this.$t('newsletter.core_option_3') },
          { value: 4, text: this.$t('newsletter.core_option_4') },
          { value: 5, text: this.$t('newsletter.core_option_5') },
          { value: 6, text: this.$t('newsletter.core_option_6') },
          { value: 7, text: this.$t('newsletter.core_option_7') },
          { value: 8, text: this.$t('newsletter.core_option_8') },
          { value: 9, text: this.$t('newsletter.core_option_9') },
          { value: 10, text: this.$t('newsletter.core_option_10') },
          { value: 11, text: this.$t('newsletter.core_option_11') },
          { value: 12, text: this.$t('newsletter.core_option_12') },
        ],
        no_data: [],
        showReferralConfirm: false,
        media_links: 0,
        old_newsletter: {},
        showEventDisable: false,
        organization: {disable_section_email:0,disable_share_sections:0,disable_share_speakers:0},
        emailDisableCallback: null,
        emailDisableNote: "",
        showTestNewsletter: false,
        send_backedup: false,
        validEmail: false,
        member_map: new Map(),
        members: [],
        allow_top_performing: 0
        }
      },
    methods: {
      getAllowTopPerformers() {
        this.$http.post("/api/region/get/allowedrecog"+(this.useruid==''? "" : "/byuseruid"), {useruid: this.useruid}).then((response) => {
          this.allow_top_performing = response.data.allow_top_performing;
          if(this.allow_top_performing){
            this.officialObjects.push(29);
          }
          this.getNewsletter();
        }).catch(() => {});
      },
      openMemberSelection(o){
        this.selectedMemberObject = o;
        this.showMemberSelection=true;
      },
      deleteMember(m){
        if(this.selectedMemberObject){
          var index = this.selectedMemberObject.members.indexOf(m);
          if (index > -1) {
            this.selectedMemberObject.members.splice(index, 1);
          }
        }
      },
      deletePhoto(photo){
        var index = this.galleryPhotos.indexOf(photo);
        if (index > -1) {
          this.galleryPhotos.splice(index, 1);
        }
      },
      addPhotoToGallery(s){
        var number = 1;
        for(number=1; number<21; number++){
          if(this.galleryPhotos.filter(v => v.number == number).length==0){
            break;
          }
        }
        this.galleryPhotos.push({number: number, link: s, description: '', rank: this.galleryPhotos.length>0? this.galleryPhotos[this.galleryPhotos.length-1].rank+1: 1});
      },
      validateEmail() {
          if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.testEmail)) {
              this.validEmail= true;
          } else {
              this.validEmail= false;
          }
      },
      goToSettings(){
        if(this.permissions.access){
          router.push("/admin/general");
        }else{
          this.$bvToast.toast(this.$t('newsletter.contact_leader'), {
              variant: 'warning',
              solid: true
            });
        }
      },
      resetLayout(){
        this.tempObjects = this.tempObjects.filter(v => {
            if(this.isCorporateConnections && this.is_parent){
              return this.ccRegionalObjects.indexOf(v.id)>-1;
            }else if(this.isCorporateConnections){
              return this.ccObjects.indexOf(v.id)>-1;
            }else if(this.official==0){
              return this.startupObjects.indexOf(v.id)>-1;
            }else if(this.official==1 && this.is_parent){
              return this.regionalObjects.indexOf(v.id)>-1;
            }else if(this.official==1 && !this.is_parent){
              return this.officialObjects.indexOf(v.id)>-1;
            }else if(this.official==2){
              return this.rstObjects.indexOf(v.id)>-1;
            }
            return false;
          });
        this.newsletter.layout = "1;3,2;3,3;3,4;1,17;1,5;1,7;2,6;1,8;3,10;1,11;1,13;1,14;3,15;3,16;3";
        this.newsletter.split_layout = [];
        this.newsletterObjects = [];
        this.unusedObjects = [];
        var layoutIds = this.newsletter.layout.split(",");
            for(var x=0 ;x<layoutIds.length; x++){
              var o = layoutIds[x].split(";");
              for(var j=0; j<this.tempObjects.length;j++){
                if(o[0] == this.tempObjects[j].id+""){
                  this.newsletterObjects.push(this.tempObjects[j]);
                  if(o.length>1){
                    this.newsletterObjects[this.newsletterObjects.length-1].cspan = parseInt(o[1]);
                  }
                  break;
                }
              }
            }
            for(var y=0; y<this.tempObjects.length;y++){
               var n =this.newsletterObjects.filter(v => v.id==this.tempObjects[y].id);
                if(n.length==0 || (this.tempObjects[y].max>1 && n.length< this.tempObjects[y].max)){
                  this.unusedObjects.push(this.tempObjects[y]);
                }
          }
            var t  = this.newsletter.layout.split(",");
            for(var i=0; i<t.length; i++){
              o = t[i].split(";");
              this.newsletter.split_layout.push(o[0]);
            }
        this.showLayoutReset = false;
      },
      runEmailDisabledCallback(){
        this.showEventDisable = false; 
        if(this.emailDisableCallback != null){
          this.emailDisableCallback();
        }
      },
      showEmailDisableModal(note, callback){
        this.emailDisableNote = note;
        this.emailDisableCallback = callback;
        this.showEventDisable = true; 
      },
      endDragUnused(event){
        this.currentDrag = "";
        var htmlid = event.clone.id;
        var sp = htmlid.split("_");
        if(sp.length>1){
          var id = parseInt(sp[1]);
          var template = this.tempObjects.filter(v => v.id==id);
          var t =  this.unusedObjects.filter(v => v.id == id);
          if(template.length>0 && t.length==0){
            var max = template[0].max;
            if(max>0){
              var n = this.newsletterObjects.filter(v => v.id == id);
              if(n.length<max){
                this.unusedObjects.push(JSON.parse(JSON.stringify(template[0])));
                this.unusedObjects[this.unusedObjects.length-1].custom_id = 0;
              }
            } 
          }
        }
      },
      dragChoose(event){
         var htmlid = event.item.id;
        var sp = htmlid.split("_");
        if(sp.length>1){
          var id = parseInt(sp[1]);
          var t =  this.unusedObjects.filter(v => v.id == id)[0];
          if(t.max>0){
            var n = this.newsletterObjects.filter(v => v.id == id);
            for(var k=1; k<=12; k++){
              if(n.filter(v => v.custom_id==k).length==0){
                t.custom_id = k;
                t.title = "";
                t.content ="";
                break;
              }
            }
          }
        }
      },
      endDrag(event){
        this.currentDrag = "";
         var htmlid = event.clone.id;
        var sp = htmlid.split("_");
        if(sp.length>1){
          var id = parseInt(sp[1]);
          var n = this.unusedObjects.filter(v => v.id == id);
          if(n.length>1){
            const index = this.unusedObjects.indexOf(n[1]);
            if (index > -1) {
              this.unusedObjects.splice(index, 1);
            }
          }
        }
        
        /*this.newsletter.split_layout = [];
        for(var i=0; i<this.newsletterObjects.length;i++){
          this.newsletter.split_layout.push(this.newsletterObjects[i].id+'');
        }*/
        /*var htmlid = event.clone.id;
        var self = this;
        if(htmlid == "block_22" && this.newsletter.split_layout.indexOf("22")>-1 && this.organization.disable_events_email==0){
         this.showEmailDisableModal(this.$t("newsletter.events_disable_note"), () => {self.organization.disable_events_email=1;});
        }else if(htmlid == "unused_22" && this.newsletter.split_layout.indexOf("22")==-1 && this.organization.disable_events_email==1){
          this.showEmailDisableModal(this.$t("newsletter.events_enable_note"), () => {self.organization.disable_events_email=0;});
        }else
        if(htmlid == "block_5" && this.newsletter.split_layout.indexOf("5")>-1 && this.organization.disable_share_speakers==0){
         this.showEmailDisableModal(this.$t("newsletter.speaker_disable_note"), () => {self.organization.disable_share_speakers=1;});
        }else if(htmlid == "unused_5" && this.newsletter.split_layout.indexOf("5")==-1 && this.organization.disable_share_speakers==1){
          this.showEmailDisableModal(this.$t("newsletter.speaker_enable_note"), () => {self.organization.disable_share_speakers=0;});
        }else if(htmlid == "block_6" && this.newsletter.split_layout.indexOf("6")>-1 && this.organization.disable_share_sections==0){
         this.showEmailDisableModal(this.$t("newsletter.section_disable_note"), () => {self.organization.disable_share_sections=1;});
        }else if(htmlid == "unused_6" && this.newsletter.split_layout.indexOf("6")==-1 && this.organization.disable_share_sections==1){
          this.showEmailDisableModal(this.$t("newsletter.section_enable_note"), () => {self.organization.disable_share_sections=0;});
        }*/
      },
      startDrag(event){
        var htmlid = event.item.id;
        var sp = htmlid.split("_");
        if(sp.length>1){
          this.currentDrag = sp[1] +(sp.length>2? "_"+sp[2] : '');
        }
      },
    preview(use_backup){
        this.newsletter.useruid = this.useruid;
        this.newsletter.tracking_periods = 0;
        for(var i=0; i<this.newsletter.performance_select.length;i++){
          this.newsletter.tracking_periods |= this.newsletter.performance_select[i].value;
        }
        var t = "";
        for(i=0; i<this.newsletterObjects.length;i++){
          t+= (t.length==0? "": ",")+ this.newsletterObjects[i].id+";"+this.newsletterObjects[i].cspan+(this.newsletterObjects[i].custom_id>0? ";"+this.newsletterObjects[i].custom_id : "");
        }
        this.newsletter.layout = t;
        //this.$http.post("/api/organization/newsletter/update"+(this.useruid==''? "" : "/byuseruid"), this.newsletter).then((response) => {
        //  if (response.data.success === true) {
          this.newsletter.core_value = this.speaker.id>0? this.speaker.core_value_id : this.newsletter.core_value;
          this.newsletter.custom_objects = this.newsletterObjects.filter(v => v.custom_id>0);
          for(var i=0; i<this.newsletter.custom_objects.length;i++){
             if(this.newsletter.custom_objects[i].members && this.newsletter.custom_objects[i].members.length==1){
            this.newsletter.custom_objects[i].member_ids = this.newsletter.custom_objects[i].members[0].id+'';
          }else if(this.newsletter.custom_objects[i].members && this.newsletter.custom_objects[i].members.length>1){
            this.newsletter.custom_objects[i].member_ids = this.newsletter.custom_objects[i].members.reduce((v1,v2) => (v1.id? v1.id : v1)+","+v2.id);
          }else{
            this.newsletter.custom_objects[i].member_ids = '';
          }
          }
          this.newsletter.gallery_photos = this.galleryPhotos;
            this.$http.post("/api/organization/newsletter/build"+(this.useruid==''? "" : "/byuseruid"), {useruid: this.useruid, newsletter: this.newsletter, use_backup: use_backup, date: this.newsletter.send_date}).then((response) => {
              if(response.data.success){
                this.previewing = true;
                 this.previewhtml = response.data.html;
                 document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0; 
              }
          }).catch(() => { });
        //  }
       // }).catch(() => { this.$bvToast.show('serverError');});
        
    },
    
      getNewsletter() {
        this.$http.post("/api/organization/newsletter"+(this.useruid==''? "" : "/byuseruid"), {useruid: this.useruid}).then((response) => {
          this.is_parent = response.data.is_parent;
            this.official = response.data.official;
            if (response.data.success) {
              response.data.newsletter.performance_select = [];
              response.data.newsletter.split_layout = [];
              response.data.newsletter.meeting_recap_pic = response.data.newsletter.meeting_recap_pic==1
              response.data.newsletter.regional_events = response.data.newsletter.regional_events==1
              this.newsletter = response.data.newsletter;
           }
           if(this.isCorporateConnections && this.newsletter.core_value<8){
            this.newsletter.core_value = 8;
           }
           //$d(new Date((newsletter.backup_date_formatted != null && newsletter.backup_blocks>0?  newsletter.backup_date_formatted : next_meeting)+" 00:00:01"), 'short')
           this.newsletter_dates = [];
           if(response.data.next_meeting){
              this.newsletter.send_date = response.data.next_meeting;
              this.newsletter_dates.push({value: response.data.next_meeting, html: this.$t('newsletter.current_meeting')+': <b>'+ this.$d(new Date(response.data.next_meeting+" 00:00:01"), 'short')+"</b>"})
           }
           if(this.newsletter.backup_date_formatted){
              if(this.newsletter.send_date==null){
                this.newsletter.send_date = this.newsletter.backup_date_formatted;
              }
              this.newsletter_dates.push({value: this.newsletter.backup_date_formatted, html: this.$t('newsletter.previous_meeting')+': <b>'+ this.$d(new Date(this.newsletter.backup_date_formatted+" 00:00:01"), 'short')+"</b>"})
           }
           this.next_meeting = response.data.next_meeting;
           this.newsletter.asks_missing = response.data.missing;
           this.tracking_nums = [];
              for(var i=0; i<this.trackingOptions.length;i++){
                if((response.data.tracking_nums & Math.pow(2,i)) != 0){
                  this.tracking_nums.push(this.trackingOptions[i]);
                }
              }
              this.no_data = [];
              for(i=0; i<this.tracking_nums.length;i++){
                if((this.newsletter.tracking_periods & this.tracking_nums[i].value) != 0){
                  this.newsletter.performance_select.push(this.tracking_nums[i]);
                }
                if(!response.data.performance_data.includes(this.tracking_nums[i].value)){
                  this.no_data.push(this.tracking_nums[i].value);
                }
              }
            this.tempObjects = this.tempObjects.filter(v => {
               if(this.isCorporateConnections && this.is_parent){
                  return this.ccRegionalObjects.indexOf(v.id)>-1;
                }else if(this.isCorporateConnections){
                  return this.ccObjects.indexOf(v.id)>-1;
               }else if(this.official==0){
                return this.startupObjects.indexOf(v.id)>-1;
              }else if(this.official==1 && this.is_parent){
                return this.regionalObjects.indexOf(v.id)>-1;
              }else if(this.official==1 && !this.is_parent){
                return this.officialObjects.indexOf(v.id)>-1;
              }else if(this.official==2){
                return this.rstObjects.indexOf(v.id)>-1;
              }
              return false;
            });
            this.galleryPhotos = response.data.newsletter.gallery_photos? response.data.newsletter.gallery_photos : [];
            var custom_blocks = response.data.custom_blocks;
            var layoutIds = this.newsletter.layout.split(",");
            for(var x=0 ;x<layoutIds.length; x++){
              var o = layoutIds[x].split(";");
              for(var j=0; j<this.tempObjects.length;j++){
                if(o[0] == this.tempObjects[j].id+""){
                  var t = JSON.parse(JSON.stringify(this.tempObjects[j]));
                  if(o.length>1){
                    if(o.length>2){
                      for(var j=0; j<custom_blocks.length; j++){
                        if(custom_blocks[j].id == o[0] && o[2] == custom_blocks[j].custom_id){
                          t = JSON.parse(JSON.stringify(custom_blocks[j]));
                          t.resizeable = false;
                          
                          break;
                        }
                      }
                    }
                    t.cspan = parseInt(o[1]);
                  }
                  t.members = [];
                  this.newsletterObjects.push(t);
                  break;
                }
              }
            }
            for(var y=0; y<this.tempObjects.length;y++){
               var n =this.newsletterObjects.filter(v => v.id==this.tempObjects[y].id);
                if(n.length==0 || (this.tempObjects[y].max>1 && n.length< this.tempObjects[y].max)){
                  this.unusedObjects.push(this.tempObjects[y]);
                }
          }
            var t  = this.newsletter.layout.split(",");
            for(i=0; i<t.length; i++){
              o = t[i].split(";");
              this.newsletter.split_layout.push(o[0]);
            }
            this.newsletter.useruid = this.useruid;
            Object.assign(this.old_newsletter, this.newsletter);
            this.getMembers();
            this.getCoreValueSpeaker();
            if(!response.data.success){
              this.updateNewsletter(true);
            }
        }).catch((e) => { console.log(e);});
      },
      updateNewsletter(noback) {
        this.newsletter.tracking_periods = 0;
        for(var i=0; i<this.newsletter.performance_select.length;i++){
          this.newsletter.tracking_periods |= this.newsletter.performance_select[i].value;
        }
        var t = "";
        for(i=0; i<this.newsletterObjects.length;i++){
          t+= (t.length==0? "": ",")+ this.newsletterObjects[i].id+";"+this.newsletterObjects[i].cspan+(this.newsletterObjects[i].custom_id>0? ";"+this.newsletterObjects[i].custom_id : "");
        }
        this.newsletter.layout = t;
        
      this.newsletter.useruid = this.useruid;

        Object.assign(this.old_newsletter, this.newsletter);
        this.updateDisabledEmails();

        this.newsletter.custom_objects = this.newsletterObjects.filter(v => v.custom_id>0);
        for(i=0; i<this.newsletter.custom_objects.length;i++){
          if(this.newsletter.custom_objects[i].members && this.newsletter.custom_objects[i].members.length==1){
            this.newsletter.custom_objects[i].member_ids = this.newsletter.custom_objects[i].members[0].id+'';
          }else if(this.newsletter.custom_objects[i].members && this.newsletter.custom_objects[i].members.length>1){
            this.newsletter.custom_objects[i].member_ids = this.newsletter.custom_objects[i].members.reduce((v1,v2) => (v1.id? v1.id : v1)+","+v2.id);
          }else{
            this.newsletter.custom_objects[i].member_ids = '';
          }
        }
        this.newsletter.gallery_photos = this.galleryPhotos;
        if(this.newsletter.custom_objects==null){
          this.newsletter.custom_objects = [];
        }
        this.newsletter.speaker = this.speaker;
        this.$http.post("/api/organization/newsletter/update"+(this.useruid==''? "" : "/byuseruid"), this.newsletter).then((response) => {
          if (response.data.success === true) {
            if(noback===true){
              return;
            }
            if(this.useruid==''){
              this.justSaved = true;
              this.back();
            }else{
              this.$bvToast.toast(this.$t('newsletter.successful_save'), {
                variant: 'success',
                solid: true
              });
            }
            
          }
        }).catch(() => { this.$bvToast.show('serverError');});
      },
      showSendModal(use_backup){
        this.send_backedup = use_backup;
        this.newsletter.useruid = this.useruid;
        if(this.is_parent){
          this.$http.post("/api/region/members/newsletter/count"+(this.useruid==''? "" : "/byuseruid"), {}).then((response) => {
            if (response.data.success) {
              this.regionalMembers = response.data;
                this.showNewsletterRegionalSend = true;
            }
          }).catch((err) => { this.$bvToast.show('serverError');});
        }else{
          if(this.newsletter.meeting_recap == null || this.newsletter.meeting_recap.length<15){
            this.confirm_step = 0;
          }else{
            this.confirm_step = 1;
          }
          this.showNewsletterSend = true;
        }
        
      },
      showSendTestModal(use_backup){
        this.send_backedup = use_backup;
        this.newsletter.useruid = this.useruid;
        this.testEmail = this.getCookie("test_email");
        this.validateEmail();
        this.showTestNewsletter = true;
      },  
      confirmSend(){
        if(this.confirm_step==0){
          this.confirm_step+=1;
        }else if(this.confirm_step == 1){
          this.showNewsletterSend = false;
          this.emailNewsletter(false);
        }
      },
      confirmRegionalSend(){
        this.showNewsletterRegionalSend = false;
        this.emailNewsletter(false);
      },
      confirmTest(){
        this.showTestNewsletter= false;
        this.setCookie("test_email", this.testEmail);
        this.emailNewsletter(true);
      },
      sendAskRequestEmails(){
        this.showReferralConfirm = false;
         this.$http.post("/api/member/referral/request/all/email"+(this.useruid==''? "" : "/byuseruid"), {useruid: this.useruid }).then(response => {
          if (response.data.success === true) {
            this.$bvToast.toast(this.$t('newsletter.successful_request_send'), {
                variant: 'success',
                solid: true
              });
          } 
        })
        .catch(() => {});
      },
      emailNewsletter(test){
        Object.assign(this.old_newsletter, this.newsletter);
        this.newsletter.custom_objects = this.newsletterObjects.filter(v => v.custom_id>0);
        for(var i=0; i<this.newsletter.custom_objects.length;i++){
           if(this.newsletter.custom_objects[i].members && this.newsletter.custom_objects[i].members.length==1){
            this.newsletter.custom_objects[i].member_ids = this.newsletter.custom_objects[i].members[0].id+'';
          }else if(this.newsletter.custom_objects[i].members && this.newsletter.custom_objects[i].members.length>1){
            this.newsletter.custom_objects[i].member_ids = this.newsletter.custom_objects[i].members.reduce((v1,v2) => (v1.id? v1.id : v1)+","+v2.id);
          }else{
            this.newsletter.custom_objects[i].member_ids = '';
          }
        }
        this.newsletter.gallery_photos = this.galleryPhotos;
        this.newsletter.speaker = this.speaker;
        this.$http.post("/api/organization/newsletter/update"+(this.useruid==''? "" : "/byuseruid"), this.newsletter).then((response) => {
          if (response.data.success === true) {
            this.$http.post("/api/organization/newsletter/email/current"+(this.useruid==''? "" : "/byuseruid"), {useruid: this.useruid, test: test, use_backup: this.send_backedup, test_email: this.testEmail, regionalSendOptions: this.regionalSendOptions, date: this.newsletter.send_date }).then(response => {
            if (response.data.success === true) {
              if(test){
                this.$bvToast.toast(this.$t('newsletter.successful_test')+" "+response.data.message, {
                  variant: 'success',
                  solid: true
                });
              }else{
                this.$bvToast.toast(this.$t('newsletter.successful_send'), {
                  variant: 'success',
                  solid: true
                });
              }
              
            } 
          }).catch(() => {});
          }
          });
        
      },
      back() {
        if(!this.permissions.organization.is_parent){
          router.push("/admin/dashboard");
        }else{
          router.push("/admin/regiondash");
        }
        
      },
      openPhotoEdit(sf){
        this.imageEdit= {photo: "", successFunc: null}
        this.showPhotoEdit=true
        this.imageEdit.successFunc = sf;
      },
      cropImage() {
        this.$refs.gallerySelector.cropImage(this.imageEdit.successFunc);
        this.showPhotoEdit = false;
      },
      getMembers() {
        this.$http.post(this.is_parent? "/api/organization/members/all/select" : ("/api/organization/members/email/select"+(this.useruid==''? "" : "/byuseruid")) , {useruid: this.useruid }).then((response) => {
          this.members = response.data.members;
          this.members.unshift({id: 0, name: ""});
          this.member_map = new Map();
          for(var i=0; i<this.members.length;i++){
            this.member_map.set(this.members[i].id, this.members[i]);
          }
          if(this.is_parent){
            for(var i=0; i<this.newsletterObjects.length; i++){
              if(this.newsletterObjects[i].member_ids){
                 var sp = this.newsletterObjects[i].member_ids.split(",");
                  this.newsletterObjects[i].members = [];
                  for(var j=0; j<sp.length;j++){
                    var k = this.member_map.get(parseInt(sp[j]));
                    if(k && k != undefined && k.id>0){
                      this.newsletterObjects[i].members.push(k);
                    }
                  }
              }
            }
          }
        }).catch(() => {});
      },
      getDisabledEmails() {
        this.$http.post("/api/organization/get/disabledemails"+(this.useruid==''? "" : "/byuseruid"), {useruid: this.useruid }).then((response) => {
          if(response.data.success){
            this.organization = response.data.organization;
          }
        }).catch(() => {});
      },
      updateDisabledEmails() {
        this.organization.useruid = this.useruid;
        this.$http.post("/api/organization/update/disabledemails"+(this.useruid==''? "" : "/byuseruid"), this.organization).catch(() => {});
      },
      getMediaDefined(){
          this.$http.post("/api/organization/media/count"+(this.useruid==''? "" : "/byuseruid"), {useruid: this.useruid }).then((response) => {
          this.media_links = response.data.media_links;
        }).catch(() => {});
      },
      getCoreValueSpeaker() {
          this.$http.post("/api/organization/corevaluespeakers/get/bydate", {day: this.newsletter.send_date}).then((response) => {
            if (response.data.success) {
              this.speaker = response.data.speaker;
            }
          }).catch(() => {});
      },
    },
    beforeRouteLeave (to, from, next) { 
      if(this.justSaved){
        next();
        return;
      }

      if(JSON.stringify(this.newsletter) != JSON.stringify(this.old_newsletter)){
        const answer = window.confirm(this.$t('newsletter.unsaved_changes'))
        if(answer){
          next();
        }else{
          next(false);
        }
      }else{
        next();
      }
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-newsletter"), "active-menu");
      this.removeClass(document.getElementById("menu-meeting"), "active-menu");
    },
    mounted() {
      if(this.$route.params.useruid){
        this.useruid = this.$route.params.useruid;
      }
      this.addClass(document.getElementById("menu-newsletter"), "active-menu");
      this.addClass(document.getElementById("menu-meeting"), "active-menu");
      
      this.getDisabledEmails();
       this.getAllowTopPerformers();
       this.getMediaDefined();
        
      }
    }
</script>
