<template>
  <div id="dash">
      <Header :byId="true"  :title="$t('dashboard.compact_mode')" :xlogout="true" :showDate="false" :showOptions="true"></Header>
    <div id="meeting-box">
       <div id="meeting-description" class="fit-width">
          <div id="organization-name" class="meeting-descript">{{permissions.organization.name}}</div>
          <div v-if="week_data.total_weeks>0" id="week-data">{{$t('dashboard.meeting_text', week_data)}}</div>
          <div id="meet-start"><b>{{next_meeting_date? $d(next_meeting_date, 'day_month_long'): ""}}</b> @ {{meet_start}}</div>
        </div>
        <b-alert :show="debug_mode" class="fit-width auto-margin" variant="danger">{{ $t('general.debug_message1') }}<br/>{{ $t('general.debug_message2') }}</b-alert>
      <div class="fit-width auto-margin" >
        <div class="portal-option large-top-margin" v-on:click="goToController">
            <div class="option-title" >{{$t('portal.meeting_controller')}}</div>
            <img  src="../assets/home-MeetingController2.png" />
            <div class="option-description" >{{$t('portal.controller_description')}}</div>
            </div>
        <div  class="portal-option large-top-margin" v-on:click="checks = []; showSystemCheck = true;">
            <div class="option-title" >{{$t('portal.system_check')}}</div>
            <img src="../assets/home-SystemCheck2.png"  />
            <div class="option-description" >{{$t('portal.system_description')}}</div>
        </div>
      </div>

      <b-button id="full-screen-button" class="action-button" v-on:click="goToDashboard" variant="clear-blue" ><span>{{$t('dashboard.full_mode')}}</span></b-button>
      
      <b-modal id="slide-modal" centered v-model="showSystemCheck" :title="$t('dashboard.system_check')" @hidden="onHidden">
        <div class="auto-overflow">
          <div id="checks-description">{{$t('dashboard.checks_description')}}</div>
          <div id="checks-grid">
            <div class="system-box">
              <img src="../assets/SysCheck-Connection.png" />
              <div>
                <div>{{$t('dashboard.connectivity_title')}}</div>
                <span>{{$t('dashboard.connectivity_descrip')}}</span>
              </div>
            </div>
            <div class="system-box">
              <img src="../assets/SysCheck-Media.png" />
              <div>
                <div>{{$t('dashboard.browser_title')}}</div>
                <span>{{$t('dashboard.browser_descrip')}}</span>
              </div>
            </div>
            <div class="system-box">
              <img src="../assets/SysCheck-Popups.png" />
              <div>
                <div>{{$t('dashboard.popup_title')}}</div>
                <span>{{$t('dashboard.popup_descrip')}}</span>
              </div>
            </div>
            <b-button v-if="checks.length==0" id="run-checks-button" class="action-button" v-on:click="runChecks()" variant="clear-blue">
              <div class="test-button"></div>
              <b>{{$t('dashboard.test_all')}}</b>
            </b-button>
            <div v-for="check in checks" :key="check.row" class="system-box" :style="{'grid-column': '2/3', 'grid-row': check.row+'/'+(check.row+1), 'max-width' : '300px'}">
              <img :src="getImgUrl(check.image)" />
              <div>
                <div :style="{'color' : check.image=='cross-red.png'? 'red' : 'rgb(0, 60, 128)'}">{{check.title}}</div>
                <span>{{check.descrip}}</span>
              </div>
            </div>
          </div>
          <div id="system-box-wrapper">
            <div class="system-box">
              <img :src="soundPlaying? getImgUrl('SysCheck-Audio-animated.gif') : getImgUrl('SysCheck-Audio.png')" />
              <div>
                <div :style="{'color':soundPlaying? ' #00aeef' : ''}">{{soundPlaying? $t('dashboard.sound_title2') : $t('dashboard.sound_title')}}</div>
                <span>{{soundPlaying? $t('dashboard.sound_descrip2') : $t('dashboard.sound_descrip')}}</span>
              </div>
            </div>
            <b-button v-if="!soundPlaying" class="action-button" v-on:click="playSound" :disabled="soundPlaying" variant="clear-blue sound-button">
              <div class="test-button"></div>
              <b>{{$t('dashboard.button_test')}}</b>
            </b-button>
            <b-button v-if="soundPlaying" class="action-button" v-on:click.stop="stopSound" variant="clear-blue sound-button">
              <div class="cancel-button"></div>
              <b>{{$t('dashboard.button_stop')}}</b>
            </b-button>
          </div>
        </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="action-button" v-on:click="showSystemCheck = false;" variant="clear-blue">
            <div class="save-button"></div>
            <span>{{$t('global.button_ok')}}</span>
          </b-button>
        </div>
      </b-modal>

      <b-modal id="browser-warning" centered v-model="showBrowserWarning">
        <div slot="modal-header" id="warning-text">
          {{$t('dashboard.warning_text')}}
        </div>
        <div id="browser-warning" v-html="$t('dashboard.browser_warning')">
          
        </div>
        <div slot="modal-footer" class="submit-box-2">
           <b-button class="action-button" v-on:click="showBrowserWarning = false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span>
          </b-button>
          <b-button class="action-button" v-on:click="continueMeetingClick" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_continue')}}</span>
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<style scoped lang="scss">
.large-top-margin{
  margin-top: 50px !important;
}
#meet-start{
  color:#333;font-size:16px;
}
#week-data{
  color:#333;font-size:12px;color:#a9a9a9;
}
#organization-name{
  color:#ff0000;margin: 0 50px;font-size:26px;
}
#full-screen-button{
  margin:auto;margin-top: 70px;border: 1px solid #eee;padding: 5px 40px;color: $VUE_APP_HOTLINK_COLOUR;
}
.auto-overflow{
  overflow:auto;
}
#checks-description{
  color:rgb(0, 174, 239);text-align:center;
}
#checks-grid{
  border:1px solid #ccc;display:grid; grid-template-columns: auto max-content;margin:10px;border-radius:10px;
}
#run-checks-button{
  grid-column: 2/3; grid-row: 2/3;margin: auto 50px;
}
#browser-warning{
  overflow:auto; text-align: center;
}
#warning-text{
  font-size:30px;color:red;width:100%;
}
.sound-button{
  margin: auto 50px
}
#system-box-wrapper{
  border:1px solid #ccc;display:grid; grid-template-columns: auto max-content;margin:10px;border-radius:10px;
}
.bucket-footer{
  text-align: center;
    font-size: 11px;
    color: #ccc;
    font-style: italic;
}

  .system-box{
    grid-column: 1/2;
    display:grid;
    grid-template-columns: max-content auto;
    margin:10px;
  }
  
  .system-box > img{
    width: 40px;
    margin-right:10px;
    filter: $VUE_APP_COLOUR_MAIN_FILTER;
  }
  .system-box > div{
    display: grid;
    grid-template-rows: max-content max-content;
    text-align: left;
  }
  .system-box > div > div{
    font-size: 16px;
    font-weight: bold;
  }
  .system-box > div > span{
    font-size: 14px;
    color: #999;
  }
  .option-description-link{
    color: $VUE_APP_HOTLINK_COLOUR;
    cursor:pointer;
  }
  .option-description-link:hover{
    text-decoration: underline;
  }
  .dash-checkmark{
    width: 14px !important;
    height: 12px !important;
    background-size: contain !important;
    background: url("../assets/checkmark-green-HOME.png") transparent no-repeat;
    margin: auto;
  }
  .dash-redx{
   width: 14px !important;
    height: 12px !important;
    background-size: contain !important;
    background: url("../assets/cross-red-HOME.png") transparent no-repeat;
    margin: auto;
  }
  .dashboard-object{
    text-align: left;
    border: #ccc solid 1px;
    border-radius: 10px;
    padding: 10px;
    width:100%;
    height: fit-content;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    cursor: pointer;
  }
  .dashboard-object:hover{
    background-color: $VUE_APP_TABLE_HOVER_COLOUR;
  }
  #dashboard-sections{
    display:grid;
    grid-gap: 20px;
     grid-template-areas: "a b" ;
    grid-template-columns: auto max-content;
    margin: 0 auto;
    width: fit-content;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: 100%; max-width:1480px;

  }
  .dashboard-section{
    width:100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap:20px;
    height: fit-content;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    padding: 10px;
    row-gap: 15px;
  }
  .dashboard-title{
    border: 1px #f2f2f2 solid;
    color: #7f7f7f;
    border-radius: 2px;
    font-size: 19px;
    background-color: #fafafa;
    padding: 5px;
    margin-top: 15px;
    line-height: 19px;
    display:grid;
    grid-template-columns: max-content auto;
  }
  .dashboard-title > img{
    height: 19px;
  }
  .dashboard-title > span{
    margin-left: 3px;
  }
  .option-title{
      color:$VUE_APP_COLOUR_DASH_TEXT;
      /*font-size:18px;
      font-weight: bold;*/
    }
    .option-description{
      color:#999;
      font-size:14px;
      text-align: left;
    }

    .dashboard-bucket{
      text-align: left;
      border: #ccc solid 1px;
      border-radius: 10px;
      width:320px;
      height: fit-content;
      height: -moz-fit-content;
      height: -webkit-fit-content;
      margin-bottom:15px;
    }

    .bucket-title{
      color: $VUE_APP_COLOUR_DASH_TEXT;
      font-size: 18px;
      padding: 5px;
      background-color: #fafafa;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      border-bottom: 1px solid #ccc;
    }

    .bucket-title >img{
      margin-right: 5px;
      height:20px;
    }

    .bucket-body{
      color: rgb(170, 170, 170);
      font-size: 14px;
      padding: 10px 30px;
      
      
    }

    .hot-bucket-body{
      cursor: pointer;
    }

    .hot-bucket-body:hover{
      background-color: $VUE_APP_TABLE_HOVER_COLOUR;
    }

    .hot-bucket{
      cursor: pointer;
    }

    .hot-bucket:hover{
      background-color: $VUE_APP_TABLE_HOVER_COLOUR
    }
    
    .portal-option{
      text-align: center;
      border: #ccc solid 1px;
      border-radius: 10px;
      padding: 10px;
      max-width: 200px;
      height: fit-content;
      height: -moz-fit-content;
      height: -webkit-fit-content;
      cursor: pointer;
    }
    .portal-option>img{
      max-height: 50px;
      margin:auto;
      padding: 3px;
      
    filter: $VUE_APP_COLOUR_MAIN_FILTER;
    }
    .portal-option:hover{
      /*background-color: #d3e3f0;*/
      background-color: $VUE_APP_TABLE_HOVER_COLOUR;
    }
  
  #meeting-box-right {
    font-size: 16px;
    padding: 5%;
    overflow: auto;
    display: grid;
  }
  .speaker-description {
    display: grid;
    grid-template-columns: 60px auto;
    font-size: 16px;
  }
  .speaker-number {
    text-align: center;
    font-size: 20px;
  }
  #speaker-icon {
    display: grid;
    grid-template-columns: max-content max-content;
    margin: auto;
    width: fit-content;
  }
  #speaker-icon > img {
    margin: auto;
    margin-right: 10px;
  }
  #speaker-icon > span {
    margin: auto;
    color: #999;
  }
  #meeting-box-middle {
    padding: 0 5%;
    margin: 5% 0;
    overflow: auto;
    border-right: 1px #999 solid;
  }
  .note-divider {
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 0px;
  }
  #meeting-box-left {
    overflow: auto;
    padding: 5%;
  }
  #meeting-logo {
    display: grid;
    grid-template-columns: max-content max-content;
    margin: auto;
    width: fit-content;
  }
  #meeting-logo > img {
    margin: auto;
    margin-right: 10px;
  }
  #meeting-logo > span {
    margin: auto;
    color: #999;
  }
  #meeting-box {
    height: 100%;
  }
  .meeting-descript {
    font-size: 30px;
    color:$VUE_APP_CHAPTER_NAME;margin: 0 50px;font-size:26px;
  }
  #meeting-description {
    background-color: #fafafa;
    min-width: 200px;
    grid-template-columns: 1fr;
    text-align: center;
    grid-template-rows: repeat(4, max-content);
    height: fit-content;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    margin-top: 5%;
    border: 1px #f2f2f2 solid;
    border-radius: 2px;
    max-width: 1000px;margin: 30px auto; padding: 10px 75px;margin-bottom: 15px;
  }


  #dash {
    height: calc(100% - 50px);
    position: relative;
    bottom: 0px;
    top: 0px;
    right: 0px;
    left: 0px;
    display: block;
  }
  .speaker-link {
    color: #00aeef;
        vertical-align: unset;
        font-weight: bold;
  }
</style>
  <script>
import router from "../router";
import sound1 from "../assets/sounds/ding-sound.mp3"
export default {
  name: "Mobile",
  watch: {
    '$i18n.locale': { 
      handler: function () {
          if (!this.audio_object.paused && !this.audio_object.paused) {
            this.audioText= this.$t('portal.playing_chime');
          } else {
            this.audioText= this.$t('portal.test_audio');
          }
      }
    }
  },
  data() {
    return {
      showBrowserWarning: false,
      soundPlaying:false,
      showSystemCheck: false,
      next_meeting: "",
      orgid: 0,
      showAlert: false,
      showSuccess: false,
      alertText: "",
      successText: "",
      next_meeting_date: null,
      audio_object: null,
      audio_timeout: null,
      
      audioText: this.$t("portal.test_audio"),
      meet_start: "",
       checks: [],
       week_data: {week: 0, total_weeks: 0},
       continueMeetingCallback: null,
       test_callback: null,
       test_count: 0,
    };
  },
  sockets: {
    test_return(data){
      this.test_count++;
      if(data.success && this.test_callback){
        this.test_callback();
      }
      if(this.test_count<5){
        this.$socket.emit("test", { });
      }
    }
  },
  methods: {
    continueMeetingClick(){
      this.showBrowserWarning = false;
      if(this.continueMeetingCallback != null){
        this.continueMeetingCallback();
      }
    },
    goToDashboard(){
        router.push("/admin/dashboard/")
    },
    goToController(){
     this.checkBrowerWarning("#/controller/view");
    },
    runChecks(){
      this.checks = [];

      var self = this;
      this.getConnectivity(function(){
        if(self.test_count==1){
          self.checks.push({title: self.$t('dashboard.complete_connectivity_title1'), descrip: self.$t('dashboard.complete_connectivity_descrip1')+" ("+self.test_count+"/5)", image: 'checkmark-orange.png', row: 1});
        }else if(self.test_count==5){
          for(var g=0; g<self.checks.length;g++){
            if(self.checks[g].title == self.$t('dashboard.complete_connectivity_title1')){
              self.checks[g].title = self.$t('dashboard.complete_connectivity_title2');
              self.checks[g].descrip = self.$t('dashboard.complete_connectivity_descrip2');
              self.checks[g].image = 'checkmark-green.png';
            }
          }
        }else{
           for(var h=0; h<self.checks.length;h++){
            if(self.checks[h].title == self.$t('dashboard.complete_connectivity_title1')){
              self.checks[h].descrip = self.$t('dashboard.complete_connectivity_descrip1')+" ("+self.test_count+"/5)";
            }
          }
        }
      });

      setTimeout(function(){
        if(self.test_count<5){
          var updated = false;
          for(var g=0; g<self.checks.length;g++){
            if(self.checks[g].title == self.$t('dashboard.complete_connectivity_title1')){
              self.checks[g].title = self.$t('dashboard.complete_connectivity_title3');
              self.checks[g].descrip = self.$t('dashboard.complete_connectivity_descrip3');
              self.checks[g].image = 'cross-red.png';
              updated = true;
            }
          }
          if(!updated){
            self.checks.push({title: self.$t('dashboard.complete_connectivity_title3'), descrip: self.$t('dashboard.complete_connectivity_descrip3'), image: 'cross-red.png', row: 3});
          }
        }
      },3000);

      var browser = this.getBrowser();
      var browser_title = browser.chrome? this.$t('dashboard.complete_browser_title1') : (browser.firefox? this.$t('dashboard.complete_browser_title2') :this.$t('dashboard.complete_browser_title3'));
      var browser_descrip = browser.chrome? this.$t('dashboard.complete_browser_descrip1') : (browser.firefox? this.$t('dashboard.complete_browser_descrip2') :this.$t('dashboard.complete_browser_descrip3'));
      this.checks.push({title: browser_title, descrip: browser_descrip, image: browser.chrome? 'checkmark-green.png' : (browser.firefox? 'checkmark-orange.png' : 'cross-red.png'), row: 2});


      var popup = !this.getPopupBlocked();
      this.checks.push({title: popup? this.$t('dashboard.complete_popup_title1') : this.$t('dashboard.complete_popup_title2'), descrip: popup? this.$t('dashboard.complete_popup_descrip1') : this.$t('dashboard.complete_popup_descrip2'), image: popup? 'checkmark-green.png' : 'cross-red.png', row: 3});
    },  
    onHidden(){
     this.stopSound();
    },
    stopSound(){
      this.soundPlaying = false;
      while(!this.audio_object.paused){
        this.audio_object.pause();
      }
        
    },
    playSound() {
      if (this.audio_object) {
        if (this.soundPlaying) {
          this.soundPlaying = false;
          this.audio_object.pause();
          this.audioText = this.$t("portal.test_audio");
        } else {
          this.audio_object.play();
          this.audioText = this.$t("portal.playing_chime");
          this.soundPlaying = true;
        }
      }
    },
    getConnectivity(callback){
      this.test_count = 0;
      this.test_callback = callback;
      this.$socket.emit("test", { });
    },
    getBrowser(){
      /*var browser = (function (agent) {
        switch (true) {
            case agent.indexOf("edge") > -1: return "MS Edge (EdgeHtml)";
            case agent.indexOf("edg") > -1: return "MS Edge Chromium";
            case agent.indexOf("opr") > -1 && !!window.opr: return "opera";
            case agent.indexOf("chrome") > -1 && !!window.chrome: return "chrome";
            case agent.indexOf("trident") > -1: return "Internet Explorer";
            case agent.indexOf("firefox") > -1: return "firefox";
            case agent.indexOf("safari") > -1: return "safari";
            default: return "other";
        }
    })(window.navigator.userAgent.toLowerCase());
      // Firefox 1.0+
      //var isFirefox = (typeof InstallTrigger !== 'undefined') || (!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime));
      // Chrome 1 - 79
      //var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime) && navigator.userAgent.indexOf("Edg") == -1;
      var isFirefox = (browser == "firefox");
      var isChrome = (browser == "chrome");*/
      return {chrome: this.$browserDetect.isChrome || this.$browserDetect.isChromeIOS || this.$browserDetect.meta.name.toLocaleLowerCase().includes("chrome"), firefox: this.$browserDetect.isFirefox};
    },
    getPopupBlocked(){
      var newWin = window.open("https://www.google.com", "_blank",'');             
      if(!newWin || newWin.closed || typeof newWin.closed=='undefined') 
      { 
          return true;
      }
      newWin.close();
      return false;
    },
    checkBrowerWarning(l){
      var browser = this.getBrowser();
      if(browser.chrome || !this.permissions.warn_browser){
        var w= window.open(l, "_blank", 'location=no,status=no,titlebar=no,toolbar=no,menubar=no');
        this.$root.$emit('newPage', w);
        return true;
      }else{
        this.showBrowserWarning = true;
        this.permissions.warn_browser = false;
        this.continueMeetingCallback = function(){
          var w= window.open(l, "_blank", 'location=no,status=no,titlebar=no,toolbar=no,menubar=no');
          this.$root.$emit('newPage', w);
        }
        return false;
      }
    },
    getDates() {
      this.$http.post("/api/organization/dates", {}).then(response => {
          if (response.data.success === true) {
            this.next_meeting = response.data.next_formatted;
            this.orgid = response.data.id;
            //this.getSpeakers(this.next_meeting);
            this.next_meeting_date =this.next_meeting.length > 0? new Date("" + this.next_meeting.split("-").join("/") + " 00:01"): "";
          }
        }).catch(() => {});
        this.$http.post("/api/organization/get/starttime", {}).then(response => {
            if (response.data.success === true) {
                this.meet_start = response.data.meet_start;
            }
        }).catch(() => {});
        this.$http.post("/api/organization/weeks", {}).then(response => {
        if (response.data.success === true) {
            this.week_data = response.data;
        }
        })
        .catch(() => {});
    }
  },
  destroyed() {
  },
  mounted() {
      this.audio_object = document.createElement("audio");
    this.audio_object.src = sound1;
    var self = this;
    this.audio_object.addEventListener("ended", function() {
      self.audio_timeout = setTimeout(function() {
        if(self.soundPlaying){
          self.audio_object.play();
        }
        
      }, 500);
    });
    this.authenticate(function(){
        self.getDates();
        document.title = self.$t('global.document_title') +" "+ self.permissions.organization.name;
    });
  }
};
</script>
