<template>
  <div>
    <Header :useruid="this.$route.params.useruid" :title="$t('category.manage_category')" :showDate="false"></Header>
    <div class="rel-grid-container">
      <div ></div>
      <form v-on:submit.prevent="updateCategory" class="form-fix">
        <span class="form-box-title">{{$t('category.desired_category')}}</span>
        <div class="form-box">
          <span class="form-box-name">{{$t('category.name_text')}}</span><b-form-input id="name-input" v-model="category.name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input" required></b-form-input>
          <div id="disabled-option">
          <b-form-checkbox  v-model="category.disabled" value="1" unchecked-value="0">
                  {{$t('member.disabled_text')}}
                </b-form-checkbox>
              </div>
        </div>
        <b-alert variant="danger" id="alert-box" class="fit-width" dismissible :show="showAlert" @dismissed="showAlert=false">
            {{alertText}}
          </b-alert>
        <div class="submit-box-2">
          <b-button v-b-modal.modal-center v-if="category.id>0" class="action-button" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          <b-button class="action-button" v-on:click="back" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="action-button" type="submit" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
        </div>
        <b-modal id="modal-center" v-model="showDelete" centered :title="$t('category.delete_title')">
          <p class="my-4">{{$t('category.delete_text')}}
            {{ category.disabled }}</p>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="showDelete=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" v-on:click="deleteCategory" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          </div>
        </b-modal>
      </form>
      <div class="div-space"></div>
    </div>
    <Footer :useruid="this.$route.params.useruid"></Footer>
  </div>
</template>
<style scoped>
#alert-box{
  margin: 10px auto;
}
#disabled-option{
  grid-column: 2;
}
#name-input{
  max-width: 300px;
}
  
</style>
<script>
  import router from "../router"
    export default {
    name: "Category",
   
      data() {
        return {
          showDelete: false,
          saveVariant: "blue",
          category: {id:0,name: "",disabled:false},
          useruid: '',
          showAlert: false,
          alertText: ''
        }
      },
        methods: {
          getCategory() {
            this.category.id = this.$route.params.id;
            if (this.category.id > 0) {
              this.category.useruid = this.useruid;
              this.$http.post("/api/organization/category/get"+(this.useruid==''? "" : "/byuseruid"), this.category).then((response) => {
                if (response.data.success) {
                  this.category = response.data.category;
                }
              }).catch(() => {});
            } 
          },
          updateCategory() {
            this.showAlert = false;
            this.category.useruid = this.useruid;
            this.$http.post("/api/organization/category/update"+(this.useruid==''? "" : "/byuseruid"), this.category).then((response) => {
              
              if (response.data.success === true) {
                this.saveVariant = 'success';
                this.back();
              }else if(response.data.success && response.data.success.errorcode == 2){
                this.showAlert = true;
                this.alertText = this.$t('category.duplicate_name');
              } else {
                this.saveVariant = 'danger';
              }
            }).catch(() => {this.$bvToast.show('serverError');});
          },
          deleteCategory() {
            this.category.useruid = this.useruid;
            this.$http.post("/api/organization/category/delete"+(this.useruid==''? "" : "/byuseruid"), this.category).then((response) => {
              if (response.data.success === true) {
                this.back();
              }
            }).catch(() => {});
          },
          back(){
             if (this.permissions.access) {
                router.push("/admin/categories");
              }else if(this.useruid.length>0){
                router.push("/categories/"+this.useruid);
              }
          }
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-categories"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      if(this.$route.params.useruid){
        this.useruid = this.$route.params.useruid;
      }
      this.addClass(document.getElementById("menu-categories"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
      this.getCategory();
    }
    }
</script>
