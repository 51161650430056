<template>
  <div class="full-height">
    <div id="setup-main-box">
        <div ></div>
        <div id="trans-wrapper">
            <div class="left-text">
                <img id="setup-logo" src="../assets/logo-MeetingHubwSlogan2020.png" />
            </div>
        <transition-group :name="slide_transition">

             <div v-if="view == 1" class="transition-view"  ref="view1" key="view1">
                 <div class="fit-width">
                    <span v-html="$t('setup.view1_text1')"></span><br/><br/>
                    <span class="center-text">{{$t('setup.view1_text2')}}:</span>
                    <b-form-select class="fit-width auto-margin" v-model="organization.region" :options="regions" value-field="id" text-field="name"/>
                    <b-button v-if="organization.region>0" class="action-button setup-next-button" v-on:click="goToView2" variant="clear-blue"><div class="next-button"></div><span>{{$t('global.button_next')}}</span></b-button>
                </div>
            </div>
            <div v-if="view == 2" class="transition-view" ref="view2" key="view2">
                <div class="fit-width">
                    <span v-html="$t('setup.view2_text1', {region: organization.org_name})"></span>
                    <div v-for="contact in signup_contacts" :key="contact.id" class="contact-button" v-on:click="goToView3(contact.id)">
                        <div>{{contact.name}}</div>
                        <div>{{contact.email}}</div>
                    </div>
                    <span id="view2-text2" v-html="$t('setup.view2_text2')"></span>
                </div>
            </div>
            <div v-if="view == 3" class="transition-view"  ref="view3" key="view3">
                <div class="fit-width">
                    <span v-html="$t('setup.view3_text1', {region: organization.org_name})"></span><br/>
                    <span class="center-text">{{organization.email}}</span><br/>
                    <span >{{$t('setup.view3_text2')}}:</span>
                    <b-form-input class="setup-input" :placeholder="$t('setup.enter_code')" v-model="organization.code" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')"  ></b-form-input>
                    <b-alert variant="danger" id="incorrect-code" dismissible :show="showAlert" @dismissed="showAlert=false">
                        {{$t('setup.code_incorrect')}}
                    </b-alert>
                    <br/>
                    <b-button v-if="organization.code.length>0" class="action-button setup-next-button" v-on:click="goToView4" variant="clear-blue"><div class="next-button"></div><span>{{$t('global.button_next')}}</span></b-button>
                </div>
            </div>
            <div v-if="view == 4" class="transition-view" ref="view4" key="view4">
                <div class="fit-width">
                    <span v-html="$t('setup.view4_text1',{region: organization.org_name})"></span>
                    <div id="white-box">

                    </div>
                    <div id="setup-agree-wrapper" class="grid-auto-2column">
                        <b-form-checkbox id="setup-agree-checkbox" v-model="organization.agree" :value="1" :unchecked-value="0">
                            <span v-html="$t('setup.view4_text2')"></span>
                        </b-form-checkbox>
                    <b-button v-if="organization.agree==1" id="agreed-next-button" class="action-button" v-on:click="goToView5" variant="clear-blue"><div class="next-button"></div><span>{{$t('global.button_next')}}</span></b-button>
                    </div>
                </div>
            </div>
            <div v-if="view == 5" class="transition-view" ref="view5" key="view5">
                <div class="fit-width">
                    <span id="view5-text1" v-html="$t('setup.view5_text1', {region: organization.org_name, username: organization.name})"></span>
                    <div class="grid-auto-2column">
                        <b-form-input class="setup-input" :placeholder="$t('setup.enter_password')" v-model="organization.password" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')"  ></b-form-input>
                        <span id="password-requirement">{{$t('global.password_requirement')}} </span>
                    </div>
                    <span id="view5-text2" v-html="$t('setup.view5_text2')"></span>
                    <b-button v-if="organization.password.length>=8" class="action-button setup-next-button" v-on:click="goToView6" variant="clear-blue"><div class="next-button"></div><span>{{$t('global.button_next')}}</span></b-button>
                </div>
                <div id="view3-text3">{{$t('setup.view3_text3')}} <TermsAndConditions /> </div>
            </div>
            <div v-if="view == 6" class="transition-view" ref="view6" key="view6">
                <div class="fit-width">
                    <span v-html="$t('setup.view6_text1', {region: organization.org_name, username: organization.name, password: organization.password})"></span><br/><br/>
                    <b-button class="action-button setup-next-button" v-on:click="goToLogin" variant="clear-blue"><div class="next-button"></div><span>{{$t('setup.goto_login')}}</span></b-button>
                </div>
            </div>
            <div v-if="view == 7" class="transition-view" ref="view7" key="view7">
                <div class="fit-width">
                    <span v-html="$t('setup.view7_text1')"></span><br/><br/>
                </div>
            </div>
        </transition-group>
        <div id="setup-footer">
            <div>{{$t('setup.footer1_text')}}</div>
            <div>{{$t('setup.footer2_text')}}</div>
        </div>
        </div>
        
      <div class="div-space"></div>
    </div>
</div>
</template>
<style scoped>
#view2-text2{
    margin-top:30px;
}
#view3-text3{
    display: block;text-align: center;margin-top: 30px;
}
#view5-text2{
    margin-top:20px;
}
#password-requirement{
    margin: auto 10px;color:#aaa;
}
#view5-text1{
    margin-bottom: 20px;
}
#agreed-next-button{
    margin-left: auto;
}
#setup-agree-checkbox{
    color:#00aeef;
}
#setup-agree-wrapper{
    padding: 10px;
}
#white-box{
    width: 500px; height: 500px;background-color:white;
}
#incorrect-code{
    margin: 10px;
}
.setup-input{
    max-width:200px;margin:auto;
}
#setup-logo{
    height: 70px;
}
.setup-next-button{
    margin: auto;margin-top:10px;
}

.contact-button{
    border-radius: 5px;
    border: 1px solid black;
    cursor: pointer;
    margin: 10px auto;
    padding: 5px;
    width: 300px;
    background-color: white;
}
.contact-button:hover{
    background-color: #e0f5fd !important;
}
#setup-footer{
    background-color: black; color: white;text-align:center;bottom: 0px;position: absolute;width: 100%;font-size: 12px;padding: 5px;
}
#trans-wrapper{
    width: 799px;
    height: 895px;
    margin:auto;
    background-image: url("../assets/SignupBackground.png");
    overflow: hidden;
    position:relative
}
.setup-header{
    font-size: 24px;
    color: #c147d1;
    margin-bottom: 30px;
}
.setup-linetext{
    font-size: 16px;
    color: black;
    margin-bottom: 20px;
}
    .transition-view{
        text-align:center;position:absolute;width:100%;padding: 50px; text-align: left;
        
    }
    .transition-view > div{
        margin:auto;
        display: grid;
        grid-template-columns: auto;
        
    }
    #setup-main-box{
        position:relative;
        top:20px; height:100%;  margin: auto;    text-align: center; display: grid; grid-template-rows: auto max-content max-content auto;
    }

    .righttolefttrans-enter-active, .righttolefttrans-leave-active {
    transition: all 0.5s ease-in;
  }
  .righttolefttrans-enter {
    transform: translateX(100%);
  }
  .righttolefttrans-leave-to {
    transform: translateX(-100%);
  }
</style>
<script>
    
    import router from "../router"
    export default {
    name: "Setup",
    data() {
        return {
            
            slide_transition: "righttolefttrans",
            confirmed: false,
            unimplemented: false,
            payed: false,
            regions: [],
            signup_contacts: [],
            organization:{
                name: "",
                email: "",
                region: 0,
                contact_id: 0,
                code: '',
                password: '',
                agree: 0,
                org_name: "",
                uid: '',
                muid: ''
            },
            organization_state:{
                name_state: null,
                email_state: null,
                region_state: null
            },
            showAlert: false,
            alertText: this.$t('setup.alert_text'),
            view: 1
        }
    },
    methods: {
        
        goToLogin(){
            router.push("/login");
        },
        getRegions(){
            this.$http.post("/api/regions/get", {}).then((response) => {
                if (response.data.success) {
                    this.regions = response.data.regions;
                    this.regions.unshift({id: 0, name: this.$t('setup.select_region')});
                }
            }).catch((errors) => {  console.log(errors);});
        },
        gotOrganizationUid(){
            this.$http.post("/api/region/id/get/byuid", {uid: this.$route.params.org}).then((response) => {
                if (response.data.success) {
                    this.organization.region = response.data.id;
                    this.organization.org_name = response.data.name;
                    this.organization.name = response.data.admin_name;
                    this.view = 5;
                }else{
                    this.view = 7;
                }
            }).catch((errors) => {  console.log(errors);});
        },
        goToView2(){
            /*for(var i=0; i<this.regions.length; i++){
                if(this.regions[i].value == this.organization.region){
                    this.organization.org_name = this.regions[i].text;
                    break;
                }
            }*/

            //if(this.organization.org_name == ""){
            //    this.view = 7;
            //}else{
                this.getSignupContacts();
                this.view = 2;
            //}
        },
        getSignupContacts(){
            this.$http.post("/api/region/signupcontacts/get", {id : this.organization.region}).then((response) => {
                if (response.data.success) {
                    this.organization.org_name = response.data.org_name;
                    this.signup_contacts = response.data.signup_contacts;
                    this.organization.name = response.data.admin_name;

                }else{
                    this.view = 7;
                    //this.signup_contacts = [{name:this.$t('setup.no_contacts'), text: ''}];
                }
            }).catch((errors) => {  console.log(errors);});
        },
        goToView3(cid){
            this.organization.contact_id = cid;
            if(this.organization.contact_id>0){
                this.$http.post("/api/region/signupcode/get", {id : this.organization.region, contact_id: this.organization.contact_id}).then((response) => {
                if (response.data.success) {
                    this.organization.email = response.data.email;
                    this.view = 3;
                }
            }).catch((errors) => {  console.log(errors);});
            }
        },
        goToView4(){
            this.showAlert = false;
            this.$http.post("/api/region/signupcode/check", {id: this.organization.region, code: this.organization.code}).then((response) => {
                if (response.data.success) {
                    this.view = 5;
                    this.organization.uid = response.data.public_uid;
                }else{
                    this.showAlert = true;
                }
            }).catch((errors) => {  console.log(errors);});
        },
        goToView6(){
            if(this.organization.contact_id>0){
                this.organization.muid = this.organization.contact_id;
            }
            this.$http.post("/api/region/enabletrial", {id : this.organization.region, muid: this.organization.muid,
            password: this.organization.password, name: this.organization.name, uid: this.organization.uid}).then((response) => {
                if (response.data.success) {
                    this.view = 6;
                    this.organization.name = response.data.admin_name;
                }
            }).catch((errors) => {  console.log(errors);});
        },
        checkData(){
            this.showAlert = false;
            this.organization_state ={
                name_state: null,
                email_state: null,
                region_state: null
            };
            var allgood = true;
            if(this.organization.name.length ==0){
                allgood = false;
                this.organization_state.name_state = false;
            }
            if(!this.validateEmail(this.organization.email)){
                allgood = false;
                this.organization_state.email_state = false;
            }
            if(this.organization.region == 0){
                allgood = false;
                this.organization_state.region_state = false;
            }
            this.showAlert = !allgood;
            if(!allgood){
                return;
            }
            if(typeof this.organization.region != 'number'){
                this.view = 4;
                 this.$http.post("/api/organization/request/implementation", this.organization).then(() => {
                   
                }).catch(() => { });
                return;
            }
            if(allgood){
                this.requestOrganization();
            }
        },
        validateEmail(mail){
            return (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail));
        },
        requestOrganization(){
            this.$http.post("/api/organization/request", this.organization).then((response) => {
                if (response.data.success) {
                   this.view = 6;
                }else if(response.data.error.code == 1){
                   this.view = 5;
                }
            }).catch(() => { });
        }
    },
    destroyed() {
    },
    mounted() {
        this.setLanguageIfAvailable();
        if(this.$route.params.org){
            this.organization.uid = this.$route.params.org;
            this.organization.muid = this.$route.params.member;
            this.gotOrganizationUid();
        }else{
            this.getRegions();
        }
    }
}
</script>
