<template>
    <div >
        <span class="form-box-title">{{$t('preparer.files_links')}}</span>
        <div class="form-box grid-auto-1column" id="files-form-box" v-on:drop="dropHandler"  @dragover.prevent  @dragenter.prevent @dragleave.prevent>
        <div v-show="showDrag" id="drop-overlay">
            <div></div>
            <div v-if="obj.files.length<5">{{$t('preparer.drop_text')}}</div>
            <div v-if="obj.files.length>=5">{{$t('preparer.max_files')}}</div>
            <div></div>
        </div>
        <div id="valid-files" class="skinned-text">{{$t('preparer.valid_files')}} <span id="full-ext-list" class="clickable" v-on:click="showValidFileExts = true;">{{$t('preparer.see_full_list')}}</span></div>
        <div class="grid-fitfirst-2column"><span v-html="$t('preparer.file_notes')"></span> <b-button v-if="getIsMac()" id="mac-file-note" variant="link" v-on:click="showMacNote=true;">{{$t('entry.mac_note')}}</b-button></div>
        <div v-for="file in obj.files" :key="file" class="files-wrapper" >
            <div class="file-number-text ">{{$t('preparer.file_text')+" #"+(obj.files.indexOf(file)+1)}}</div>
            <b-button v-if="obj.files.length>1 || (file.link != '' || file.file != null) " v-on:click="removeFileLink(file)" class="action-button delete-file-button" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
            <b-form-radio class="setting-check first-column" v-model="file.type" :value="1">{{$t('preparer.power_point')}}</b-form-radio>
            <div class="grid-fitfirst-2column first-column" v-if="file.type==1">
                <b-button class="action-button select-file-button" v-on:click="openFileDialog(file)" variant="blue">{{file.link.length>0 && file.file_path.length>0? $t('entry.reselect_presentation') : $t('preparer.upload_pp')}}</b-button>
                <div v-if="file.filename && file.filename.length>0" id="preparer-filename" class="cut-filename">{{file.filename}}</div>
                <div v-if="(!file.filename || file.filename.length==0)  && file.link.length>0 && file.file_path.length>0" class="uploaded-file-actions">
                    <div class="cut-filename">{{file.description}}</div>
                    <div><b-button variant="link" v-on:click="viewFile(file.hash)" class="view-file-button">{{$t('entry.speaker_upload')}}</b-button></div>
                    <div class="download-file-button"><a :href="file.file_path && file.file_path.includes('/')? file.file_path : ('/uploads/'+file.file_path)" :download="file.description">{{$t('entry.download_original')}}</a></div>
                </div>
                <b-button class="action-button resend-confirmation-button" v-if="(!file.filename || file.filename.length==0)  && file.link.length>0 && file.file_path.length>0" v-on:click="popupResendModal(file)" variant="clear-blue"><div class="email-button"></div><span>{{$t('preparer.resend_confirm')}}</span></b-button>
            </div>
            <b-form-file :id="'fileupload'+file.number" accept=".pdf, .doc, .docx, .pps, .ppt, .pptx, .ppsx, .xls, .xlsx, .vsd, .vsdx, .odp, .ods, .odt, .key, .bmp, .jpeg, .jpg, .png, .svg, .tif, .tiff, .webp" @change="onFileFileChange($event, file)" plain hidden></b-form-file> 
            <b-form-radio class="setting-check first-column" v-model="file.type" :value="2">{{$t('preparer.link_web')}}</b-form-radio>
            <div class="grid-fitfirst-2column first-column" v-if="file.type==2">
            <b-form-input v-model="file.link" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input link-input" maxlength="255"></b-form-input>
            <div v-if="file.link.length>0 " class="view-link-wrapper">
                <div><b-button variant="link" v-on:click="viewLink(file.link)" class="view-link-button">{{$t('entry.speaker_link')}}</b-button></div>
            </div>
            </div>
            <hr class="separator-line" />
        </div>
        <b-button class="action-button" v-if="obj.files.length<5 && !(obj.files.length>0 && ((obj.files[obj.files.length-1].type == 1 && obj.files[obj.files.length-1].file == null && obj.files[obj.files.length-1].link == '') || (obj.files[obj.files.length-1].type == 2 && obj.files[obj.files.length-1].link == '')))" v-on:click="addFileLink" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
        </div>
        <b-modal centered v-model="showResendModal" :title="$t('preparer.resend_link')">
            <div id="resend-text">{{$t('preparer.resend_text1')}}</div><br/>
            <div id="resend-wrapper" class="grid-auto-2column">
                <b-form-radio class="column-span2" v-model="resendType" value="0">
                {{$t('preparer.current_recipients')}}
                </b-form-radio>
                <b-form-radio class="column-span2" v-model="resendType" value="1">
                {{$t('preparer.alternate_email')}}
                </b-form-radio>
                <span v-if="resendType==1" class="form-box-name">{{$t('preparer.recipient_text')}}</span>
                <b-form-input v-if="resendType==1" v-model="resendEmail" type="text" pattern="[^<>]*" :state="validEmail" :title="$t('global.pattern_title')" @input="validateEmail"></b-form-input>
            </div><br/>
            <div class="center-text" v-html="$t('preparer.resend_text2')"></div>
            <div slot="modal-footer" class="submit-box-2">
                <b-button class="action-button" v-on:click="showResendModal=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
                <b-button class="action-button" :disabled="resendType==1 && !validEmail" v-on:click="resendFileConfirm" variant="clear-blue"><div class="email-button"></div><span>{{$t('global.button_send')}}</span></b-button>
            </div>
        </b-modal>
        <b-modal id="modal-center" v-model="showMacNote" centered >
          <p class="my-4" v-html="$t('entry.mac_modal')"></p>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="showMacNote=false" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
          </div>
        </b-modal>
        <b-modal id="modal-center" v-model="showValidFileExts" centered >
            <p class="my-4" v-html="$t('preparer.upload_file_types')"></p>
            <table id="file-ext-table">
                <tr><td>Microsoft Office</td><td>DOC DOCX PPS PPT PPTX PPSX XLS XLSX VSD VSDX</td></tr>
                <tr><td>OpenOffice</td><td>ODP ODS ODT</td></tr>
                <tr><td>Apple iWork</td><td>KEY NUMBERS PAGES</td></tr>
                <tr><td>Image</td><td>BMP JPEG JPG PNG SVG TIF TIFF WEBP</td></tr>
            </table>
            <div slot="modal-footer" class="submit-box-2">
                <b-button class="action-button" v-on:click="showValidFileExts=false" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
            </div>
        </b-modal>
    </div>
</template>
<style scoped>
#full-ext-list{
    text-decoration: underline;
}
#valid-files{
    text-align:center;color: rgb(0, 174, 239);
}
#file-ext-table > tr > td{
  padding:5px;
  border: lightgray 1px solid;
}
#file-ext-table{
    border-collapse: collapse;border: lightgray 1px solid;text-align: left;
}
.separator-line{
    width: 100%;border-color: grey;grid-column:1/3;
}
.view-link-button{
    padding:0;border: none;font-weight: bold;
}
.view-link-wrapper{
    text-align:center;line-height: 12px;margin:auto;
}
.link-input{
    grid-column:1/2;width:200px;grid-column: 1/2;
}
.resend-confirmation-button{
    grid-column:2;margin-top:20px;
}
.download-file-button{
    font-size: 14px;
}
.view-file-button{
    padding:0;border: none;font-weight: bold;
}
.uploaded-file-actions{
    text-align:center;line-height: 12px;margin:auto;
}
.delete-file-button{
    margin:auto;grid-column:2/3;grid-row:2/5;
}
.select-file-button{
    margin: auto 20px;
}
.file-number-text{
    text-align:center; grid-column:1/3;color: #003c80;font-size: 20px;
}
#files-form-box{
    position:relative;
}
.files-wrapper{
    display: grid;
    grid-template-columns: minmax(500px, max-content) auto;
}
#mac-file-note{
padding:0; width:70px;font-size:14px;
}

#resend-text{
margin-left:40px;
}
#resend-wrapper{
margin-left:40px;
}

</style>
<script>
import router from "../router"
export default {
    name: "UploadFiles",
    props: {obj: {type: Object, default: {files: []}}, page: {type: String, default: ""}, useruid: {type: String, default: ""}, chapter_id: {type: Number, default: 0}},
    data() {
        return {
            showMacNote: false,
            showResendModal: false,
            validEmail: false,
            resendFile: null,
            resendEmail: "",
            resendType: 0,
            showValidFileExts: false,
        }
    },
    methods: {
        validateEmail() {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.resendEmail)) {
                this.validEmail= true;
            } else {
                this.validEmail= false;
            }
        },
        resendFileConfirm(){
            if(this.resendFile!= null){
                var file = this.resendFile;
                if(this.resendType==0){
                    this.resendEmail = "";
                }else{
                    this.setCookie("resend_email", this.resendEmail);
                }
                this.showResendModal = false;
                this.$http.post("/api/organization/"+this.page+"/resendconfirm/byhash"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""), 
                {presentation_id: this.obj.id, speaker_id: file.speaker_id, org: this.chapter_id, section_id: file.section_id, hash: this.obj.hash, number: file.number, useruid: this.useruid, id: this.obj.id, recipient: this.resendEmail}).then((response) => {
                    if(response.data.success){
                        this.$bvToast.toast(this.$t('preparer.emails_sent_to')+": "+response.data.emails.join(", "), {
                        title: this.$t('preparer.resend_success'),
                        variant: 'success',
                        solid: true
                        });
                    }
                }).catch(() => {});
            }
        },
        popupResendModal(file){
            this.resendEmail = this.getCookie("resend_email");
            this.validateEmail();
            this.resendFile = file;
            this.showResendModal = true;
        },
        dropHandler(ev){
            if(ev){
            ev.preventDefault();
            if (ev.dataTransfer.items) {
                for (var i = 0; i < ev.dataTransfer.items.length; i++) {
                if (ev.dataTransfer.items[i].kind === 'file' && this.checkFileSize(ev.dataTransfer.items[i])) {
                    var file = ev.dataTransfer.items[i].getAsFile();
                    this.addFileLink();
                    this.obj.files[this.obj.files.length-1].file = file;
                    this.obj.files[this.obj.files.length-1].filename = file.name;
                    this.obj.files[this.obj.files.length-1].description = file.name;
                }
                }
            } else {
                for (var j = 0; j < ev.dataTransfer.files.length; j++) {
                if(this.checkFileSize(ev.dataTransfer.files[j])){
                    this.addFileLink();
                    this.obj.files[this.obj.files.length-1].file = ev.dataTransfer.files[j];
                    this.obj.files[this.obj.files.length-1].filename = ev.dataTransfer.files[j].name;
                    this.obj.files[this.obj.files.length-1].description = ev.dataTransfer.files[j].name;
                }
                }
            }
            }
            this.showDrag = false;
            this.dragging = false;
        },
        dragEnterHandler(){
            this.showDrag = true;
            this.dragging = true;
        },
        dragHandler(){
            this.showDrag = true;
            this.dragging = true;
        },
        dragLeaveHandler(){
            this.dragging = false;
            var self = this;
            clearTimeout( this.dragTimeout );
            this.dragTimeout = setTimeout( function(){
            if( !self.dragging ){ self.showDrag = false;  }
        }, 200 );
        },
        addFileLink() {
            if((this.obj.files[this.obj.files.length-1].type == 1 && this.obj.files[this.obj.files.length-1].file == null && this.obj.files[this.obj.files.length-1].link == '') || (this.obj.files[this.obj.files.length-1].type == 2 && this.obj.files[this.obj.files.length-1].link == "")){
            var j = this.obj.files[this.obj.files.length-1].number;
            this.obj.files[this.obj.files.length-1] = { member_uid: this.useruid,slide_id: 0, presentation_id: 0, speaker_id:0, section_id:0, number: j, type: 1, link: "", description: "", filename: "", file_path: "", file: null , new: true};
            return this.obj.files.length-1;
            }
            for (var k = 1; k <= 5; k++) {
            var found = false;
            for (var i = 0; i < this.obj.files.length; i++) {
                if (this.obj.files[i].number == k) {
                found = true;
                
                }
            }
            if (!found) {
                this.showSuccess = false;
                this.obj.files.push({ member_uid: this.useruid, slide_id: 0, presentation_id: 0, speaker_id:0, section_id:0, number: k, type: 1, link: "", description: "", filename: "", file_path: "", file: null , new: true, hash: ''});
                return k;
            }
            }
            return -1;
        },
        openFileDialog(file) {
            document.getElementById('fileupload' + file.number).click();
        },
        onFileFileChange(e, fileElement) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length || !this.checkFileSize(files[0]))
            return;
            files[0].number = fileElement.number;
            fileElement.link = "";
            fileElement.file_path = "";
            fileElement.description = "";
            fileElement.new = true;
            fileElement.filename = files[0].name;
            fileElement.file = files[0];
            fileElement.new = true;
            if(fileElement.description.length == 0){
                fileElement.description = files[0].name;
            }
        },
        viewLink(l){
            var link = (l.startsWith("http") ? "" : "http://") + l;
            window.open(link, "_blank", 'location=no,status=no,titlebar=no,toolbar=no,menubar=no');
        },
        removeFileLink(file) {
            var index = this.obj.files.indexOf(file);
            if (index > -1) {
            this.obj.files.splice(index, 1);
            }
            if(this.obj.files.length == 0){
                this.obj.files.push({ member_uid: this.useruid, slide_id:0, presentation_id: 0, speaker_id:0, section_id:0, number: 1, type: 1, link: "", description: "", filename: "", file_path: "", file: null , new: true});
            }
        },
        gotoFile(hash){
            router.push('/filecontrol/'+hash);
        },
            viewFile(h){
                window.open("/#/filecontrol/"+h, "_blank", 'location=no,status=no,titlebar=no,toolbar=no,menubar=no');
            },
            getIsMac(){
            return window.navigator.platform.indexOf('Mac')>=0 || window.navigator.platform.indexOf('mac')>=0 || window.navigator.platform.indexOf('Apple')>=0 || window.navigator.platform.indexOf('apple')>=0;
        },
    },
    mounted() {
    }
}
</script>
  