<template>
  <div>
    <div id="page-wrapper" class="rel-grid-container">
      <div ></div>
      <div>
        <div id="contact-type-select">
          <span class="form-box-name">{{$t('members.show_type')}} </span>
          <b-form-select id="type-options" required v-model="type_id" :options="typeOptions"  class="mb-3 " value-field="id" text-field="name"/>
        </div>
        <SetupTip class="setup-tip" :tipNumber="9"/>
        <table v-if="permissions.access" class="table b-table table-bordered table-sm table-hover-new">
          <thead>
            <tr>
              <th class="tableHeader">{{$t('members.name_header')}} </th>
              <th class="tableHeader">{{$t('members.company_header')}} </th>
              <th class="tableHeader">{{$t('members.type_header')}} </th>
            </tr>
          </thead>
          <tbody>
            <tr v-bind:id="'member_'+member.id" class="dragndrop" v-for="member in members.filter(m => (m.contact_types.find(v => v.id == type_id)!=undefined || type_id == 0))" :key="member.id" v-bind:class="{ disabledClass : member.disabled,'striped-color': member.is_parent==1}" v-on:click="editMember(member)">
              <td><div class="contact-name"><span>{{member.name}}</span>
             
                <span :id="'member_login'+member.id" class="member-login" v-if="member.has_pass==1"></span>
                <b-popover :target="'member_login'+member.id" triggers="hover focus" >
                  <template #title>{{$t('members.has_login')}}</template>
                  <div>{{$t('members.email_text')+': '+member.email}}</div>
                  <div>{{$t('members.contact_access') }}</div>
                </b-popover>
              </div></td>
              <td>{{member.company}}</td>
              <td>
                {{member.contact_types.length>0? member.contact_types.map(v => v.name).join(",") : ""}}
                <b-popover :target="'member_'+member.id" triggers="hover focus" @show="onPopoverShow(member)">
                  <img v-if="member.thumbnail && member.thumbnail.length>0" :src="member.thumbnail" class="contact-image" />
                  <span v-if="!(member.thumbnail && member.thumbnail.length>0)">{{$t('members.no_member_photo')}} </span>
                </b-popover>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="showAlert">
        <b-alert variant="danger" dismissible :show="showAlert" @dismissed="showAlert=false">
          {{alertText}}
        </b-alert>
      </div>
      <div class="submit-box-2">
        <b-button v-if="permissions.access" class="action-button" v-on:click="newMember" variant="clear-blue"><div class="new-button"></div><span>{{$t('global.button_new')}}</span></b-button>
        <b-button v-if="permissions.access" class="action-button" v-on:click="showImportMembers=true" variant="clear-blue"><div class="import-button"></div><span>{{$t('members.import_contacts')}}</span></b-button>
      </div>
      <div class="div-space"></div>
    </div>
    <b-modal centered  v-model="showImportMembers" :title="$t('members.import_contacts_title')">
         <div>
           <div id="import-box">
            <div id="import-form-box" class="form-box">
              <b-button id="import-file-button" variant="blue" v-on:click="openDialog">{{$t('import.select_file')}}</b-button>
              <b-form-file ref="file-input"  id='fileid' accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="onFileChange" plain hidden></b-form-file>
              <div ></div>
              <div id="import-note">
                *{{$t('members.contact_import_note')}}
              </div>
            </div>
          </div>
           <div v-if="showUpload">
            <b-alert id="upload-alert" variant="warning" :show="showUpload">
              {{$t('import.importing_message')}}
              <b-spinner id="alert-spinner" label="Uploading" variant="warning"></b-spinner>
            </b-alert>
          </div>
         </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="action-button" v-on:click="showImportMembers=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
        </div>
    </b-modal>
       <b-modal centered id="slide-modal-4" v-model="showPreviewImport" :title="$t('import.import_title')">
        <div id="preview-members">
          <b-table class="table-hover-new" small bordered :items="previewMembers" :fields="previewFields">
            <template v-slot:cell(roles)="data">
              {{data.item.roles.join(",")}}
            </template>
            <template v-slot:cell(import)="data">
              <b-form-checkbox v-model="data.item.import" class="auto-margin">
            </b-form-checkbox>
            </template>
          </b-table>
        </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="action-button" v-on:click="cancelImport" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="action-button" v-on:click="importChanges" variant="clear-blue"><div class="import-button"></div><span>{{$t('global.button_import')}}</span></b-button>
        </div>
      </b-modal>
    </div>
</template>
<style scoped>
.contact-image{
  height:150px;width:auto;
}
#alert-spinner{
  float:right;
}
#upload-alert{
  line-height:30px;
}
#import-note{
  grid-column: 1/3; margin-top: 20px;font-size:12px;
}
#import-file-button{
  width:200px;
}
#import-form-box{
  grid-template-columns: 50% 50%;
}
#import-box{
  max-width: 600px;margin: auto;
}
.contact-name{
  display:grid; grid-template-columns: max-content auto;
}
#type-options{
  margin: 5px 0px;
}
#page-wrapper{
  top:20px
}
#contact-type-select{
  display:grid; grid-template-columns:auto auto; width: 400px;margin:auto;
}
.striped-color {
        background-color: rgba(0,0,0,.05);
    }
    .member-login{
      width: 20px;
    height: 20px;
    display: inline-block;
    background-size: 100%;
    margin-left: 5px;
    background-image: url("../assets/button-Login.png");
    margin: auto 5px;
}
</style>
<script>
    
  import router from "../router"
    export default {
    name: "Contacts",
    watch:{
      '$i18n.locale': {
        handler: function () {
          this.getContactTypes();
        },
        deep: true
      }
      },
      data() {
        return {
          members: [],
          showUpload: false,
          showAlert: false,
          saveVariant: "",
          alertText: "",
          showSuccess: false,
          members_num: 0,
          type_id: 0,
          typeOptions: [],
           showImport: false,
          showImportMembers : false,
          showPreviewImport: false,
          previewMembers: [],
          previewFields: [
          { key: 'name',
            label: this.$t('import.name_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'email',
            label: this.$t('import.email_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" },
            tdClass: 'breakAll'
          },
           { key: 'phone',
            label: this.$t('import.phone_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
          { key: 'roles',
            label: this.$t('import.company_header'),
            sortable: true,
            thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" }
          },
           { key: 'import',
              label: this.$t('events.import_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff", 'text-align': 'center'},
            }
        ],
        }
      },
    methods: {
      openDialog() {
        document.getElementById('fileid').click();
      },
       onFileChange(e) {
         var files = e.target.files || e.dataTransfer.files;
        if (!files.length || !this.checkFileSize(files[0]))
          return;
        this.file = files[0];
        //this.getMembers();
        this.importMembers();
      },
      importMembers() {
        this.showImportMembers = false;
        this.showUpload = true;
        this.showAlert = false;
        this.alertText = this.$t('import.alert_text');
        this.saveVariant = "";
        this.$refs['file-input'].reset();
        let formData = new FormData();
        formData.append("file", this.file);
        this.$http.post("/api/region/contacts/import/excel", formData, {headers: {"Content-Type": "multipart/form-data"}}).then((response) => {
          this.showImport = false;
          this.showUpload = false;
          this.saveVariant = "blue";
          if (response.data.success == true) {
            this.previewMembers = response.data.members;
            this.showPreviewImport = true;
          } else {
            this.showAlert = true;
            this.alertText = response.data.error;
          }
        }).catch((errors) => {
          this.showImport = false;
          this.showUpload = false;
          this.saveVariant = "blue";
          this.showAlert = true;
          this.alertText = JSON.stringify(errors);
        });
      },
      cancelImport() {
        this.previewMembers = [];
        this.showPreviewImport = false;
        this.showRolePreviewImport = false;
        this.file = { name: "" };
      },
      importChanges() {
          var vs = this.previewMembers.filter(g => g.import);
          if(vs.length>0){
            this.$http.post("/api/region/contacts/import/all", { members: this.previewMembers}).then((response) => {
            if (response.data.success === true) {
              this.showPreviewImport = false;
              this.getMembers();
            }
          }).catch(() => { });
        }else{
          this.showPreviewImport = false;
        } 
      },
      getContactTypes(){
        this.$http.post("/api/contact/types/get", {}).then((response) => {
          this.typeOptions = response.data.types;
          this.typeOptions.unshift({id: 0, name: this.$t('global.contact_option0')});
          this.getMembers();
        }).catch(() => {
        });
      },
      onPopoverShow(member) {
        if (!member.gotThumbnail) {
          this.$http.post("/api/member/get/photo", { id: member.id }).then((response) => {
            if (response.data.success) {
              this.$set(member, "thumbnail", response.data.photo);
              this.$set(member, "gotThumbnail", true);
            }
          }).catch((errors) => {
            console.log(errors);
          });
        }
      },
      getMembers() {
        this.$http.post("/api/organization/contacts", {}).then((response) => {
            var tm = response.data.contacts;
            for(var x=0; x<tm .length;x++){
              var ids = tm[x].contact_type.split(",");
              tm[x].contact_types = [];
              for(var i=0; i<this.typeOptions.length;i++){
                if(ids.indexOf(this.typeOptions[i].id+"")>=0){
                  tm[x].contact_types.push(this.typeOptions[i]);
                }
              }
            }
            this.members = tm;
        }).catch((errors) => {console.log(errors);});
      },
      newMember() {
        if (this.permissions.access) {
          router.push("/admin/contact/0");
        }
        
      },
      editMember(item) {
        if (this.permissions.access && item.is_parent==0) {
          router.push("/admin/contact/" + item.id);
        }
      }
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-contacts"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-contacts"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
      this.getContactTypes();
    }
    }
</script>
