<template>
  <div>
    <div id="translations-main-page" class="rel-grid-container">
      <div ></div>
      <div>
          
        <div class="grid-auto-1column fit-width auto-margin">
          <span class="form-box-name">{{$t('languageadmin.type_text')}}</span>
            <b-form-select id="translations-group-select" v-model="permissions.languageObjectType" :options="objectOptions" class="mb-3 form-box-input" />
        </div>
        <table class="table b-table table-hover-new table-bordered table-sm">
          <thead>
            <tr>
              <th id="name-header" class="tableHeader">{{$t('languageadmin.name_header')}}</th>
              <th class="tableHeader translations-header"><b-form-select class="select-header" v-model="language_from" :options="languageOptions" value-field="abbrev" text-field="name" /></th>
              <th class="tableHeader translations-header"><b-form-select class="select-header" v-model="language_to" :options="languageOptions" value-field="abbrev" text-field="name" /></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="object in languageObjects" :key="object.id">
              <td>
                <b>{{object.name}}</b>
              </td>
              <td>
                <pre v-if="object.language1 != null" class="pre-format" v-html="object.language1"></pre>
              </td>
               <td v-on:click="editScript(object)" class="clickable">
                <pre v-if="object.language2 != null" class="pre-format" v-html="object.language2"></pre>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <b-modal id="language-modal" centered v-model="showScriptEdit" :title="$t('languageadmin.edit_translation')">
           <div v-if="permissions.languageObjectType==1 || permissions.languageObjectType==2" class="grid-auto-3column">
            <div>
              <span class="form-box-title">{{$t('languageadmin.original')}}</span>
              <div class="form-box grid-auto-1column">
                <pre v-if="currentObject.language1 != null" class="pre-format" v-html="currentObject.language1"></pre>
              </div>
            </div>
            <div>
              <span class="form-box-title">{{$t('languageadmin.translation')}}</span>
              <div class="form-box grid-auto-1column">
                <base-editor ref="translation-editor" v-model="currentObject.language2" :variables="variable_map" :minheight="320"/>
              </div>
            </div>
            <div id="variable-box">
              <span class="form-box-title">{{$t('scripts.insert_variables')}}</span>
              <div class="form-box grid-auto-1column">
                <div>
                  <b-form-radio-group id="variables-group-options" v-model="variableGroup" :options="groupOptions" value-field="value" text-field="name" >
                  </b-form-radio-group>
                </div>
                <div id="variables-table-wrapper">
                  <table class="table b-table table-hover-new table-bordered table-sm">
                    <tbody>
                      <tr class="variable-row" v-for="variable in variable_map.filter(v => v.group == variableGroup)" v-on:click="addTextVariable(variable)" :key="variable.id">
                        <td>{{ variable.name }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div id="click-entry">{{$t('scripts.click_entry')}}</div>
              </div>
            </div>
          </div>
          <div  v-if="permissions.languageObjectType>2" class="fit-content grid-auto-2column auto-margin">
            <div class="form-box grid-auto-1column">
              <span class="form-box-name">{{$t('languageadmin.original')}}</span>
              <b-form-input disabled v-model="currentObject.language1" class="form-box-input medium-width-input" ></b-form-input>
              <span class="form-box-name">{{$t('languageadmin.translation')}}</span>
              <b-form-input v-model="currentObject.language2" class="form-box-input medium-width-input" ></b-form-input>
            </div>
          </div>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="copyObject" variant="clear-blue"><div class="copy-button"></div><span>{{$t('global.button_copy')}}</span></b-button>
            <b-button class="action-button" v-on:click="updateLanguageObject" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
          </div>
        </b-modal>
      </div>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
#variable-box{
  font-size:12px;
}
#translations-group-select{
  max-width: 200px
}
#name-header{
  width: 200px;
}
.translations-header{
  width: 40% !important; 
}
.select-header{
  max-width: 200px;margin-left:30px;
}
#translations-main-page{
  max-width: 90%;
}
#variables-group-options{
  display: grid;
}
#variables-table-wrapper{
  height: 221px; overflow-y: auto; margin-top: 10px;
}
#click-entry{
  font-size: 10px;color: #777;
}
 
</style>
<script>
    export default {
    name: "Translations",
    watch: {
      'permissions.languageObjectType': {
        handler: function () {
            this.getLanguageObjects();
        }
      },
      language_from: {
        handler: function () {
            this.getLanguageObjects();
        }
      },
      language_to: {
        handler: function () {
            this.getLanguageObjects();
        }
      },
      '$i18n.locale': {
        handler: function () {
          this.groupOptions= [{name: this.$t('slide.group_option1'), value: 'chapter'},{name: this.$t('slide.group_option2'), value: 'analytic'},
          {name: this.$t('slide.group_option3'), value: 'regional'},{name: this.$t('slide.group_option4'), value: 'role'},{name: this.$t('slide.group_option7'), value: 'transition'},
          {name: this.$t('slide.group_option5'), value: 'speaker'},{name: this.$t('slide.group_option6'), value: 'other'}];
          this.objectOptions= [{value: 1, text: this.$t('languageadmin.scripts')},
          {value: 3, text: this.$t('languageadmin.slide_names')},
          {value: 4, text: this.$t('languageadmin.section_names')},
          {value: 5, text: this.$t('languageadmin.roles')}];
          if(this.permissions.organization.is_parent==1){
            
           this.objectOptions.push({value: 6, text: this.$t('languageadmin.analytics')});
          }
          this.getLanguageObjects();
          this.getLanguages();
        },
        deep: true
      }
    },
      data() {
        return {
          groupOptions: [{name: this.$t('slide.group_option1'), value: 'chapter'},{name: this.$t('slide.group_option2'), value: 'analytic'},
          {name: this.$t('slide.group_option3'), value: 'regional'},{name: this.$t('slide.group_option4'), value: 'role'},{name: this.$t('slide.group_option7'), value: 'transition'},
          {name: this.$t('slide.group_option5'), value: 'speaker'},{name: this.$t('slide.group_option6'), value: 'other'}],
          variableGroup: 'chapter',
          showScriptEdit: false,
          variable_map: [],
          currentObject: {id: 0, name: '', language1: '', language2 : ''},
          languageOptions:[],
          objectOptions: [{value: 1, text: this.$t('languageadmin.scripts')},
          {value: 3, text: this.$t('languageadmin.slide_names')},
          {value: 4, text: this.$t('languageadmin.section_names')},
          {value: 5, text: this.$t('languageadmin.roles')},
          {value: 6, text: this.$t('languageadmin.analytics')}],
          language_from: '',
          language_to: '',
          languageObjects: []
        }
      },
    methods: {
      getVariableData(){
        this.$http.post("/api/organization/slide/get/variables", {}).then((response) => {
          if (response.data.success === true) {
            this.variable_map = response.data.variable_map;
          }
        }).catch((errors) => {
          console.log(errors)
        });
      },
      getLanguages(){
        this.$http.post("/api/organization/languages/possible", {}).then((response) => {
          if (response.data.success === true) {
            this.languageOptions = response.data.languages;
            this.language_from = response.data.languages.length>0? response.data.languages[0].abbrev : '';
            this.language_to = response.data.languages.length>1? response.data.languages[1].abbrev : '';
          }
        }).catch((errors) => {
          console.log(errors)
        });
        },
        copyObject(){
          this.currentObject.language2 = this.currentObject.language1;
        },
      getLanguageObjects(){
        if(this.language_from.length==2 && this.language_to.length==2 && this.permissions.languageObjectType>0){
          switch(this.permissions.languageObjectType){
            case 1:
              this.getScripts();
              break;
            case 2:
              this.getSlideTexts();
              break;
            case 3:
              this.getSlideNames();
              break;
            case 4:
              this.getSectionNames();
              break;
            case 5:
              this.getRoleNames();
              break;
            case 6:
              this.getAnalyticNames();
              break;
            default:
              break;
          }
        }
      },
      updateLanguageObject(){
        if(this.language_from.length==2 && this.language_to.length==2 && this.permissions.languageObjectType>0){
          switch(this.permissions.languageObjectType){
            case 1:
              this.updateScript();
              break;
            case 2:
              this.updateSlideText();
              break;
            case 3:
              this.updateSlideName();
              break;
            case 4:
              this.updateSectionName();
              break;
            case 5:
              this.updateRoleName();
              break;
            case 6:
              this.updateAnalyticName();
              break;
            default:
              break;
          }
          this.showScriptEdit = false;
        }
      },
      highlight(script) {
        this.variable_map.forEach(function (item) {
           if (script ) {//&& script.indexOf(item.id) >= 0
            //script = script.split(item.id).join(item.noHighlight? item.value : ("<b class='script-variable'>" + item.id + "</b>"));
            //var reg = new RegExp('(<span data-type="mention" class="mention" data-id="'+item.id+'.*?<\/span>)', 'g');
            //script.var_script = script.var_script.replaceAll(reg, item.noHighlight? item.value : ("<b class='script-variable'>"+(item.value.length>0? item.value: "")+"</b>"));
          }
        });
        return script;
      },
      editScript(item) {
        this.currentObject = item;
          this.showScriptEdit = true;
      },
      addTextVariable(variable) {
        this.$refs['translation-editor'].insertVariable(variable);
      },
      getScripts() {
        this.$http.post("/api/organization/scripts/languageadmin", {language_from: this.language_from, language_to: this.language_to}).then((response) => {
          if (response.data.success) {
            this.languageObjects = response.data.scripts;
          } 
        }).catch((errors) => {
          console.log(errors);
        });
      },
      getSlideTexts(){
        this.$http.post("/api/organization/slides/languageadmin", {language_from: this.language_from, language_to: this.language_to}).then((response) => {
          if (response.data.success) {
            this.languageObjects = response.data.slides;
          } 
        }).catch((errors) => {
          console.log(errors);
        });
      },
      getSlideNames(){
        this.$http.post("/api/organization/slidenames/languageadmin", {language_from: this.language_from, language_to: this.language_to}).then((response) => {
          if (response.data.success) {
            this.languageObjects = response.data.slides;
          } 
        }).catch((errors) => {
          console.log(errors);
        });
      },
      getSectionNames(){
        this.$http.post("/api/organization/sectionnames/languageadmin", {language_from: this.language_from, language_to: this.language_to}).then((response) => {
          if (response.data.success) {
            this.languageObjects = response.data.sections;
          } 
        }).catch((errors) => {
          console.log(errors);
        });
      },
      getRoleNames(){
        this.$http.post("/api/organization/rolenames/languageadmin", {language_from: this.language_from, language_to: this.language_to}).then((response) => {
          if (response.data.success) {
            this.languageObjects = response.data.roles;
          } 
        }).catch((errors) => {
          console.log(errors);
        });
      },
      getAnalyticNames(){
        this.$http.post("/api/organization/analyticnames/languageadmin", {language_from: this.language_from, language_to: this.language_to}).then((response) => {
          if (response.data.success) {
            this.languageObjects = response.data.analytics;
          } 
        }).catch((errors) => {
          console.log(errors);
        });
      },
      updateScript() {
           this.$http.post("/api/organization/scripts/languageadmin/update", {id: this.currentObject.id, language2: this.currentObject.language2, language_to: this.language_to}).then(() => {
          }).catch(() => {
            this.$bvToast.show('serverError');
          });
      },
      updateSlideText() {
          this.$http.post("/api/organization/slides/languageadmin/update", {id: this.currentObject.id, language_from: this.language_from, language2: this.currentObject.language2, language_to: this.language_to}).then(() => {
        }).catch(() => {
          this.$bvToast.show('serverError');
        });
      },
      updateSlideName() {
          this.$http.post("/api/organization/slidenames/languageadmin/update", {id: this.currentObject.id,  language2: this.currentObject.language2, language_to: this.language_to}).then(() => {
        }).catch(() => {
          this.$bvToast.show('serverError');
        });
      },
      updateSectionName() {
          this.$http.post("/api/organization/sectionnames/languageadmin/update", {id: this.currentObject.id,  language2: this.currentObject.language2, language_to: this.language_to}).then(() => {
        }).catch(() => {
          this.$bvToast.show('serverError');
        });
      },
      updateRoleName() {
          this.$http.post("/api/organization/rolenames/languageadmin/update", {id: this.currentObject.id,  language2: this.currentObject.language2, language_to: this.language_to}).then(() => {
        }).catch(() => {
          this.$bvToast.show('serverError');
        });
      },
      updateAnalyticName() {
          this.$http.post("/api/organization/analyticnames/languageadmin/update", {id: this.currentObject.id, type: this.currentObject.type, language2: this.currentObject.language2, language_to: this.language_to}).then(() => {
        }).catch(() => {
          this.$bvToast.show('serverError');
        });
      },
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-translations"), "active-menu");
      this.removeClass(document.getElementById("menu-admin"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-translations"), "active-menu");
      this.addClass(document.getElementById("menu-admin"), "active-menu");
      this.objectOptions= [{value: 1, text: this.$t('languageadmin.scripts')},
          {value: 3, text: this.$t('languageadmin.slide_names')},
          {value: 4, text: this.$t('languageadmin.section_names')},
          {value: 5, text: this.$t('languageadmin.roles')}];
          if(this.permissions.organization.is_parent==1){
           this.objectOptions.push({value: 6, text: this.$t('languageadmin.analytics')});
          }
      this.getLanguages();
      this.getVariableData();
    }
  }
</script>
