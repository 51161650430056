<template>
  <div id="dash">
    <div id="meeting-box">
       <div id="meeting-description" class="fit-width">
          <div id="region-dash-org-name">{{permissions.organization.name}}</div>
          <div id="region-dash-date"><b>{{$d(new Date(), 'day_month_long')}}</b></div>
          <div v-if="regionOptions.length>1" id="alternate-regions">
            <div v-for="reg in regionOptions" :key="reg.value" v-on:click="loginToAlternate(reg)" :class="{clickable: !reg.disabled}" class=" region-switch-button" :style="{'color': reg.value==permissions.organization.id?'black':''}">
            {{reg.text}}
          </div>
          </div>
        </div>
          <div v-if="permissions.organization.onboard==1 && permissions.organization.official" id="region-dash-setup-box">
            <div class="dashboard-title center-text">
              <span>{{$t('dashboard.setup_launch')}}:</span>
            </div>
              <div class="portal-option"  v-on:click="goToSetupPage('/admin/regional', '',8)">
                <div class="option-title black-text">{{$t('dashboard.setup_region_settings')}}</div>
              </div>
              <div class="portal-option"  v-on:click="goToSetupPage('/admin/agenda', '',12)">
                <div class="option-title black-text">{{$t('dashboard.setup_region_agenda')}}</div>
              </div>
              <div class="portal-option"  v-on:click="goToSetupPage('/admin/scripts', '',13)">
                <div class="option-title black-text">{{$t('dashboard.setup_region_script')}}</div>
              </div>
              <div class="portal-option"  v-on:click="goToSetupPage('/admin/chapters', '',11)">
                <div class="option-title black-text">{{$t('dashboard.setup_invite_chapters')}}</div>
              </div>
              <b-button variant="link" v-on:click="hideSetup" id="hide-setup"><i>{{$t('dashboard.hide_setup')}}</i></b-button>
          </div>
      <div v-if="permissions.organization.onboard==0 || !permissions.organization.official">
        <div id="search-bar" class="fit-width" v-if="dates.length>0">
          <b-form-radio class="auto-margin" v-model="showWeek" :value="0">
            {{$t('dashboard.day_text')}}
          </b-form-radio>
          <b-form-radio class="auto-margin" v-model="showWeek" :value="1">
            {{$t('dashboard.week_text')}}
          </b-form-radio>
          <span class="form-box-name region-dash-contact">{{$t('dashboard.regional_contact')}}</span><b-form-select class="region-dash-contact-select" v-model="contactId" :options="select_contacts" value-field="id" text-field="name" />
          <span v-if="(official==1 && !isCorporateConnections)" class="form-box-name region-dash-contact">{{$t('dashboard.leadership_contact')}}</span><b-form-select v-if="(official==1 && !isCorporateConnections)" class="region-dash-contact-select" v-model="leaderId" :options="leaders" value-field="id" text-field="name"  />
          <b-button class="action-button auto-margin" @click.stop="clearSearch" variant="clear-blue">
            <div class="cancel-button"></div>
            <span>
            {{$t('global.button_clear')}}
            </span>
          </b-button>
        </div>
        <div v-if="dates.length==0" class="none-found-note">{{$t('dashboard.no_meetings')}}</div>
          <div>
            <div class="none-found-note" v-if="dates.length>0 && dates.filter(d => d.chapters.filter(v => (contactId==0 || v.contacts.includes(contactId)) && (leaderId==0 || v.leadership.filter(l => l.id == leaderId).length>0)).length>0 && (showWeek? true : d.day==today)).length==0">{{$t('organizations.no_chapters_found')}}</div>
            <div v-for="date in dates.filter(v=> showWeek? true : v.day==today)" :key="date.day" class="chapters-by-date-box">
              <div class="region-dash-meeting-date" v-if="date.chapters.filter(v => (contactId==0 || v.contacts.includes(contactId)) && (leaderId==0 || v.leadership.filter(l => l.id == leaderId).length>0)).length>0"><b>{{$d(new Date("" + date.day.split("-").join("/") + " 00:01"), 'short_weekday')}}</b></div>
              <b-table class="table-hover-new clickable" v-on:row-clicked="editOrganization" small bordered :items="date.chapters.filter(v => (contactId==0 || v.contacts.includes(contactId)) && (leaderId==0 || v.leadership.filter(l => l.id == leaderId).length>0))" v-if="date.chapters.filter(v => (contactId==0 || v.contacts.includes(contactId)) && (leaderId==0 || v.leadership.filter(l => l.id == leaderId).length>0)).length>0" :fields="fields">
                    <template v-slot:cell(leadership)="data">
                        <div :id="'leader_'+leader.id" v-for="leader in data.item.leadership" :key="leader.id">
                          <span class="region-dash-member-name">{{leader.name}}</span>
                          <b-popover v-if="leader.id>0"  custom-class="wide-popover" :target="'leader_'+leader.id" triggers="hover focus" @show="onPopoverShowLeader(leader)" >
                            <div class="regiona-dash-member-popover">
                              <img v-if="popup.photo_small && popup.photo_small.length>0" :src="popup.photo_small" class="member-popover-image" />
                              <div class="left-text">
                                  <div :style="{'color': isCorporateConnections? '#FFD700' : '#CF2030', 'font-weight': 'bold'}">{{popup.name}}</div>
                                  <div >{{popup.company}}</div>
                                  <div><b>{{popup.category}}</b></div>
                                  <div><i>{{popup.roles}}</i></div>
                                  <div><a :href="'mailto:'+popup.email">{{popup.email}}</a></div>
                                  <div v-if="popup.phone"><a :href="'tel:'+popup.phone">{{popup.phone}}</a></div>
                              </div>
                            </div>
                          </b-popover>
                        </div>
                  </template>
                  <template v-slot:cell(speakers)="data">
                        <div :id="'leader_'+speaker.id"  v-for="speaker in data.item.speakers" :key="speaker.id">
                          <span class="region-dash-member-name">{{speaker.name}}</span>
                          <b-popover v-if="speaker.id>0"  custom-class="wide-popover" :target="'leader_'+speaker.id" triggers="hover focus" @show="onPopoverShowSpeaker(speaker)" >
                            <div class="regiona-dash-member-popover">
                              <img v-if="popup.photo_small && popup.photo_small.length>0" :src="popup.photo_small" class="member-popover-image" />
                              <div class="left-text">
                                  <div :style="{'color': isCorporateConnections? '#FFD700' : '#CF2030', 'font-weight': 'bold'}">{{popup.name}}</div>
                                  <div >{{popup.company}}</div>
                                  <div><b>{{popup.category}}</b></div>
                                  <div><i>{{popup.roles}}</i></div>
                                  <div><a :href="'mailto:'+popup.email">{{popup.email}}</a></div>
                                  <div v-if="popup.phone"><a :href="'tel:'+popup.phone">{{popup.phone}}</a></div>
                              </div>
                            </div>
                          </b-popover>
                        </div>
                  </template>
                  <template v-slot:cell(categories)="data">
                        <div v-for="category in data.item.categories" :key="category">{{category}}</div>
                  </template>
                  <template v-slot:cell(attendance)="data">
                        <div><b>{{data.item.present}}</b> | <span>{{data.item.registered}}</span></div>
                  </template>
                    <template v-slot:cell(actions)="data">
                        <b-button class="chapter-login-as-button" size="sm"  @click.stop="loginAsChapter(data.item)" variant="light-blue-outline">
                      {{$t('organizations.login_as')}}
                      </b-button>
                  </template>
              </b-table>
            </div>
          </div>
      </div>
    </div>
    <b-modal id="slide-modal-3"  centered v-model="showNewOrg" :title="$tc('organizations.admin_title', organization_edit? 0 : 1)">
          <form id="org-edit-form">
            <div> 
              <div class="new-org-box">
                <div class="column-span2">
                  <span class="form-box-title">{{$t('organizations.chapter_info')}}</span>
                  <div id="chapter-info-form-box" class="form-box">
                    <span class="form-box-name">{{$t('organizations.chapter_name')}}</span><b-form-input v-model="new_org.org_name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input medium-width-input" required></b-form-input>
                    
                    <b-form-checkbox v-if="organization_edit" :disabled="atMaxChapters && new_org.disabled==1" class="form-box-input valign-margin" v-model="new_org.disabled" >
                      {{$t('organizations.disabled_header')}}
                    </b-form-checkbox>
                    <span class="form-box-name first-column">{{$t('organizations.admin_name')}}</span><b-form-input v-model="new_org.admin_name" :state="new_org.admin_name.length>0 && !chapterAdminNameInUse? null : false" @input="checkAdminName('chapterAdminNameInUse', new_org.admin_id, new_org.admin_name)" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input medium-width-input" required></b-form-input>
                    <div v-if="chapterAdminNameInUse" class="red-text name-dupe">{{ $t("global.name_duplicate") }}</div>
                    <span class="form-box-name">{{$t('organizations.admin_password')}}</span><b-form-input v-model="new_org.admin_pass" type="password" class="form-box-input medium-width-input" ></b-form-input>
                    <span v-if="new_org.admin_pass.length>0" class="form-box-name">{{$t('organizations.retype_password')}}</span><b-form-input v-if="new_org.admin_pass.length>0" :state="new_org.admin_pass===new_org.admin_retype && new_org.admin_retype.length>0" v-model="new_org.admin_retype" type="password" class="form-box-input" ></b-form-input>
                    <div v-if="organization_edit" id="password-change">{{$t('organizations.password_change')}}</div>
                    <span v-if="organization_edit" class="form-box-name first-column">{{$t('organizations.languages_text')}}</span>
                    <div v-if="organization_edit"  id="language-select">
                      <multiselect  v-model="languages" :options="languageOptions" class="blueselect" :noOptionsText="$t('global.empty_list')"  mode="tags" track-by="name" valueProp="id" :close-on-select="false" :searchable="true" :placeholder="$t('country.choose_language')" label="name">
                      </multiselect>
                    </div>
                    <b-form-checkbox v-if="organization_edit" class="form-box-input valign-margin" v-model="new_org.onboard" >
                      {{$t('organizations.setup_header')}}
                    </b-form-checkbox>
                    <b-button v-if="official_region>0 && organization_edit" id="move-chapter-button" v-on:click="showNewOrg=false;showMoveChapter=true;" variant="light-blue-outline"><span>{{$t('organizations.move_chapter')}}</span></b-button>
                    <div v-if="!organization_edit" id="no-logins-note" v-html="$t('organizations.no_logins_note')">
                    </div>
                  </div>
                </div>
              </div>
            <div class="no-logins center-text" v-if="new_org.allow_logins==0" v-html="$t('organizations.no_logins')"></div>
            </div>
            <div class="new-org-box" v-if="this.organization_edit">
              <div class="column-span2">
                <span class="form-box-title">{{$t('organizations.chapter_contacts')}}</span>
                <div class="form-box grid-auto-1column">
                  <div v-for="ctype in typeOptions" :key="ctype.id" class="contact-type">
                    <span class="form-box-name">{{ctype.name}}</span>
                    <multiselect class="blueselect contact-select" v-model="ctype.members" :options="contacts.filter(t => (t.contact_type.split(',').find(v => v == ctype.id)!=undefined))" :object="true" :placeholder="$t('global.select_types')" label="name" track-by="name"  :noOptionsText="$t('global.empty_list')"  mode="tags" valueProp="id" :close-on-select="false" :searchable="true">
                  </multiselect>
                  </div>
                </div>
              </div>
            </div>
            <b-alert variant="danger"
                     dismissible
                     :show="showAlert"
                     @dismissed="showAlert=false">
              {{alertText}}
            </b-alert>
          </form>
          <div slot="modal-footer" class="submit-box-2">
            <b-button v-if="new_org.was_disabled==1"  class="action-button" v-on:click="showNewOrg=false;showDeleteChapter=true" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
            <b-button  class="action-button" v-on:click="showNewOrg=false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" v-on:click="saveOrg" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
          </div>
        </b-modal>
        <b-modal id="modal-center" v-model="showDeleteChapter" centered :title="$t('organizations.delete_title')">
            <p class="my-4" v-html="$t('organizations.delete_chapter')"></p>
            <div slot="modal-footer" class="submit-box-2">
              <b-button class="action-button" v-on:click="showDeleteChapter=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
              <b-button class="action-button" v-on:click="deleteChapter" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
            </div>
          </b-modal>
          <b-modal id="modal-center" v-model="showMoveChapter" centered :title="$t('organizations.move_chapter')">
            <p class="my-4" v-html="isCorporateConnection? $t('organizations.move_confirm_cc') : $t('organizations.move_confirm')"></p>
            <div slot="modal-footer" class="submit-box-2">
              <b-button class="action-button" v-on:click="showMoveChapter=false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
              <b-button class="action-button" v-on:click="moveChapter" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_yes')}}</span></b-button>
            </div>
          </b-modal>
    <b-modal centered v-model="showUnderConstruction" >
        <div class="center-text" v-html="$t('dashboard.rst_construction')">
        </div>
        <div slot="modal-footer" class="submit-box-2">
          <b-button class="action-button" v-on:click="showUnderConstruction = false;" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
        </div>
      </b-modal>
  </div>
</template>
<style scoped>
.no-logins{
  padding:10px;
  font-size:12px;
}
#org-edit-form{
  display:grid; grid-template-columns: 40% 60%;;
}
.name-dupe{
  grid-column: 2/4;
}
.contact-type{
  display: grid;
  grid-template-columns: 30% 70%;
  margin: 5px 0px;
}
#move-chapter-button{
  grid-column: 2;margin-top:10px;
}
#chapter-info-form-box{
  grid-template-columns: 40% 60%;
}
#password-change{
  font-size: 10px;grid-column: 1/3;margin-left: auto;font-size: 10px;margin-bottom: 20px;
}
#no-logins-note{
  grid-column: 2 / 3;font-size: 10px;margin: auto;margin-bottom: 10px;
}
.new-org-box{
  display:grid;grid-template-columns: 20% 80%;
}
.chapter-login-as-button{
  position:relative;font-size:16px;
}
.member-popover-image{
  width:75px;height:auto;
}
.regiona-dash-member-popover{
  display:grid; grid-template-columns: 100px auto;
}
.region-dash-member-name{
  color: #01aeef;
}
.region-dash-meeting-date{
  font-size: 22px;margin-top:20px;
}
.chapters-by-date-box{
  margin:auto;max-width:1000px;
}
.none-found-note{
  text-align:center; font-size: 30px; margin-top: 50px;
}
.region-dash-contact-select{
  margin: 5px;
}
.region-dash-contact{
  margin: auto;margin-left: 10px;
}
#hide-setup{
  padding:0;border: none;
}
#alternate-regions{
  display:grid; grid-template-columns: repeat(3,auto); grid-gap:10px;
}
#region-dash-date{
  color:#333;font-size:16px;
}
#region-dash-org-name{
  color:#ff0000;margin: 0 50px;font-size:26px;
}
#region-dash-setup-box{
  display: grid;grid-gap: 10px;width: fit-content;margin: auto;
}
.contact-select{
  grid-column:2/4;
}
#language-select{
  grid-column: 2/3;margin-bottom: 10px; max-width: 300px;
}
#search-bar{
  display:grid;
  grid-template-columns: repeat(7, auto);
  grid-gap: 10px;
  padding: 10px;
  margin: auto;
  border-radius: 2px;
}
.region-switch-button{
  border-radius: 10px;
    border: 1px solid #aaa;
    color: #aaa;
    background-color: #fff;
    padding: 5px 10px;
}
.region-switch-button:hover{
  background-color: #e0f5fd;
}
.dashboard-title{
    border: 1px #f2f2f2 solid;
    color: #7f7f7f;
    border-radius: 2px;
    font-size: 19px;
    background-color: #fafafa;
    padding: 5px;
    margin-top: 15px;
    line-height: 19px;
    display:grid;
    grid-template-columns: max-content auto;
  }
.portal-option{
      text-align: left;
      border: #ccc solid 1px;
      border-radius: 10px;
      padding: 10px;
      max-width: 200px;
      height: fit-content;
      height: -moz-fit-content;
      height: -webkit-fit-content;
      cursor: pointer;
      min-width: 350px;
    }
    .portal-option>img{
      max-height: 50px;
      margin:auto;
          padding: 3px;
    }
    .portal-option:hover{
      /*background-color: #d3e3f0;*/
      background-color: #e0f5fd;
    }
#dash {
    height: calc(100% - 50px);
    position: relative;
    bottom: 0px;
    top: 0px;
    right: 0px;
    left: 0px;
    display: block;
  }
  #meeting-box {
    height: 100%;
  }
  #meeting-description {
    background-color: #fafafa;
    grid-template-columns: 1fr;
    text-align: center;
    grid-template-rows: repeat(4, max-content);
    height: fit-content;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    margin-top: 5%;
    border: 1px #f2f2f2 solid;
    border-radius: 2px;
    max-width: 1000px;margin: 30px auto; padding: 10px 75px;margin-bottom: 15px;
  }
</style>
  <script>
  
  import router from "../router"
export default {
  name: "RegionDash",
  watch: {
    '$i18n.locale': { 
      handler: function () {
        if(!this.isCorporateConnections && this.official==1 && this.fields.length>6){
          this.fields[0].label = this.$t('dashboard.time_header');
          this.fields[1].label = this.$t('dashboard.chapter_header');
          this.fields[2].label = this.$t('dashboard.size_header');
          this.fields[3].label = this.$t('dashboard.leadership_header');
          this.fields[4].label = this.$t('dashboard.speakers_header');
          this.fields[5].label = this.$t('dashboard.top_categories');
          this.fields[6].label = this.$t('dashboard.attendance_header');
          this.fields[7].label = this.$t('dashboard.manage_header');
        }else if(!this.isCorporateConnections && this.official!=1  && this.fields.length>6){
           this.fields[0].label = this.$t('dashboard.time_header');
          this.fields[1].label = this.$t('dashboard.chapter_header');
          this.fields[2].label = this.$t('dashboard.size_header');
          this.fields[3].label = this.$t('dashboard.speakers_header');
          this.fields[4].label = this.$t('dashboard.top_categories');
          this.fields[5].label = this.$t('dashboard.attendance_header');
          this.fields[6].label = this.$t('dashboard.manage_header');
        }else if(this.isCorporateConnections && this.official==1){
          this.fields[0].label = this.$t('dashboard.time_header');
          this.fields[1].label = this.$t('dashboard.chapter_header');
          this.fields[2].label = this.$t('dashboard.size_header');
          //this.fields[3].label = this.$t('dashboard.leadership_header');
          this.fields[3].label = this.$t('dashboard.speakers_header');
          this.fields[4].label = this.$t('dashboard.attendance_header');
          this.fields[5].label = this.$t('dashboard.manage_header');
        }else if(this.isCorporateConnections && this.official!=1){
          this.fields[0].label = this.$t('dashboard.time_header');
          this.fields[1].label = this.$t('dashboard.chapter_header');
          this.fields[2].label = this.$t('dashboard.size_header');
          this.fields[3].label = this.$t('dashboard.speakers_header');
          this.fields[4].label = this.$t('dashboard.attendance_header');
          this.fields[5].label = this.$t('dashboard.manage_header');
        }
        
      }
    }
  },
  data() {
    return {
      showAlert: false,
      chapterAdminNameInUse: false,
      organization_edit: false,
      official: 0,
      select_contacts: [{id: 0, name: this.$t('dashboard.select_contact')}],
      official_region: 0,
      new_org: { admin_id: 0, org_id:0, org_name: "", admin_name: "", admin_pass: "", languages: [], language_admin: 0, onboard: 0,
          edmin_email: "", admin_retype: "", pull_parent: 1, exec_director: 0, ambassador: 0, director_consultant: 0, area_director: 0,was_disabled:0 },
      showNewOrg: false,
      showMoveChapter: false,
      showDeleteChapter: false,
      today: '',
      leaders: [{id: 0, name: this.$t('dashboard.select_contact')}],
      contacts: [{id: 0, name: this.$t('dashboard.select_contact')}],
      contactId: 0,
      leaderId: 0,
      showWeek: 0,
      dates: [],
      showUnderConstruction: false,
      regionOptions: [],
    organizations: [],
    languages: [],
    regionType: 0,
      fields: [
        { key: 'start',
              label: this.$t('dashboard.time_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"},
              tdClass: "no-wrap"
            },
            { key: 'name',
              label: this.$t('dashboard.chapter_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"},
              tdClass: "bolded-text"
            },
            { key: 'size',
              label: this.$t('dashboard.size_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" , "text-align": "center" },
              tdClass: "actions-centre"
            },
             { key: 'speakers',
              label: this.$t('dashboard.speakers_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            },
            
             { key: 'attendance',
              label: this.$t('dashboard.attendance_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" , "text-align": "center"},
              tdClass: "actions-centre"
            },
            { key: 'actions',
              label: this.$t('dashboard.manage_header'),
              sortable: false,
              thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff", "width": "150px", "text-align": "center" },
              tdClass: "actions-centre"
            }
          ],
          typeOptions: [],
          languageOptions: [],
          popup: {}
    };
  },
  methods: {
    checkAdminName(varName, id, name){
        this.$http.post("/api/member/admin/name/check", {id: id, name: name}).then((response) => {
            if (response.data.success == true) {
              this.$set(this, varName, response.data.check);
            }
          }).catch(() => {
          });
      },
    clearSearch(){
      this.showWeek = 0;
      this.contactId = 0;
      this.leaderId = 0;
    },
    getContactTypes(){
        this.$http.post("/api/contact/types/get", {only_assignable: true}).then((response) => {
          for(var j=0; j<response.data.types.length;j++){
              response.data.types[j].members = [];
            }
          this.typeOptions = response.data.types;
          
        }).catch(() => {
        });
      },
    hideSetup(){
        this.$http.post("/api/organization/onboard/update", {onboard: 0}).then((response) => {
          if (response.data.success) {
            this.permissions.organization.onboard = 0;
          }
        }).catch((errors) => {
          console.log(errors)
        });
      },
    goToSetupPage(page, anchor, popup){
      this.permissions.setup_popup = popup;
      router.push({path:page, hash: anchor});
    },
    setFields(){
      this.fields=[
        { key: 'start',
              label: this.$t('dashboard.time_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"},
              tdClass: "no-wrap"
            },
            { key: 'name',
              label: this.$t('dashboard.chapter_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"},
              tdClass: "bolded-text"
            },
            { key: 'size',
              label: this.$t('dashboard.size_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" , "text-align": "center" },
              tdClass: "actions-centre"
            },
            { key: 'speakers',
              label: this.$t('dashboard.speakers_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            },
            { key: 'attendance',
              label: this.$t('dashboard.attendance_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" , "text-align": "center"},
              tdClass: "actions-centre"
            },
            { key: 'actions',
              label: this.$t('dashboard.manage_header'),
              sortable: false,
              thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff", "width": "150px", "text-align": "center" },
              tdClass: "actions-centre"
            }
          ];
          if(!this.isCorporateConnections && this.official==1){
          this.fields.splice(3, 0, 
            { key: 'leadership',
              label: this.$t('dashboard.leadership_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            });
        }
        if(!this.isCorporateConnections){
          this.fields.splice(this.official==1? 5 : 4, 0, { key: 'categories',
            label: this.$t('dashboard.top_categories'),
            sortable: true,
            thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
          });
        }
    },
    loginToAlternate(reg){
      if(reg.disabled){
        return;
      }
      var value = reg.value;
      if(value != this.permissions.organization.id){
        this.$http.post("/api/login/toalternative", {id: value}).then((response) => {
            if(response.data.success){
              this.permissions.access = response.data.permissions.access;
              this.permissions.admin = response.data.permissions.admin;
              this.permissions.access = response.data.permissions.access;
              this.permissions.access = response.data.permissions.access;
              this.permissions.access = response.data.permissions.access;
              this.permissions.organization = response.data.permissions.organization;
              this.official = response.data.permissions.organization.official;
              this.setFields();
              this.permissions.user = response.data.permissions.user;
              document.title = this.$t('global.document_title_admin') +" "+ this.permissions.organization.name;
              if(this.permissions.organization.rst){
                router.push("/admin/dashboard");
              }else{
                this.getOrganizations();
                this.getAlternativeRegions();
              }
            }else if(response.data.error==1){
              this.showUnderConstruction = true;
            }
        }).catch((errors) => {console.log(errors);});
      }
    },
      goToScripts(id) {
        router.push("/admin/scripts/" + id);
      },
      goToAgenda(id) {
        router.push("/admin/agenda/" + id);
      },
      onPopoverShowLeader(leader){
        this.popup = {};
        this.$http.post("/api/member/getinfo/popup", {id: leader.id}).then((response) => {
            if(response.data.success){
              this.popup = response.data.member;
            }
        }).catch((errors) => {console.log(errors);});
      },
      onPopoverShowSpeaker(speaker){
        this.popup = {};
        this.$http.post("/api/speaker/getinfo/popup", {id: speaker.id}).then((response) => {
            if(response.data.success){
              this.popup = response.data.speaker;
            }
        }).catch((errors) => {console.log(errors);});
      },
      loginAsChapter(org){
        this.$http.post("/api/login/tochild", {id: org.id}).then((response) => {
            if(response.data.success){
              this.permissions.access = response.data.permissions.access;
              this.permissions.admin = response.data.permissions.admin;
              this.permissions.access = response.data.permissions.access;
              this.permissions.access = response.data.permissions.access;
              this.permissions.access = response.data.permissions.access;
              this.permissions.organization = response.data.permissions.organization;
              this.permissions.user = response.data.permissions.user;
              document.title = this.$t('global.document_title_admin') +" "+ this.permissions.organization.name;
              router.push("/admin/dashboard");
              this.$root.$emit('userChange', "");
            }
        }).catch((errors) => {console.log(errors);});
      },
    getOrganizations() {
        this.$http.post("/api/organization/get/children/week", {}).then((response) => {
          /*for(var i=0; i<response.data.children.length;i++){
            var d = response.data.children[i];
            if(d.meet_start != null){
              d.next_meeting = " @ "+d.meet_start; //this.$t('general.day_option_'+(d.meet_day_of_week+1))+
            }
          }
          this.organizations = response.data.children;*/
          if(response.data.success){
            this.today = response.data.today;
            this.dates = response.data.dates;
            this.leaders = [];
            for(var i=0; i<this.dates.length; i++){
              for(var j=0; j<this.dates[i].chapters.length; j++){
                if(this.dates[i].chapters[j].leadership.length>0){
                  Array.prototype.push.apply(this.leaders, this.dates[i].chapters[j].leadership);
                }
              }
            }
            this.leaders.sort(function(a, b) {
                var textA = a.name.toUpperCase();
                var textB = b.name.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
            this.leaders.unshift({id: 0, name: this.$t('dashboard.select_contact')});
          }
        }).catch(() => {
        });
      },
      getAlternativeRegions(){
        this.$http.post("/api/organization/get/alternatives", {}).then((response) => {
          if(response.data.success){
            this.official = response.data.official;
            this.setFields();
            this.regionOptions = [];
            for(var i=0; i<response.data.alternatives.length;i++){
              var a = response.data.alternatives[i];
              if(a.official == 1){
                this.regionOptions.push({value: a.id, text: this.$t('organizations.chapter_weekly'), disabled: false});
              }else if(a.official == 0){
                this.regionOptions.push({value: a.id, text: this.$t('organizations.startup_chapter'), disabled: false}); //this.isCorporateConnections
              }else if(a.official == 2){
                this.regionOptions.push({value: a.id, text: this.isCorporateConnections? this.$t('organizations.director_meetings') : this.$t('organizations.support_team'), disabled: this.isCorporateConnections});
              }
            }
            this.regionType = response.data.region_type;
          }
        }).catch(() => {
        });
      },
       getContacts() {
        this.$http.post("/api/organization/contacts", {}).then((response) => {
            this.contacts = response.data.contacts;
            this.select_contacts = response.data.contacts.filter(function () {
              return true;
            });
             this.select_contacts.unshift({id: 0, name: this.$t('dashboard.select_contact')});
            
        }).catch((errors) => {console.log(errors);});
      },
      editOrganization(org) {
        this.$http.post("/api/organization/get/admin_info", {id: org.id}).then((response) => {
          if (response.data.success) {
            this.new_org.org_name = response.data.organization.name;
            this.new_org.admin_name = response.data.admin.name;
            this.new_org.admin_id = response.data.admin.id;
            this.new_org.org_id = response.data.organization.id;
            this.new_org.official = response.data.organization.official;
            this.new_org.admin_pass = "";
            this.new_org.admin_email = response.data.admin.email;
            this.new_org.admin_retype = "";
            this.organization_edit = true;
            this.showNewOrg = true;
            this.new_org.was_disabled = response.data.organization.disabled;
            this.new_org.disabled = response.data.organization.disabled==1;
            this.new_org.cron_speakers = response.data.organization.cron_speakers;
            this.new_org.contacts = {};
            this.new_org.language_admin = response.data.organization.language_admin==1;
            this.new_org.onboard = response.data.organization.onboard==1;
            this.new_org.allow_logins = response.data.organization.allow_logins;
           
            for(var j=0; j<this.typeOptions.length;j++){
              this.typeOptions[j].members = [];
            }
            this.languages = response.data.organization.languages.split(",");
            this.$http.post("/api/organization/contacts/assigned/noauth", {org: org.id}).then((response) => {
                for(var i=0; i<response.data.contacts.length; i++){
                  for(var j=0; j<this.typeOptions.length;j++){
                      if(response.data.contacts[i].type_id == this.typeOptions[j].id){
                        this.typeOptions[j].members.push(response.data.contacts[i]);
                      }
                    }
                }
            }).catch((errors) => {console.log(errors);});
            
          }
        }).catch((e) => {console.log(e);
        });
      },
      updateAdmin() {
        this.showAlert = false;
        if (this.new_org.org_name.length == 0) {
          this.showAlert = true;
          this.alertText = this.$t('organizations.alert_chapter');
          return;
        }
        if (this.new_org.admin_name.length == 0  || this.chapterAdminNameInUse) {
          this.showAlert = true;
          this.alertText = this.$t('organizations.alert_admin');
          return;
        }
        /*if (this.new_org.admin_email.length == 0) {
          this.showAlert = true;
          this.alertText = this.$t('organizations.alert_email');
          return;
        }*/
        if (this.new_org.admin_pass.length>0 && this.new_org.admin_pass != this.new_org.admin_retype) {
          this.showAlert = true;
          this.alertText = this.$t('organizations.alert_match');
          return;
        }
        this.alertText = "";
        this.new_org.contacts = this.typeOptions;

        this.new_org.languages = this.languages.join(",");
        this.$http.post("/api/organization/update/admin_info", this.new_org).then((response) => {
          if (response.data.success) {
            this.organization_edit = false;
            this.showNewOrg = false;
            this.getOrganizations();
          } else if (response.data.error.errno == 1062) {
            this.showAlert = true;
            this.alertText = "The name '" + response.data.error.conflict + "' is already in use.";
          } else {
            this.showAlert = true;
            this.alertText = response.data.error;
          }
        }).catch(() => {
          this.$bvToast.show('serverError');
        });
      },
      saveOrg() {
        if (this.organization_edit) {
          this.updateAdmin();
          return;
        }
        this.showAlert = false;
        if (this.new_org.org_name.length == 0) {
          this.showAlert = true;
          this.alertText = this.$t('organizations.alert_chapter');
          return;
        }
        if (this.new_org.admin_name.length == 0) {
          this.showAlert = true;
          this.alertText = this.$t('organizations.alert_admin');
          return;
        }
        if (this.new_org.admin_pass.length == 0) {
          this.showAlert = true;
          this.alertText = this.$t('organizations.alert_password');
          return;
        }
        /*if (this.new_org.admin_email.length == 0) {
          this.showAlert = true;
          this.alertText = this.$t('organizations.alert_email');
          return;
        }*/
        if (this.new_org.admin_pass != this.new_org.admin_retype) {
          this.showAlert = true;
          this.alertText = this.$t('organizations.alert_match');
          return;
        }
        this.alertText = "";
        this.new_org.allow_logins = this.new_org.allow_logins? 1 : 0;
        this.$http.post("/api/organization/new", this.new_org).then((response) => {
          if (response.data.success) {
            this.showNewOrg = false;
            this.getOrganizations();
          } else {
            this.showAlert = true;
            this.alertText = response.data.error;
          }
        }).catch(() => {
        });
      },
      moveChapter(){
        this.$http.post("/api/organization/child/makeofficial", {id: this.new_org.org_id}).then((response) => {
            if(response.data.success){
              this.getOrganizations();
            }
            this.showMoveChapter=false;
        }).catch((errors) => {console.log(errors); this.showMoveChapter=false;});
      },
      deleteChapter(){
        this.$http.post("/api/region/chapter/delete", {id: this.new_org.org_id}).then((response) => {
          if(response.data.success){
            //this.clearFilters();
            this.getOrganizations();
            this.showDeleteChapter = false;
          }else{
            this.showDeleteChapter = false;
          }
        }).catch(() => {
        });
      },
      getOfficialRegion(){
        this.$http.post("/api/region/get/officialregion", {}).then((response) => {
            if(response.data.success && response.data.official==0){
              this.official_region = response.data.official_region;
            }
        }).catch((errors) => {console.log(errors);});
      },
      getLanguages(){
        this.$http.post("/api/organization/languages/possible", {}).then((response) => {
          if (response.data.success === true) {
            this.languageOptions = response.data.languages;
          }
        }).catch((errors) => {
          console.log(errors)
        });
      },
      checkPermissions(){
      this.$http.get("/api/auth/userdash").then((response) => {
        var self = this;
        if (response.data.authorized != true) {
          router.push("/login");
        }else if (!response.data.permissions.organization.is_parent) {
          router.push("/admin/dashboard");
        }
      }).catch((e) => { 
        console.log(e);
        router.push("/login");
      })
    },
  },
  destroyed() {
    this.removeClass(document.getElementById("menu-home"), "active-menu");
    this.$root.$emit('allowOptions', false);
  },
  mounted() {
    this.addClass(document.getElementById("menu-home"), "active-menu");
    this.checkPermissions();
    this.getContacts();
    this.getContactTypes();
    this.getOfficialRegion();
    this.getLanguages();
    this.getOrganizations();
    this.getAlternativeRegions();
    this.$root.$emit('allowOptions', true);
  }
};
</script>
