<template>
  <div>
    <Header :byId="true" :showDate="true" xlogout="true"></Header>
    <div class="rel-grid-container">
      <div ></div>
        <div id="dashboard-main-view-wrapper" class="grid-fit-2column fit-width auto-margin center-text">
            <div id="my-profile">{{$t('userdash.my_profile')}}</div>
            <div id="manage-content">{{$t('userdash.manage_content')}}</div>
            <div id="dashboard-member-info" class="dashboard-object">
                <img id="dashboard-member-image" :src="member.photo" alt="member photo" />
                <div>{{member.email}}</div>
                <div>{{member.phone}}</div>
                <div>{{member.website}}</div>
                <b-button class="action-button auto-margin" v-on:click="goToProfile" variant="clear-blue"><div class="reset-button"></div><span>{{$t('global.button_update')}}</span></b-button>
            </div>
            <div id="dashboard-options-box" class="fit-height">
                <div v-if="content.permissions.categories" class="dashboard-object" v-on:click="goToCategories">
                    <div class="option-title">{{$t('userdash.open_categories')}}</div>
                    <div :style="{'color': content.categories>0? '#555555' : '#F8696B','font-size':'14px'}">{{$tc("userdash.categories_num",content.categories)}}</div>
                </div>
                <div v-if="content.permissions.speakers" class="dashboard-object" v-on:click="goToSpeakers">
                    <div class="option-title">{{$t('userdash.upcoming_speakers')}}</div>
                    <div :style="{'color': content.speakers>0? '#555555' : '#F8696B','font-size':'14px'}">{{$tc("userdash.speakers_num",content.speakers)}}</div>
                </div>
                <div v-for="speaker in content.featured" :key="speaker.id" class="dashboard-object" v-on:click="goToSpeaker(speaker.hash)">
                    <div class="option-title">{{$t('userdash.featured_speaker')}}</div>
                    <div class="dashboard-option-text">{{$d(new Date(speaker.day), 'day_text')}}</div>
                </div>
                <div v-if="content.permissions.performance" class="dashboard-object" v-on:click="goToPerformance">
                    <div class="option-title">{{$t('userdash.chapter_performance')}}</div>
                    <div :style="{'color': content.performance>0? '#63BE7B' : '#F8696B','font-size':'14px'}">{{content.performance>0? $t("userdash.updated_text") : $t("userdash.not_set")}}</div>
                </div>
                <div v-if="content.permissions.attendance" class="dashboard-object" v-on:click="goToAttendance">
                    <div class="option-title">{{$t('userdash.attendance_text')}}</div>
                    <div class="dashboard-option-text">{{$d(new Date(content.meeting.next_meeting), 'day_text')}}</div>
                </div>
                <div v-if="content.permissions.newsletter" class="dashboard-object" v-on:click="goToNewsletter">
                    <div class="option-title">{{$t('userdash.newsletter_text')}}</div>
                    <div class="dashboard-option-text">{{content.meeting.t>0 ? $tc("userdash.hours_to", content.meeting.t) : $d(new Date(content.meeting.next_meeting), 'day_text')}}</div>
                </div>
                <div v-for="section in content.sections" :key="section.id" class="dashboard-object" v-on:click="goToSection(section.preparer_hash)">
                    <div class="option-title">{{$t('userdash.meeting_section')}}</div>
                    <div class="dashboard-option-text">{{section.name}}</div>
                </div>
                <div v-if="content.permissions.quote" class="dashboard-object" v-on:click="goToQuote">
                    <div class="option-title">{{$t('userdash.closing_quote')}}</div>
                    <div :style="{'color': content.quote? '#63BE7B' : '#F8696B','font-size':'14px'}">{{content.quote? $t("userdash.updated_text") : $t("userdash.not_set")}}</div>
                </div>
                <div v-if="content.permissions.events" class="dashboard-object" v-on:click="goToEvents">
                    <div class="option-title">{{$t('userdash.events_text')}}</div>
                    <div class="dashboard-option-text">{{$t("userdash.chapter_text")+": "+content.events.chapter+"  "+$t("userdash.region_text")+": "+content.events.region}}</div>
                </div>
            </div>
        </div>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
#dashboard-main-view-wrapper{
    grid-gap: 50px;
}
#manage-contet, #my-profile{
    font-size: 24px;
}
#dashboard-member-info{
    text-align:center; cursor:auto; display: grid; grid-template-columns: auto;min-width: 200px;
}
#dashboard-member-image{
    height:200px; margin: 20px auto;
}
#dashboard-options-box{
    display:grid; grid-template-columns: repeat(3,1fr);grid-gap: 20px;
}
.dashboard-option-text{
    color:#555555;font-size:14px;
}
 .dashboard-object{
    text-align: left;
    border: #ccc solid 1px;
    border-radius: 10px;
    padding: 10px;
    width:100%;
    height: fit-content;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    cursor: pointer;
  }
  .dashboard-object:hover{
    background-color: #e0f5fd;
  }
  .dashboard-object:hover{
      background-color: #f7f7f7;
  }
  .dashboard-title{
    border: 1px #f2f2f2 solid;
    color: #7f7f7f;
    border-radius: 2px;
    font-size: 19px;
    background-color: #fafafa;
    padding: 5px;
    margin-top: 15px;
    line-height: 19px;
    display:grid;
    grid-template-columns: max-content auto;
  }
  .dashboard-title > img{
    height: 19px;
  }
  .dashboard-title > span{
    margin-left: 3px;
  }
    .option-title{
      color:#00aeef;
      font-size:18px;
      /*font-weight: bold;*/
    }
    .option-description{
      color:#aaa;
      font-size:14px;
      text-align: left;
    }
  
</style>
<script>
    
export default {
name: "UserDash",

    data() {
    return {
        member: {id: 0, name: "", useruid: '', pin: ''},
        content: {permissions: {}}
    }
    },
    methods: {
        getUserinfo() {
            this.$http.post("/api/member/getinfo", {}).then((response) => {
            if (response.data.success) {
                this.member = response.data.member;
            }
            }).catch(() => {});
        },
        getContentinfo() {
            this.$http.post("/api/member/getmanagepermissions", {}).then((response) => {
            if (response.data.success) {
                this.content = response.data;
            }
            }).catch(() => {});
        },
        goToAttendance(){
            window.open("/#/controller/attendance", "_blank", 'location=no,status=no,titlebar=no,toolbar=no,menubar=no');
        },
        goToNewsletter(){
            window.open("#/newsletter/"+this.member.useruid, "_blank", 'location=no,status=no,titlebar=no,toolbar=no,menubar=no');
        },
        goToQuote(){
            window.open("#/quote/"+this.member.useruid, "_blank", 'location=no,status=no,titlebar=no,toolbar=no,menubar=no');
        },
        goToEvents(){
            window.open("#/events/"+this.member.useruid, "_blank", 'location=no,status=no,titlebar=no,toolbar=no,menubar=no');
        },
        goToSection(hash){
             window.open("#/prepare/"+hash+"/"+this.member.useruid, "_blank", 'location=no,status=no,titlebar=no,toolbar=no,menubar=no');
        },
        goToSpeaker(hash){
            window.open("#/presentation/"+hash, "_blank", 'location=no,status=no,titlebar=no,toolbar=no,menubar=no');
        },
        goToSpeakers(){
            window.open("#/schedule/"+this.member.useruid, "_blank", 'location=no,status=no,titlebar=no,toolbar=no,menubar=no');
        },
        goToPerformance(){
            window.open("#/performance/"+this.member.useruid, "_blank", 'location=no,status=no,titlebar=no,toolbar=no,menubar=no');
        },
        goToCategories(){
             window.open("#/categories/"+this.member.useruid, "_blank", 'location=no,status=no,titlebar=no,toolbar=no,menubar=no');
        },
        goToProfile(){
            window.open("#/memberinfo/"+this.member.useruid, "_blank", 'location=no,status=no,titlebar=no,toolbar=no,menubar=no');
        }
    },
    destroyed() {
      
    },
    mounted() {
        this.genericAuthenticate("/api/auth/userdash","/login");
       this.getUserinfo();
       this.getContentinfo();
    }
}
</script>
