<template>
  <div  id="file-box" class="full-area">
    <div v-if="fileReturned && fileFound" id="file-top-bar" class="top-home-bar">
      <div class="fit-content grid-fit-2column no-margin">
        <div id="home-logo" class="fit-content pre-format full-height">
        <div v-on:click="toggleFullScreen" id="fullscreen-overlay" ><div class="fit-content">{{$t('global.fullscreen')}}</div></div>
      </div>
         <div id="general-info-header">
          <span v-if="windowWidth>668" id="home-chapter-name">{{organization_name}}</span>
          <div v-if="windowWidth>668" id="file-day">{{fileData.day? fileData.day : (next_meeting_date? $d(next_meeting_date,'day_month_long'): '')}}</div>
          <div v-if="windowWidth>668" id="file-name">{{fileData.description}}</div>
        </div>
        </div>
        <div id="file-info-header">
          <div></div>
          <div id="below-text">
              <div v-if="id_string != undefined && id_string.length>0" class="auto-margin">
                <span id="can-control">{{$t('global.can_control_1')}}</span>
              </div>
              <div v-if="id_string != undefined && id_string.length==0" class="auto-margin">
                <span id="cant-control">{{$t('global.cant_control_1')}}</span>
              </div>
              <div v-if="pdfRet" id="file-controls" class="fit-content">
                <b-button v-if="loaded" :disabled="pdfPage==1" v-on:click.stop="goToPresentationSlide(pdfPage-1)" class="file-control-button width-button"  :class="{ 'grey-arrow-left': pdfPage==1, 'blue-arrow-left': pdfPage>1}" variant="clear-blue"></b-button>
                <div>
                  <div v-if="!(id_string != undefined && id_string.length>0 && current_time>=0)" id="preview-text">{{$t('general.preview_text')}}</div>
                  <div v-if="(id_string != undefined && id_string.length>0 && current_time>=0)" :id="current_time>=60? 'timer-text' : 'timer-text-low'">{{getTimeString()}}</div>
                  <div v-if="pdfRet.pages>0" id="page-number">{{pdfPage+" "+$t('global.of_text')+" "+pdfRet.pages}}</div>
                </div>
                <b-button v-if="loaded" :disabled="pdfPage==pdfRet.pages" v-on:click.stop="goToPresentationSlide(pdfPage+1)" class="file-control-button width-button" :class="{ 'grey-arrow-right': pdfPage==pdfRet.pages, 'blue-arrow-right': pdfPage<pdfRet.pages}" variant="clear-blue" ></b-button>
            </div>
          </div>
          <div></div>
        </div>
        <div id="logout-button-wrapper">
          <div v-if="(window && (window.opener != null || window.history.length == 1))" class="clickable"  id="portal-exit-button" v-on:click="logout"><b-img :src="getImgUrl('nav-Close-Gray.png')" /></div>
        </div>  
       </div>
    <div v-if="pdfRet && pdfRet.pdf != null && fileReturned && fileFound" id="page-wrapper" :style="{'grid-template-columns': pdfRet && pdfRet.pages>1? '220px calc(100% - 220px)' : '100%'}">
      <div id="side-wrapper" v-if="pdfRet.pages>1">
        <div v-if="pdfRet && pdfRet.pdf != null" id="side-preview" class="fit-height">
           <div  :id="'preview_slide_'+i" class="preview-slide fit-height h" :class="{'active-page': i==pdfPage}"  v-for="i in pdfRet.pages" :key="i" :style="{'cursor': 'pointer'}" v-on:click="goToPage(i)">
            <VuePDF v-if="pdfRet && pdfRet.pdf != null" @loaded="onLoaded" :pdf="pdfRet.pdf" :page="i" :scale="windowWidth/10" fit-parent/>
            <span>{{i}}</span>
          </div>
        </div>
      </div>
      <div id="main-view" class="full-area">
        <div id="pdf-wrapper-wrapper">  
          <div id="pdf-wrapper"  >
            <VuePDF v-if="pdfRet && pdfRet.pdf != null" :style="{'margin':'auto', 'width': 'fit-content'}" :pdf="pdfRet.pdf" :page="pdfPage" :width="Math.min(fileWidth, fileHeight*pdfRatio)" />
          </div>
          <div v-if="loaded && pdfPage>1" id="previous-page-area" v-on:click.stop="goToPresentationSlide(pdfPage-1)"></div>
          <div v-if="loaded && pdfRet && pdfPage<pdfRet.pages" id="next-page-area" v-on:click.stop="goToPresentationSlide(pdfPage+1)"></div>
        </div>
      </div>
    </div>
    <div v-if="(!pdfRet || (pdfRet && pdfRet.pdf == null)) && fileReturned && fileFound" id="loading-view" class="valign-grid auto-margin">
      <div></div>
      <div class="loading-icon auto-margin"></div>
      <div></div>
    </div>
      
    <div v-if="fileReturned && !fileFound" id="file-not-found" class="full-area">
        <div></div>
        <div id="file-not-found-wrapper">
            <b-alert id="file-not-found-alert" variant="warning" :show="!fileFound">
                <span v-html="$t('general.file_not_found')"></span>
            </b-alert>
        </div>
        <div></div>
    </div>
  </div>
</template>
<style scoped lang="scss">
#loading-view{
  width: 50%;
  height:calc(100% - 80px);
}
#pdf-wrapper-wrapper{
  height: calc(100% - 20px);
  width: calc(100% - 20px);
  margin-left: 20px;
}
#file-not-found-alert{
  width: 100%;text-align:center;color:black;
}
#file-not-found-wrapper{
  max-width: 600px;margin:auto;width: 100%;text-align:center;
}
#file-not-found{
  display: grid; grid-template-rows: auto max-content auto;
}
#previous-page-area{
  position:absolute;left:0%;top:0%;z-index:100; height:100%;width:50%;cursor:pointer;
}
#next-page-area{
  position:absolute;left:50%;top:0%; height:100%;z-index:100;width:50%;cursor:pointer;
}
#main-view{
  padding:10px;max-height: 100%;overflow: hidden;
}
#main-view >div{
  position: relative;
}
#side-preview{
  display:grid; grid-gap:20px;width:180px;
}
#page-number{
  font-size:12px;
}
#file-controls{
  margin: 0px auto;grid-gap: 50px; display: grid;grid-template-columns: auto auto auto;
}
#cant-control{
  color:red;
}
#can-control{
  color:green;
}
#file-day{
  padding-left: 10px;font-size: 15px;
}
.no-margin{
  margin:0px;
}
#logout-button-wrapper{
  display:grid; grid-template-columns:repeat(1,1fr);margin-left:20px;
}
#general-info-header{
  display:grid;grid-template-rows: auto auto auto;text-align: left; line-height: 12px;
}
#file-info-header{
  display:grid;grid-template-rows: auto min-content auto;text-align: center;
}
#below-text{
  font-size:15px; font-weight:bold;
}
#file-top-bar{
  grid-template-columns: max-content auto max-content; height:80px;
}
#timer-text-normal{
  font-weight: normal;
  color: $VUE_APP_COLOUR_MAIN;
  text-align: center;
}
#timer-text-low{
  font-weight: normal;
  color: red;
  text-align: center;
}
#preview-text{
  font-weight: bold;
  color: $VUE_APP_COLOUR_MAIN;
  text-align: center;
}
#file-name{
  color:$VUE_APP_COLOUR_MAIN; margin-left: 5px;
}
#page-wrapper{
  position: relative;margin: auto;height:calc(100% - 80px); width:100%;display:grid;;
}
.preview-slide{
  width:140px;
  padding:3px;
}
#controller-header{
  display: grid; padding: 3px;border-bottom:1px solid rgb(0, 174, 239);
}
#pdf-wrapper{
  margin:auto;
  width:100%;
  height:100%;
}
.blue-arrow-left{
  background: url("../assets/Prev-Blue.png") transparent center no-repeat !important;
  filter: $VUE_APP_COLOUR_MAIN_FILTER;
}
.grey-arrow-left{
  background: url("../assets/Prev-Gray.png") transparent center no-repeat !important;
}
.blue-arrow-right{
  background: url("../assets/Next-Blue.png") transparent center no-repeat !important;
  filter: $VUE_APP_COLOUR_MAIN_FILTER;
}
.grey-arrow-right{
  background: url("../assets/Next-Gray.png") transparent center no-repeat !important;
}
.file-control-button{
   width: 36px !important;
    height: 40px!important;
  background-size: 100% 100% !important;
  background-color: transparent !important;
  margin: auto;
}
.active-page{
  border-right: 5px solid $VUE_APP_COLOUR_MAIN;
}
#side-wrapper{
  height:100%; overflow-y:scroll;padding:20px;width:240px;text-align:center;
}
</style>
<script>
import favicon from "../assets/favicon-present.png";
import { VuePDF, usePDF } from '@tato30/vue-pdf'
    export default {
    name: "FileControl",
     components: {
      VuePDF
    },
    watch: {
      '$i18n.locale': {
        handler: function () {
          document.title = this.$t('global.document_title') +" - "+ this.file_name;
        },
        deep: true
      },

      'pdfRet': {
        handler: function (v) {
          if(this.pdfRet && this.pdfRet.pdf){
            this.loaded = true;
          }
        },
        deep: true
      },
    },
      data() {
        return {
          fileWidth: 1000,
          fileHeight: 1000,
          fileReturned: false,
          fileFound: false,
            client: {},
            id_string: "",
            pdfPage: 1,
            pdfPath: "",
            maxHeightDiv: "pdf-wrapper",
            filehash: "",
            loaded: false,
            organization_name: "",
            windowWidth: 1000,
            file_name: "",
            current_time: -1,
            timer: {interval: null, end_time: 0,disabled:1,maxTime: 0},
            fileData : {file_path: '', control_type: 0, day: "", description: ""},
            file_id_string : "",
            pdfRet: {pdf: null, pages:0},
            org: null,
            pdfLoad: 0,
            pdfRatio: 1
        }
      },
    sockets: {
        presentation_info(data){
          if (data.showing) {
              if(this.id_string==""){
                this.timer.end_time = (new Date()).getTime() / 1000 + this.timer.maxTime;
                if(this.fileData.control_type!=1){
                    this.startTimer();
                }
              }
              this.id_string = data.id_string;
          }else{
              this.id_string = "";
          }
          if(data.slide){
              this.$set(this,'pdfPage',data.slide);
          }
        },
        sync_timer(data){
          this.timer.end_time = data.end_time;
          if(data.timer_playing){
            this.startTimer();
          }else{
            this.stopTimer();
          }
        },
        connect(data){
          this.$socket.emit("register_controller", {file_hash: this.file_id_string, org: self.org });
        }
    },
    methods: {
      onLoaded(pdfDescrip){
        this.pdfRatio = pdfDescrip.width/pdfDescrip.height;
      },
      goToPage(i){
        this.goToPresentationSlide(i);
      },
      logout() {
      if(window.opener != null || window.history.length == 1){
            window.close()
          }
      },
       getDates(org) {
          this.$http.post("/api/organization/dates/noauth", {org: org}).then((response) => {
            if (response.data.success === true) {
              this.next_meeting = response.data.next_formatted;
              this.orgid = response.data.id;
              this.next_meeting_date = this.next_meeting.length > 0 ? new Date("" + this.next_meeting.split("-").join("/") + " 00:01") : new Date();
              this.organization_name = response.data.name;
            }
          }).catch((e) => { console.log(e);});
          
        },
        getTimeString(){
          return ((Math.floor(this.current_time/60)>0? Math.floor(this.current_time/60)+":" : "")+((Math.floor(this.current_time)%60) < 10 && Math.floor(this.current_time/60)>0? "0"+(Math.floor(this.current_time)%60) : (Math.floor(this.current_time)%60)))
        },
    goToPresentationSlide(i){
        this.pdfPage = i;
        if(this.id_string.length>0){
            this.$socket.emit("goto_file", { id_string: this.id_string, section_id: 0, slide_id: 0, member_id: 0, externalPresentation: true, presentation_slide: i });
        }
      },
        syncPdfController(pages){
            this.loaded = true;
            this.maxPages = pages;
        },
         onResize() {
        if(document.getElementById("file-box")){
          this.windowWidth=document.getElementById("file-box").parentElement.offsetWidth;
        }
        
        if(document.getElementById("pdf-wrapper")){
          this.fileWidth=document.getElementById("pdf-wrapper").offsetWidth;
          this.fileHeight=document.getElementById("pdf-wrapper").offsetHeight;
        }
      },

      onProgress({ loaded, total }) {
        this.pdfLoad = (loaded/total);
      },

      onError(reason) {
        console.error(`PDF loading error: ${reason}`)
      },
      auth(){
          this.filehash = this.$route.params.h;
          var self = this;
          this.fileReturned = false;
            this.fileFound = false;
        if(self.filehash.length>0){
            self.$http.post("/api/organization/file/get/byhash", {hash: self.filehash}).then((response) => {
                if(response.data.success){
                  var file = response.data.file;
                  self.file_name = file.file_path;
                  self.timer.maxTime = file.time;
                  self.fileData = file;
                  self.org = file.org;
                  document.title = self.$t('global.document_title') +" - "+ self.fileData.description;
                  self.getDates(file.org);
                  self.pdfPath = file.url && file.url.includes("/")? file.url : "pdfs/"+file.url;
                  self.pdfLoad = 0;
                  self.pdfRet = usePDF(self.pdfPath,{
                    onProgress: self.onProgress,
                    onError: self.onError
                  });
                  
                  self.file_id_string = file.file_id_string;
                  self.$socket.emit("register_controller", {file_hash: self.file_id_string, org: self.org });
                  this.fileFound = true;
                  this.fileReturned = true;
                }else{
                   this.fileFound = false;
                  this.fileReturned = true;
                }
                
            }).catch(() =>{
               this.fileFound = false;
                this.fileReturned = true;
            });
        }
      },
      stopTimer(){
        if(this.timer.interval != null){
          clearInterval(this.timer.interval);
          this.timer.interval = null;
        }
      },
      startTimer(){
        if(this.timer.interval != null){
          clearInterval(this.timer.interval);
          this.timer.interval = null;
        }
        this.timer.interval = setInterval(() => {
              var secs = this.timer.end_time - (new Date()).getTime() / 1000;
              if (secs > 0) {
                this.current_time = secs;
              } else {
                clearInterval(this.timer.interval);
                this.timer.interval = null;
                this.current_time = 0;
              }
            }, 50);
      },
      stopTimer(){
        if(this.timer.interval != null){
          clearInterval(this.timer.interval);
          this.timer.interval = null;
        }
        
        var secs = this.timer.end_time - (new Date()).getTime() / 1000;
              if (secs > 0) {
                this.current_time = secs;
              }
      },
      onPress(event) {
        if (event.keyCode == 37 && this.pdfPage>1) {
            this.goToPresentationSlide(--this.pdfPage)
        }else if (event.keyCode == 39 && this.pdfRet && this.pdfPage<this.pdfRet.pages){
          this.goToPresentationSlide(++this.pdfPage)
        }
      }
    },
    
     created(){
      window.addEventListener("resize", this.onResize);
      document.addEventListener("keydown", this.onPress);
    },
    destroyed() {
      clearInterval(this.timeInterval);
      window.removeEventListener("resize", this.onResize);
      document.removeEventListener("keydown", this.onPress);
    },
    mounted() {
      if(!this.isCorporateConnections){
        (function() {
            var link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            link.href = favicon;
        })();
      }
      this.onResize();
        this.auth();
        
    }
    }
</script>
