<template>
  <div id="choiceDiv2" >
    <div id="inner-div-grid">
      <div id="controller-top-bar" class="top-home-bar">
        <div id="home-logo" class="fit-content pre-format" >
        <div v-on:click="toggleFullScreen" id="fullscreen-overlay" ><div class="fit-content">{{$t('global.fullscreen')}}</div></div>
      </div>
         <div id="header-names">
          <span v-if="windowWidth>768" id="home-chapter-name" >{{permissions.organization.name}}</span>
          <span v-if="windowWidth>768" id="user-name" class="curtail-text" > {{permissions.user.name}}</span>
        </div>
        <div  id="time-difference-wrapper">
          <div></div>
          <div v-if="windowWidth>500" id="below-text">
           <div id="date-text">{{$d(today, 'day_text')}}</div>
            <div :style="{'color': (total_difference>=0? 'green' : 'red')}" class="center-text">
                {{time_difference}}
              </div>
          </div>
          <div></div>
        </div>
        <div id="other-views-wrapper">
          <div v-if="(id_string.length>0 && view_ready) && current_slide && current_slide.timer == 0" class="fit-content first-column full-height">
             <b-img id="stop-timer-button" class="clickable image-colour-shift" :src="getImgUrl('icon-Timer-Blue.png')"  v-on:click="stopTimer" />
          </div>
          <div id="guide-button" v-if="(id_string.length>0 && view_ready) && permissions.access && windowWidth<768"  v-on:click="guideClick" v-bind:class="{'menu-button2': true,'script-controller-button': showtype == 1, 'agenda-controller-button': showtype != 1}">
          </div>
          <div v-on:click="showAttendance=true;" class="menu-button2 attendance-button">
          </div>
        </div>
        <div id="settings-wrapper">
          <div class="clickable"  id="portal-option-button" v-on:click="showOptions=true;"><b-img id="settings-image" :src="getImgUrl('icon-NavSettings-Gray.png')" /></div>
          <div class="clickable"  id="portal-exit-button" v-on:click="logout"><b-img :src="getImgUrl('nav-Close-Gray.png')" /></div>
        </div>
       </div>
       <div v-if="showControlBar && !(controlPermissions.top && !controlPermissions.multi && !controlPermissions.requesters && controlRequests.length==0)" id="control-bar">
         <div class="fit-width valign-grid">
           <div></div>
           <div v-if="windowWidth>768" class="grid-fit-2column">
             <img id="icon-live" class="image-colour-shift" :src="controlPermissions.access? getImgUrl('icon-presentation-live.png') : getImgUrl('icon-presentation-inactive.png')" />
             <div id="multiple-controllers" v-html="$t('controller.multiple_controllers')">
             </div>
           </div>
          <div></div>
         </div>
          <div v-if="!controlPermissions.access && !controlPermissions.requested && !controlPermissions.top" class="control-banner" >
            <div v-if="controlPermissions.top_controller>=0">{{$t('controller.request_control_text', {type: $t('controller.controller_type'+controlPermissions.top_controller)})}}</div>
            <b-button v-if="controlPermissions.leadership || controlPermissions.admin" class="action-button" v-on:click="requestControl" variant="clear-blue"><div class="request-button"></div><span>{{$t('controller.request_control')}}</span></b-button>
          </div>
          <div v-if="controlPermissions.granted" class="control-banner" >
            <div class="dark-green-text">{{$t('controller.return_control_text')}}</div>
            <b-button class="action-button" v-on:click="returnControl" variant="clear-blue"><div class="stop-button"></div><span>{{$t('controller.return_control')}}</span></b-button>
          </div>
          <div v-if="!controlPermissions.granted && controlPermissions.requested" class="control-banner" >
            <div class="dark-blue-text">{{$t('controller.control_requested',{n: controlPermissions.timeout})}}</div>
            <b-button class="action-button" v-on:click="cancelRequest" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('controller.cancel_request')}}</span></b-button>
          </div>
          <div v-if="controlPermissions.top && !controlPermissions.multi && controlPermissions.requesters && controlRequests.length==0" class="control-banner" >
            <div class="dark-green-text">{{$t('controller.requesters_control', {type: (controlPermissions.president? $t('controller.leadership_or_admin'):  $t('controller.admin_text'))})}}</div>
            <b-button class="action-button" v-on:click="showControlBar=false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_close')}}</span></b-button>
          </div>
          <div v-if="controlPermissions.top && controlPermissions.multi && controlRequests.length==0" class="control-banner" >
            <div class="dark-green-text">{{$t('controller.multi_control', {type: (controlPermissions.president? $t('controller.president_text') : (controlPermissions.leadership? $t('controller.leadership_text') : (controlPermissions.admin? $t('controller.admin_text') : $t('controller.member_text'))))})}}</div>
            <b-button class="action-button" v-on:click="showControlBar=false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_close')}}</span></b-button>
          </div>
          <div v-for="request in controlRequests" :key="request.uid" class="control-banner" >
            <div v-if="request.granted">{{$t('controller.has_control', {name : request.name})}}</div>
            <div v-if="!request.granted" class="dark-blue-text">{{$t('controller.requested_control', {name : request.name})}}</div>
            <b-button v-if="request.granted" v-on:click="takeControl(request)"  class="action-button" variant="clear-blue"><div class="play-button"></div><span>{{$t('controller.take_control')}}</span></b-button>
            <div v-if="!request.granted" class="grid-fit-2column">
              <b-button class="action-button" v-on:click="acceptRequest(request)" variant="clear-blue" :style="{'color' : request.timeout<=3? 'red' : ''}"><div class="save-button"></div><span >{{$t('global.button_accept')}}</span> <span id="accept-countdown">{{$t('controller.accept_countdown', {n: request.timeout})}}</span></b-button>
              <b-button class="action-button" v-on:click="declineRequest(request)" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_decline')}}</span></b-button>
            </div>
          </div>
      </div>
      <div id="start-meeting-box" class="box-back" v-if="(id_string.length==0 || !view_ready)">
        <div id="start-meeting-wrapper">
          <b>{{$t('controller.start_meeting')}}</b>
          <div class="loading-icon auto-margin"></div>
          <i>{{$t('controller.waiting_presenter')}}</i>
        </div>
      </div>
      <multipane v-if="(showtype == 1 || windowWidth>=768) && id_string.length>0 && view_ready"  layout="vertical">
        <div :style="{ width: windowWidth<768 ? windowWidth+'px' : (windowWidth>=768 && windowWidth<1160? (windowWidth*.4)+'px': (windowWidth*.34)+'px'), maxWidth: windowWidth<768 ? '100%' : (windowWidth>=768 && windowWidth<1160? '75%': '50%'), minWidth: windowWidth<768 ? '100%' : (windowWidth>=768 && windowWidth<1160? '25%' : '17%'), 'height': Math.max(windowHeight-84)+'px'}" id="buttonScroll"  class="box-back" >
          <div id="inner-buttonScroll" v-touch:swipe.left="hideTimeBar" v-touch:swipe.right="showTimeBar">
            <div id="sections-wrapper">
              <div v-for="section in sections" :key="section.id" class="padding-div section-div">
                <div class="inner-section-wrapper">
                  <span v-if="!showTimes"></span>
                  <span v-if="showTimes" class="section-start-time">{{$d(section.start_time, 'time12l').slice(0,-2)}}</span>
                  <b-button  v-on:click="sectionClick($event,section)" class="width-button" :variant="section.variant">
                    <div class="section-info">
                      <div>{{section.section_name && section.section_name.length != 0? section.section_name : $t('global.no_translation')}}</div>
                      <img class="image-colour-shift section-loop" v-if="section.looped>0 && !section.disabled" src="../assets/tiny-SectionLoop.png" />
                      <div class="section-preparers-wrapper">
                          <div v-for="prep in section.preparers" :key="prep" class="speaker-name-div">{{prep}}</div>
                      </div>
                      <div v-if="section.force_time!=null && !section.disabled">
                        <img class="image-colour-shift section-auto-start" src="../assets/tiny-SectionAutostart.png" />
                        <i class="section-force-time">{{formatAMPM(section.force_time)}}</i>
                      </div>
                      <div class="section-speakers-wrapper">
                        <div v-for="sp in section.speakers" :key="sp" class="speaker-name-div">{{sp}}</div>
                      </div>
                    </div>
                    <div v-if="current_section==section">
                      <div v-if="section.topic.length>0"><span class="section-topic">{{$t("dashboard.topic_text")}}:</span> {{section.topic}}</div>
                      <div  v-for="file in section.files" :key="file.link" class="padding-div files-padding">
                        <div class="file-controls" v-on:click.stop="1==1">
                          <div class="curtail-text column-span2">{{file.type == 2? file.link :file.description}}</div>
                          <b-button :disabled="file == currentFile" v-on:click.stop="startFile(file, section, false)" class="width-button start-presentation" variant="outline-success">{{ getStartButtonText(file)}}</b-button>
                          <b-button v-if="file.type==1" v-on:click.stop="downloadFile=file;showDownload=true;" class="width-button backup-download" variant="outline-success">{{ $t('controller.backup_download') }}</b-button>
                          <div class="file-control-buttons">
                            <b-button v-if="pslide>0 && file.type == 1 && pdfHash == file.hash" :disabled="pslide==1" v-on:click.stop="goToPresentationSlide(--pslide)" class="arrow-button" variant="outline-success" ><img  src="../assets/White-Prev.png" /></b-button>
                            <div v-if="file == currentFile" class="file-time" :style="{'color': section.time>=60? 'rgb(0, 174, 239' : 'red'}">{{getTimeString(section.time)}}</div>
                            <b-button v-if="pslide>0 && file.type == 1 && pdfHash == file.hash" :disabled="pslide==maxPresentationSlide" v-on:click.stop="goToPresentationSlide(++pslide)" class="arrow-button" variant="outline-success" ><img src="../assets/White-Next.png" /></b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="padding-div" v-if="current_section==section">
                      <div v-for="slide in section.slides" :key="slide.id" class="padding-div">
                        <b-button v-on:click.stop="slideClick(section,slide)" class="width-button" :variant="slide.variant" v-html="slide.name && slide.name.length!=0? slide.name : $t('global.no_translation')"></b-button>
                        <div v-if="current_slide==slide && slide.slide_files && slide.slide_files.length>0" class="padding-div files-padding">
                          <div v-for="file in slide.slide_files" :key="file.link" class="file-controls" v-on:click.stop="1==1">
                            <div class="curtail-text column-span2">{{file.type == 2? file.link : file.description}}</div>
                            <b-button :disabled="file == currentFile" v-on:click.stop="startFile(file, slide, false)" class="width-button start-presentation" variant="outline-success">{{getStartButtonText(file)}}</b-button>
                            <b-button v-if="file.type==1" v-on:click.stop="downloadFile=file;showDownload=true;" class="width-button backup-download" variant="outline-success" >{{$t('controller.backup_download')}}</b-button>
                            <div class="file-control-buttons">
                              <b-button v-if="pslide>0 && file.type == 1 && pdfHash == file.hash" :disabled="pslide==1" v-on:click.stop="goToPresentationSlide(--pslide)" class="arrow-button" variant="outline-success" ><img src="../assets/White-Prev.png" /></b-button>
                              <div v-if="file.started" ></div>
                              <b-button v-if="pslide>0 && file.type == 1 && pdfHash == file.hash" :disabled="pslide==maxPresentationSlide" v-on:click.stop="goToPresentationSlide(++pslide)" class="arrow-button" variant="outline-success" ><img src="../assets/White-Next.png" /></b-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <span v-if="current_section==section && section.auto_play==0" class="section-no-autoplay">{{$t('controller.no_autoplay')}}</span>
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <multipane-resizer v-if="windowWidth>=768"  :style="{'background-color': '#ddd', 'z-index': 100, 'height': Math.max(windowHeight-84)+'px'}"></multipane-resizer>
        <div :style="{ display: 'block', flexGrow: '1', width: '100px',  'height': (windowHeight-84)+'px'}" class="box-back" >
          <div v-if="current_section==null" class="valign-grid full-height">
            <div></div>
            <div>{{$t('controller.section_start')}}</div>
            <div></div>
          </div>
          <div v-if="scripts.section.length>1" v-on:click="toggleScripts(0)">
            <div  class="scriptBanner">
              <span >{{isCorporateConnections? $t('controller.section_script_cc') : $t('controller.section_script')}} </span>
            </div>
            <b-collapse  v-model="showSectionScript">
              <div class="script-box" :style="{height: (windowHeight-164+(scripts.slide.length>1? 0 : 40))/(showSlideScript && showSectionScript && scripts.slide.length>1? 2 : 1)+'px','font-size': slideFontSize+'px'}">
                <pre  class="script-text" v-html="scripts.section"></pre>
              </div>
            </b-collapse>
          </div>
          <div v-if="scripts.slide.length>1" v-on:click="toggleScripts(1)">
            <div  class="scriptBanner">
              <span>{{permissions.organization.name.toUpperCase()+" "+$t('controller.slide_script')}}</span> 
            </div>
            <b-collapse  v-model="showSlideScript" >
              <div class="script-box" :style="{height: (windowHeight-164+(scripts.section.length>1? 0 : 40))/(showSectionScript && showSlideScript && scripts.section.length>1? 2 : 1)+'px','font-size': slideFontSize+'px'}">
                  <pre  class="script-text" v-html="scripts.slide"></pre>
              </div>
            </b-collapse>
          </div>
      </div>
      </multipane>
      <div v-if="windowWidth<768 && showtype == 2" class="full-height"> 
        <div v-if="current_section==null" class="valign-grid full-height">
            <div></div>
            <div>{{$t('controller.section_start')}}</div>
            <div></div>
          </div>
          <div v-if="scripts.section.length>1" v-on:click="toggleScripts(0)">
            <div  class="scriptBanner">
              <span >{{isCorporateConnections? $t('controller.section_script_cc') : $t('controller.section_script')}} </span>
            </div>
            <b-collapse  v-model="showSectionScript">
              <div class="script-box" :style="{height: (windowHeight-164+(scripts.slide.length>1? 0 : 40))/(showSlideScript && showSectionScript && scripts.slide.length>1? 2 : 1)+'px','font-size': slideFontSize+'px'}">
                  <pre  class="script-text" v-html="scripts.section"></pre>
              </div>
            </b-collapse>
          </div>
          <div v-if="scripts.slide.length>1" v-on:click="toggleScripts(1)">
            <div  class="scriptBanner">
              <span>{{permissions.organization.name.toUpperCase()+" "+$t('controller.slide_script')}}</span> 
            </div>
            <b-collapse  v-model="showSlideScript" >
              <div class="script-box" :style="{height: (windowHeight-164+(scripts.section.length>1? 0 : 40))/(showSectionScript && showSlideScript && scripts.section.length>1? 2 : 1)+'px', 'font-size': slideFontSize+'px'}">
                  <pre  class="script-text" v-html="scripts.slide"></pre>
              </div>
            </b-collapse>
          </div>
      </div>
    </div>
    <b-modal static id="modal-fullscreen2" centered v-model="showMemberSelect" @hidden="getDates">
      <div slot="modal-header" class="w-100 member-select-header">
        <div class="selection-header" v-html="current_slide?  current_slide.name && current_slide.name.length>0? current_slide.name : $t('global.no_translation') : ''"></div>
        <div id="member-type-wrapper" class="grid-fit-2column">
          <b-button class="action-button header-member-type" v-on:click="showRegional=false" :variant="!showRegional? 'grey-slide' : 'grey-slide-outline'">{{$t('controller.members_text')}}</b-button>
          <b-button class="action-button header-member-type" v-on:click="showRegional=true" :variant="showRegional? 'grey-slide' : 'grey-slide-outline'">{{$t('controller.regional_text')}}</b-button>
        </div>
       <div >
            <div v-if="(id_string.length>0 && view_ready) && current_slide && current_slide.timer == 0" class="grid-fit-2column auto-margin full-height fit-content">
              <b-img class="clickable image-colour-shift header-stop-timer-button" :src="getImgUrl('icon-Timer-Blue.png')"  v-on:click="stopTimer" />
            </div>
          </div>
        <b-button class="action-button auto-margin" v-on:click="showMemberSelect = false;" variant="clear-blue"><div class="close-button"></div><span>{{$t('global.button_close')}}</span></b-button>
      </div>
      <div id="letters-wrapper" v-if="!showRegional && current_slide != null && (current_slide.selection==1)">
        <b-button class="fit-width letter-button" v-for="letter in member_letters" :key="letter.letter" :variant="letter.variant" v-on:click="changeLetter(letter)">
          {{letter.letter}}
        </b-button>
      </div>
      <div v-if="!showRegional" id="member-select">
        <div v-if="current_slide != null && (current_slide.selection==1)"  :style="{'display':'grid','grid-template-rows': 'repeat('+(selected_members.length/15>4? Math.ceil(selected_members.length/4) : Math.ceil(selected_members.length/Math.ceil(selected_members.length/15)))+',max-content)', 'grid-template-columns': 'repeat('+Math.min(Math.ceil(selected_members.length/15),4)+',1fr)'}">
          <div v-for="member in selected_members" class="padding-div curtail-text" :key="member.id">
            <b-button :id="'memberbutton_'+member.id" v-on:click="memberSelectClick(member)" class="width-button curtail-text emphasized-button-text" :variant="member.variant">{{member.name}}</b-button>
          </div>
        </div>
        <div v-if="current_slide != null && (current_slide.selection==2  || current_slide.selection==3 || current_slide.selection==4) && selected_members.length>0">
            <div v-if="currentMemberIndex<0 && current_slide.selection==3" id="member-sort-options"> 
                <b-form-select v-model="shuffleMemberSort" :options="sortOptions" class="mb-3 " text-field="name" value-field="id" />
                <b-form-select v-model="shuffleStartMember" :options="startMembers" class="mb-3 " text-field="name" value-field="id" />
                <b-button  class="action-button" v-b-popover.hover.right="$t('controller.shuffle_members')" v-on:click="shuffleMembers" variant="clear-blue"><div class="refresh-button"></div><span>{{$t('global.button_reshuffle')}}</span></b-button>
            </div>
            
            <div v-if="currentMemberIndex>=0" class="current-selection">{{$t('controller.current_member')+": "}}<span :style="{'color': selected_members[currentMemberIndex].sub_name? '#aaa' : ''}"><b>{{selected_members[currentMemberIndex].name}}</b><br v-if="selected_members[currentMemberIndex].sub_name" /><span class="selected-member-text" v-if="selected_members[currentMemberIndex].sub_name">{{$t('controller.sub_text')+": "+selected_members[currentMemberIndex].sub_name}}</span></span></div>
            <div v-if="currentMemberIndex>=0" class="next-previous-selection">
              <b-button v-if="previousMemberIndex>=0"  v-on:click="memberSelectClick(selected_members[previousMemberIndex])" class="width-button curtail-text emphasized-button-text" :variant="selected_members[previousMemberIndex].variant">{{$t('controller.prev_member')+": "}}<span :style="{'color': selected_members[previousMemberIndex].sub_name? '#aaa' : ''}">{{selected_members[previousMemberIndex].name}}<br v-if="selected_members[previousMemberIndex].sub_name" /><span class="selected-member-text" v-if="selected_members[previousMemberIndex].sub_name">{{$t('controller.sub_text')+": "+selected_members[previousMemberIndex].sub_name}}</span></span></b-button>
              <b-button v-if="nextMemberIndex<selected_members.length" v-on:click="memberSelectClick(selected_members[nextMemberIndex])" class="width-button curtail-text emphasized-button-text next-selected" :variant="selected_members[nextMemberIndex].variant">{{$t('controller.next_member')+": "}}<span :style="{'color': selected_members[nextMemberIndex].sub_name? '#aaa' : ''}">{{selected_members[nextMemberIndex].name}}<br v-if="selected_members[nextMemberIndex].sub_name" /><span class="selected-member-text" v-if="selected_members[nextMemberIndex].sub_name">{{$t('controller.sub_text')+": "+selected_members[nextMemberIndex].sub_name}}</span></span></b-button>
            </div>
             <div :style="{'grid-gap': '30px','display':'grid','grid-template-rows': '1fr', 'grid-template-columns': 'repeat('+Math.min(Math.ceil(selected_members.length/15),4)+',1fr)'}">
            <div v-for="i in Math.min(Math.ceil(selected_members.length/15),4)" :key="i" class="present-column-header">
                <div></div>
                <div class="present-header-text">{{$t('attendance.status_present')}}</div>
            </div>
          </div>
            <div :style="{'grid-auto-flow': 'column','grid-column-gap': '30px','display':'grid','grid-template-rows': 'repeat('+(selected_members.length/15>4? Math.ceil(selected_members.length/4) : Math.ceil(selected_members.length/Math.ceil(selected_members.length/15)))+',max-content)', 'grid-template-columns': 'repeat('+Math.min(Math.ceil(selected_members.length/15),4)+',1fr)'}">
              <div v-for="member in selected_members" class="padding-div curtail-text" :key="member.id">
                <div class="selection-option-layout">
                  <b-button :id="'memberbutton_'+member.id" :disabled="member.present==0"  v-on:click="memberSelectClick(member)"  class="width-button curtail-text emphasized-button-text member-select-button" :variant="member.variant"><span :style="{'color': member.sub_name? '#aaa' : ''}">{{member.name}}</span>
                    <br v-if="member.sub_name" /><span class="normal-text" v-if="member.sub_name">{{$t('controller.sub_text')+": "+member.sub_name}}</span>
                    <br v-if="current_slide.selection==4" /><span class="member-city" v-if="current_slide.selection==4">{{member.city}}</span>
                    </b-button>
                  <b-button  :disabled="member.disable_present==1" v-on:click="changeMemberPresence(member)" class="width-button curtail-text emphasized-button-text center-text" :variant="member.present ==1? 'outline-success': 'outline-danger'"><img class="member-present-image" :src="getImgUrl(member.present ==1?'checkmark-green.png' : 'cross-red.png')" /></b-button>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div v-if="showRegional" id="member-select" :style="{ 'grid-template-columns': '1fr', 'grid-auto-flow': 'row'}">
        <div v-for="member in contacts" class="padding-div curtail-text" :key="member.id">
          <b-button v-on:click="memberSelectClick(member)" class="width-button curtail-text emphasized-button-text auto-margin select-contact-button" :variant="member.variant">{{member.name}}</b-button>
        </div>
      </div>
      <div slot="modal-footer" class="submit-box-2"> 
      </div>
    </b-modal>

    <b-modal static id="modal-fullscreen2" centered v-model="showMemberByPowerteamSelect" >
      <div slot="modal-header" class="w-100 member-select-header">
        <div class="selection-header" v-html="current_slide?  current_slide.name && current_slide.name.length>0? current_slide.name : $t('global.no_translation') : ''"></div>
        <div class="grid-fit-2column">
        </div>
       <div >
            <div v-if="(id_string.length>0 && view_ready) && current_slide && current_slide.timer == 0" class="grid-fit-2column auto-margin full-height fit-content">
              <b-img class="clickable image-colour-shift header-stop-timer-button" :src="getImgUrl('icon-Timer-Blue.png')"  v-on:click="stopTimer" />
            </div>
          </div>
        <b-button class="action-button auto-margin" v-on:click="showMemberByPowerteamSelect = false;" variant="clear-blue"><div class="close-button"></div><span>{{$t('global.button_close')}}</span></b-button>
      </div>
      <div >
        <div id="powerteam-selection" v-if="current_slide!= null && current_slide.selection==5" :style="{'grid-template-rows': 'repeat('+(powerteams.length/3>4? Math.ceil(powerteams.length/4) : Math.ceil(powerteams.length/Math.ceil(powerteams.length/3)))+',max-content)', 'grid-template-columns': 'repeat('+Math.min(Math.ceil(powerteams.length/3),4)+',1fr)'}">
          <div v-for="powerteam in powerteams" :key="powerteam.id">
            <b-button style="font-size:16px;font-weight:bold;" v-on:click="getMembersByPowerTeam(powerteam)" class="width-button curtail-text" :variant="powerteam.variant" :disabled="powerteam.disabled==1">{{powerteam.name}}</b-button>
          </div>
        </div>
        <div v-if="current_slide!=null && current_slide.selection==6" >
          <div class="fit-width left-text auto-margin" v-if="currentMemberIndex>=0 && selected_members[currentMemberIndex]"><pre>{{selected_members[currentMemberIndex].ask}}</pre></div>
        </div>
        <hr v-if="current_slide!=null"/>
        <div v-if="current_slide != null && (powerteams.filter(v => v.variant=='grey-slide' ).length>0 || current_slide.selection!=5) && selected_members.length>0">
            <div v-if="currentMemberIndex>=0" class="current-selection">{{$t('controller.current_member')+": "}}<b>{{selected_members[currentMemberIndex].name}}</b></div>
            <div v-if="currentMemberIndex>=0" class="next-previous-selection">
              <b-button v-if="previousMemberIndex>=0"  v-on:click="memberSelectClick(selected_members[previousMemberIndex])" class="width-button curtail-text emphasized-button-text" :variant="selected_members[previousMemberIndex].variant">{{$t('controller.prev_member')+": "+selected_members[previousMemberIndex].name}}</b-button>
              <b-button v-if="nextMemberIndex<selected_members.length" v-on:click="memberSelectClick(selected_members[nextMemberIndex])" class="width-button curtail-text emphasized-button-text next-selected" :variant="selected_members[nextMemberIndex].variant">{{$t('controller.next_member')+": "+selected_members[nextMemberIndex].name}}</b-button>
            </div>
             <div :style="{'grid-gap': '30px','display':'grid','grid-template-rows': '1fr', 'grid-template-columns': 'repeat('+Math.min(Math.ceil(selected_members.length/15),4)+',1fr)'}">
            <div v-for="i in Math.min(Math.ceil(selected_members.length/15),4)" :key="i" class="present-column-header">
                <div></div>
                <div class="present-header-text">{{$t('attendance.status_present')}}</div>
            </div>
          </div>
            <div :style="{'grid-auto-flow': 'column','grid-column-gap': '30px','display':'grid','grid-template-rows': 'repeat('+(selected_members.length/15>4? Math.ceil(selected_members.length/4) : Math.ceil(selected_members.length/Math.ceil(selected_members.length/15)))+',max-content)', 'grid-template-columns': 'repeat('+Math.min(Math.ceil(selected_members.length/15),4)+',1fr)'}">
              <div v-for="member in selected_members" class="padding-div curtail-text" :key="member.name">
                <div class="selection-option-layout">
                  <b-button :id="'memberbutton_'+member.id" :disabled="member.present==0"  v-on:click="memberSelectClick(member)"  class="width-button curtail-text emphasized-button-text member-select-button" :variant="member.variant">
                    {{member.name}}<br/><pre class="member-ask">{{member.ask}}</pre></b-button>
                  <b-button :disabled="member.disable_present==1" v-on:click="changeMemberPresence(member)" class="width-button curtail-text emphasized-button-text center-text" :variant="member.present ==1? 'outline-success': 'outline-danger'"><img class="member-present-image" :src="getImgUrl(member.present ==1?'checkmark-green.png' : 'cross-red.png')" /></b-button>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div slot="modal-footer" class="submit-box-2"> 
      </div>
    </b-modal>

    <b-modal static id="modal-fullscreen2" centered v-model="showAttendeeSelect" >
      <div slot="modal-header" class="w-100 selection-body">
        <div class="selection-header">{{$t('controller.attendee_presentations')}}</div>
        <div >
            <div v-if="(id_string.length>0 && view_ready) && current_slide && current_slide.timer == 0" class="grid-fit-2column auto-margin full-height fit-content">
              <b-img class="clickable image-colour-shift header-stop-timer-button" :src="getImgUrl('icon-Timer-Blue.png')"  v-on:click="stopTimer" />
            </div>
          </div>
        <b-button class="action-button auto-margin" v-on:click="showAttendeeSelect = false;" variant="clear-blue"><div class="close-button"></div><span>{{$t('global.button_close')}}</span></b-button>
      </div>
      <div v-if="current_slide != null && (current_slide.selection==1)" id="attendee-select" :style="{'grid-template-rows': 'repeat('+(attendees.length/15>4? Math.ceil(attendees.length/4) : Math.ceil(attendees.length/Math.ceil(attendees.length/15)))+',max-content)', 'grid-template-columns': 'repeat('+Math.min(Math.ceil(attendees.length/15),4)+',1fr)'}">
        <div v-for="attendee in attendees" class="padding-div" :key="attendee.id">
          <b-button v-on:click="attendeeSelectClick(attendee)" class="width-button  larger-select-button-font" :variant="attendee.variant">
            <span class="normal-text" v-html="attendee.name"></span> <br/>
            <span v-if="attendee.member_name && attendee.member_name.length>0">{{$t('controller.invited_by_member', {member: attendee.member_name})}}</span>
          </b-button>
        </div>
      </div>
      <div v-if="current_slide != null && (current_slide.selection==2  || current_slide.selection==3) && select_attendees.length>0">
          <div class="left-text">
            <b-button class="action-button shuffle-members-button" v-b-popover.hover.right="$t('controller.shuffle_members')" v-on:click="shuffleAttendees" variant="clear-blue"><div class="refresh-button"></div><span>{{$t('global.button_reshuffle')}}</span></b-button>
          </div>
          <div v-if="currentAttendeeIndex>=0" class="current-selection">{{$t('controller.current_member')+": "}}<b v-html="select_attendees[currentAttendeeIndex].name"></b></div>
          <div v-if="currentAttendeeIndex>=0" class="next-previous-selection">
            <b-button v-if="previousAttendeeIndex>=0"  v-on:click="attendeeSelectClick(select_attendees[previousAttendeeIndex])" class="width-button curtail-text emphasized-button-text" :variant="select_attendees[previousAttendeeIndex].variant">{{$t('controller.prev_member')+": "}}<span v-html="select_attendees[previousAttendeeIndex].name"></span></b-button>
            <b-button v-if="nextAttendeeIndex<select_attendees.length" v-on:click="attendeeSelectClick(select_attendees[nextAttendeeIndex])" class="width-button curtail-text emphasized-button-text next-selected" :variant="select_attendees[nextAttendeeIndex].variant">{{$t('controller.next_member')+": "}}<span v-html="select_attendees[nextAttendeeIndex].name"></span></b-button>
          </div>
          <div :style="{'grid-gap': '30px','display':'grid','grid-template-rows': '1fr', 'grid-template-columns': 'repeat('+Math.min(Math.ceil(select_attendees.length/15),4)+',1fr)'}">
            <div v-for="i in Math.min(Math.ceil(select_attendees.length/15),4)" :key="i" class="present-column-header">
                <div></div>
                <div class="present-header-text">{{$t('attendance.status_present')}}</div>
            </div>
          </div>
          <div :style="{'grid-auto-flow': 'column','grid-column-gap': '30px','display':'grid','grid-template-rows': 'repeat('+(select_attendees.length/15>4? Math.ceil(select_attendees.length/4) : Math.ceil(select_attendees.length/Math.ceil(select_attendees.length/15)))+',max-content)', 'grid-template-columns': 'repeat('+Math.min(Math.ceil(select_attendees.length/15),4)+',1fr)'}">
            <div v-for="attendee in select_attendees" class="padding-div curtail-text" :key="attendee.id">
              <div class="selection-option-layout">
                <b-button :disabled="attendee.present==0"  v-on:click="attendeeSelectClick(attendee)"  class="width-button curtail-text emphasized-button-text" :variant="attendee.variant" ><div v-html="attendee.name"></div></b-button>
                <b-button :disabled="attendee.disable_present==1" v-on:click="changeAttendeePresence(attendee)" class="width-button curtail-text emphasized-button-text center-text" :variant="attendee.present ==1? 'outline-success': 'outline-danger'"><img class="member-present-image" :src="getImgUrl(attendee.present ==1?'checkmark-green.png' : 'cross-red.png')" /></b-button>
              </div>
            </div>
          </div>
        </div>
      <div slot="modal-footer" class="submit-box-2">
      </div>
    </b-modal>
    <b-modal static id="modal-fullscreen2" centered v-model="showMemberRoleSelect" >
      <div slot="modal-header" class="w-100 selection-body">
        <div class="selection-header" v-html="current_slide?  current_slide.name && current_slide.name.length>0? current_slide.name : $t('global.no_translation') : ''"></div>
        <div >
            <div v-if="(id_string.length>0 && view_ready) && current_slide && current_slide.timer == 0" class="grid-fit-2column auto-margin full-height fit-content">
              <b-img class="clickable image-colour-shift header-stop-timer-button" :src="getImgUrl('icon-Timer-Blue.png')"  v-on:click="stopTimer" />
            </div>
          </div>
        <b-button class="action-button auto-margin" v-on:click="showMemberRoleSelect = false;" variant="clear-blue"><div class="close-button"></div><span>{{$t('global.button_close')}}</span></b-button>
      </div>
      <div v-if="current_slide != null " id="member-select" :style="{'grid-template-rows': 'repeat('+(roleMembers.length/15>4? Math.ceil(roleMembers.length/4) : Math.ceil(roleMembers.length/Math.ceil(roleMembers.length/15)))+',max-content)', 'grid-template-columns': 'repeat('+Math.min(Math.ceil(roleMembers.length/15),4)+',1fr)'}">
        <div v-for="rm in roleMembers" class="padding-div" :key="rm.id">
          <b-button v-on:click="memberRoleSelectClick(rm)" class="width-button  larger-select-button-font" :variant="rm.variant">
            <span class="normal-text" v-html="rm.name"></span> 
          </b-button>
        </div>
      </div>
      <div slot="modal-footer" class="submit-box-2">
      </div>
    </b-modal>
    <b-modal static id="modal-fullscreen2" centered v-model="showContactSelect" >
      <div slot="modal-header" class="w-100 selection-body">
        <div class="selection-header" v-html="current_slide?  current_slide.name && current_slide.name.length>0? current_slide.name : $t('global.no_translation') : ''"></div>
        <div >
            <div v-if="(id_string.length>0 && view_ready) && current_slide && current_slide.timer == 0" class="grid-fit-2column auto-margin full-height fit-content">
              <b-img class="clickable image-colour-shift header-stop-timer-button" :src="getImgUrl('icon-Timer-Blue.png')"  v-on:click="stopTimer" />
            </div>
          </div>
        <b-button class="action-button auto-margin" v-on:click="showContactSelect = false;" variant="clear-blue"><div class="close-button"></div><span>{{$t('global.button_close')}}</span></b-button>
      </div>
      <div v-if="current_slide != null && (current_slide.selection==1)" :style="{'display': 'grid', 'grid-template-rows': 'repeat('+(contacts.length/15>4? Math.ceil(contacts.length/4) : Math.ceil(contacts.length/Math.ceil(contacts.length/15)))+',max-content)', 'grid-template-columns': 'repeat('+Math.min(Math.ceil(contacts.length/15),4)+',1fr)'}">
        <div v-for="contact in contacts" class="padding-div curtail-text"  :key="contact.id">
          <b-button v-on:click="contactSelectClick(contact)" class="width-button curtail-text  larger-select-button-font" :variant="contact.variant">
            <span class="normal-text" v-html="contact.name"></span> 
          </b-button>
        </div>
      </div>
      <div v-if="current_slide != null && (current_slide.selection==2  || current_slide.selection==3 ) && select_contacts.length>0">
            <div v-if="currentContactIndex<0 && current_slide.selection==3" class="reshuffle-button-wrapper">
               <b-button  class="action-button" v-b-popover.hover.right="$t('controller.shuffle_members')" v-on:click="shuffleContacts" variant="clear-blue"><div class="refresh-button"></div><span>{{$t('global.button_reshuffle')}}</span></b-button>
            </div>
            
            <div v-if="currentContactIndex>=0" class="current-selection">{{$t('controller.current_member')+": "}}<b>{{select_contacts[currentContactIndex].name}}</b></div>
            <div v-if="currentContactIndex>=0" class="next-previous-selection">
              <b-button v-if="previousContactIndex>=0"  v-on:click="contactSelectClick(select_contacts[previousContactIndex])" class="width-button curtail-text emphasized-button-text" :variant="select_contacts[previousContactIndex].variant">{{$t('controller.prev_member')+": "+select_contacts[previousContactIndex].name}}</b-button>
              <b-button v-if="nextContactIndex<select_contacts.length" v-on:click="contactSelectClick(select_contacts[nextContactIndex])" class="width-button curtail-text emphasized-button-text next-selected" :variant="select_contacts[nextContactIndex].variant">{{$t('controller.next_member')+": "+select_contacts[nextContactIndex].name}}</b-button>
            </div>
             <div :style="{'grid-gap': '30px','display':'grid','grid-template-rows': '1fr', 'grid-template-columns': 'repeat('+Math.min(Math.ceil(select_contacts.length/15),4)+',1fr)'}">
              <div v-for="i in Math.min(Math.ceil(select_contacts.length/15),4)" :key="i" class="present-column-header">
                  <div></div>
                  <div class="present-header-text">{{$t('attendance.status_present')}}</div>
              </div>
            </div>
            <div :style="{'grid-auto-flow': 'column','grid-column-gap': '30px','display':'grid','grid-template-rows': 'repeat('+(select_contacts.length/15>4? Math.ceil(select_contacts.length/4) : Math.ceil(select_contacts.length/Math.ceil(select_contacts.length/15)))+',max-content)', 'grid-template-columns': 'repeat('+Math.min(Math.ceil(select_contacts.length/15),4)+',1fr)'}">
              <div v-for="contact in select_contacts" class="padding-div curtail-text" :key="contact.id">
                <div class="selection-option-layout">
                  <b-button :id="'contactbutton_'+contact.id" :disabled="contact.present==0"  v-on:click="contactSelectClick(contact)"  class="width-button curtail-text emphasized-button-text member-select-button" :variant="contact.variant" ><div v-html="contact.name"></div></b-button>
                  <b-button :disabled="contact.disable_present==1" v-on:click="changeContactPresence(contact)" class="width-button curtail-text emphasized-button-text center-text" :variant="contact.present ==1? 'outline-success': 'outline-danger'"><img class="member-present-image" :src="getImgUrl(contact.present ==1?'checkmark-green.png' : 'cross-red.png')" /></b-button>
                </div>
              </div>
            </div>
        </div>
      <div slot="modal-footer" class="submit-box-2">
      </div>
    </b-modal>
    <b-modal static id="modal-fullscreen2" centered v-model="showSpeakerSelect" >
      <div slot="modal-header" class="w-100 selection-body">
        <div class="selection-header" v-html="current_slide?  current_slide.name && current_slide.name.length>0? current_slide.name : $t('global.no_translation') : ''"></div>
        <div></div>
        <b-button class="action-button auto-margin" v-on:click="showSpeakerSelect= false;" variant="clear-blue"><div class="close-button"></div><span>{{$t('global.button_close')}}</span></b-button>
      </div>
      <div v-if="current_slide != null " class="speakers-wrapper">
        <div class="topics-wrapper">
            <div v-for="speaker in speakers" class="padding-div" :key="speaker.id">
            <b-button v-on:click="SpeakerSelectClick(speaker)" class="width-button  larger-select-button-font" :variant="speaker.variant">
              <span :style="{'font-weight': (current_slide.speaker && current_slide.speaker.id == speaker.id? 'bold' : 'normal')}">{{ (speaker.powerteam_id>0? $t("schedule.power_team")+": " : "")+speaker.name }}</span> 
            </b-button>
          </div>
        </div>
        <div v-if="current_slide.speaker" class="left-text">
            <div class="speaker-timer-controls">
              <div :style="{'margin':'auto','color': current_slide.speaker.time>=60? 'rgb(0, 174, 239' : 'red'}">{{getTimeString(current_slide.speaker.time)}}</div>
              <b-img id="speaker-timer-image" v-if="(id_string.length>0 && view_ready) && current_slide && current_slide.timer == 0" class="clickable image-colour-shift" :src="getImgUrl('icon-Timer-Blue.png')"  v-on:click="stopTimer" />
              <b-button v-if="(id_string.length>0 && view_ready) && current_slide && current_slide.timer == 0" v-on:click="showTimer" class="width-button start-presentation" variant="outline-success">{{$t('controller.show_timer')}}</b-button>
            </div>
            <div class="padding-div files-padding" v-if="current_slide.speaker.files && current_slide.speaker.files.length>0">
              <div class="file-controls speaker-files" v-for="file in current_slide.speaker.files" :key="file.number">
                <div :style="{'grid-column': '1/3', 'font-weight': (file == currentFile? 'bold' : 'normal')}" class="curtail-text">{{file.type == 2? (file.description.length==0? file.url : file.description) : file.description}}</div>
                <b-button :disabled="file == currentFile" v-on:click.stop="startFile(file, current_slide.speaker,true)" class="width-button start-presentation" variant="outline-success">{{getStartButtonText(file)}}</b-button>
                <b-button v-if="file.type==1"  v-on:click.stop="downloadFile=file;showDownload=true;" class="width-button backup-download" variant="outline-success">{{$t('controller.backup_download')}}</b-button>
                <div class="file-control-buttons">
                  <b-button v-if="pslide>0 && file.type==1 && pdfHash == file.hash" :disabled="pslide==1" v-on:click.stop="goToPresentationSlide(--pslide)" class="arrow-button" variant="outline-success" ><img src="../assets/White-Prev.png" /></b-button>
                  <div></div>
                  <b-button v-if="pslide>0 && file.type==1 && pdfHash == file.hash" :disabled="pslide==maxPresentationSlide" v-on:click.stop="goToPresentationSlide(++pslide)" class="arrow-button" variant="outline-success" ><img src="../assets/White-Next.png" /></b-button>
                </div>
                <div class="first-column">
                  <b-button v-on:click="resendConfirmation(current_slide.speaker.id, file)" class="width-button start-presentation" variant="outline-success">{{$t('controller.resend_speaker')}}</b-button>
                </div>
                <div class="first-column" v-if="current_slide.speaker.powerteam_id>0">
                  <b-button  v-on:click="sendFilesToPowerteam(current_slide.speaker.id, current_slide.speaker.powerteam_id, file)" class="width-button start-presentation" variant="outline-success">{{$t('controller.send_powerteam_files')}}</b-button>
                </div>
              </div>
              
            </div>
            
            <div class="speaker-bio-script">
              <pre class="script-text" v-html="current_slide.speaker.bio" :style="{'font-size': bioFontSize+'px'}"></pre>
            </div>
        </div>
      </div>
      <div slot="modal-footer" class="submit-box-2">
      </div>
    </b-modal>
    <b-modal static id="modal-fullscreen2" centered v-model="showTopicSelect" :title="$t('controller.select_powerteam')">
      <div slot="modal-header" class="w-100 selection-body">
        <div class="selection-header" v-html="current_slide?  current_slide.name && current_slide.name.length>0? current_slide.name : $t('global.no_translation') : ''"></div>
        <div></div>
        <b-button class="action-button auto-margin" v-on:click="showTopicSelect= false;" variant="clear-blue"><div class="close-button"></div><span>{{$t('global.button_close')}}</span></b-button>
      </div>
      <div v-if="current_slide != null " class="speakers-wrapper">
        <div class="topics-wrapper">
            <div v-for="topic in topics" class="padding-div" :key="topic.id">
            <b-button v-on:click="TopicSelectClick(topic)" class="width-button  larger-select-button-font" :variant="topic.variant">
              <span class="normal-text" v-html="topic.title"></span> 
            </b-button>
          </div>
        </div>
        <div v-if="current_slide.topic" class="left-text">
            <div class="padding-div files-padding" v-if="current_slide.topic.files && current_slide.topic.files.length>0">
              <div class="file-controls speaker-files" v-for="file in current_slide.topic.files" :key="file.number">
                <div class="curtail-text column-span2">{{file.type == 2? (file.description.length==0? file.url : file.description) : file.file_path}}</div>
                <b-button :disabled="file == currentFile" v-on:click.stop="startFile(file, current_slide.topic,true)" class="width-button start-presentation" variant="outline-success">{{getStartButtonText(file)}}</b-button>
                <b-button v-if="file.type==1"  v-on:click.stop="downloadFile=file;showDownload=true;" class="width-button backup-download" variant="outline-success">{{$t('controller.backup_download')}}</b-button>
                <div class="file-control-buttons">
                  <b-button v-if="pslide>0 && file.type==1 && pdfHash == file.hash" :disabled="pslide==1" v-on:click.stop="goToPresentationSlide(--pslide)" class="arrow-button" variant="outline-success" ><img src="../assets/White-Prev.png" /></b-button>
                  <div></div>
                  <b-button v-if="pslide>0 && file.type==1 && pdfHash == file.hash" :disabled="pslide==maxPresentationSlide" v-on:click.stop="goToPresentationSlide(++pslide)" class="arrow-button" variant="outline-success" ><img src="../assets/White-Next.png" /></b-button>
                </div>
              </div>
            </div>
            <div class="speaker-bio-script">
              <pre class="script-text" v-html="current_slide.topic.description" :style="{'font-size': bioFontSize+'px'}"></pre>
            </div>
        </div>
      </div>
      <div slot="modal-footer" class="submit-box-2">
      </div>
    </b-modal>
    <b-modal static id="modal-fullscreen2" centered v-model="showRecognitionSelect" >
      <div slot="modal-header" class="w-100 selection-body">
        <div class="selection-header" v-html="current_slide?  current_slide.name && current_slide.name.length>0? current_slide.name : $t('global.no_translation') : ''"></div>
        
        <div >
            <div v-if="(id_string.length>0 && view_ready) && current_slide && current_slide.timer == 0" class="grid-fit-2column auto-margin full-height fit-content">
              <b-img class="clickable image-colour-shift header-stop-timer-button" :src="getImgUrl('icon-Timer-Blue.png')"  v-on:click="stopTimer" />
            </div>
          </div>
        <b-button class="action-button auto-margin" v-on:click="showRecognitionSelect = false;" variant="clear-blue"><div class="close-button"></div><span>{{$t('global.button_close')}}</span></b-button>
      </div>
      
      <div class="recognition-title" v-if="current_slide != null && (current_slide.member_id==1  || current_slide.member_id==2)">{{$t('role.network_leaders')}}</div>
      <div v-if="current_slide != null && (current_slide.member_id==1  || current_slide.member_id==2)"  id="member-select" :style="{'grid-template-rows': 'repeat('+Math.ceil(Math.max(15,networkLeaders.length/4))+',max-content)', 'grid-template-columns': 'repeat('+Math.min(Math.ceil(networkLeaders.length/15),4)+',1fr)'}">
        <div v-for="rm in networkLeaders" class="padding-div" :key="rm.id">
          <b-button v-on:click="memberRoleSelectClick(rm)" class="width-button larger-select-button-font" :variant="rm.variant">
            <span class="normal-text" v-html="rm.name"></span> 
          </b-button>
        </div>
      </div>
      <br v-if="current_slide != null && current_slide.member_id==1" />
      <div class="recognition-title" v-if="current_slide != null && (current_slide.member_id==1  || current_slide.member_id==3)">{{$t('role.renewing_members')}}</div>
      <div  id="member-select" v-if="current_slide != null && (current_slide.member_id==1  || current_slide.member_id==3)" :style="{'grid-template-rows': 'repeat('+Math.ceil(Math.max(15,renewingMembers.length/4))+',max-content)', 'grid-template-columns': 'repeat('+Math.min(Math.ceil(renewingMembers.length/15),4)+',1fr)'}">
        <div v-for="rm in renewingMembers" class="padding-div" :key="rm.id">
          <b-button v-on:click="memberRoleSelectClick(rm)" class="width-button larger-select-button-font" :variant="rm.variant">
            <span class="normal-text" v-html="rm.name"></span> 
          </b-button>
        </div>
      </div>
      <div slot="modal-footer" class="submit-box-2">
      </div>
    </b-modal>
    <b-modal static id="modal-fullscreen2" centered v-model="showGoalSelect">
      <div slot="modal-header" class="w-100 selection-body">
        <div class="selection-header">{{current_slide? current_slide.name && current_slide.name.length>0? current_slide.name : $t('global.no_translation')  : ""}}</div>
        <div >
            <div v-if="(id_string.length>0 && view_ready) && current_slide && current_slide.timer == 0" class="grid-fit-2column auto-margin full-height fit-content">
              <b-img class="clickable image-colour-shift header-stop-timer-button" :src="getImgUrl('icon-Timer-Blue.png')"  v-on:click="stopTimer" />
            </div>
          </div>
        <b-button class="action-button auto-margin" v-on:click="showGoalSelect = false;" variant="clear-blue"><div class="close-button"></div><span>{{$t('global.button_close')}}</span></b-button>
      </div>
      <div :style="{'grid-template-rows': 'repeat('+Math.ceil(Math.max(15,goals.length/4))+',max-content)', 'grid-template-columns': 'repeat('+Math.min(Math.ceil(goals.length/15),4)+',1fr)'}">
        <div v-for="goal in goals" class="padding-div" :key="goal.id">
          <b-button v-on:click="goalSelectClick(goal)" class="width-button larger-select-button-font" :variant="goal.variant">
            {{goal.name}}
          </b-button>
        </div>
      </div>
      <div slot="modal-footer" class="submit-box-2">
      </div>
    </b-modal>
    <b-modal static id="modal-fullscreen2" centered v-model="showQuoteSelect" :title="$t('controller.select_quote')">
      <div slot="modal-header" class="w-100 selection-body">
        <div class="selection-header">{{current_slide? current_slide.name && current_slide.name.length>0? current_slide.name : $t('global.no_translation')  : ""}}</div>
        <div >
            <div v-if="(id_string.length>0 && view_ready) && current_slide && current_slide.timer == 0" class="grid-fit-2column auto-margin full-height fit-content">
              <b-img class="clickable image-colour-shift header-stop-timer-button" :src="getImgUrl('icon-Timer-Blue.png')"  v-on:click="stopTimer" />
            </div>
          </div>
        <b-button class="action-button auto-margin" v-on:click="showQuoteSelect = false;" variant="clear-blue"><div class="close-button"></div><span>{{$t('global.button_close')}}</span></b-button>
      </div>
      <div :style="{'grid-template-rows': 'repeat('+Math.ceil(Math.max(15,quotes.length/4))+',max-content)', 'grid-template-columns': 'repeat('+Math.min(Math.ceil(quotes.length/15),4)+',1fr)'}">
        <div v-for="quote in quotes" class="padding-div" :key="quote.id">
          <b-button v-on:click="quoteSelectClick(quote)" class="width-button larger-select-button-font" :variant="quote.variant">
            <div ><b>{{quote.source}}</b></div>
            <div>{{$t('controller.submitted_by') +" "+ quote.name}}</div>
          </b-button>
        </div>
      </div>
      <div slot="modal-footer" class="submit-box-2">
      </div>
    </b-modal>
    <b-modal static id="modal-fullscreen2" centered v-model="showPerformanceSelect" :title="$t('controller.select_performance')">
      <div slot="modal-header" class="w-100 selection-body">
        <div class="selection-header">{{current_slide? current_slide.name && current_slide.name.length>0? current_slide.name : $t('global.no_translation')  : ""}}</div>
        <div >
            <div v-if="(id_string.length>0 && view_ready) && current_slide && current_slide.timer == 0" class="grid-fit-2column auto-margin full-height fit-content">
              <b-img class="clickable image-colour-shift header-stop-timer-button" :src="getImgUrl('icon-Timer-Blue.png')"  v-on:click="stopTimer" />
            </div>
          </div>
        <b-button class="action-button auto-margin" v-on:click="showPerformanceSelect = false;" variant="clear-blue"><div class="close-button"></div><span>{{$t('global.button_close')}}</span></b-button>
      </div>
      <div  id="attendance-buttons" v-if="tracking_periods!=0">
          <b-button v-on:click="getPreviousPeriods" class="action-button periods-button" variant="clear-blue">
            <div class="back-button"></div><span>{{$t('global.button_back')}}</span>
          </b-button>
          <div id="period-day">{{period_day.length>0 ? $d(new Date(period_day.split("-").join("/") + " 00:01"), 'short') : ''}}</div>
          <b-button v-show="period_day != day" v-on:click="getNextPeriods" class="action-button periods-button" variant="clear-blue">
            <div class="next-button"></div><span>{{$t('global.button_next')}}</span>
          </b-button> 
        </div>
      <div :style="{'grid-template-rows': 'repeat('+Math.ceil(Math.max(15,performancePeriods.length/4))+',max-content)', 'grid-template-columns': 'repeat('+Math.min(Math.ceil(performancePeriods.length/15),4)+',1fr)'}">
        <div v-if="performancePeriods.length == 0">{{$t('controller.no_performance_data')}}</div>
        <div v-for="period in performancePeriods" class="padding-div" :key="period.id">
          <b-button v-on:click="periodSelectClick(period)" class="width-button larger-select-button-font" :variant="period.variant">
            {{$t('general.tracking_option_'+period.period_id)}}
          </b-button>
        </div>
      </div>
      <div slot="modal-footer" class="submit-box-2">
      </div>
    </b-modal>
    <b-modal static id="modal-fullscreen2" centered v-model="showEventSelect" :title="$t('controller.select_event')">
      <div slot="modal-header" class="w-100 selection-body">
        <div class="selection-header" v-html="current_slide?  current_slide.name && current_slide.name.length>0? current_slide.name : $t('global.no_translation') : ''"></div>
        <div >
            <div v-if="(id_string.length>0 && view_ready) && current_slide && current_slide.timer == 0" class="grid-fit-2column auto-margin full-height fit-content">
              <b-img class="clickable image-colour-shift header-stop-timer-button" :src="getImgUrl('icon-Timer-Blue.png')"  v-on:click="stopTimer" />
            </div>
          </div>
        <b-button class="action-button auto-margin" v-on:click="showEventSelect = false;" variant="clear-blue"><div class="close-button"></div><span>{{$t('global.button_close')}}</span></b-button>
      </div>
      <div v-if="events.chapter_events.length+events.region_events.length == 0">{{$t('controller.no_events')}}</div>
        <div v-if="events.chapter_events.length>0" class="events-title">{{$t('controller.chapter_events')}}</div>
      <div :style="{'grid-template-rows': 'repeat('+Math.ceil(Math.max(15,events.chapter_events.length/4))+',max-content)', 'grid-template-columns': 'repeat('+Math.min(Math.ceil(events.chapter_events.length/15),4)+',1fr)'}">
        <div v-for="event in events.chapter_events" class="padding-div" :key="event.id">
          <b-button v-on:click="eventSelectClick(event)" class="width-button emphasized-button-text" :variant="event.variant">
            {{event.title}}
          </b-button>
        </div>
      </div>
      <br/>
      <div :style="{'grid-template-rows': 'repeat('+Math.ceil(Math.max(15,events.region_events.length/4))+',max-content)', 'grid-template-columns': 'repeat('+Math.min(Math.ceil(events.region_events.length/15),4)+',1fr)'}">
        <div v-if="events.region_events.length>0" class="events-title">{{$t('controller.region_events')}}</div>
        <div v-for="event in events.region_events" class="padding-div" :key="event.id">
          <b-button v-on:click="eventSelectClick(event)" class="width-button emphasized-button-text" :variant="event.variant">
            {{event.title}}
          </b-button>
        </div>
      </div>
      <div slot="modal-footer" class="submit-box-2">
      </div>
    </b-modal>
    <b-modal static id="modal-fullscreen2" centered v-model="showBioModal">
      <div slot="modal-header" class="w-100 bio-selection-body">
        <div class="selection-header">{{$t('controller.speaker_bio')}}: {{current_speaker.name}}</div>
        <div >
            <div v-if="(id_string.length>0 && view_ready) && current_slide && current_slide.timer == 0" class="grid-fit-2column auto-margin full-height fit-content">
              <b-img class="clickable image-colour-shift header-stop-timer-button" :src="getImgUrl('icon-Timer-Blue.png')"  v-on:click="stopTimer" />
            </div>
          </div>
        <b-button class="action-button auto-margin" v-on:click="showBioModal= false;" variant="clear-blue"><div class="close-button"></div><span>{{$t('global.button_close')}}</span></b-button>
      </div>
      <div id="bio-script">
        <pre class="script-text" v-html="current_speaker.bio" :style="{'font-size': bioFontSize+'px'}"></pre>
      </div>
      <div slot="modal-footer" class="submit-box-2">
        <div id="biofont-options">
          <b-button id="minus-button" class="size-button" v-on:click="decBioFont"></b-button>
          <img class="image-colour-shift" src="../assets/fontsize-T.png" />
          <b-button id="plus-button" class="size-button" v-on:click="incBioFont"></b-button>
        </div>
      </div>
    </b-modal>
    <b-modal centered v-model="showFullScreenConfirm">
      <div >
        {{$t('controller.confirm_fullscreen')}}
      </div>
      <div slot="modal-footer" class="fit-content modal-footer-style">
        <b-button class="action-button" v-on:click="showFullScreenConfirm = false;" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_no')}}</span></b-button>
          <b-button class="action-button" v-on:click="executeFullscreenAction" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_yes')}}</span></b-button>
          
        </div>
    </b-modal>
    <b-modal centered v-model="showOptions" :title="$t('controller.options_text')">
      <div class="center-text">
        <div class="option-title">{{$t('controller.text_size')}}</div>
        <div class="fit-width font-options" >
          <b-button v-for="n in 5" :key="n" class="font-button" v-on:click="setFont(n)" :variant="(sectionFontSize-6)/4==n? 'light-blue' : 'light-blue-outline'">{{n}}</b-button>
        </div>
        <div v-if="controlPermissions.access" class="option-title">{{$t('controller.timer_volume')}}</div>
        <div v-if="controlPermissions.access" class="fit-width volume-options">
          <b-img class="clickable volume-button"  :class="{'volume-active': globalVolume==0}" :src="getImgUrl('volume-mute.png')" v-on:click="setVolume(0)" />
          <b-img class="clickable volume-button"  :class="{'volume-active': globalVolume==.33}" :src="getImgUrl('volume-low.png')" v-on:click="setVolume(.33)" />
          <b-img class="clickable volume-button"  :class="{'volume-active': globalVolume==.66}"  :src="getImgUrl('volume-medium.png')" v-on:click="setVolume(.66)" />
          <b-img class="clickable volume-button"  :class="{'volume-active': globalVolume==1}" :src="getImgUrl('volume-high.png')" v-on:click="setVolume(1)" />
        </div>
      </div>
      <div slot="modal-footer" class="fit-content modal-footer-style">
        <b-button class="action-button" v-on:click="showOptions = false;" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
      </div>
    </b-modal>
    <b-modal id="modal-fullscreen3" centered v-model="showAttendance" :title="$t('controller.attendance_title')">
      <div slot="modal-header" class="w-100 selection-body">
          <div id="attendance-title">{{$t('controller.attendance_title')}}</div>
          <div></div>
          <b-button class="action-button auto-margin" v-on:click="showAttendance= false;" variant="clear-blue"><div class="close-button"></div><span>{{$t('global.button_close')}}</span></b-button>
      </div>
      <AttendanceModal :embedded="true"> </AttendanceModal>
      <div slot="modal-footer" class="fit-content modal-footer-style">
      </div>
    </b-modal>
    <b-modal  centered v-model="showDownload" :title="$t('controller.download_title')">
      <div class="center-text">
        {{$t('controller.download_message')}}<br/>
         {{$t('controller.download_message2')}}
      </div>
      <div slot="modal-footer" class="fit-content modal-footer-style">
        <b-button class="action-button" v-on:click="showDownload = false;downloadFile='';" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_no')}}</span></b-button>
        <a v-if="downloadFile && downloadFile.file_path && downloadFile.file_path.length>0" :href="downloadFile.file_path.includes('/')? downloadFile.file_path : ('/uploads/'+downloadFile.file_path)" :download="downloadFile.description">
          <b-button class="action-button" v-on:click="showDownload = false;downloadFile='';" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_yes')}}</span></b-button>
        </a>
      </div>
    </b-modal>
    <b-toast id="discToastCon" variant="danger" solid :title="$t('controller.disconnect_text')">
      {{$t('controller.disconnect_description')}}
    </b-toast>
  </div>
</template>
<style scoped lang="scss">
#powerteam-selection{
  margin-top: 10px;
  grid-gap: 10px;
  display:grid;
}
#controller-top-bar{
  grid-template-columns: max-content max-content auto max-content max-content;
}
.period-day{
  grid-column:2/3; font-size: 24px; text-align:center;
}
.periods-button{
  margin:auto;margin-left:0px;
}
#speaker-timer-image{
  height:60px;padding: 5px;
}
.speaker-files{
  margin-bottom: 20px;
}
.speaker-bio-script{
  padding:30px;
}
.recognition-title{
  font-size:30px;
}
.speaker-timer-controls{
  display:grid; grid-template-columns: repeat(3,auto);margin:20px;
}
.topics-wrapper{
  display:grid;grid-template-columns: 1fr;grid-auto-rows: max-content;
}
.events-title{
  font-size:18px;
}
.bio-selection-body{
  display: grid;grid-template-columns: minmax(150px, 90%) max-content max-content;
}
#bio-script{
  height:100%; overflow: auto;text-align: left;
}
#biofont-options{
  display:grid; grid-template-columns:auto auto auto; width: 200px; margin: auto;
}
.font-options{
  display:grid; grid-template-columns: repeat(5, max-content);column-gap: 20px;margin: auto;
}
#attendance-title{
  font-size: 1.25rem;font-weight: 500;margin: auto;
}
.volume-options{
  display:grid; grid-template-columns: repeat(4, max-content);column-gap: 20px; margin: auto;
}
.modal-footer-style{
  display:grid; grid-template-columns:auto auto;margin: auto;grid-gap:30px;
}
.speakers-wrapper{
  display:grid; overflow:auto;grid-template-columns: 1fr 1fr;height:100%;
}
.reshuffle-button-wrapper{
  display:grid; grid-template-columns: repeat(3,max-content);
}
.shuffle-members-button{
  margin:10px;
}
.member-ask{
  font-size: 12px;
}
.larger-select-button-font{
  font-size:16px;
}
.selection-body{
  display: grid;grid-template-columns: minmax(150px,max-content) minmax(max-content, 90%)  max-content;grid-gap:20px;
}
.select-contact-button{
  max-width:600px;
}
.member-present-image{
  max-height:20px;
}
.member-select-button{
  line-height:16px;
}
.member-city{
  font-weight:normal; font-size:12px;
}
.normal-text{
  font-weight:normal;
}
.selection-option-layout{
  display: grid; grid-template-columns: auto max-content;grid-column: 2/3; grid-gap: 10px;
}
.present-header-text{
  font-size: 12px;color: #777;width: 48px;
}
.next-selected{
  grid-column: 2/3;
}
.selected-member-text{
  font-weight:normal;color:#333;
}
.next-previous-selection{
  display: grid; grid-template-columns: 1fr 1fr; grid-gap: 10px 50px;margin-bottom:30px;
}
.current-selection{
  margin: 30px auto;font-size: 26px;
}
.present-column-header{
  display: grid; grid-template-columns: auto max-content;    padding: 0 3px;
}
#member-sort-options{
  display:grid; grid-template-columns: repeat(3,max-content);grid-gap: 10px;
}
#letter-wrapper{
  text-align: left;padding: 10px;
}
.letter-button{
  font-size:16px;margin:2px;padding: 7px 13px 7px 13px !important;height:35px;font-weight:bold;
}
.header-stop-timer-button{
  height:60px;margin: auto;padding: 5px;
}
#member-type-wrapper{
  column-gap: 10px;
}
@media (max-width: 767px){
  #member-type-wrapper{
    grid-row: 2;
    grid-column: 1 / 4;
    margin: auto;
    margin-bottom: 20px;
  }
}

.header-member-type{
  margin: 4px 15px;padding: 0px 10px;width: 120px;margin: auto;height: 60px;line-height: 60px;
}
.member-select-header{
  display: grid;grid-template-columns: minmax(150px,max-content) max-content minmax(max-content, 90%) max-content;grid-gap:20px;
}
.section-no-autoplay{
  color:#a60d0d;
}
.file-control-buttons{
  display:grid; grid-template-columns: 30% 40% 30%;
}
.backup-download{
  border-width:2px;font-size: 9px;word-wrap: break-word;
}
.start-presentation{
  border-width:2px;font-weight:bold;
}
.files-padding{
  padding-left: 5%;padding-right:5%;
}
.section-topic{
  color:#aaa;font-size:14px;
}
.section-speakers-wrapepr{
  display: flex; flex-direction: row;flex-wrap: wrap;grid-column-gap: 10px;
}
.section-force-time{
  font-size: 12px;
}
.section-auto-start{
  height: 20px;margin: auto 5px;
}

.section-preparers-wrapper{
  display:flex; flex-direction: row;flex-wrap: wrap;grid-column-gap: 10px;
}
.section-loop{
  height: 16px;margin: 3px 5px;
}
.section-info{
  display: grid;grid-template-columns:auto;grid-gap: 2px;
}
.section-start-time{
  font-weight:400;margin:8px 5px; color: #aaa;width: 35px;text-align:right;
}
.inner-section-wrapper{
  display: grid; grid-template-columns:max-content auto;
}
#sections-wrapper{
  padding:5px;padding-right: 20px;
}
.section-div{
  padding: 2px 0px 2px 0px;display: grid;
}
#start-meeting-box{
  font-size: 20px;height: calc(100% - 125px);
}
#start-meeting-wrapper{
  display:grid;margin-top: 5%;height: 200px;color:black;font-size:20px;
}
.emphasized-button-text{
  font-size:16px;font-weight:bold;
}
#accept-countdown{
  font-size:12px;
}
#multiple-controllers{
  color:rgb(0, 174, 239);font-weight: bold;font-size:12px;margin: 0 10px;text-align:left;
}
#icon-live{
  height:30px;margin: auto 10px;
}
#control-bar{
  display: grid; grid-template-columns: max-content auto;width:100%; border-bottom:1px solid rgb(0, 174, 239); background-color: #fafafa;
}
#settings-wrapper{
  display:grid; grid-template-columns:repeat(2,1fr);margin-left:20px;
}
#guide-button{
  margin: auto;width: 40px !important; height: 40px; grid-column:2;
}
#stop-timer-button{
  height:46px;margin: auto;padding: 5px; 
}
#settings-image{
  height:25px;
}
#other-views-wrapper{
  display:grid; grid-template-columns: repeat(3,1fr);margin:auto; column-gap:30px;
}
#time-difference-wrapper{
  display:grid;grid-template-rows: auto min-content auto;
}
#user-name{
  color:#000;font-size:14px;padding-left: 10px;white-space: nowrap;line-height: 20px;
}
#header-names{
  display:grid;grid-template-rows: auto auto;text-align: left;
}
.control-banner{
  display: grid; grid-template-columns: auto max-content;
  grid-column: 2/3;
}
.control-banner > div{
      margin: auto 10px;
      text-align: left;
}
.fade-enter-active, .fade-leave-active {
  transition: all .5s;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(-30px);
}

#attendance-buttons{
  display:grid;grid-template-columns: max-content auto max-content;width:100%; margin: 20px auto;
}
.option-title{
  margin:20px;
  font-size:24px;
}
.volume-button{
  height:40px;padding: 5px;border-radius: 50px;
  filter: $VUE_APP_COLOUR_MAIN_FILTER;
  background-color: transparent !important;

}
.volume-active{
  border: 1px solid $VUE_APP_COLOUR_MAIN;
  padding: 6px;
}
.font-button{
      border-radius: 50px;
    width: 40px;
}
.language-flag{
  margin: auto 10px;padding:0px;
}
.selection-header{
  margin-right: auto;max-width: 400px;font-size: 20px;border: $VUE_APP_BUTTON_BORDER solid 2px;border-radius:5px;padding: 13px;font-weight: bold;width:100%;text-align: left;
}
.file-controls{
    grid-template-columns: auto minmax(auto,80px);
    display: grid;
    grid-gap: 10px;
    border: solid 2px #28a745;
    border-radius: .25rem;
    padding:5%;
}
.file-time{
  text-align:center;grid-column: 2/3;
  margin: auto;
}
.arrow-button{
  text-align: center;
    padding-left: 0;
    padding-right: 0;
    border-width: 2px;
    text-align: center;
}
.arrow-button > img{
  filter: invert(52%) sepia(30%) saturate(1074%) hue-rotate(82deg) brightness(94%) contrast(91%);
}
.arrow-button:hover >img{
  filter: none;
}
.submit-button-atten {
    width: 150px;
    margin: auto;
    height: 40px;
    font-weight: bold;
  }
.script-box{
  padding:20px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
}
.script-box::-webkit-scrollbar {
    width: 12px;
}

.script-box::-webkit-scrollbar-track {
    background-color: white;
}

.script-box::-webkit-scrollbar-thumb {
    background-color: #cccccc;
}

#inner-buttonScroll::-webkit-scrollbar {
    width: 12px;
}

#inner-buttonScroll::-webkit-scrollbar-track {
    background-color: white;
}

#inner-buttonScroll::-webkit-scrollbar-thumb {
    background-color: #cccccc;
}
.speaker-name-div{
    color: #333333;
    background-color: rgba(0,0,0,0);
    border-color: #aaaaaa;
    border-style: dotted;
    border-width: 2px;
    width: fit-content;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    border-radius: 5px;
    padding: 2px;
    color: #aaaaaa;
}

#controller-header{
  display: grid; padding: 3px;border-bottom:1px solid rgb(0, 174, 239);height:50px;
}
.scriptBanner{
  background-color: #eee;
  color: $VUE_APP_COLOUR_MAIN;
  height: 40px; 
  display: grid; 
  grid-template-columns:auto 40px 40px;
}
.scriptBanner > span{
  text-align: left;padding: 5px;font-size: 22px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 15px;
}

.scriptBanner > img{
  width: 30px;
  margin: auto;
}
  #member-select {
    display: grid;
    overflow: auto;
    grid-auto-flow:column;
  }
  #attendee-select {
    display: grid;
    overflow: auto;
    grid-auto-flow: column;
  }
  
  #below-text {
    display: grid;
    font-size:15px;padding: 0px 10px;
  }
  #copywrite {
    float: right;
    margin-right: 10px;
    color: #333;
  }
  .navbar-light .navbar-toggler {
    color: rgba(0,0,0,.5);
    border: none;
    margin:2%;
  }
  .navbar-light .navbar-toggler-icon {
    background-image: url("../assets/MenuBars.png");
  }
  .size-button {
    width: 30px;
    height: 30px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
    margin: 0px 15px;
    background-color: #fff;
    border: none;
  }
  #plus-button {
    background-image: url("../assets/ZoomIn.png")
  }
  
  #minus-button {
    background-image: url("../assets/ZoomOut.png")
  }
#plus-button:hover {
    border: none;
      background-color: rgba(0,0,0,0);
  }
    #minus-button:hover {
      border: none;
      background-color: rgba(0,0,0,0);
    }
    .attendance-button{
      background-image: url("../assets/home-AttendanceConsole2.png");
      background-color:transparent !important;
      filter: $VUE_APP_COLOUR_MAIN_FILTER;
      margin: auto;width: 40px !important; height: 40px; grid-column:3;
    }
  .agenda-controller-button {
    background-image: url("../assets/icon-Agenda-Blue.png");
    background-color:transparent !important;
      filter: $VUE_APP_COLOUR_MAIN_FILTER;
  }
    .agenda-controller-button-on {
      background-image: url("../assets/icon-Agenda-Blue.png");
      background-color:transparent !important;
      filter: $VUE_APP_COLOUR_MAIN_FILTER;
    }
  
  .script-controller-button {
    background-image: url("../assets/icon-Script-Blue.png");
    background-color:transparent !important;
      filter: $VUE_APP_COLOUR_MAIN_FILTER;
  }
    .script-controller-button-on {
      background-image: url("../assets/icon-Script-Blue.png");
      background-color:transparent !important;
      filter: $VUE_APP_COLOUR_MAIN_FILTER;
    }
  
  
  #switch-controller-button {
    width: 60px;
    height: 60px;
    background-image: url("../assets/icon-Timer-Blue.png");
  }

    #switch-controller-button:hover {
      background-image: url("../assets/icon-Timer-Blue.png");
    }

  #internet-controller-button {
    width: 60px;
    height: 60px;
    background-image: url("../assets/connection1.png");
  }
  @media (max-width: 767px) {
    #nav-text-con{
      display: none;
    }
    #internet-controller-button {
      width: 40px;
      height: 40px;
      margin-bottom: 30px;
    }
  }
  #multi-controllers{
    display: block;
  }
  @media (max-width: 580px) {
    #multi-controllers{
      display: none;
    }
  }
  #nav-text-con {
    margin: auto;
  }
  #note-date {
    color: #47afc6;
    font-weight: bold;
    text-align: center;
    grid-column: 2;
  }
  #time-diff {
    text-align: left;
  }
  #inner-div-grid {
    grid-template-rows: 120px auto 30px;
    height: 100%;
  }

  .btn-controller {
    color: #fff;
    background-color: rgb(39,170,226);
    border-color: rgb(39,170,226);
  }

  .btn-controller:hover {
    color: #fff;
    background-color: rgb(39,170,226);
    border-color: rgb(39,170,226);
  }

  .btn-outline-gray {
    color: #888;
    background-color: transparent;
    border-color: #888;
  }

    .btn-outline-gray:hover {
      color: #fff;
      background-color: rgb(39,170,226);
      border-color: rgb(39,170,226);
    }

  .btn-outline-controller {
    color: #fff;
    background-color: transparent;
    border-color: #fff;
  }
    .btn-outline-controller:hover {
      color: #fff;
      background-color: rgb(39,170,226);
      border-color: rgb(39,170,226);
    }

  .controller-button {
    font-weight: 700;
    height: 100%;
    font-size: 9px;
    max-width: 100px;
    max-height: 40px;
    padding-left: 0px;
    padding-right: 0px;
  }
  #attendance-div {
    grid-column: 1/3;
    display: grid;
    grid-template-rows: 50px auto;
    text-align: left;
    overflow: auto;
    padding: 10px 3%;
  }
 
  .script-text {
    color: black; /*#003c80;*/
    white-space: pre-wrap; /* Since CSS 2.1 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    font-family: arial;
    margin: 0px;
  }
  #controller-footer {
    width: 100%;
    padding: 2px;
    color: #fff;
    text-align: left;
    padding-left: 20px;
    font-weight: bold;
    border-top: 2px solid #999;
  }
  #choiceDiv2 {
    text-align: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  #buttonScroll {
    width: 100%;
    height: 100%;
    margin-right: 6px;
  }
  #inner-buttonScroll {
    display: grid;
    grid-template-columns:auto;
    width: 100%;
    height: 100% ;
    overflow: auto;
    font-size: 14px;
    grid-template-rows: max-content;
  }
  .width-button {
    width: 100%;
    font-size: 14px;
    text-align: left;
  }
  .padding-div{
    padding:2px;
  }
  
</style>
<script>
  import router from "../router"
  
  import { Multipane, MultipaneResizer } from 'vue-multipane';
  import connection1 from "../assets/connection1.png";
  import connection2 from "../assets/connection2.png";
  import connection3 from "../assets/connection3.png";
  import connection4 from "../assets/connection4.png";

  import AttendanceModal from '../components/ControllerAttendance.vue';

  import favicon from "../assets/favicon-control-red.png";

  export default {
    name: "Controller",
    components: {
      Multipane,
      MultipaneResizer,
      AttendanceModal
    },
    props:{
      test: Boolean
    },
    watch: {
      "showtype": {
        handler: function (v) {
          if (v == 3) {
            this.notesVariant = "controller";
          } else {
            this.notesVariant = "outline-controller";
          }
        },
        deep: true
      },
      'currentMember.type': {
        handler: function (v) {
          this.presentVariant = (v == 0 ? "grey-section" : "grey-section-outline");
          this.subVariant = (v == 1 ? "grey-section" : "grey-section-outline");
        },
        deep: true
      },
      'shuffleMemberSort':{
        handler: function (v) {
          this.sortRandomList(this.shuffleStartMember, v);
        },
        deep: true
      },
      'shuffleStartMember': {
        handler: function (v) {
          this.sortRandomList(v, this.shuffleMemberSort);
        },
        deep: true
      },
     
    },
    computed: {
      controllerStyle: function () {
        var s = {};
        s["grid-template-columns"] = (this.windowWidth<768 ? "100%" : this.windowWidth>=768 && this.windowWidth<1160? "50% 50%" : "34% 33% 33%");
        s["grid-template-rows"] = (this.windowWidth>=768 && this.windowWidth<1160 ? "50% 50%" : "100%");
        return s;
      }
    },
  data() {
    return {
      roleMap: {},
      tracking_periods: [],
      showContactSelect: false,
      showGoalSelect: false,
      showTopicSelect: false,
      showMemberByPowerteamSelect: false,
      powerteams: [],
      topics: [],
      showSpeakerSelect: false,
      speakers: [],
      controlRequests: [],
      filterAttendance: 1,
      showEventSelect: false,
      events: {chapter_events: [], region_events: []},
      roleMembers: [],
      showMemberRoleSelect: false,
      downloadFile: null,
      showDownload: false,
      showAttendance: false,
      languageOptions:[],
      showOptions:false,
      globalVolume: 1,
      showSectionScript: true,
      showSlideScript: false,
      paymentOptions: [{id: 0, name: this.$t('controller.payment_option0')},{id: 1, name: this.$t('controller.payment_option1')},{id: 2, name: this.$t('controller.payment_option2')}],
      showAttendeeSelect: false,
      internetInterval: null,
      slideFontSize: 18,
      sectionFontSize: 18,
      bioFontSize: 18,
      showtype: 1,
      view_ready: false,
      commands: ["test"],
      client: {},
      sections: [],
      variable_map: [],
      id_string: "",
      current_section: null,
      current_slide: null,
      current_speaker: {name: "", bio: ""},
      members: [],
      shuffleMemberSort: 0,
      startMembers: [],
      sortOptions: [{id: 0, name: this.$t('controller.start_with')},{id: 1, name: this.$t('controller.end_with')}],
      section_name: "",
      slide_name: "",
      scripts: {slide_is_parent: "",section_is_parent: "",section_name: "", slide_name: "", section: "", slide: "", slide_notes: "", section_notes: ""},
      today: new Date(),
      day: '',
      period_day : '',
      current_time: new Date(),
      time_difference: "",
      total_difference: 0,
      windowWidth: 1000,
      windowHeight: 1000,
      meeting_note: { text:"", day:null, dayText:""},
      notesVariant: "outline-controller",
      scriptVariant: "controller",
      agendaVariant: "outline-controller",
      attendanceVariant: "outline-controller",
      checkInterval: null,
      previousNumber: 0,
      nextNumber: 0,
      attendanceType: 0,
      showMemberSelect: false,
      currentMemberIndex: -1,
      nextMemberIndex: 0,
      previousMemberIndex: -1,
      currentAttendeeIndex: -1,
      nextAttendeeIndex: 0,
      previousAttendeeIndex: -1,
      random_attendees: [],
      select_attendees: [],
      currentContactIndex: -1,
      nextContactIndex: 0,
      previousContactIndex: -1,
      random_contacts: [],
      select_contacts: [],
      //attendance
      editable: true,
      presentVariant: "primary",
      subVariant: "primary",
      showMemberAttendance: false,
      showVisitorAttendance: false,
      showBioModal: false,
      showFullScreenConfirm: false,
      fullscreenAction: null,
      showTimes: true,
      showRecognitionSelect: false,
      networkLeaders: [],
      renewingMembers: [],
      goals: [],
      showControlBar: false,
      controlPermissions : {president: false, leadership: false, control: false, granted: false, top: false},
      fields: [
         { key: 'name',
          label: this.$t('controller.name_company_header'),
          thStyle: { "background-color":  "#ccc","color": "#666", "border":"none","font-weight": "300"}
        },
         { key: 'type',
              label: this.$t('controller.type_header'),
              thStyle: { "background-color":  "#ccc","color": "#666", "text-align":"center", "border":"none" ,"font-weight": "300"},
              tdClass: "actions-centre"
            },
         { key: 'status',
          label: this.$t('controller.status_header'),
          thStyle: { "background-color":  "#ccc","color": "#666", "text-align":"center", "border":"none" ,"font-weight": "300"},
          tdClass: "actions-centre"
        },
           { key: 'visits',
            label: this.$t('controller.visit_number'),
            thStyle: { "background-color":  "#ccc","color": "#666", "text-align":"center", "border":"none","font-weight": "300"},
            tdClass: "actions-centre"
          },
           { key: 'linked_member',
            label: "",
            thStyle: { "background-color": "#ccc","color": "#666", "border":"none","font-weight": "300" }
          }
      ],
      fields2: [
         { key: 'name',
          label: this.$t('controller.name_header'),
          thStyle: { "background-color":  "#ccc","color": "#666", "border":"none","font-weight": "300" }
        },
         { key: 'status',
          label: this.$t('controller.status_header'),
          thStyle: { "background-color":  "#ccc","color": "#666","border":"none","font-weight": "300" }
        }
      ],
      attendance_members: [],
      visitorsubs: [],

      counter: 0,
      timer: null,

      member_letters: [],
      selected_members: [],
      random_members: [],
      contacts: [],
      last_letter: null,
      dates: [],
      date_index: 0,
      attendees: [],
      showVisitorError: false,
      visitorErrorText: "",
      showRegional: false,
      pslide: 0,
      maxPresentationSlide: 2,
      showPerformanceSelect: false,
      performancePeriods: [],
      pdfHash: "",
      currentFile: null,
      currentFileParent: null,
      shuffleStartMember: 0,
      scrollToMember: true,
      showQuoteSelect: false,
      quotes: [],
      timerRunning: false,
    }
  },
  sockets: {
    attendance_refresh(data){
      this.getDates();
    },
    view_ready(data) {
      if (!this.view_ready) {
        this.getMembers();
        this.getSections();
        this.view_ready = data.ready;
      }
    },
      presentation_info(data) {
        if (data.maxSlide) {
          this.maxPresentationSlide = data.maxSlide;
        }
        if(data.slide){
          this.pslide = data.slide;
        }
      },
      goto_controller(data)  {
        if((this.current_section== null || data.section_id != this.current_section.section_id) || (this.current_slide == null || data.slide_id != this.current_slide.id)){
            for(var i=0; i<this.sections.length;i++){
              if(this.sections[i].section_id == data.section_id && this.sections[i].slides){
                this.resetColors();
                this.$set(this.sections[i], 'variant', "grey-section");
                this.current_section = this.sections[i];
                for(var j=0; j<this.sections[i].slides.length; j++){
                  if(this.sections[i].slides[j].id == data.slide_id){
                    this.$set(this.sections[i].slides[j], 'variant', "grey-slide");
                    this.current_slide = this.sections[i].slides[j];
                    this.setScripts(this.current_section, this.current_slide);
                    break;
                  }
                }
                break;
              }
            } 
        }
        
      },
      disconnect()  {
        this.$bvToast.show('discToastCon');
      },
      connect(){
        this.$socket.emit("register_controller", { id_string: this.id_string, name: this.permissions.user.name });
      },

      play_audio(data)  {
        this.playSound(data.timer);
      },
      sync_timer(data)  {
        if(this.currentFileParent != null){
          if(data.timer_playing && data.slide_type == 3){
            this.currentFileParent.end_time = data.end_time;
            this.startSpeakerTimer(this.currentFileParent);
          }else if(data.slide_type == 3){
            this.stopSpeakerTimer(this.currentFileParent);
          }
        }
      },
      permissions(data)  {
        if(this.controlPermissions.timouetInterval){
          clearInterval(this.controlPermissions.timouetInterval);
        }
        this.controlPermissions = data;
        if(!this.controlPermissions.top){
          this.controlRequests = [];
        }
        if(this.controlPermissions.time_requested){
          this.$set(this.controlPermissions, 'timeout',Math.max(0,10-Math.floor((new Date().getTime() / 1000)-this.controlPermissions.time_requested)));
          var self = this;
          this.controlPermissions.timouetInterval = setInterval(function(){
            self.$set(self.controlPermissions, 'timeout',Math.max(0,10-Math.floor((new Date().getTime() / 1000)-self.controlPermissions.time_requested)));
            if(self.controlPermissions.timeout==0){
              clearInterval(self.controlPermissions.timouetInterval);
            }
          },100);
        }
        this.showControlBar = true;
      },
      requests(data)  {
        for(var i=0; i<this.controlRequests.length; i++){
          if(this.controlRequests[i].timouetInterval){
            clearInterval(this.controlRequests[i].timouetInterval);
          }
        }
        this.controlRequests = data;
        this.showControlBar = true;
        for(i=0; i<this.controlRequests.length; i++){
            if(this.controlRequests[i].time_requested){
              this.$set(this.controlRequests[i], 'timeout', Math.max(0,10-Math.floor((new Date().getTime() / 1000)-this.controlRequests[i].time_requested)));
              this.controlRequests[i].timouetInterval = this.getInterval(this.controlRequests[i]);
            }
        }
      },
  },
    methods: {
      resendConfirmation(speaker_id, file){
        this.$http.post("/api/organization/speaker/resendconfirm", 
            {speaker_id: speaker_id, number: file.number, recipient: ""}).then((response) => {
                if(response.data.success){
                  this.$bvToast.toast(this.$t('preparer.resend_success'), {
                    variant: 'success',
                    solid: true
                  });
                }
        }).catch(() => {});
      },

      sendFilesToPowerteam(speaker_id, powerteam_id, file){
          this.$http.post("/api/organization/powerteam/speaker/files/send", {presentation_id: powerteam_id, speaker_id: speaker_id, number: file.number}).then((response) => {
              if(response.data.success){
                  this.$bvToast.toast(this.$t('preparer.resend_success'), {
                    variant: 'success',
                    solid: true
                  });
              }
          }).catch(() => {});
      },
      sortRandomList(specific_id, sort_type){
        var index = -1;
        for(var x =0; x<this.selected_members.length;x++){
          if(this.selected_members[x].id==specific_id){
            index = x;
            break;
          }
        }
        if(index>=0){
          if(sort_type==0){
            var temp = this.selected_members[0];
            this.selected_members[0] = this.selected_members[index];
            this.selected_members[index] = temp;
          }else if(sort_type==1){
            var temp = this.selected_members[this.selected_members.length-1];
            this.selected_members[this.selected_members.length-1] = this.selected_members[index];
            this.selected_members[index] = temp;
          }
        }
      },
      getStartButtonText(file){
        var fileName = file.file_path? file.file_path : "";
        var sp = fileName.split(".");
        var enc = "pdf";
        if(sp.length>1){
          enc = sp[sp.length-1];
        }
       return enc=="png" || enc=="jpg" || enc=="jpeg"?  this.$t('controller.show_image') : (file.type==1? this.$t('controller.start_slideshow') : this.$t('controller.open_link'));
      },
      requestControl(){
        this.controlPermissions.requested = true;
        this.$socket.emit("request_control", { id_string: this.id_string });
      },
      returnControl(){
        this.controlPermissions.requested = false;
        this.controlPermissions.access = false;
        this.controlPermissions.granted = false;
        this.$socket.emit("return_control", { id_string: this.id_string });
      },
      cancelRequest(){
        this.controlPermissions.requested = false;
        this.$socket.emit("cancel_request", { id_string: this.id_string });
      },
      takeControl(request){
        this.$socket.emit("take_control", { id_string: this.id_string, request: request });
      },
      acceptRequest(request){
        this.$socket.emit("accept_request", { id_string: this.id_string, request: request });
      },
      declineRequest(request){
        this.$socket.emit("decline_request", { id_string: this.id_string, request: request });
      },
      changeMemberPresence(member){
        member.present = Math.abs(member.present-1);
        this.$http.post("/api/attendance/update/present", member).then((response) => {
          if (response.data.success === true) {
            this.$socket.emit("request_attendance_refresh",{ org: this.permissions.organization.id, id_string: this.id_string});
          }
        }).catch((errors) => {console.log(errors)});
        this.calcMemberIndexes(this.selected_members[this.currentMemberIndex]);
      },
      changeContactPresence(contact){
        contact.present = Math.abs(contact.present-1);
        this.calcContactIndexes(this.select_contacts[this.currentContactIndex]);
      },
      changeAttendeePresence(attendee){
        attendee.present = Math.abs(attendee.present-1);
        this.calcAttendeeIndexes(this.attendees[this.currentAttendeeIndex]);
      },
    toggleScripts(n){
      if(this.scripts.section.length>1 && this.scripts.slide.length>1){
        /*if(this.showSectionScript && this.showSlideScript){
            
          return;
        }
        this.showSlideScript = !this.showSlideScript;
        this.showSectionScript = !this.showSectionScript;*/
        this.showSlideScript = (n==1);
        this.showSectionScript = (n==0);
      }
    },
     getNetworkDownloadSpeed() {
        //let startTime = new Date().getTime();
        let fileSize = 500000;
        this.$http.post("/api/test/internet", { length: fileSize }).then((response) => {
            const endTime = new Date().getTime();
            const duration = (endTime - response.data.startTime) / 1000;
            const bitsLoaded = fileSize * 8;
            const bps = (bitsLoaded / duration).toFixed(2);
            const kbps = (bps / 1024).toFixed(2);
            const mbps = (kbps / 1024).toFixed(2);
            let speed = {bps, kbps, mbps};
            if(speed.mbps>8){
                document.getElementById("internet-controller-button").style.backgroundImage = 'url(' + connection4 + ')';
            }else if(speed.mbps>5){
                document.getElementById("internet-controller-button").style.backgroundImage = 'url(' + connection3 + ')';
            }else if(speed.mbps>2){
                document.getElementById("internet-controller-button").style.backgroundImage = 'url(' + connection2 + ')';
            }else{
                document.getElementById("internet-controller-button").style.backgroundImage = 'url(' + connection1 + ')';
            }
        }).catch((errors) => {
          console.log(errors);
        });
      },
      changeLetter(letter) {
        if (this.last_letter != null) {
          this.last_letter.variant = "grey-slide-outline";
        }
        var self = this;
        this.selected_members = this.members.filter(function (item) {
            return (letter.letter == self.$t('controller.all_letter') || item.name[0] == letter.letter) && item.disabled == 0;
          });
        this.last_letter = letter;
        letter.variant = "grey-slide";
      },
      timerEnd(){
        this.$socket.emit("timer_end", { id_string: this.id_string });
      },
      playSound(timer){
          timer.current_audio= new Audio(this.getImgUrl("sounds/"+timer.sound_file));
          timer.current_audio.load();
          timer.current_audio.volume = timer.volume*this.globalVolume;
          timer.current_audio.addEventListener("ended", this.timerEnd);
          timer.current_audio.oncanplaythrough = function() {
              var promise = timer.current_audio.play();
              if (promise !== undefined && promise  !== null) {
                promise.catch(function (error) { /*self.slides[1].timer.current_audio.play(); console.log(error);*/
                  console.log(error);
                });
              }
          };
      },
      renewVariables() {
        this.$http.post("/api/organization/slide/get/variables", {}).then((response) => {
          if (response.data.success) {
            this.variable_map = response.data.variable_map;
            this.setScripts(this.current_section, this.current_slide);
          }
        }).catch((errors) => {console.log(errors); });
      },
      connectWithId(uid){
        this.id_string = uid;
        this.$socket.emit("register_controller", { id_string: this.id_string, name: this.permissions.user.name });
      },

      getInterval(request){
        var self = this;
        return setInterval(function(){
                self.$set(request,'timeout', Math.max(0,10-Math.floor((new Date().getTime() / 1000)-request.time_requested)));
                if(request.timeout==0){
                  clearInterval(request.timouetInterval);
                }
              },100);
      },

      auth() {
        var self = this;
        this.checkInterval = setInterval(function () {
          //self.today = new Date();
          self.current_time = new Date();
          if (self.current_section != null) {
            var stime = new Date(new Date().toDateString() + " " + self.$d(self.current_section.start_time, 'full_time'));
            var ctime = new Date();
            self.total_difference = (stime.getTime() - ctime.getTime()) / 1000;
            var diff = Math.abs(self.total_difference);
            var hours = Math.floor(diff / 3600);
            diff -= hours * 3600;
            var mins = Math.floor(diff / 60);
            diff -= mins * 60;
            var secs = Math.floor(diff);
            self.time_difference = (self.total_difference<0? "-" : "") + (hours > 0 ? hours + "h " : "") + (mins > 9 ? mins : "0" + mins) + "m " + (secs > 9 ? secs : "0" + secs)+"s";
          }
        }, 50);
        this.authenticate(function(){
          if(!self.test){
              document.title = self.$t('global.document_title_control') +" "+ self.permissions.organization.name;
          }
          self.$http.post("/api/client/get", {controller: true}).then((response) => {
            if (response.data.id_string) {
              self.connectWithId(response.data.id_string);
            }
          }).catch((errors) => {
            console.log(errors);
          });
        });
      },
      getLanguages(){
        this.$http.post("/api/organization/languages", {}).then((response) => {
          if (response.data.success === true) {
            this.languageOptions = [];
            for(var i=0; i<response.data.languages.length; i++){
                this.languageOptions.push(response.data.languages[i]);
            }
          }
        }).catch((errors) => {
          console.log(errors)
        });
      },
      getScrollToMember(){
        this.$http.post("/api/organization/get/scrolltomember", {}).then((response) => {
          if (response.data.success === true) {
           this.scrollToMember = response.data.scrollToMember;
          }
        }).catch((errors) => {
          console.log(errors)
        });
      },
      setVolume(volume) {
        this.globalVolume = volume;
        this.$socket.emit("set_volume", { id_string: this.id_string, volume: volume });
      },
      scriptSwipe() {
        if (this.windowWidth<768) {
          this.guideClick();
        }
      },
      guideSwipe() {
        if (this.permissions.access) {
          if (this.windowWidth<768 && this.showtype == 2) {
            this.guideClick();
          } else if (this.windowWidth<768 && this.showtype == 1) {
            this.showScript();
          }
        }
      },
      getTimeString(t){
          return (Math.floor(t/60)>0? Math.floor(t/60)+":" : "")+((Math.floor(t)%60) < 10 && Math.floor(t/60)>0? "0"+(Math.floor(t)%60) : (Math.floor(t)%60))
        },
      
      getSectionFiles(section){
        this.$http.post("/api/meeting/sections/section/files/byid", { id: section.id }).then((response) => {
            this.$set(section, 'files', response.data.files);
        }).catch(() => {
        });
      },
      sectionClick(evt, section) {
        this.resetColors();
        section.variant = "grey-section";
        this.current_section = section;
        this.getSectionFiles(section);
        this.setScripts(section);
        if (section.slides && section.auto_play > 0) {//section.slides.length == 1 || 
          this.slideClick(section, section.slides[0]);
        }
        

      },
      formatAMPM(date) {
        var f = date.split(":")
        var hours = f[0];
        var minutes = f[1];
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
      },
      shuffleArray(a) {
          var j, x, i;
          for (i = a.length - 1; i > 0; i--) {
              j = Math.floor(Math.random() * (i + 1));
              x = a[i];
              a[i] = a[j];
              a[j] = x;
          }
          return a;
      },
      hideTimeBar(){
        this.showTimes = false;
      },
      showTimeBar(){
        this.showTimes = true;
      },
      endDrag() {

      },
      shuffleMembers(){
        var cmember = this.selected_members[this.currentMemberIndex];

        this.shuffleArray(this.random_members);
        
        if(this.current_slide && this.current_slide.selection==3){
          this.selected_members = this.random_members.filter(function (item) {
            return item.disabled == 0;
          });
          this.sortRandomList(this.shuffleStartMember, this.shuffleMemberSort)
       

          for(var i=0; i<this.selected_members.length;i++){
              if(this.selected_members[i] == cmember){
                this.currentMemberIndex = i;
                break;
              }
            }
          this.calcMemberIndexes(cmember);
         }
      },
      shuffleAttendees(){
        var cmember = this.attendees[this.currentAttendeeIndex];
        this.shuffleArray(this.random_attendees);
        this.attendees = this.random_attendees.filter(function () {
              return true;
            });

        for(var i=0; i<this.attendees.length;i++){
            if(this.attendees[i] == cmember){
              this.currentAttendeeIndex = i;
              break;
            }
          }
        this.calcAttendeeIndexes(cmember);
      },
      shuffleContacts(){
        var ccontact = this.select_contacts[this.currentContactIndex];

        this.shuffleArray(this.random_contacts);
        var index = -1;
        for(var x =0; x<this.random_contacts.length;x++){
          if(this.random_contacts[x].id==this.shuffleStartContact){
            index = x;
            break;
          }
        }
        if(index>=0){
          var temp = this.random_contacts[0];
          this.random_contacts[0] = this.random_contacts[index];
          this.random_contacts[index] = temp;
        }
        this.select_contacts = this.random_contacts.filter(function (item) {
            return true;
          });

        for(var i=0; i<this.select_contacts.length;i++){
            if(this.select_contacts[i] == ccontact){
              this.currentContactIndex = i;
              break;
            }
          }
        this.calcContactIndexes(ccontact);
      },
      calcContactIndexes(contact){
        if(this.select_contacts.indexOf(contact)>=0){
            this.currentContactIndex = this.select_contacts.indexOf(contact);
          }
           this.previousContactIndex = -1;
          for(var i=this.currentContactIndex-1; i>=0;i--){
            if(this.select_contacts[i].present>0){
              this.previousContactIndex = i;
              break;
            }
          }

          var next_id = [];
          this.nextContactIndex = this.select_contacts.length;
          for(i=this.currentContactIndex+1; i<this.select_contacts.length;i++){
            if(this.select_contacts[i].present>0){
              this.nextContactIndex = i;
              break;
            }
          }
          for(i=this.currentContactIndex+1; i<this.select_contacts.length;i++){
            if(this.select_contacts[i].present>0){
              next_id.push({id: this.select_contacts[i].id,name:this.select_contacts[i].next_name? this.select_contacts[i].next_name : this.select_contacts[i].name});
              if(next_id.length==3){
                break;
              }
            }
          }
          return next_id;
      },
      slideClick(section, slide) {
        this.resetColors();
        section.variant = "grey-section";
        slide.variant = "grey-slide";
        this.current_section = section;
        //this.setScripts(section, slide);
        if (this.windowWidth>=768){
          this.showScript();
        }
        var self = this;
        this.getFileInfo(slide);
        if (slide.type == 1) {
          this.current_slide = slide;
          if(this.current_slide.selection==3){
             this.selected_members = this.random_members.filter(function (item) {
              return item.disabled == 0;
            });
          }else if(this.current_slide.selection==4){
            var groupByArray = function(xs, key) { return xs.reduce(function (rv, x) { let v = key instanceof Function ? key(x) : x[key]; let el = rv.find((r) => r && r.key === v); if (el) { el.values.push(x); } else { rv.push({ key: v, values: [x] }); } return rv; }, []); } 
            var m = this.random_members.filter(function (item) {
                return item.disabled == 0;
              });

            var m2 = groupByArray(m, "city");
            this.selected_members = [];
            for(var i=0; i<m2.length; i++){
              Array.prototype.push.apply(this.selected_members,m2[i].values);
            }
          }else if(this.current_slide.selection==5){
            this.current_slide = slide;
            this.getPowerTeams();
            this.showMemberByPowerteamSelect = true;
          }else if(this.current_slide.selection==6){
            this.current_slide = slide;
            this.getMemberAsks();
            this.showMemberByPowerteamSelect = true;
          }else{
            this.members.sort((a, b) => (a.name.toLocaleLowerCase()> b.name.toLocaleLowerCase()) ? 1 : -1);
             this.selected_members = this.members.filter(function (item) {
                return item.disabled == 0;
              });
          }
          if(this.current_slide.selection<5){
            if(this.selected_members.length==1){
              this.memberSelectClick(this.selected_members[0]);
            }else{
              this.showMemberSelect = true;
              this.getStartsWithMembers();
            }
          }
          this.nextMemberIndex = -1;
          this.currentMemberIndex = -1
          this.previousMemberIndex = -1;
         } else if (slide.type == 3) {
           if(this.current_slide != slide){
             this.current_slide = slide;
            //this.getSpeakerInfo(slide);
           this.getSpeakers(slide);
           }
          this.showSpeakerSelect = true;
         
        } else if (slide.type == 6) {
          this.current_slide = slide;
          this.getRoleMembers(slide.role_id);
        } else if (slide.type == 7) {
          this.current_slide = slide;
          this.getAttendees();
        }else if(slide.type == 8){
          this.current_slide = slide;
          this.getGoals();
          this.showGoalSelect = true;
        } else if(slide.type==10){
          this.current_slide = slide;
          this.getPeriods();
          this.showPerformanceSelect = true;
        } else if(slide.type==12){
          this.current_slide = slide;
          this.getEvents();
          this.showEventSelect = true;
        }else if(slide.type==14){
          this.current_slide = slide;
          this.getRecognitions(function(){
            if(self.current_slide.member_id == 1 && self.networkLeaders.length+self.renewingMembers.length==1){
                if(self.networkLeaders.length==1){
                  self.memberRoleSelectClick(self.networkLeaders[0]);
                }else{
                  self.memberRoleSelectClick(self.renewingMembers[0]);
                }
            }else if (self.current_slide.member_id == 2 && self.networkLeaders.length==1){
              self.memberRoleSelectClick(self.networkLeaders[0]);
            }else if (self.current_slide.member_id == 3 && self.renewingMembers.length==1){
              self.memberRoleSelectClick(self.renewingMembers[0]);
            }else{
              self.showRecognitionSelect = true;
            }
          });
          
        } else if(slide.type==16){
           this.current_slide = slide;
          
          this.getQuotes(function(){
            if(self.quotes.length==1){
              self.quoteSelectClick(self.quotes[0]);
            }else if (self.quotes.length>1){
              self.showQuoteSelect = true;
            }
          });
        } else if(slide.type==18){
           this.current_slide = slide;
           this.getContacts();
        }else if (slide.type == 20 || slide.type == 22) {
           if(this.current_slide != slide){
             this.current_slide = slide;
            this.getTopics();
           }
        } else {
          this.current_slide = slide;
          this.goToSection(section.section_id, slide.id,0);
        }
      },
      getSpeakerInfo(slide) {
        var speaker = {type:0,url:"",name:"",file_path:"",hash:"",time:0}
        this.$set(slide, 'speaker', speaker);
        this.$http.post("/api/organization/speaker/getbynum", { num: slide.num }).then((response) => {
          if (response.data.success) {
            response.data.speaker.started = 0;
            this.$set(slide, 'speaker', response.data.speaker);
          }
        }).catch(() => {
        });
      },
      getSpeakerInfoById(slide, id) {
        var speaker = {type:0,url:"",name:"",file_path:"",hash:"",time:0}
        this.$set(slide, 'speaker', speaker);
        this.$http.post("/api/organization/speaker/getbyid", { id: id }).then((response) => {
          if (response.data.success) {
            response.data.speaker.started = 0;
            this.$set(slide, 'speaker', response.data.speaker);
            this.currentFileParent = this.current_slide.speaker;
          }
        }).catch(() => {
        });
      },
      getTopicInfoById(slide, id) {
        var topic = {type:0,url:"",name:"",file_path:"",hash:"",time:0}
        this.$set(slide, 'topic', topic);
        this.$http.post("/api/organization/presentation/getbyid", { id: id }).then((response) => {
          if (response.data.success) {
            response.data.presentation.started = 0;
            this.$set(slide, 'topic', response.data.presentation);
            this.currentFileParent = this.current_slide.topic;
          }
        }).catch(() => {
        });
      },
      getQuotes(onEnd){
         this.$http.post("/api/organization/quotes", {}).then((response) => {
          if (response.data.success) {
            for (var j = 0; j < response.data.quotes.length; j++) {
                response.data.quotes[j].variant = "grey-slide-outline";
              }
            this.quotes = response.data.quotes;
            onEnd();
          }
        }).catch(() => {});
      },
      getMemberAsks(){
        this.selected_members = [];
        this.nextMemberIndex = -1;
        this.currentMemberIndex = -1
        this.previousMemberIndex = -1;
        this.$http.post("/api/organization/members/withasks", {}).then((response) => {
          if (response.data.success) {
            for (var j = 0; j < response.data.members.length; j++) {
                response.data.members[j].variant = "grey-slide-outline";
                response.data.members[j].present = 1;
              }
            this.selected_members = response.data.members.filter(function (item) {
              return item.disabled == 0;
            });
            this.getDates();
          }
        }).catch((e) => {console.log(e);});
      },
      getPowerTeams(onEnd){
        this.selected_members = [];
        this.$http.post("/api/organization/powerteams/select", {}).then((response) => {
          if (response.data.success) {
            if(response.data.show_none){
              response.data.powerteams.push({id: 0, name: this.$t("controller.not_in_powerteams")});
            }
            for (var j = 0; j < response.data.powerteams.length; j++) {
                response.data.powerteams[j].variant = "grey-slide-outline";
              }
            this.powerteams = response.data.powerteams;
            if(onEnd){
              onEnd();
            }
          }
        }).catch(() => {});
      },
      getMembersByPowerTeam(powerteam){
        for (var i = 0; i < this.powerteams.length; i++) {
          this.powerteams[i].variant = "grey-slide-outline";
        }
        this.nextMemberIndex = -1;
          this.currentMemberIndex = -1
          this.previousMemberIndex = -1;
        powerteam.variant = "grey-slide";
        this.$http.post("/api/organization/members/bypowerteam", {id: powerteam.id}).then((response) => {
          if (response.data.success) {
            for (var j = 0; j < response.data.members.length; j++) {
                response.data.members[j].variant = "grey-slide-outline";
                response.data.members[j].present = 1;
                response.data.members[j].powerteam_id = powerteam.id;
              }
            this.selected_members = response.data.members.filter(function (item) {
              return item.disabled == 0;
            });
            this.getDates();
          }
        }).catch((e) => {console.log(e);});
      },
      getSpeakers(slide) {
        this.$http.post("/api/organization/speakers/next", {}).then((response) => {
          if (response.data.success) {
            for (var j = 0; j < response.data.speakers.length; j++) {
                response.data.speakers[j].variant = "grey-slide-outline";
              }
            this.speakers = response.data.speakers;
            if(slide.speaker_num>0 && slide.speaker_num<=this.speakers.length){
              this.SpeakerSelectClick(this.speakers[slide.speaker_num-1]);
              this.speakers = [this.speakers[slide.speaker_num-1]];
            }
          }
        }).catch(() => {});
      },
      getTopics() {
        this.$http.post("/api/organization/topics/meeting", {}).then((response) => {
          if (response.data.success) {
            for (var j = 0; j < response.data.topics.length; j++) {
                response.data.topics[j].variant = "grey-slide-outline";
              }
            this.topics = response.data.topics;
            if(this.topics.length==1){
              this.TopicSelectClick(this.topics[0]);
              this.topics = [this.topics[0]];
            }
            this.showTopicSelect = true;
          }
        }).catch(() => {});
      },
      getFileInfo(slide) {
        this.$http.post("/api/meeting/sections/slide/files", { slide_id: slide.id }).then((response) => {
          this.$set(slide, 'slide_files', response.data.slide_files);
        }).catch(() => {
        });
      },
      memberClick(section, slide, member) {
        this.resetColors();
        section.variant = "grey-section";
        slide.variant = "grey-slide";
        member.variant = "grey-slide";
        this.goToSection(section.section_id, slide.id, member.id);
      },
      calcMemberIndexes(member){
        if(this.selected_members.indexOf(member)>=0){
            this.currentMemberIndex = this.selected_members.indexOf(member);
          }
           this.previousMemberIndex = -1;
          for(var i=this.currentMemberIndex-1; i>=0;i--){
            if(this.selected_members[i].present>0){
              this.previousMemberIndex = i;
              break;
            }
          }

          var next_id = [];
          this.nextMemberIndex = this.selected_members.length;
          for(i=this.currentMemberIndex+1; i<this.selected_members.length;i++){
            if(this.selected_members[i].present>0){
              this.nextMemberIndex = i;
              break;
            }
          }
          for(i=this.currentMemberIndex+1; i<this.selected_members.length;i++){
            if(this.selected_members[i].present>0){
              next_id.push({id: this.selected_members[i].id, name: this.selected_members[i].name});
              if(next_id.length==3){
                break;
              }
            }
          }
          return next_id;
      },
      memberSelectClick(member) {
        if (this.current_slide && this.current_section) {
          

           var d = document.getElementById("memberbutton_"+member.id);
            if(d && this.scrollToMember){
              d.scrollIntoView({ behavior: 'auto',block: 'center',inline: 'center' });
            }

          this.resetColors();
          this.current_section.variant = "grey-section";
          this.current_slide.variant = "grey-slide";
          
          var next_id = this.calcMemberIndexes(member);
          member.variant = "grey-slide";
          //this.setScripts(this.current_section, this.current_slide);
          var power_id = this.current_slide.selection==5? member.powerteam_id : 0;
          if(this.current_slide.type==1 && this.current_slide.best_weekly==1){
            this.$http.post("/api/organization/set/bestweekly", {id: member.id }).then(() => {
                this.goToSection(this.current_section.section_id, this.current_slide.id, member.id, next_id, power_id);
              }).catch((errors) => {
                console.log(errors);
              });
          }else{
             this.goToSection(this.current_section.section_id, this.current_slide.id, member.id, next_id, power_id);
          }
         
        }
      },
      contactSelectClick(contact){
        if (this.current_slide && this.current_section) {
          this.resetColors();
          this.current_section.variant = "grey-section";
          this.current_slide.variant = "grey-slide";
          contact.variant = "grey-slide";
          var next_id = this.calcContactIndexes(contact);
          this.goToSection(this.current_section.section_id, this.current_slide.id, contact.id, next_id);
        }
      },
      calcAttendeeIndexes(attendee){
        if(this.select_attendees.indexOf(attendee)>=0){
            this.currentAttendeeIndex = this.select_attendees.indexOf(attendee);
          }
           this.previousAttendeeIndex = -1;
          for(var i=this.currentAttendeeIndex-1; i>=0;i--){
            if(this.select_attendees[i].present>0){
              this.previousAttendeeIndex = i;
              break;
            }
          }

          var next_id = [];
          this.nextAttendeeIndex = this.select_attendees.length;
          for(i=this.currentAttendeeIndex+1; i<this.select_attendees.length;i++){
            if(this.select_attendees[i].present>0){
              this.nextAttendeeIndex = i;
              break;
            }
          }
          for(i=this.currentAttendeeIndex+1; i<this.select_attendees.length;i++){
            if(this.select_attendees[i].present>0){
              next_id.push({id: this.select_attendees[i].id,name:this.select_attendees[i].next_name? this.select_attendees[i].next_name : this.select_attendees[i].name});
              if(next_id.length==3){
                break;
              }
            }
          }
          return next_id;
      },
      attendeeSelectClick(attendee){
        if (this.current_slide && this.current_section) {
          this.resetColors();
          this.current_section.variant = "grey-section";
          this.current_slide.variant = "grey-slide";

          var next_id = this.calcAttendeeIndexes(attendee);
          attendee.variant = "grey-slide";
          //this.setScripts(this.current_section, this.current_slide);
          this.goToSection(this.current_section.section_id, this.current_slide.id, attendee.id, next_id);
        }
      },
      memberRoleSelectClick(member){
        if (this.current_slide && this.current_section) {
          this.resetColors();
          this.current_section.variant = "grey-section";
          this.current_slide.variant = "grey-slide";
          member.variant = "grey-slide";
          //this.setScripts(this.current_section, this.current_slide);
          this.goToSection(this.current_section.section_id, this.current_slide.id, member.id);
        }
      },
      goalSelectClick(goal){
        if (this.current_slide && this.current_section) {
          this.resetColors();
          this.current_section.variant = "grey-section";
          this.current_slide.variant = "grey-slide";
          goal.variant = "grey-slide";
          //this.setScripts(this.current_section, this.current_slide);
          this.goToSection(this.current_section.section_id, this.current_slide.id, goal.id);
        }
      },
      quoteSelectClick(quote){
        if (this.current_slide && this.current_section) {
          this.resetColors();
          this.current_section.variant = "grey-section";
          this.current_slide.variant = "grey-slide";
          quote.variant = "grey-slide";
          //this.setScripts(this.current_section, this.current_slide);
          this.goToSection(this.current_section.section_id, this.current_slide.id, quote.id);
        }
      },
      periodSelectClick(period){
        if (this.current_slide && this.current_section) {
          this.resetColors();
          this.current_section.variant = "grey-section";
          this.current_slide.variant = "grey-slide";
          period.variant = "grey-slide";
          //this.setScripts(this.current_section, this.current_slide);
          this.goToSection(this.current_section.section_id, this.current_slide.id, period.id);
        }
      },
      eventSelectClick(event){
        if (this.current_slide && this.current_section) {
          this.resetColors();
          this.current_section.variant = "grey-section";
          this.current_slide.variant = "grey-slide";
          event.variant = "grey-slide";
          //this.setScripts(this.current_section, this.current_slide);
          this.goToSection(this.current_section.section_id, this.current_slide.id, event.id);
        }
      },
      SpeakerSelectClick(speaker){
        if (this.current_slide && this.current_section) {
          this.resetColors();
          this.current_section.variant = "grey-section";
          this.current_slide.variant = "grey-slide";
          speaker.variant = "grey-slide";
          
          //this.setScripts(this.current_section, this.current_slide);
          this.getSpeakerInfoById(this.current_slide,speaker.id);
          this.goToSection(this.current_section.section_id, this.current_slide.id, speaker.id);
        }
      },
      TopicSelectClick(topic){
        if (this.current_slide && this.current_section) {
          this.resetColors();
          this.current_section.variant = "grey-section";
          this.current_slide.variant = "grey-slide";
          topic.variant = "grey-slide";
          
          //this.setScripts(this.current_section, this.current_slide);
          this.getTopicInfoById(this.current_slide,topic.id);
          this.goToSection(this.current_section.section_id, this.current_slide.id, topic.id);
        }
      },
      goToScript(section, slide) {
        this.setScripts(section, slide);
        this.showScript();
      },
      
      setScripts(section, slide) {
        this.scripts = {section_is_parent: this.$t('controller.my_text'), 
        slide_is_parent: this.$t('controller.my_text'),
            section_name: section ? (section.section_name && section.section_name.length>0? section.section_name : this.$t('global.no_translation') ) : "", 
            slide_name: slide ? slide.name : "",
            section: "", slide: "", slide_notes: "", section_notes: ""};
        if (section != null) {
          this.$http.post("/api/meeting/section/get/script", { id: section.section_id }).then((response) => {
            if (response.data.success) {
              var section_script = response.data.script.script != null && response.data.script.is_parent.length>0? response.data.script.script  : "";
              var section_notes = response.data.script.is_parent.length>0? (response.data.script.notes != null ? response.data.script.notes  : "") : (response.data.script.script != null ? response.data.script.script  : "");
              
              this.showSectionScript = section_script.length>1;
              this.showSlideScript = section_notes.length>1;
              this.scripts.section_is_parent = "BNI"; //response.data.script.is_parent
              this.scripts.slide_is_parent = this.permissions.organization.name;
            }
            /*if (slide != null && false) {
              this.$http.post("/api/meeting/slide/get/script", { id: slide.id }).then((response) => {
                 if (response.data.success) {
                  this.scripts.slide = response.data.script.script != null ? response.data.script.script  : "";
                  this.scripts.slide_notes = response.data.script.notes != null ? response.data.script.notes  : "";
                  this.scripts.slide_is_parent = this.$t('controller.my_text');
                }
                this.insertVariables();
              }).catch((errors) => {
                console.log(errors);
              });
            } else {*/
            this.$http.post("/api/organization/get/variables/volatile", {}).then((response) => {
                if(response.data.success){
                  for(var i=0; i<response.data.variable_map.length; i++){
                    for(var j=0; j<this.variable_map.length; j++){
                      if(this.variable_map[j].id == response.data.variable_map[i].id){
                        this.variable_map[j].value = response.data.variable_map[i].value;
                      }
                    }
                  }
                }

                this.insertVariables(section_script, section_notes);
              }).catch((errors) => {
                console.log(errors);
              });
            //}
          }).catch((errors) => {
            console.log(errors);
          });
        }
      },
      getSectionsMemberNames(){
        this.$http.post("/api/meeting/sections/names", { }).then((response) => {
          if (response.data.success) {
            for(var i=0; i<this.sections.length; i++){
              if(response.data.preparers[this.sections[i].section_id]){
                this.$set(this.sections[i], 'preparers', response.data.preparers[this.sections[i].section_id]);
              }
              if(response.data.speakers[this.sections[i].section_id]){
                this.$set(this.sections[i], 'speakers', response.data.speakers[this.sections[i].section_id]);
              }
            }
          }
        }).catch((errors) => {
          console.log(errors);
        });
      },
      getSections() {
        this.$http.post("/api/meeting/sections/slides", {}).then((response) => {
          this.populateVariables(response);
          var t = 0;
          for (var i = 0; i < response.data.sections.length; i++) {
            if (!response.data.sections[i].disabled) {
              var datetime = new Date(new Date().toDateString() + " " + response.data.start);
              datetime.setSeconds(datetime.getSeconds() + t);
              response.data.sections[i].start_time = datetime;
              t += response.data.sections[i].target_duration;
            }
          }

          for (i = 0; i < response.data.sections.length; i++) {
            response.data.sections[i].variant = "grey-section-outline";
            if (response.data.sections[i].slides) {
              for (var j = 0; j < response.data.sections[i].slides.length; j++) {
                response.data.sections[i].slides[j].variant = "grey-slide-outline";
              }
            }
          }
          //this.meeting_note = response.data.note;
          //this.meeting_note.dayText = new Date(this.meeting_note.day.split("-").join("/"));
          //this.previousNumber = this.meeting_note.number;
          this.sections = response.data.sections;

          var self = this;
          this.variable_map.forEach(function (item) { 
            if(item.id == "{Current_Time}"){
              item.value = self.$d(new Date(), 'time12') ;
            }else if(item.id == "{Current_Date}"){
              item.value = self.$d(new Date(), 'short') ;
            }
            for (var i = 0; i < self.sections.length; i++) {
              if (self.sections[i].slides) {
                for (let slide of self.sections[i].slides) {
                  var reg = new RegExp('(<span data-type="mention" class="mention" data-id="'+item.id+'.*?<\/span>)', 'g');
                  if (slide.name) {
                    slide.name = slide.name.replaceAll(reg, (item.value && item.value.length>0? item.value.replaceAll("$", "$$$$"): ""));
                  }
                }
              }
            }
          });
          this.getSectionsMemberNames();
        }).catch((errors) => {
          console.log(errors)
        });
      },
      resetColors() {
        for (var i = 0; i < this.sections.length; i++) {
          this.$set(this.sections[i],'variant',"grey-section-outline");
          if (this.sections[i].slides) {
            for (var j = 0; j < this.sections[i].slides.length; j++) {
               this.$set(this.sections[i].slides[j],'variant',"grey-slide-outline");
            }
          }
        }
        
        for (i = 0; i < this.selected_members.length; i++) {
          this.selected_members[i].variant = "grey-slide-outline";
        }
        for (i = 0; i < this.members.length; i++) {
          this.members[i].variant = "grey-slide-outline";
        }
        for (i = 0; i < this.contacts.length; i++) {
          this.contacts[i].variant = "grey-slide-outline";
        }
        for (i = 0; i < this.topics.length; i++) {
          this.topics[i].variant = "grey-slide-outline";
        }
        for (i = 0; i < this.attendees.length; i++) {
          this.attendees[i].variant = "grey-slide-outline";
        }
        for (i = 0; i < this.goals.length; i++) {
          this.goals[i].variant = "grey-slide-outline";
        }
        for (i = 0; i < this.performancePeriods.length; i++) {
          this.performancePeriods[i].variant = "grey-slide-outline";
        }

        for (i = 0; i < this.roleMembers.length; i++) {
          this.roleMembers[i].variant = "grey-slide-outline";
        }
        for (i = 0; i < this.events.region_events.length; i++) {
          this.events.region_events[i].variant = "grey-slide-outline";
        }
        for (i = 0; i < this.events.chapter_events.length; i++) {
          this.events.chapter_events[i].variant = "grey-slide-outline";
        }
        for (i = 0; i < this.networkLeaders.length; i++) {
          this.networkLeaders[i].variant = "grey-slide-outline";
        }
        for (i = 0; i < this.renewingMembers.length; i++) {
          this.renewingMembers[i].variant = "grey-slide-outline";
        }
        for (i = 0; i < this.speakers.length; i++) {
          this.speakers[i].variant = "grey-slide-outline";
        }
        for (i = 0; i < this.quotes.length; i++) {
          this.quotes[i].variant = "grey-slide-outline";
        }
      },
      insertVariables(section_script, section_notes) {
        var self = this;
        this.variable_map.forEach(function (item) {
          if(item.id == "{Current_Time}"){
            item.value = self.$d(new Date(), 'time12') ;
          }else if(item.id == "{Current_Date}"){
            item.value = self.$d(new Date(), 'short') ;
          }else if(item.id == "{Slide_Name}"){
            item.value = self.scripts.slide_name;
          }else if(item.id == "{Section_Topic}"){
            item.value = self.current_section.topic;
          }
          var reg = new RegExp('(<span data-type="mention" class="mention" data-id="'+item.id+'.*?<\/span>)', 'g');
          if(section_script){
            section_script = section_script.replaceAll(reg, item.value? (item.noHighlight? item.value.replaceAll("$", "$$$$") : ("<b class='script-variable'>"+(item.value.length>0? item.value.replaceAll("$", "$$$$"): "")+"</b>")) : "");
          }
          if(section_notes){
            section_notes = section_notes.replaceAll(reg, item.value? (item.noHighlight? item.value.replaceAll("$", "$$$$") : ("<b class='script-variable'>"+(item.value.length>0? item.value.replaceAll("$", "$$$$"): "")+"</b>")) : "");
          }
          /*if (self.scripts.slide && self.scripts.slide.indexOf(item.id) >= 0) {
            self.scripts.slide = self.scripts.slide.split(item.id).join( item.noHighlight? item.value : ("<b class='script-variable'>" +item.value +"</b>"));
          }
          if (self.scripts.slide_notes && self.scripts.slide_notes.indexOf(item.id) >= 0) {
            self.scripts.slide_notes = self.scripts.slide_notes.split(item.id).join( item.noHighlight? item.value : ("<b class='script-variable'>" +item.value +"</b>"));
          }*/
          
        });
        self.scripts.section = section_script? section_script : "";
        self.scripts.slide = section_notes? section_notes : "";
      },
      populateVariables(response) {
        this.variable_map = response.data.variable_map;
      },
      goToSection(section_id,slide_id, member_id, next_id, other_id) {
        if(this.currentFile){
          this.currentFile.started = false;
          this.currentFile = null;
        }
        this.pslide = 0;
        this.$socket.emit("goto", { id_string: this.id_string, section_id: section_id, slide_id: slide_id, member_id: member_id, externalPresentation: false, next_id: next_id, other_id: other_id? other_id : 0});
      },
      startExternalPresentation(s) {
        this.pslide = 1;
        this.currentFile = s;
        s.started = 1;
        this.startPresentationTimer(s);
        this.pdfHash = s.hash;
        this.$socket.emit("goto", { id_string: this.id_string, section_id: 0, slide_id: 0, member_id: 0, externalPresentation: true , link: s.url, hash: s.hash,type: s.type });
        /*this.$bvToast.toast(this.$t('controller.toast_message'), {
          title: this.$t('controller.toast_title'),
          variant: 'warning',
          solid: true
        });*/
      },
      goToPresentationSlide(){
        this.$socket.emit("goto", { id_string: this.id_string, section_id: 0, slide_id: 0, member_id: 0, externalPresentation: true, presentation_slide: this.pslide });
      },
      toggleSpeakerTimer(file){
         if(file.interval != undefined && file.interval != null){
            clearInterval(file.interval);
            file.interval = null;
          }else{
            file.end_time = (new Date()).getTime() / 1000 + file.time;
            file.interval = setInterval(() => {
                var secs = file.end_time - (new Date()).getTime() / 1000;
                if (secs > 0) {
                  file.time = secs;
                } else {
                  clearInterval(file.interval);
                  file.interval = null;
                  file.time = 0;
                }
              }, 50);
          }
       
      },
      stopSpeakerTimer(file){
        if(file.interval != undefined && file.interval != null){
            clearInterval(file.interval);
            file.interval = null;
          }
      },
      startSpeakerTimer(file){
        if(file.interval != undefined && file.interval != null){
          clearInterval(file.interval);
          file.interval = null;
        }
        file.interval = setInterval(() => {
            var secs = file.end_time - (new Date()).getTime() / 1000;
            if (secs > 0) {
              file.time = secs;
            } else {
              clearInterval(file.interval);
              file.interval = null;
              file.time = 0;
            }
          }, 50);
      },
      startPresentationTimer(file,speaker){
        if(this.currentFileParent!=null && this.currentFileParent.id != file.id && this.currentFileParent.interval != undefined && this.currentFileParent.interval != null){
          clearInterval(this.currentFileParent.interval);
          this.currentFileParent.interval = null;
        }
        if(!speaker){//this.current_slide==null || 
          if(this.currentFileParent==null || this.currentFileParent.id != file.id){
            if(file.interval != undefined && file.interval != null){
              clearInterval(file.interval);
              file.interval = null;
            }
            file.end_time = (new Date()).getTime() / 1000 + file.time;
              file.interval = setInterval(() => {
                  var secs = file.end_time - (new Date()).getTime() / 1000;
                  if (secs > 0) {
                    file.time = secs;
                  } else {
                    clearInterval(file.interval);
                    file.interval = null;
                    file.time = 0;
                  }
                }, 50);
            }
        }else if(speaker && (file.interval == undefined || file.interval == null)){// && (this.currentFileParent==null || this.currentFileParent.id != file.id)
          if(file.interval != undefined && file.interval != null){
              clearInterval(file.interval);
              file.interval = null;
            }
            this.$socket.emit("stop_timer", { id_string: this.id_string});
        }
        this.currentFileParent = file;
      },
      startFile(file, parent, speaker) {
        this.pslide = 1;
        this.currentFile = file;
       
        file.started = true;
        if(parent.time == undefined || parent != this.currentFileParent){
          parent.time = file.time;
        }
        this.startPresentationTimer(parent, speaker);
        this.pdfHash = file.hash;
        var time = 0;
        if(this.currentFileParent != null){
          time = this.currentFileParent.time;
        }
        this.$socket.emit("goto", { id_string: this.id_string, section_id: 0, slide_id: 0, member_id: 0, externalPresentation: true, link: file.link, hash: file.hash, type: file.type, time: time });
      },
      stopTimer() {
        this.$socket.emit("stop_timer", { id_string: this.id_string});
      },
      showTimer(){
        this.$socket.emit("show_timer", { id_string: this.id_string});
      },
      getAttendees() {
        this.$http.post("/api/organization/attendance/get/attendees", {}).then((response) => {
          if (response.data.success) {
            if(response.data.attendees.length != this.select_attendees.length){
               for (var i = 0; i < response.data.attendees.length; i++) {
                response.data.attendees[i].variant = "grey-slide-outline";
                response.data.attendees[i].present = 1;
              }
              this.random_attendees = response.data.attendees.filter(function () {
                return true;
              });
            
              this.shuffleArray(this.random_attendees);
              this.attendees = response.data.attendees.filter(function () {
                return true;
              });
            }

            if(this.current_slide && this.current_slide.selection==3){
              this.select_attendees = this.random_attendees.filter(function () {
                return true;
              });
            }else{
              this.attendees.sort((a, b) => (a.attendee_name.toLocaleLowerCase()> b.attendee_name.toLocaleLowerCase()) ? 1 : -1);
              this.select_attendees = this.attendees.filter(function () {
                  return true;
                });
              
            }

            if(this.select_attendees.length==1 && !this.isCorporateConnections){
              this.attendeeSelectClick(this.select_attendees[0]);
            }else{
              this.showAttendeeSelect = true;
            }
          }
        }).catch(() => { });
      },
      getAllMemberRoles(){
        this.roleMap = {};
        this.$http.post("/api/organization/roles/members", {}).then((response) => {
          if (response.data.success) {
            for(var role of response.data.roles){
              this.roleMap[role.id] = role.members;
            }
          }
        }).catch(() => { });
      },
      getRoleMembers(role_id){
        this.roleMembers = this.roleMap[role_id] ? this.roleMap[role_id] : [];
        for (var i = 0; i < this.roleMembers.length; i++) {
          this.roleMembers[i].variant = "grey-slide-outline";
        }
        if(this.roleMembers.length==0){
          this.memberRoleSelectClick({id: 0});
        }else if(this.roleMembers.length == 1){
          this.memberRoleSelectClick(this.roleMembers[0]);
        }else{
          this.showMemberRoleSelect = true;
        }
        /*this.$http.post("/api/organization/members/byrole", {role_id: role_id}).then((response) => {
          if (response.data.success) {
          }
        }).catch(() => { });*/
      },
      getGoals(){
        this.$http.post("/api/goals/select", {}).then((response) => {
            if(response.data.success){
              for (var i = 0; i < response.data.goals.length; i++) {
              response.data.goals[i].variant = "grey-slide-outline";
            }
              this.goals = response.data.goals;
            }else{
              this.goals = [];
            }
          }).catch((errors) => {console.log(errors);
          });
      },
      getNextPeriods(){
        if(this.period_day != this.day){
          var d = new Date("" + this.period_day.split("-").join("/") + " 00:01");
          d.setDate(d.getDate() + 7);
          this.period_day= d.toISOString().substr(0,10);
          this.getPeriods();
        }
        
      },
      getPreviousPeriods(){
         var d = new Date("" + this.period_day.split("-").join("/") + " 00:01");
        d.setDate(d.getDate() - 7);
        this.period_day= d.toISOString().substr(0,10);
        this.getPeriods();
      },
      getPeriods(){
        this.$http.post("/api/organization/reports/get", {org: this.permissions.organization.id, day:this.period_day}).then((response) => {
            if(response.data.success){
              for (var i = 0; i < response.data.reports.length; i++) {
                response.data.reports[i].variant = "grey-slide-outline";
              }
              this.performancePeriods = response.data.reports;
            }else{
              this.performancePeriods = [];
            }
          }).catch((errors) => {console.log(errors);
          });
      },
      getEvents(){
        this.$http.post("/api/organization/events/all", {}).then((response) => {
            if(response.data.success){
              for (var i = 0; i < response.data.events.chapter_events.length; i++) {
                response.data.events.chapter_events[i].variant = "grey-slide-outline";
              }
              for (i = 0; i < response.data.events.region_events.length; i++) {
                response.data.events.region_events[i].variant = "grey-slide-outline";
              }
              this.events = response.data.events;
            }else{
              this.events = [];
            }
          }).catch((errors) => {console.log(errors);
          });
      },
      getRecognitions(callback){
          this.$http.post("/api/organization/recognition", {}).then((response) => {
              if (response.data.success) {
                for(var i=0; i<response.data.renewing_members.length; i++){
                  response.data.renewing_members[i].variant = "grey-slide-outline";
                }
                for(i=0; i<response.data.network_leaders.length; i++){
                  response.data.network_leaders[i].variant = "grey-slide-outline";
                }
                this.renewingMembers= response.data.renewing_members;
                this.networkLeaders = response.data.network_leaders;
                callback();
              } else {
                this.renewingMembers = [];
                this.networkLeaders = [];
              }
            }).catch((errors) => {console.log(errors);});
      },
      getContacts() {
        this.$http.post("/api/attendance/contacts/assigned", {}).then((response) => {
          if (response.data.success) {
            if(response.data.contacts.length != this.select_contacts.length){
               for (var i = 0; i < response.data.contacts.length; i++) {
                response.data.contacts[i].variant = "grey-slide-outline";
                response.data.contacts[i].present = 1;
                response.data.contacts[i].next_name = response.data.contacts[i].name;
                response.data.contacts[i].name = response.data.contacts[i].name+ (response.data.contacts[i].contact_name && response.data.contacts[i].contact_name.length>0?" ("+response.data.contacts[i].contact_name+")" : "");
              }
              this.random_contacts = response.data.contacts.filter(function () {
                return true;
              });
            
              this.shuffleArray(this.random_contacts);
              this.contacts = response.data.contacts.filter(function () {
                return true;
              });
            }

            if(this.current_slide && this.current_slide.selection==3){
              this.select_contacts = this.random_contacts.filter(function () {
                return true;
              });
            }else{
              
              this.contacts.sort((a, b) => (a.name.toLocaleLowerCase()> b.name.toLocaleLowerCase()) ? 1 : -1);
              this.select_contacts = this.contacts.filter(function () {
                  return true;
                });
            }   
            if(this.current_slide && this.current_slide.type==18){
              if(this.select_contacts.length==1){
                this.contactSelectClick(this.select_contacts[0]);
              }else{
                this.showContactSelect = true;
              }
            }
          }
        }).catch((errors) => {console.log(errors);});
      },
      getMembers() {
        this.$http.post("/api/organization/members", {}).then((response) => {
          for (var i = 0; i < response.data.members.length; i++) {
            response.data.members[i].variant = "grey-slide-outline";
          }
          this.members2 = response.data.members.slice();
          this.members2.push({ id: 0, name: this.$t('controller.none_option') });
          this.members = response.data.members;
          
         
          this.$http.post("/api/organization/contacts/attending", {}).then((response) => {
             if(response.data.success){
                Array.prototype.push.apply(this.members,response.data.contacts);
              }
              this.createMemberLists();
           }).catch(() => {  this.createMemberLists();});
        }).catch(() => { });
      },
      getStartsWithMembers(){
        this.startMembers = this.members.slice();
        this.$http.post("/api/organization/newsletter/get/best_weekly", {}).then((response) => {
             if(response.data.success && response.data.best_weekly>0){
                this.startMembers.unshift({id: response.data.best_weekly, name: this.$t('controller.best_weekly')})
              }
              this.startMembers.unshift({id: 0, name: this.$t('controller.any_member')})
           }).catch(() => {  this.startMembers.unshift({id: 0, name: this.$t('controller.any_member')})});
      },
      createMemberLists(){
          var letters = [];
          for (var i = 0; i < this.members.length; i++) {
            this.$set(this.members[i], 'present', 1);
            if (this.members[i].name.length > 0) {
              letters.push(this.members[i].name[0]);
            }
          }

        this.random_members = this.members.filter(function (item) {
          return item.disabled == 0;
        });
        
        this.shuffleArray(this.random_members);
        
        this.selected_members = this.members.filter(function (item) {
          return item.disabled == 0;
        });
        
        var sl = Array.from(new Set(letters));
        this.member_letters = [];
        this.member_letters.push({ letter: this.$t('controller.all_letter'), variant: "grey-slide" });
        for (var j = 0; j < sl.length; j++) {
          this.member_letters.push({ letter: sl[j], variant: "grey-slide-outline" });
        }

        this.last_letter = this.member_letters[0];
        this.getDates();
      },
      guideClick() {
        //this.attendanceVariant = "outline-controller";
        //this.scriptVariant = 'outline-controller';
        //this.notesVariant = 'outline-controller';
        //this.agendaVariant = 'controller';
        this.showtype = this.showtype == 1? 2 : 1;
      },
      notesClick() {
        this.attendanceVariant = "outline-controller";
        this.scriptVariant = 'outline-controller';
        this.notesVariant = 'controller';
        this.agendaVariant = 'outline-controller';
        this.showtype = 3;
      },
      scriptClick() {
        this.setScripts(this.current_section, this.current_slide);
        this.showScript();
      },
      showScript() {
        this.attendanceVariant = "outline-controller";
        this.scriptVariant = 'controller';
        this.notesVariant = 'outline-controller';
        this.agendaVariant = 'outline-controller';
        this.showtype = 2;
      },
      attendanceClick() {
        //this.attendanceVariant = "controller";
        //this.scriptVariant = 'outline-controller';
        //this.notesVariant = 'outline-controller';
        //this.agendaVariant = 'outline-controller';
        //this.showtype = this.showtype===5? 1 : 5;
        router.push("/controller/attendance");
      },
      executeFullscreenAction(){
        this.showFullScreenConfirm = false;
        if(this.fullscreenAction != null){
          this.fullscreenAction();
          this.fullscreenAction = null;
        }
      },
      showSpeakerBio(slide) {
        this.current_speaker.name = slide.speaker.name;
        this.current_speaker.bio = slide.speaker.bio;
        this.showBioModal = true;
      },
     

      onResize() {
        if (document.getElementById("choiceDiv2")) {
          this.windowWidth = document.getElementById("choiceDiv2").parentElement.offsetWidth;
          this.windowHeight = document.getElementById("choiceDiv2").parentElement.offsetHeight;
        }
      },
      
      logout() {
        /*this.$http.get("/api/logout")
          .then((response) => {
            router.push("/login");
          })
          .catch((errors) => {
            router.push("/login");
          });*/
          window.close();
      },
      incSlideFont() {
        if(this.slideFontSize<26){
          this.slideFontSize+=4;
        }
      },
      decSlideFont() {
        if(this.slideFontSize>10){
          this.slideFontSize-=4;
        }
      },
      incSectionFont() {
        if(this.sectionFontSize<26){
          this.sectionFontSize+=4;
        }
        if(this.slideFontSize<26){
          this.slideFontSize+=4;
        }
      },
      decSectionFont() {
        if(this.sectionFontSize>10){
          this.sectionFontSize-=4;
        }
        if(this.slideFontSize>10){
          this.slideFontSize-=4;
        }
      },
      setFont(n){
        this.sectionFontSize=n*4+6;
        this.slideFontSize=n*4+6;
      },
      incBioFont() {
        if(this.bioFontSize<26){
          this.bioFontSize+=4;
        }
      },
      decBioFont() {
        if(this.bioFontSize>10){
          this.bioFontSize-=4;
        }
      },
      getAttendance(date) {
        this.day = date;
        this.period_day = date;
        this.current_date = new Date("" + this.day.split("-").join("/") + " 00:01");
        this.org = this.$route.params.org ? this.$route.params.org : this.permissions.organization.id;
        this.visitorsubs = [];
        this.$http.post("/api/organization/attendance/day", { date: this.day, org: this.org, displayAll: true }).then((response) => {
          
          var tempmembers = [];
          if (response.data.members) {
            tempmembers = response.data.members;
            for (var i = 0; i < tempmembers.length; i++) {
              var t = tempmembers[i].type;
              tempmembers[i].type_text = (t == 2 ? this.$t('controller.late_text') : this.$t('controller.absent_text'));
            }
          }
          this.attendance_members = tempmembers;
          this.attendance_members.sort((a, b) => (a.name.toLocaleLowerCase()> b.name.toLocaleLowerCase()) ? 1 : -1);
          if (response.data.visitorsubs) {
            this.visitorsubs = response.data.visitorsubs;
            this.visitorsubs.sort((a, b) => (a.name.toLocaleLowerCase()> b.name.toLocaleLowerCase()) ? 1 : -1);
          }
          for(var k=0; k<this.selected_members.length;k++){
            this.selected_members[k].disable_present = 0;
            for(var j=0;j<this.attendance_members.length;j++){
              if(this.selected_members[k].id == this.attendance_members[j].member_id && this.attendance_members[j].type == -1){
                this.selected_members[k].present = 0;
                //this.selected_members[k].disable_present = 1;
                break;
              }
            }
          }
          
          this.calcMemberIndexes(this.selected_members[this.currentMemberIndex]);
          
        }).catch((errors) => {console.log(errors);});
      },
      getDates() {
        this.$http.post("/api/organization/get/nextmeeting", {}).then((response) => {
          if (response.data.success === true) {
            this.today = new Date(response.data.day.split("-").join("/")+' 00:00:01');
            this.getAttendance(response.data.day);
          }
        }).catch(() => { });
      }
    },
    created() {
      if(!this.test){
        document.title = this.$t('global.document_title_control') +" "+ this.permissions.organization.name;
      }
      window.addEventListener("resize", this.onResize);
    },
    destroyed() {
      this.$socket.emit("unregister_controller", { id_string: this.id_string});
      window.removeEventListener("resize", this.onResize);
      if (this.checkInterval) {
        clearInterval(this.checkInterval);
        this.checkInterval = null;
      }
    },
    mounted() {
      if(!this.isCorporateConnections){
        (function() {
          var link = document.querySelector("link[rel~='icon']");
          if (!link) {
              link = document.createElement('link');
              link.rel = 'icon';
              document.getElementsByTagName('head')[0].appendChild(link);
          }
          link.href = favicon;
      })();
      }
     
      var self = this;
      window.addEventListener("focus", function(event) {
        self.authenticate();
      }, false);
      
      this.auth();
      this.windowWidth = document.getElementById("choiceDiv2").parentElement.offsetWidth;
      this.windowHeight = document.getElementById("choiceDiv2").parentElement.offsetHeight;
      this.getAllMemberRoles();
      this.getMembers();
      if(!this.test){
        this.getLanguages();
      }
      this.getScrollToMember();
      this.$root.$on('paneSizeChange', () => {
          self.windowWidth = document.getElementById("choiceDiv2").parentElement.offsetWidth;
          self.windowHeight = document.getElementById("choiceDiv2").parentElement.offsetHeight;
      });
      this.getContacts();
      //self.getNetworkDownloadSpeed();
      //this.internetInterval = setInterval(function () {
      //  self.getNetworkDownloadSpeed();
      //},1000*60);
    }
  }
</script>
