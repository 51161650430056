<template>
  <div>
    <div class="rel-grid-container">
      <div ></div>
      <div >
        <b-table class="table-hover-new clickable" small bordered :items="tips" :fields="fields" v-on:row-clicked="editTip">
          <template v-slot:cell(content)="data">
            <pre class="pre-format" v-html="data.item.content"></pre>
          </template>
         
        </b-table>
        <b-modal id="slide-modal-2" centered v-model="showTipEdit" :title="$t('tips.edit_tip')">
            <div>
              <span class="form-box-title">{{$t('tips.edit_tip')}}</span>
              <div class="form-box" >
                  <span class="form-box-name">{{$t('tips.title_header')}}</span><b-form-input v-model="currentTip.title" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input>
      
               <b-form-checkbox id="tip-disabled-checkbox" v-model="currentTip.disabled" class="form-box-input">
                {{$t('tips.disabled_text')}}
                </b-form-checkbox>
                <base-editor id="tip-editor" v-model="currentTip.content" />
            </div>
            <div :style="{width: currentTip.width+'px'}" class="auto-margin">
                <div id="top-content-wrapper">
                    <div class="pre-format" v-html="currentTip.content"></div>
                </div>
            </div>
            </div>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="updateTip" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
          </div>
        </b-modal>
      </div> 
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
#tip-editor{
  grid-column: 1/4;
}
#top-content-wrapper{
  margin-top: 20px;text-align:left
}
#tip-disabled-checkbox{
  text-align:left;margin-bottom:20px;
}
  
</style>
<script>
    export default {
    name: "OnboardTips",
    watch:{
        '$i18n.locale': { 
        handler: function () {
            this.getTips();
        },
        deep: true
        }
    },
    data() {
      return {
        showTipEdit: false,
        tips: [],
        moving:false,
        currentTip: {},
        currentTipIndex: -1,
        fields: [
            {
              key: 'tag',
              label: this.$t('tips.tag_header'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
            { key: 'title',
              label: this.$t('tips.title_header'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            },
             { key: 'content',
              label: this.$t('tips.content_header'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            },
          ],
      }
    },
    methods: {
        updateTip(){
            this.$http.post("/api/superadmin/tips/update", this.currentTip).then((response) => {
                this.showTipEdit = false;
                if (response.data.success) {
                    this.tips[this.currentTipIndex] = this.currentTip;
                    this.currentTip = {}
                }
            }).catch(() => {
                this.showTipEdit = false;
            });

        },
      getTips() {
        this.$http.post("/api/superadmin/onboardtips", {}).then((response) => {
          if (response.data.success) {
            for(var tip of response.data.tips){
              tip.disabled = tip.disabled==1;
            }
            this.tips = response.data.tips;
          }
        }).catch(() => {
        });
      },
      editTip(item,index) {
          this.currentTip = item;
          this.currentTipIndex = index;
          this.showTipEdit = true;
      }
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-onboardtips"), "active-menu");
      this.removeClass(document.getElementById("menu-admin"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-onboardtips"), "active-menu");
      this.addClass(document.getElementById("menu-admin"), "active-menu");
      this.getTips();
    }
    }
</script>
