// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue, { onMounted } from 'vue';
import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'babel-polyfill';
import App from './App.vue';
import router from './router';
import VueMq from 'vue-mq';
import VueHtml2Canvas from 'vue-html2canvas';
import vueCountryRegionSelect from 'vue-country-region-select';
import axios from 'axios';
import VueCookies from 'vue-cookies';
import Vue2TouchEvents from 'vue2-touch-events';
import mitt from 'mitt'
import {VueMasonryPlugin} from 'vue-masonry';
import VueQRCodeComponent from 'vue-qr-generator'
import browserDetect from "vue-browser-detect-plugin";
import SocketIO from 'socket.io-client'
import VueSocketIO from 'vue-socket.io'
import Vuex from 'vuex'

import "./assets/global.scss";
import Tip from './components/Tip.vue';
import BaseEditor from './components/BaseEditor.vue';
import ImageSelector from './components/ImageSelector.vue';
import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'
import SetupTip from './components/SetupTip.vue';
import TermsAndConditions from './components/TermsAndConditions.vue';
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';

import draggable from "vuedraggable";
draggable.compatConfig = { MODE: 3 };

const emitter = mitt()
Vue.component('qr-code', VueQRCodeComponent)
Vue.use(browserDetect);
Vue.use(VueCookies)
Vue.$cookies.config('1y');

Vue.config.productionTip = true;
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(vueCountryRegionSelect);
Vue.use(VueHtml2Canvas);
Vue.use(Vue2TouchEvents)
Vue.use(VueMq, {breakpoints: {mobile: 768,tablet: 1160, desktop: Infinity}});
Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {},
  plugins: []
})
Vue.use(new VueSocketIO({
  debug: false,
  connection: SocketIO({}),
  vuex: { 
      store,
      actionPrefix: 'SOCKET_',
      mutationPrefix: 'SOCKET_'
  },
}))


var date_formats = {
  short_weekday: {weekday: 'long', month: 'long', day: 'numeric'},
  time12l: {hour12: true, hour: '2-digit', minute: '2-digit' },
  day_text: {year: 'numeric', month: 'long', day: 'numeric'},
  time12: {hour12: true, hour: 'numeric', minute: 'numeric' },
  short: {year: 'numeric', month: 'long', day: 'numeric'},
  day_month_short: {month: 'short', day: 'numeric'},
  day_month_long: {month: 'long', day: 'numeric'},
  month_year: {month: 'long', year: 'numeric'},
  long: {year: 'numeric', month: 'short', day: 'numeric',weekday: 'short', hour: 'numeric', minute: 'numeric'},
  all: {year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'},
  weekday: {weekday: 'short',hour12: true, hour: 'numeric', minute: 'numeric'},
  full_time: { hour: '2-digit', minute: '2-digit',second: '2-digit' }
};

const dateTimeFormats = {
  'en': date_formats,
  'fr': date_formats,
  'de':date_formats,
  'es':date_formats,
  'it':date_formats
};

import messages from './lang/translations/en.json'

if(process.env.VUE_APP_IS_CORPORATE==1){
  messages = JSON.parse(JSON.stringify(messages).replaceAll("BNI+","CC Meeting +"));
}

const i18n = createI18n({
  allowComposition: true,
  locale: 'en',
  fallbackLocale: 'en',
  messages: {'en' : messages},
  datetimeFormats: dateTimeFormats
});

Vue.prototype.$http = {
  post: function(url, data){
    data.user_language = i18n.global.locale != undefined? i18n.global.locale : (Vue.$cookies.isKey("language")? Vue.$cookies.get("language") : "en"); 
    return axios.post(url,data).catch((error) => {
      if(error.message=="Request failed with status code 401" && url.includes("byuseruid")){
        router.push("/unauthorized");
      }
      throw(error);
    });
  },
  get: function(url){
    return axios.get(url);
  }
};
//Vue.prototype.$io = io;

var permissions = { help_url:"", copy_items: [], languageObjectType: 1, lockAspect: true, agenda_back: "", warn_browser: true, showSpeakerGrid: 0, speakerGridOffset: 0, setup_popup:0, regionsFilter: {core_type:-1,name: '', chapter_name: '', subscription_status: -1, member_email: "", country_id:0},
  previousSpeakers: false, previousDates: false, showDisabledSlides: true, filterRoleId: 0,  shownAnnouncements: false, sectionFilterId: 0, getFiles: false,getSlides:true, populateVariables: false, showDisabledCategories: true,
  admin: false,country: false, access:false, organization: {onboard: 0, name: "", id: 0, is_parent: false, official: false, rst: false, parent_name: "" , language_admin: 0}, user: {name: "", id: 0, uid: ''}};

const loadedLanguages = ['en'] 

function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}
if(!Vue.$cookies.isKey("uid")){
  Vue.$cookies.set("uid", uuidv4(),Infinity);
}


Vue.mixin({
  components:{
    Tip,
    SetupTip,
    Header,
    Footer,
    TermsAndConditions,
    BaseEditor,
    ImageSelector,
     Multiselect,
     draggable
  },
  data() {
    return { permissions: permissions, isCorporateConnections: process.env.VUE_APP_IS_CORPORATE==1,emits: [], };
  },
  methods:{
    signout(){
      
      this.$http.get("/api/logout").then(() => {
        //channel.postMessage({signedIn: false});
        permissions = { help_url: "", copy_items: [], languageObjectType: 1, lockAspect: true, agenda_back: "", warn_browser: true, showSpeakerGrid: 0, speakerGridOffset: 0, setup_popup:0, regionsFilter: {core_type:-1,name: '', chapter_name: '', subscription_status: -1, member_email: "", country_id:0},
          previousSpeakers: false, previousDates: false, showDisabledSlides: true, filterRoleId: 0,  shownAnnouncements: false, sectionFilterId: 0, getFiles: false,getSlides:true, populateVariables: false, showDisabledCategories: true,
          admin: false,country: false, access: false, organization: {onboard: 0, name: "", id: 0, is_parent: false, parent_name: "", language_admin: 0 }, user: {name: "", id: 0, uid: ''}};
        router.push("/login");
      }).catch(() => {
        //channel.postMessage({signedIn: false});
        permissions = { help_url: "", copy_items: [], languageObjectType: 1, lockAspect: true, agenda_back: "", warn_browser: true, showSpeakerGrid: 0, speakerGridOffset: 0, setup_popup:0, regionsFilter: {core_type:-1,name: '', chapter_name: '', subscription_status: -1, member_email: "", country_id:0},
          previousSpeakers: false, previousDates: false, showDisabledSlides: true, filterRoleId: 0,  shownAnnouncements: false, sectionFilterId: 0, getFiles: false,getSlides:true, populateVariables: false, showDisabledCategories: true,
          admin: false,country: false, access: false, organization: {onboard: 0, name: "", id: 0, is_parent: false, parent_name: "" , language_admin: 0}, user: {name: "", id: 0, uid: ''}};
        router.push("/login");
      });
    },
    loginAuthenticate(callback){
      this.genericAuthenticate("/api/auth/login","/login", callback);
    },
    authenticate(callback) {
      this.genericAuthenticate("/api/auth","/login", callback);
    },
    genericAuthenticate(url, reroute, callback, failCalback){
      this.$http.get(url).then((response) => {
        if (response.data.authorized != true) {
          if(reroute.length>0){
            router.push(reroute);
          }
          if(failCalback){
            failCalback();
          }
        } else {
          this.setCookie("tips", 0, true);
          this.$set(this.permissions, 'admin', response.data.permissions.admin);
          this.$set(this.permissions, 'country', response.data.permissions.country);
          this.$set(this.permissions, 'organization',  response.data.permissions.organization);
          this.$set(this.permissions, 'access', response.data.permissions.access || response.data.permissions.admin);
          this.$set(this.permissions, 'user',  response.data.permissions.user);
          if(this.$t){
            document.title = this.$t('global.document_title_admin') +" "+ this.permissions.organization.name;
          }
          if(!this.setCookie("language", this.permissions.organization.language, true)){
            this.permissions.organization.language = this.getCookie("language", "en");
          }
          this.loadLanguageAsync(this.permissions.organization.language);
          if(callback){
            callback();
          }
        }
      }).catch((errors) => {
        console.log(errors);
        if(reroute.length>0){
          router.push(reroute);
        }
        if(failCalback){
          failCalback();
        }
      });
    },
    checkFileSize(f){
      if(f.kind === 'file'){
        f = f.getAsFile();
      }
      var max_size = 1024*1024*100;
      if(f.size>=max_size){
        this.$bvToast.toast(this.$t('global.exceeds_maxsize', {max_size: '100MB'}), {
          title: this.$t('global.could_not_upload'),
          variant: 'danger',
          solid: true
        });
      }
      return f.size<max_size;
    },
    setCookie(name, value, init){
      /*var cookiesEnabled = false;
      if(this.$cookies.isKey("cookies_enabled")){
        cookiesEnabled = this.$cookies.get("cookies_enabled");
      }
      if(!cookiesEnabled){
        return false;
      }*/
      if(!init || !this.$cookies.isKey(name)){
        this.$cookies.set(name, value,Infinity);
        return true;
      }
      return false;
    },
    getCookie(name, def){
      var ret = def;
      if(this.$cookies.isKey(name)){
        return this.$cookies.get(name);
      }
      if(ret==undefined){
        ret = "";
      }
      return ret;
    },
    hasCookie(name){
      return this.$cookies.isKey(name);
    },
    setI18nLanguage (lang) {
      i18n.global.locale = lang;
      if(this.permissions.organization.name.length>0 && this.$t){
        document.title = this.$t('global.document_title_admin') +" "+ this.permissions.organization.name;
      }
      this.setCookie("language",lang);
      this.permissions.organization.language = lang;
      axios.defaults.headers.common['Accept-Language'] = lang
      document.querySelector('html').setAttribute('lang', lang)
      return lang
    },
    loadLanguageAsync: function (lang, alert) {
      if (i18n.global.locale === lang) {
        return Promise.resolve(this.setI18nLanguage(lang))
      }
      if (loadedLanguages.includes(lang)) {
        return Promise.resolve(this.setI18nLanguage(lang))
      }
      var file = `./assets/translations/${lang}.json`;
      var self = this;
      axios({
        url: file, //your url
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
      var fr = new FileReader();
      fr.onload = function() {
          var messages = JSON.parse(this.result);
        i18n.global.setLocaleMessage(lang, messages)
        loadedLanguages.push(lang)
        return self.setI18nLanguage(lang)
      };
      fr.onerror = function(){
        if(alert === false){
          self.$bvToast.toast("Language file has not been created", {
            variant: 'danger',
            solid: true
          });
        }
      }
      fr.readAsText(response.data);
    }).catch((errors) => {
      if(alert === false){
        self.$bvToast.toast("Language file has not been created", {
          variant: 'danger',
          solid: true
        });
      console.log(errors);
    }
    });
      
     
    },
    setLanguageIfAvailable(){
        if(this.$cookies.isKey("language")){
          this.loadLanguageAsync(this.getCookie("language", "en"));
        }
    },
    toggleFullScreen() {
      if ((document.fullScreenElement && document.fullScreenElement !== null) ||
        (!document.mozFullScreen && !document.webkitIsFullScreen)) {
        if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    detectIE() {
      var ua = window.navigator.userAgent;
      var msie = ua.indexOf('MSIE ');
      if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
      }
      var trident = ua.indexOf('Trident/');
      if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
      }
      var edge = ua.indexOf('Edge/');
      if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
      }
      // other browser
      return -1;
    },
    getImgUrl(pic) {
      return require('@/assets/'+pic);
    },
    hasClass(el, className){
        if (el.classList)
            return el.classList.contains(className);
        return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
    },
    addClass(el, className){
      if(el){
        if (el.classList)
            el.classList.add(className)
        else if (!this.hasClass(el, className))
            el.className += " " + className;
      }
        
    },
    removeClass(el, className){
      if(el){
        if (el.classList)
            el.classList.remove(className)
        else if (this.hasClass(el, className))
        {
            var reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
            el.className = el.className.replace(reg, ' ');
        }
      }
    },
    setMenu(top, sub){
      this.addClass(document.getElementById("menu-"+sub), "active-menu");
      this.addClass(document.getElementById("menu-"+top), "active-menu");
    },
    unsetMenu(top, sub){
      this.removeClass(document.getElementById("menu-"+sub), "active-menu");
      this.removeClass(document.getElementById("menu-"+top), "active-menu");
    }
  },
  mounted(){
  }
});

const app = createApp({
  components: { App },
  render: h => h(App),
})
app.config.globalProperties.emitter = emitter
app.use(VueMasonryPlugin)
app.use(router);
app.use(i18n);
app.mount('#app')


