<template>
    <div class="rel-grid-container valign-grid">
      <div ></div>
      <div>
          <Tip :tipNumber="20"></Tip>
          <b-tabs v-model="tabIndex" content-class="mt-3" justified>
            <b-tab active :title-link-class="linkClass(0)">
                <template #title>
                    <img :src="getImgUrl('nav-Roles-Gray.png')"><span>{{$t('yearroles.by_role')}}</span>
                </template>
                <b-table small bordered :items="roles" :fields="fields">
                    <template v-slot:cell(name)="data">
                        <div :class="{'bolded-text': data.item.names!= null && data.item.names.length>0}">
                            {{data.item.name}}
                        </div>
                    </template>
                    <template v-slot:cell(names)="data">
                        <div v-for="m in data.item.members" :key="m" :style="getMemberStyle(m.name)">
                            {{m.name}}
                        </div>
                    </template>
                    <template v-slot:cell(next_year)="data">
                        <multiselect v-model="data.item.next_year" :options="members_select" :object="true" :placeholder="$t('global.select_members')" label="name" track-by="name" @input="onRoleChange(data.item)" class="blueselect" :noOptionsText="$t('global.empty_list')"  mode="tags" valueProp="id" :close-on-select="false" :searchable="true">
                        </multiselect>
                    </template>
                </b-table>
                <div class="member-note"><i>{{$t('yearroles.members_italicized')}}</i></div>
            </b-tab>
            <b-tab :title-link-class="linkClass(1)">
                <template #title>
                    <img :src="getImgUrl('nav-Members-Gray.png')"><span>{{$t('yearroles.by_member')}}</span>
                </template>
                <b-table small bordered :items="members" :fields="fields">
                    <template v-slot:cell(name)="data">
                        <div :style="getMemberStyle(data.item.name)" class="bolded-text">
                            {{data.item.name}}
                        </div>
                    </template>
                    <template v-slot:cell(names)="data">
                        <div v-for="m in data.item.names" :key="m" >
                            {{m}}
                        </div>
                    </template>
                    <template v-slot:cell(next_year)="data" >
                        <multiselect v-model="data.item.next_year" :options="roles_select" :object="true" :placeholder="$t('global.select_roles')" label="name" track-by="name"  @input="onMemberChange(data.item)" class="blueselect" :noOptionsText="$t('global.empty_list')"  mode="tags" valueProp="id" :close-on-select="false" :searchable="true">
                        </multiselect>
                    </template>
                </b-table>
                <div class="member-note"><i>{{$t('yearroles.members_italicized')}}</i></div>
            </b-tab>
        </b-tabs>
        <br/>
        <div v-if="showAlert">
          <b-alert variant="danger"
                   dismissible
                   :show="showAlert"
                   @dismissed="showAlert=false">
            <span v-html="alertText"></span>
          </b-alert>
        </div>
        <div v-if="showWarning">
          <b-alert variant="warning"
                   dismissible
                   :show="showWarning"
                   @dismissed="showWarning=false">
            <span v-html="warningText"></span>
          </b-alert>
        </div>
        <div slot="modal-footer" class="submit-box-2">
           
            <b-button class="action-button" v-on:click="back" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" v-on:click="updateYearRoles" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
             <b-button class="action-button" v-if="transition_time==1" v-on:click="showRunTransition=true;" variant="clear-blue"><div class="save-button"></div><span>{{$t('yearroles.complete_transition')}}</span></b-button>
          </div>
          <b-modal id="modal-center" v-model="showRunTransition" centered :title="$t('yearroles.run_transition_title')">
          <div v-html="$t('yearroles.run_transition_text', {trans_year: this.next_year})">
          </div>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="showRunTransition=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" v-on:click="runTransition" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_yes')}}</span></b-button>
          </div>
        </b-modal>
      </div>
      <div class="div-space"></div>
    </div>
</template>

<style lang="scss">
.member-note{
    text-align:center;width:100%;
}
.tabClass{
    text-align: left;
    color: #495057 !important;
        color: #495057 !important;
        border: 1px solid grey !important;
    border-radius: 0px !important;
}
.tabClass > span{
    margin-left: 5px;
}
    .tabClassActive{
        background-color: $VUE_APP_TABLE_HOVER_COLOUR !important;
        text-align: left;
    color: #495057 !important;
        border: 1px solid grey !important;
    border-radius: 0px !important;
    }
    .tabClassActive > img{
        filter: $VUE_APP_COLOUR_MAIN_FILTER;
    }
    .tabClassActive > span{
        font-weight: bold;
        margin-left: 5px;
    }
    .bolded-text{
        font-weight: bold;
    }
</style>
<script>

import router from "../router"
    export default {
    name: "YearRoles",
   
    data() {
      return {
        
          tabIndex: 0,
          fields: [
            { key: 'name',
              label: this.$t('yearroles.role_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff","width": "24%"},
            },
             { key: 'names',
              label: "",
              sortable: false,
              thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff", "width": "38%"},
            },
             { key: 'next_year',
              label:"",
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff", "width": "38%"},
            }
          ],
          members: [],
          members_select: [],
          roles: [],
          roles_select: [],
          showAlert: false,
          alertText: "",
          showWarning: false,
          warningText: "",
          no_role_members: [],
          showRunTransition: false,
          next_year: "",
          transition_time: 0
      }
    },
    methods: {
        getMemberStyle(m){
            m = m.split("(")[0].trim();
            for(var k=0; k<this.members.length;k++){
                if(this.members[k].name == m && this.members[k].next_year.length==0){
                    return {'font-style': 'italic'};
                }
            }
        },
        onRoleChange(v){
            this.showWarning = false;
            var self = this;
            this.$nextTick(function(){  
                for(var k=0; k<self.members.length;k++){
                    if(v.next_year.filter(g => g.id ==self.members[k].id).length>0){
                        if(self.members[k].next_year.filter(t => t.id == v.id).length==0){
                            self.members[k].next_year.push({id: v.id, name: v.name});
                        }   
                    }else{
                        
                        self.members[k].next_year = self.members[k].next_year.filter(t => t.id != v.id);
                    }
                }
            });
        },
        onMemberChange(v){
            this.showWarning = false;
            var self = this;
            this.$nextTick(function(){
                for(var k=0; k<self.roles.length;k++){
                    if(v.next_year.filter(g => g.id ==self.roles[k].id).length>0){
                        if(self.roles[k].next_year.filter(t => t.id == v.id).length==0){
                            self.roles[k].next_year.push({id: v.id, name: v.name});
                        }
                    }else{
                        self.roles[k].next_year = self.roles[k].next_year.filter(t => t.id != v.id);
                    }
                }
            });
        },
        linkClass(idx) {
        if (this.tabIndex === idx) {
            this.fields[0].label = idx == 0? this.$t('yearroles.role_header') : this.$t('yearroles.member_header');
          return ['tabClassActive']
        } else {
          return ['bg-light','tabClass']
        }
      },
        rowClass(item) {
        if (!item) return
        if (item.names.length>0){
            return 'bolded-text'
        } 
        },
        runTransition(){
            this.showRunTransition = false;
            this.showWarning = false;
            this.showAlert = false;
            var yearroles = new Set();
            var x = "";
            for(var i=0;i<this.roles.length;i++){
                for(var j=0;j<this.roles[i].next_year.length;j++){
                    x = JSON.stringify([this.roles[i].next_year[j].id,this.roles[i].id]);
                    yearroles.add(x);
                }
            }
            for(i=0;i<this.members.length;i++){
                for(j=0;j<this.members[i].next_year.length;j++){
                     x = JSON.stringify([this.members[i].id, this.members[i].next_year[j].id]);
                    yearroles.add(x);
                }
            }
            
            var yearrolesArray = Array.from(yearroles).map(v => JSON.parse(v));
            this.$http.post("/api/organization/yearroles/update", {yearroles : yearrolesArray}).then(response => {
                if (response.data.success) {
                    this.$http.post( "/api/organization/yearroles/run", {}).then(response => {
                        if(response.data.success){
                            this.back();
                        }
                    });
                }
            }).catch(() => {});
            
        },
        getYearRoles() {
            this.$http.post("/api/organization/yearroles", {}).then(response => {
                if (response.data.success) {
                    for(var i=0;i<response.data.yearroles.length;i++){
                        for(var j=0;j<this.roles.length;j++){
                            if(this.roles[j].id == response.data.yearroles[i].role_id){
                                this.roles[j].next_year.push({id: response.data.yearroles[i].member_id, name : response.data.yearroles[i].member_name});
                                break;
                            }
                        }
                        for(var k=0;k<this.members.length;k++){
                            if(this.members[k].id == response.data.yearroles[i].member_id){
                                this.members[k].next_year.push({id: response.data.yearroles[i].role_id, name : response.data.yearroles[i].role_name});
                                break;
                            }
                        }
                    }
                    this.fields[1].label = response.data.current_year;
                    this.fields[2].label = response.data.next_year;
                    this.next_year = response.data.next_year;
                    this.transition_time = response.data.transition_time;
                }
                this.loading = false;
            }).catch(() => {});
        },
        updateYearRoles(){
            this.showWarning = false;
            this.showAlert = false;
            var yearroles = new Set();
            var x = "";
            for(var i=0;i<this.roles.length;i++){
                for(var j=0;j<this.roles[i].next_year.length;j++){
                    x = JSON.stringify([this.roles[i].next_year[j].id,this.roles[i].id]);
                    yearroles.add(x);
                }
            }
            for(i=0;i<this.members.length;i++){
                for(j=0;j<this.members[i].next_year.length;j++){
                     x = JSON.stringify([this.members[i].id, this.members[i].next_year[j].id]);
                    yearroles.add(x);
                }
            }
            
            var yearrolesArray = Array.from(yearroles).map(v => JSON.parse(v));
            this.$http.post("/api/organization/yearroles/update", {yearroles : yearrolesArray}).then(response => {
                if (response.data.success) {
                    this.back();
                }
            }).catch(() => {});
        },
          getRoles() {
        this.$http.post("/api/organization/roles/members/transition", {})
            .then(response => {
            if (response.data.success) {
                for(var i=0;i<response.data.roles.length;i++){
                    response.data.roles[i].next_year = [];
                }
                this.roles = response.data.roles;
                this.roles_select = response.data.roles_select;
                this.no_role_members = response.data.no_role_members;
                
            }
            this.getMembers();
            
            })
            .catch(() => {});
        },
        getMembers() {
        this.$http.post("/api/organization/members/roles/select", {})
            .then(response => {
            if (response.data.success) {
                 for(var i=0;i<response.data.members.length;i++){
                    response.data.members[i].next_year = [];
                }
                this.members = response.data.members;
                this.members_select = response.data.members_select;
                
            }
            this.getYearRoles();
            })
            .catch(() => {});
        },
        back() {
        router.push("/admin/dashboard");
      },
    },
    destroyed() {
      
    },
    mounted() {
        this.loading = true;
        this.getRoles();
    }
}
</script>
