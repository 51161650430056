<template>
  <div>
    <div class="rel-grid-container">
      <div ></div>
        <span class="form-box-title">{{$t('super.source_slide')}}</span>
        <div id="slide-copy-form-box" class="form-box auto-margin grid-fitfirst-2column">
          <span  class="form-box-name">{{$t('super.region_text')}}</span><b-form-select v-model="slideCopy.org_id" value-field="id" text-field="name" :options="regions" class="mb-3" v-on:change="getSections"/>
          <span v-if="slideCopy.org_id>0" class="form-box-name">{{$t('super.section_text')}}</span><b-form-select v-if="slideCopy.org_id>0" v-model="slideCopy.section_id" value-field="id" text-field="name" :options="sections" class="mb-3" v-on:change="getSlides" />
          <span v-if="slideCopy.section_id>0" class="form-box-name">{{$t('super.slide_text')}}</span><b-form-select v-model="slideCopy.slide_id" v-if="slideCopy.section_id>0" value-field="id" text-field="name" html-field="name" :options="slides" class="mb-3" v-on:change="getCopyRegions" />
          <div class="grid-split-3column  second-column">
            <b-button v-on:click="selectAll" class="action-button auto-margin" v-if="slideCopy.slide_id>0" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.select_all')}}</span></b-button>
            <b-button v-on:click="selectNone" class="action-button auto-margin" v-if="slideCopy.slide_id>0" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.select_none')}}</span></b-button>
            <b-button v-on:click="clearSearch" id="clear-button" class="action-button auto-margin " variant="clear-blue"><div class="cancel-button"></div><span>{{$t('super.clear_button')}}</span></b-button>
          </div>
        </div>
        <br/>
        <b-table v-if="slideCopy.slide_id >0" class="table-hover-new" small bordered :items="copyRegions" :fields="fields" >
            <template v-slot:cell(selected)="data">
              <b-form-checkbox :disabled="data.item.section_id == null" v-model="data.item.selected" class="auto-margin">
            </b-form-checkbox>
            </template>
          <template v-slot:cell(section)="data">
              {{data.item.section_id >0? 'Y': 'N'}}
            </template>
             <template v-slot:cell(slide)="data">
              {{data.item.section_id >0? (data.item.slide_id >0? 'Y': 'N'): ''}}
            </template>
          <template v-slot:cell(logAs)="data">
            <b-button v-if="data.item.id>0" class="login-as-chapter" size="sm" @click.stop="loginAsChapter(data.item)" variant="light-blue-outline">
              {{$t('organizations.login_as')}}
            </b-button>
          </template>
        </b-table>
        <b-alert class="alert-line-height" variant="warning" :show="loading">
          {{$t('global.please_wait')}}
          <b-spinner class="alert-spinner" label="Uploading" variant="warning"></b-spinner>
        </b-alert>
        <b-alert id="slide-copy-alert" class="fit-width" :variant="alertStatus" dismissible :show="showAlert" @dismissed="showAlert=false">{{alertText}}</b-alert>
        <div class="submit-box-2">
            <b-button v-if="slideCopy.slide_id>0 && copyRegions.filter(v => v.selected).length>0" :disabled="loading" v-on:click="copySlide" class="action-button" variant="clear-blue"><div class="save-button"></div><span>{{$t('super.copy_slide')}}</span></b-button>
        </div>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
#slide-copy-alert{
  margin: 20px auto;
}
#slide-copy-form-box{
  max-width: 600px;
}
.login-as-chapter{
  position:relative;font-size:16px;
}
#clear-button{
  grid-column: 3;
}
</style>
<script>
import router from "@/router"
    export default {
    name: "SlideCopy",
    data() {
    return {
      loading: false,
        regions: [],
        sections: [],
        slides: [],
        showAlert: false,
        alertText: '',
        alertStatus: 'danger',
        slideCopy: {org_id: 0, section_id: 0, slide_id: 0},
        copyRegions: [],
        fields: [{
              key: 'selected',
              label: this.$t('super.select_header'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff", "text-align": "center"},
              tdClass: "actions-centre"
            },
            { key: 'country_name',
              label: this.$t('super.country_header'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"  }
            },
             { key: 'name',
              label: this.$t('super.region_header'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
            { key: 'section',
              label: this.$t('super.section_header'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" , "text-align": "center"},
              tdClass: "actions-centre"
            },
             { key: 'slide',
              label: this.$t('super.slide_header'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" , "text-align": "center"},
              tdClass: "actions-centre"
            },
            { key: 'logAs',
              label: "",
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" , "text-align": "center"},
              tdClass: "actions-centre"
            },
          ],
    }
    },
    methods: {
      selectAll(){
        for(let region of this.copyRegions){
          region.selected = true;
        }
      },
      selectNone(){
        for(let region of this.copyRegions){
          region.selected = false;
        }
      },
        clearSearch(){
            this.slideCopy.org_id = 0;
            this.sections = [];
            this.slideCopy.section_id = 0;
            this.slides = [];
            this.slideCopy.slide_id = 0;
        },
        loginAsChapter(org){
            this.$http.post("/api/login/tochild", {id: org.id}).then((response) => {
                if(response.data.success){
                this.permissions.access = response.data.permissions.access;
                this.permissions.admin = response.data.permissions.admin;
                this.permissions.organization = response.data.permissions.organization;
                this.permissions.user = response.data.permissions.user;
                document.title = this.$t('global.document_title_admin') +" "+ this.permissions.organization.name;
                router.push("/admin/regiondash");
                this.$root.$emit('userChange', "");
                }
            }).catch((errors) => {console.log(errors);});
        },
        copySlide(){
            this.showAlert = false;
            var cRegions = this.copyRegions.filter(v => v.selected);
            if(this.slideCopy.slide_id>0 && cRegions.length>0){
                this.slideCopy.copyRegions = cRegions;
                this.loading = true;
                this.$http.post("/api/superadmin/slidecopy", this.slideCopy).then((response) => {
                  this.loading = false;
                    if (response.data.success) {
                      this.alertStatus="success";
                      this.alertText = this.$t('super.copy_success', {deleted: response.data.deleted, created: response.data.created});
                      this.getCopyRegions();
                    }else{
                        this.alertStatus="danger";
                        this.alertText = this.$t('super.copy_fail');
                    }
                    this.showAlert = true;
                }).catch(() => {
                  this.loading = false;
                    this.alertStatus="danger";
                    this.alertText = this.$t('super.copy_fail');
                    this.showAlert = true;
                });
            }
        },
        getCopyRegions(){
          this.copyRegions = [];
            if(this.slideCopy.slide_id>0){
                var section_name = this.sections.filter(v => v.id == this.slideCopy.section_id)[0].name;
                var slide_name = this.slides.filter(v => v.id == this.slideCopy.slide_id)[0].name;
                var org_type = this.regions.filter(v => v.id == this.slideCopy.org_id)[0].official;
                this.$http.post("/api/superadmin/slidecopy/regions", {org_id: this.slideCopy.org_id, section_name: section_name, slide_name: slide_name, org_type: org_type}).then((response) => {
                    if (response.data.success) {
                        for(var i=0; i<response.data.regions.length; i++){
                            response.data.regions[i].selected = response.data.regions[i].section_id!=null? true : false;
                        }
                        this.copyRegions= response.data.regions;
                    }
                }).catch(() => {});
            }
            
        },
        getRegions() {
            this.$http.post("/api/superadmin/regions/select/all", {}).then((response) => {
                if (response.data.success) {
                    this.regions = response.data.regions;
                    this.regions.unshift({id: 0, name: this.$t('super.select_region'), official:-1});
                }
            }).catch(() => {});
        },
        getSections() {
            if(this.slideCopy.org_id==0){
                this.sections = [];
                this.slideCopy.section_id = 0;
                this.slides = [];
                this.slideCopy.slide_id = 0;
                return;
            }
            this.$http.post("/api/superadmin/sections/select/all", this.slideCopy).then((response) => {
                if (response.data.success) {
                    this.sections = response.data.sections;
                    this.sections.unshift({id: 0, name: this.$t('super.select_section')});
                }
            }).catch(() => {});
        },
        getSlides() {
            if(this.slideCopy.section_id==0){
                this.slides = [];
                this.slideCopy.slide_id = 0;
                return;
            }
            this.$http.post("/api/superadmin/slides/select/all", this.slideCopy).then((response) => {
                if (response.data.success) {
                    this.slides = response.data.slides;
                    this.slides.unshift({id: 0, name: this.$t('super.select_slide')});
                }
            }).catch(() => {});
        },
        
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-admin"), "active-menu");
      this.removeClass(document.getElementById("menu-copyslide"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-admin"), "active-menu");
      this.addClass(document.getElementById("menu-copyslide"), "active-menu");
      this.getRegions();
    }
    }
</script>
