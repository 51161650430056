<template>
  <div>
    <div class="rel-grid-container">
      <div ></div>
      <form v-on:submit.prevent="updateSettings" class="form-fix">
        <span class="form-box-title">{{$t('general.settings_header')}}</span>
        <div  class="form-box grid-fitfirst-2column">
          <span  class="form-box-name">{{$t('region.default_chapter')}}</span><b-form-select v-model="settings.default_chapter_id" value-field="id" text-field="name" :options="regions.filter(v=> v.official==1 || v.official==-1)" class="mb-3" />
          <span  class="form-box-name">{{$t('region.default_startup')}}</span><b-form-select v-model="settings.default_startup_id" value-field="id" text-field="name" :options="regions.filter(v=> v.official==0 || v.official==-1)" class="mb-3" />
        </div>
        <div v-if="showAlert">
            <b-alert variant="danger" dismissible :show="showAlert" @dismissed="showAlert=false">
              {{alertText}}
            </b-alert>
          </div>
            <div class="submit-box-2">
            <b-button class="action-button" v-on:click="back" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" type="submit" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
        </div>
      </form>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
</style>
<script>
  import router from "@/router"
    export default {
    name: "Settings",
      data() {
        return {
          regions: [],
            showAlert: false,
            alertText: '',
            settings: {default_chapter_id: 0, default_startup_id: 0}
        }
      },
        methods: {
          getSettings() {
            this.$http.post("/api/superadmin/settings/get", {}).then((response) => {
            if (response.data.success) {
                this.settings = response.data.settings;
            }
            }).catch((e) => {console.log(e);});
          },
          updateSettings() {
            this.showAlert = false;
            this.$http.post("/api/superadmin/settings/update", this.settings).then((response) => {
              if (response.data.success === true) {
                this.back();
              }
            }).catch(() => {this.$bvToast.show('serverError');});
          },
          back(){
            window.history.length > 1 ? router.go(-1) : router.push("/admin/regions");
          },
          getRegions() {
                this.$http.post("/api/superadmin/regions/select", {}).then((response) => {
                if (response.data.success) {
                    this.regions = response.data.regions;
                    this.regions.unshift({id: 0, name: this.$t('region.select_region'), official:-1});
                }
                }).catch(() => {
                });
            },
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-admin"), "active-menu");
      this.removeClass(document.getElementById("menu-settings"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-admin"), "active-menu");
      this.addClass(document.getElementById("menu-settings"), "active-menu");
      this.getSettings();
      this.getRegions();
    }
    }
</script>
