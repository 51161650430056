<template>
  <div id="meeting-div">
    <transition-group name="slide-down">
    <div v-if="showHeader && !test" class="top-home-bar" :style="{'grid-template-columns': 'max-content max-content auto max-content max-content'}" @mouseleave="hideHeaderIfFull">
      <div id="home-logo" class="fit-content pre-format" >
        <div v-on:click="toggleFullScreen" id="fullscreen-overlay" ><div class="fit-content">{{$t('global.fullscreen')}}</div></div>
      </div>
        <div class="grid-auto-2column left-text" >
        <span v-if="windowWidth>768" id="home-chapter-name" >{{permissions.organization.name}}</span>
        <span v-if="windowWidth>768" id="header-username" class="curtail-text"> {{permissions.user.name}}</span>
      </div>
       <div  id="aspect-switch">
          <div v-if="!aspectRatioIs169" class="aspect-lock-box"  v-on:click="toggleLockAspect">
            <div class="menu-button2 aspect-lock-icon" :class="{'aspect-button-lock': permissions.lockAspect, 'aspect-button-unlock': !permissions.lockAspect}"></div>
            <span class="aspect-lock-text">{{permissions.lockAspect?$t('meeting.fit_screen') : $t('meeting.dont_fit')}}</span>
          </div>
          <div class="aspect-lock-box"  v-on:click="toggleFullScreen">
            <div class="menu-button2 fullscreen-button  aspect-lock-icon"></div>
            <span class="aspect-lock-text">{{$t('meeting.fullscreen_text')}}</span>
          </div>
        </div>
      <div></div>
      <div id="header-exit-wrapper">
        <div class="clickable"  id="portal-exit-button" v-on:click="logout"><b-img :src="getImgUrl('nav-Close-Gray.png')" /></div>
      </div>
    </div>
    </transition-group>
    <div id="show-header-area" @mouseenter="showHeaderFunc" @mouseleave="hideHeaderIfFullSpecial"></div>
    <div v-if="!externalPresentation"  id="hide-header-area" @mouseenter="hideHeaderIfFull"></div>
    <div v-if="((connected && !meetingStarted) ||  (connected && meetingStarted && !externalPresentation && (!slides[1].id || slides[1].id==0)))" id="meeting-splash" >
      <div></div>
      <div class="center-text">
        <div id="meeting-waiting">
          <b>{{meetingStarted? $t('meeting.meeting_started') : $t('meeting.start_presentation')}}</b>
          <br/>
          <div class="loading-icon auto-margin"></div>
          <i>{{meetingStarted? $t('meeting.waiting_selection') : $t('meeting.waiting_controller')}}</i>
        </div>
         <b-button v-if="!meetingStarted" class="action-button auto-margin" v-on:click="refresh" variant="clear-blue"><div class="refresh-button"></div><span>{{$t('global.button_refresh')}}</span></b-button>
        <Tip v-if="!test" :tipNumber="2"></Tip>
      </div>
      <div></div>
    </div>
      <iframe id="external-iframe" v-if="externalPresentation && !showPDF" :src="externalURL" class="full-area" @mouseenter="hideHeaderIfFull" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"  crossorigin="anonymous" target="_parent" frameborder="0" allowfullscreen></iframe>
      <div v-if="externalPresentation && showPDF" id="meeting-pdf-wrapper">
        <div class="full-width auto-margin full-height">
          <VuePDF v-if="pdfRet && pdfRet.pdf != null" :style="{'margin':'auto', 'width': 'fit-content'}" :pdf="pdfRet.pdf" :page="pdfPage" :width="Math.min(fileWidth, fileHeight*pdfRatio)" @loaded="syncPdfController"/>
          <div v-if="!pdfRet || (pdfRet && !pdfRet.pdf)" id="loading-view" class="valign-grid auto-margin">
            <div></div>
            <div class="loading-icon auto-margin"></div>
            <div></div>
          </div>
        </div>
      </div>
      
      <div id="main-meeting-view">
        <div v-if="!test"></div>
        <transition-group  :name="slide_transition" :style="{width: (test ? '100%' : windowWidth+'px'),height: (test? '100%' : windowHeight+'px'),display: 'block',margin: 'auto'}">
        <div v-for="slide in slides.filter(value => ((connected && meetingStarted)) && value.id>0 && value.show)" :key="slide.id" class="main-meeting-view" v-bind:style="backgroundStyleFunc(slide)">
          <div v-if="([1,2,3,4,6,7,9,14,18,23].indexOf(slide.memberDescription.type) !== -1 && slide.memberDescription.show_photo==1)" class="draggable" 
             :style="{'z-index': 1000, left: slide.memberDescription.graphic_x_pos+'%', top: slide.memberDescription.graphic_y_pos+'%',  height: slide.memberDescription.graphic_height+'%', width: slide.memberDescription.graphic_width+'%'}">
                <div v-if="!isCorporateConnections && (slide.slide_member.is_network ==1 || slide.slide_member.is_renewing==1) && slide.show_recognition==1" class="member-banner" :style="{'font-size': (.04*slide.memberDescription.graphic_height/100)*windowHeight+'px'}">{{slide.slide_member.is_network ==1? $t('slide.network_leader', slide.language!=''? slide.language :$i18n.locale) : $t('slide.renewing_member', slide.language!=''? slide.language :$i18n.locale)}}</div>
                <img v-if="slide.settings.show_bni_accent==1 && slide.memberDescription.show_accent!=0" class="member-corner-image"  :src="isCorporateConnections? getImgUrl(slide.memberDescription.show_accent==1?'Picture-CC-backYellow.png':'Picture-CC-backGray.png') : getImgUrl('Corner.png')"/>
                <div v-if="slide.slide_member.photo" :class="{'circular-member': slide.memberDescription.circular==1}" class="member-graphic" :style="{'background-image': 'url('+slide.slide_member.photo+')'}" ></div>
                <img v-if="best_weekly == slide.slide_member.id" class="best-weekly" :style="{'bottom': slide.slide_member.is_speaker ==1? '5%' : ''}" src="../assets/OverlayIcon-BestWeekly.png" />
                <div v-if="!isCorporateConnections && slide.slide_member.is_speaker ==1" class="member-banner" :style="{'bottom':'0', 'font-size': (.04*slide.memberDescription.graphic_height/100)*windowHeight+'px'}">{{$t('slide.featured_speaker', slide.language!=''? slide.language :$i18n.locale)}}</div>
              </div>
              <div v-if="slide.memberDescription.type == 8" :style="{'font-family' : slide.goal.font}">
                <div :style="{position: 'absolute',display: 'grid', 'grid-template-rows': 'repeat(3,min-content)',left: slide.goal.text_x+'%', top: slide.goal.text_y+'%', width: slide.goal.text_width+'%', height: 'fit-content', height: '-moz-fit-content', height: '-webkit-fit-content'}">
                  <b :style="{color: slide.goal.title_color,'font-size': ((slide.goal.height/100)*windowHeight)+'px', 'line-height':'100%'}">{{slide.slide_goal.title}}</b>
                  <i :style="{color: slide.goal.description_color,'font-size': ((slide.goal.description_size/100)*windowHeight)+'px', 'line-height':'100%'}">{{slide.slide_goal.description}}</i>
                  <span :style="{color: slide.goal.notes_color,'font-size': ((slide.goal.notes_size/100)*windowHeight)+'px', 'line-height':'100%', 'margin-top': '30px'}" v-html="slide.slide_goal.notes"></span>
                </div>
                <div v-if="slide.slide_goal.history.length>0" :style="{'text-align': 'center',position: 'absolute',left: slide.goal.history_x+'%', top: slide.goal.history_y+'%', width: 'fit-content', width: '-moz-fit-content', width: '-webkit-fit-content', height: 'fit-content', height: '-moz-fit-content', height: '-webkit-fit-content', 'font-size': ((slide.goal.history_font_size/100)*windowHeight)+'px'}">
                  <table class="table b-table table-bordered table-sm table-hover-new">
                    <thead>
                      <tr>
                        <th :style="{'background-color': slide.goal.heading_back_colour, 'color': slide.goal.heading_colour}">{{$t('goal.day_text', slide.language!=''? slide.language :$i18n.locale)}} </th>
                        <th :style="{'background-color': slide.goal.heading_back_colour, 'color': slide.goal.heading_colour}">{{$t('goal.value_text', slide.language!=''? slide.language :$i18n.locale)}} </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="h in slide.slide_goal.history.slice(0,slide.goal.max_table_rows)" :key="h.day" :style="{'font-weight': h == slide.slide_goal.history[0]? 'bold' : 'normal'}">
                        <td :style="{'background-color': slide.goal.table_back_colour, 'color': slide.goal.table_colour}">{{h.day}}</td>
                        <td :style="{'background-color': slide.goal.table_back_colour, 'color': slide.goal.table_colour}">{{h.value}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="donation-meter" :style="{position: 'absolute',left: slide.goal.thermo_x+'%', top: slide.goal.thermo_y+'%', height: '80%'}">
                  <span class="glass">
                    <!--<strong v-if="!slide.slide_goal.value || (slide.slide_goal.value/slide.slide_goal.target)*100 < slide.slide_goal.target-5" class="goal" :style="{color: slide.goal.color}">{{$t('slide.target_text')+" "+ (slide.slide_goal.target != null && slide.slide_goal.target != undefined ? slide.slide_goal.target : "")}}</strong>
                      <strong  class="total" :style="{color: slide.goal.color,bottom: (slide.slide_goal.value? Math.max(Math.min((slide.slide_goal.value/slide.slide_goal.target)*100,98),0) : 0)+'%'}">{{$t('slide.current_text')+" "+(slide.slide_goal.value != null && slide.slide_goal.value != undefined ? slide.slide_goal.value : "0")}}</strong>-->
                      <span class="amount" :style="{height: (slide.slide_goal.value? Math.max(Math.min((slide.slide_goal.value/slide.slide_goal.target)*100,98),0) : 0)+'%'}"></span>
                      <hr class="goal-increment" v-for="x in [1,2,3,4,5,6,7,8,9,15]" :key="x" :style="{'bottom': (x*10-4)+'%'}">
                  </span>
                  <div class="bulb">
                      <span class="red-circle"></span>
                      <span class="filler">
                          <span></span>
                      </span>
                  </div>
                </div>
              </div>
          <div class="textElement meeting-text" v-if="[1,2,3,4,6,7,9,14,18,23].indexOf(slide.memberDescription.type) !== -1" :style="{'text-align': slide.memberDescription.alignment,left: slide.memberDescription.x_pos+'%', top: slide.memberDescription.y_pos+'%', width: slide.memberDescription.width+'%', height: slide.memberDescription.height+'%'}">
            <div x-ms-format-detection="none">
              <div id="member-name" :style="{'font-size': ((slide.memberDescription.name_height/100)*windowHeight)+'px', 'line-height':'100%', color: slide.memberDescription.name_color, 'font-family' : slide.memberDescription.name_font, 'font-weight': slide.memberDescription.name_weight}">{{slide.slide_member.name}}</div>
              <div v-if="slide.memberDescription.job_title_show==1 && slide.slide_member.job_title && slide.slide_member.job_title.length>0" id="member-job_title" :style="{'font-size': ((slide.memberDescription.job_title_height/100)*windowHeight)+'px','line-height':'100%', color: slide.memberDescription.job_title_color, 'font-family' : slide.memberDescription.job_title_font, 'font-weight': slide.memberDescription.job_title_weight}">{{slide.slide_member.job_title}}</div>
              <div v-if="slide.memberDescription.show_company==1 && slide.slide_member.company && slide.slide_member.company.length>0" id="member-company" :style="{'font-size': ((slide.memberDescription.company_height/100)*windowHeight)+'px', 'line-height':'100%', color: slide.memberDescription.company_color, 'font-family' : slide.memberDescription.company_font, 'font-weight': slide.memberDescription.company_weight}">{{slide.slide_member.company}}</div>
              <div v-if="slide.memberDescription.show_category==1 && slide.slide_member.category && slide.slide_member.category.length>0 && slide.memberDescription.type !=7" id="member-category" :style="{'font-size': ((slide.memberDescription.category_height/100)*windowHeight)+'px', 'line-height':'100%', color: slide.memberDescription.category_color, 'font-family' : slide.memberDescription.category_font, 'font-weight': slide.memberDescription.category_weight}">{{slide.slide_member.category}}</div>
              <div x-ms-format-detection="none" v-if="slide.memberDescription.show_phone==1 && slide.slide_member.phone && slide.slide_member.phone.length>0" id="member-phone" :style="{'font-size': ((slide.memberDescription.phone_height/100)*windowHeight)+'px', 'line-height':'100%', color: slide.memberDescription.phone_color, 'font-family' : slide.memberDescription.phone_font, 'font-weight': slide.memberDescription.phone_weight}">{{slide.slide_member.phone}}</div>
              <div v-if="slide.memberDescription.show_email==1 && slide.slide_member.email && slide.slide_member.email.length>0" id="member-email" :style="{'font-size': ((slide.memberDescription.email_height/100)*windowHeight)+'px', 'line-height':'100%', color: slide.memberDescription.email_color, 'font-family' : slide.memberDescription.email_font, 'font-weight': slide.memberDescription.email_weight}">{{slide.slide_member.email}}</div>
              <div v-if="slide.memberDescription.show_website==1 && slide.slide_member.website && slide.slide_member.website.length>0" id="member-website" :style="{'font-size': ((slide.memberDescription.website_height/100)*windowHeight)+'px', 'line-height':'100%', color: slide.memberDescription.website_color, 'font-family' : slide.memberDescription.website_font, 'font-weight': slide.memberDescription.website_weight}">{{slide.slide_member.website}}</div>
              <div v-if="slide.memberDescription.show_category==1 && slide.slide_member.category && slide.slide_member.category.length>0 && slide.memberDescription.type ==7" id="member-category" :style="{'font-size': ((slide.memberDescription.category_height/100)*windowHeight)+'px', 'line-height':'100%', color: slide.memberDescription.category_color, 'font-family' : slide.memberDescription.category_font, 'font-weight': slide.memberDescription.category_weight, 'margin-top':'10px'}">{{slide.slide_member.category}}</div>
            </div>
          </div>
          <div id="timer-text" v-if="!slide.timer.disabled && (slide.timer.type==1 || (slide.timer.type==2 && showSpeakerTimer))" class="draggable "
               :style="{'z-index': 1000000,'-webkit-text-stroke': '1px '+slide.timer.back_color, 'font-size': ((slide.timer.height/(100))*windowHeight)+'px', 'line-height':'100%',left: slide.timer.x_pos+'%', top: slide.timer.y_pos+'%',  height: slide.timer.height+'%', color: (slide.timer.countdown_change ==0? slide.timer.color : (current_time<=(slide.timer.alter_time+1)? slide.timer.alter_colour : slide.timer.color)), 'font-family' : slide.timer.font, width: ((slide.timer.height/(100))*windowHeight)*2.5+'px', 'text-align': 'right'}">
            {{getTimeString()}}
          </div>
          <div id="timer-bar" v-if="!slide.timer.disabled && slide.timer.type==0" class="draggable"
               :style="timerStyle(slide)">
            
          </div>

        <div v-if="(slide.memberDescription.type == 1 || slide.memberDescription.type == 7 || slide.memberDescription.type == 18) && slide.memberDescription.selection>1 && slide.slide_member.next"  class="draggable"
                :style="{left: slide.memberDescription.next_x_pos+'%', top: slide.memberDescription.next_y_pos+'%',color: slide.memberDescription.next_color,'font-family' : slide.memberDescription.next_font, width: 'fit-content', width: '-moz-fit-content', width: '-webkit-fit-content', height: (slide.memberDescription.next_height*(slide.memberDescription.next_number+1))+'%', 'text-align': 'center','font-size': ((slide.memberDescription.next_height/100)*windowHeight)+'px', 'line-height':'100%'}">
                <i v-if="slide.memberDescription.next_number>0">{{$t((slide.memberDescription.next_number==1? 'slide.next_presenter' : 'slide.next_presenters'), slide.language!=''? slide.language :$i18n.locale)}}</i><br/>
                <div v-for="n in slide.slide_member.next.slice(0,Math.min(slide.memberDescription.next_number, slide.slide_member.next.length))" :key="n.name"><i :style="{ 'font-weight': n==slide.slide_member.next[0]? 'bold' : 'normal', 'font-size': n==slide.slide_member.next[0]? ((slide.memberDescription.next_height/100)*windowHeight)+'px' : ((slide.memberDescription.next_height/100)*windowHeight)*.8+'px'}">{{n.name}}</i></div>
          </div>
          <!--.filter(v => v.language == $i18n.locale)-->
          <div class="draggable textElement" v-for="text in slide.texts" v-bind:id="text.html_id" :key="text.html_id" :class="{verticalTextAlign: text.vertical_align==1}"
               :style="{border:'solid 1px '+text.border_color, 'background-color': text.back_color, 'z-index': text.z_pos,'font-size': ((text.size/100)*windowHeight)+'px', 'line-height':(100*text.spacing)+'%',left: text.x_pos+'%', top: text.y_pos+'%',  height: text.height+'%', width: text.width+'%', color: text.color, 'font-family' : text.font, 'font-weight': text.weight, 'text-align': text.alignment, transform: 'rotate('+text.rotation+'deg)', borderRadius: text.ellipse==1? '50%' : ''}">
               <div v-if="!text.show_qr" v-html="text.var_text"></div>
                <div v-if="text.show_qr"><div v-if="text.var_text"><qr-code class="qr-block" :text="text.var_text"></qr-code><div id="linkedin-image" v-if="text.is_linkedin" ></div></div></div>
          </div>
          <div class="draggable" v-for="graphic in slide.graphics" :key="graphic.html_id"
               v-bind:id="graphic.html_id" :style="{'text-align': 'center', 'z-index': graphic.z_pos,left: graphic.x_pos+'%', top: graphic.y_pos+'%',  height: graphic.height+'%', width: (graphic.special.length>0 || graphic.fit==1)? graphic.width+'%' : '', transform: 'rotate('+graphic.rotation+'deg)'}">
            <img v-if="graphic.photo && graphic.photo.length>0" :style="{'width': graphic.special.length>0 || graphic.fit==1? '100%' : '','object-fit': graphic.special.length>0?  'contain' : '' }" :src="graphic.photo" />
          </div>
        <div id="role-members" v-if="slide.memberDescription.type==5 || slide.memberDescription.type==15 || slide.memberDescription.type==17 || slide.memberDescription.type==19 || slide.memberDescription.type==21 || slide.memberDescription.type==22"
             :style="{'text-align': slide.memberDescription.alignment, left: slide.memberDescription.x_pos+'%', top: slide.memberDescription.y_pos+'%', height: slide.memberDescription.height+'%', width: slide.memberDescription.width+'%'}">
             <div :style="{display:'grid', height: '100%', width: '100%', 'grid-template-columns': 'repeat('+Math.min(slide.memberDescription.num_rows_x, slide.slide_members.length)+', 1fr)','grid-template-rows': 'repeat('+Math.min(slide.memberDescription.num_rows_y,Math.ceil(slide.slide_members.length/slide.memberDescription.num_rows_x))+', 1fr)'}">
              <div  class="slide-role-member" :style="{'grid-template-rows': slide.memberDescription.multi_vertical==1?  slide.memberDescription.graphic_height+'% '+(100-slide.memberDescription.graphic_height)+'%' : '100%', 'grid-template-columns': slide.memberDescription.multi_vertical==0?  slide.memberDescription.graphic_height+'% '+(100-slide.memberDescription.graphic_height)+'%' : '100%'}" v-for="member in slide.slide_members.slice(0, Math.min(slide.slide_members.length, slide.memberDescription.num_rows_x*slide.memberDescription.num_rows_y))" :key="member">
              <!--<div class="role-image-wrapper" >
                <div class="role-image-div">
                  <div v-if="(member.is_network ==1 || member.is_renewing==1) && slide.show_recognition==1" class="members-banner" :style="{'width': (windowHeight*(slide.memberDescription.graphic_height/(100*Math.min(slide.memberDescription.num_rows_y,Math.ceil(slide.slide_members.length/slide.memberDescription.num_rows_x))) )-40)*.8*0.6157+'px', 'z-index': '10', 'font-size': (.04*slide.memberDescription.graphic_height/(100*Math.min(slide.memberDescription.num_rows_y,Math.ceil(slide.slide_members.length/slide.memberDescription.num_rows_x))))*windowHeight+'px'}">{{member.is_network ==1? $t('slide.network_leader', slide.language!=''? slide.language :$i18n.locale) : $t('slide.renewing_member', slide.language!=''? slide.language :$i18n.locale)}}</div>
                  <img class="member-role-image" :src="member.photo"  />
                  <img v-if="best_weekly == member.id" class="members-best-weekly" :style="{'bottom': member.is_speaker ==1? '5%' : ''}" src="../assets/OverlayIcon-BestWeekly.png" />
                  <div v-if="member.is_speaker ==1" class="members-banner " :style="{'width': (windowHeight*(slide.memberDescription.graphic_height/(100*Math.min(slide.memberDescription.num_rows_y,Math.ceil(slide.slide_members.length/slide.memberDescription.num_rows_x))) )-40)*.8*0.6157+'px', 'bottom': '0','font-size': (.04*slide.memberDescription.graphic_height/(100*Math.min(slide.memberDescription.num_rows_y,Math.ceil(slide.slide_members.length/slide.memberDescription.num_rows_x))))*windowHeight+'px'}">{{$t('slide.featured_speaker', slide.language!=''? slide.language :$i18n.locale)}}</div>
                </div>
              </div>-->
              <div v-if="member.photo" class="role-image-wrapper-2" :style="multiMemberStyle(slide)">
                  <img v-if="slide.settings.show_bni_accent==1 && slide.memberDescription.show_accent!=0" class="member-corner-image below-image"  :src="isCorporateConnections? getImgUrl(slide.memberDescription.show_accent==1?'Picture-CC-backYellow.png':'Picture-CC-backGray.png') : getImgUrl('Corner.png')"/>
                  <div class="role-image-div">
                    <div v-if="!isCorporateConnections && (member.is_network ==1 || member.is_renewing==1) && slide.show_recognition==1" class="members-banner" :style="{'width': (windowHeight*(slide.memberDescription.height/(100*Math.min(slide.memberDescription.num_rows_y,Math.ceil(slide.slide_members.length/slide.memberDescription.num_rows_x))) )-40)*.8*0.6157+'px', 'z-index': '10', 'font-size': (.04*slide.memberDescription.height/(100*Math.min(slide.memberDescription.num_rows_y,Math.ceil(slide.slide_members.length/slide.memberDescription.num_rows_x))))*windowHeight+'px'}">{{member.is_network ==1? $t('slide.network_leader') : $t('slide.renewing_member')}}</div>
                    <div :class="{'member-role-image-2': slide.memberDescription.circular==0, 'circular-member': slide.memberDescription.circular==1}" :style="{'background-image': 'url('+member.photo+')'}"  ></div>
                    <div v-if="!isCorporateConnections && member.is_speaker ==1" class="members-banner " :style="{'width': (windowHeight*(slide.memberDescription.height/(100*Math.min(slide.memberDescription.num_rows_y,Math.ceil(slide.slide_members.length/slide.memberDescription.num_rows_x))) )-40)*.8*0.6157+'px', 'bottom': '0','font-size': (.04*slide.memberDescription.height/(100*Math.min(slide.memberDescription.num_rows_y,Math.ceil(slide.slide_members.length/slide.memberDescription.num_rows_x))))*windowHeight+'px'}">{{$t('slide.featured_speaker')}}</div>
                  </div>
                </div>
                <div v-if="!member.photo"></div>
              <div  :class="{'fit-width': slide.memberDescription.multi_vertical==1}" class="role-image-name" :style="{margin: slide.memberDescription.multi_vertical==0? 'auto' : '','background-color': slide.memberDescription.name_back_color,'line-height':'100%', 'text-align': slide.memberDescription.multi_vertical==1? 'center' : 'left', width:'100%'}">
                <span :style="{'font-size': ((slide.memberDescription.name_height/(100*Math.min(slide.memberDescription.num_rows_y,Math.ceil(slide.slide_members.length/slide.memberDescription.num_rows_x))))*windowHeight)+'px', 'line-height':'100%', color: slide.memberDescription.name_color, 'font-family' : slide.memberDescription.name_font, 'font-weight': slide.memberDescription.name_weight}">{{member.name && slide.memberDescription.first_name>0? member.name.split(" ")[0] : member.name}}</span><br/>
                <span v-if="slide.memberDescription.type==17 || slide.memberDescription.type==19 || slide.memberDescription.type==21 || slide.memberDescription.type==22 || slide.memberDescription.show_renewing_date==1 || (slide.memberDescription.type==15 && slide.memberDescription.member_id>3)" :style="{'font-size': ((slide.memberDescription.category_height/(100*Math.min(slide.memberDescription.num_rows_y,Math.ceil(slide.slide_members.length/slide.memberDescription.num_rows_x))))*windowHeight)+'px', 'line-height':'100%', color: slide.memberDescription.category_color, 'font-family' : slide.memberDescription.category_font, 'font-weight': slide.memberDescription.category_weight}" v-html="member.category"></span>
                <!--<div v-if="member.linkedin_url && slide.memberDescription.multi_vertical==0" class="member-qr"><qr-code class="qr-block" :text="member.linkedin_url"></qr-code><div id="linkedin-image"></div></div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition-group>
        <div  v-if="!test"></div>
      </div>
     <b-modal centered v-model="showOptions" :title="$t('controller.options_text')">
            <div class="center-text">
              <div class="option-title">{{$t('controller.language_text')}}</div>
              <div  class="fit-content header-language-options">
                <b-button v-for="language in languageOptions" :key="language.id"  :class="{'action-button' : true, 'active-flag': $i18n.locale == language.abbrev}" class="language-flag"  v-on:click="loadLanguageAsync(language.abbrev)" variant="clear-blue" ><img :src="getImgUrl('flags/'+language.flag+'.png')" v-bind:alt="language.flag+' flag'"></b-button>
              </div>
            </div>
            <div slot="modal-footer" class="fit-content footer-button-style">
              <b-button class="action-button" v-on:click="showOptions = false;" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
            </div>
          </b-modal>
    <b-toast id="discToastMeet" variant="danger" solid :title="$t('meeting.disconnect_text')">
      {{$t('meeting.disconnect_description')}}
    </b-toast>
    <b-toast id="discToastMeetCon" variant="warning" solid :title="$t('meeting.disconnect_text')">
      {{$t('meeting.con_disconnect_description')}}
    </b-toast>
  </div>
</template>
<style scoped lang="scss">
#loading-view{
  width: 50%;
  height:calc(100% - 80px);
}
#show-header-area{
  position:absolute; z-index:100000;width:100%;height:50px;
}
#hide-header-area{
  position:absolute; z-index:100000;width:100%;height: 100px; top: 50px;
}
#meeting-waiting{
  display:grid;color:black;font-size:20px;
}
#meeting-pdf-wrapper{
  position:absolute;background-color: white;width:100%;height:100%;z-index:10000
}
.below-image{
  z-index:0;
}
.member-qr{
  width: 100%;padding-bottom: 100%;position: relative;margin-top:10px;
}
.meeting-text{
  position: absolute;
}
#header-exit-wrapper{
  margin-left:20px;
}
#header-username{
  color:#000;font-size:14px;padding-left: 10px;white-space: nowrap;line-height: 20px;
}
#aspect-switch{
  border: none !important;
  margin:auto;display: grid;grid-template-columns: max-content max-content;grid-gap: 20px;
}
.aspect-lock-text{
  margin: auto 10px;
}
.aspect-lock-box{
  display: grid;grid-template-columns: max-content max-content;cursor: pointer;
}
.aspect-lock-icon{
  margin: auto;width: 30px !important; height: 30px;
}
#main-meeting-view{
  position:absolute;display:grid; width:100%;height:100%;grid-template-rows: auto max-content auto;background-color: white;
}
.aspect-button-lock{
  background-image: url("../assets/icon-Stretch-"+$VUE_APP_IMAGE_MOD+".png");
}
.aspect-button-unlock{
  background-image: url("../assets/icon-DoNotStretch-"+$VUE_APP_IMAGE_MOD+".png");
}
.fullscreen-button{
  background-image: url("../assets/icon-fullscreen2.png");
  filter: $VUE_APP_COLOUR_MAIN_FILTER;
  background-color: transparent !important;
  border: none !important;
}
.slide-down-enter-active {
  transition: all 0s linear;
}
.slide-down-leave-active {
  transition: all 0s linear;
}
.slide-down-enter, .slide-down-leave-to {
  transform: translateY(-50px);
}
#meeting-header{
  display: grid; padding: 3px;border-bottom:1px solid rgb(0, 174, 239);height:50px;
      position: absolute;
    z-index: 1000000;
    width: 100%;
    background: white;
}
#meeting-exit{
  opacity: 0;background-color: rgba(0,0,0,0);border: none;position: absolute;right:0px; z-index:100;margin: 5px 5px;
}
#meeting-exit:hover{
  opacity: 1;
  
}
#meeting-splash{
  display: grid;
  grid-template-rows: auto max-content auto;
  position: relative;
      text-align: center;
    width: 100%;
    height: 100%;
}
  

  .frame-class {
    height: 100%;
    width: 100%;
    border: none;
    margin: 0px;
    padding: 0px;
    display:block;
  }
  #meeting-div {
    width: 100%;
    height: 100%;
    position: relative;
    display: block;
    
  }

  .main-meeting-view {
    background-repeat: no-repeat;
    position: absolute;
    background-size: 100% 100%;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
    width: 100%;
    border:none;
    border-radius:0px;
    height:100%;
    display:grid;
  }

#pf1{
  display: block;
}
  .pf{
    display: none;
    margin: auto;
  }

</style>
<script>

class Timer{
  constructor(callback, delay){
    this.timerId = -1;
    this.start = -1;
    this.remaining = delay;
    this.cb = callback;
    this.resume();
  }
  pause() {
      window.clearTimeout(this.timerId);
      this.remaining -= Date.now() - this.start;
  }

  resume() {
      this.start = Date.now();
      window.clearTimeout(this.timerId);
      this.timerId = window.setTimeout(this.cb, this.remaining);
  }
}
    import favicon from "../assets/favicon-present-red.png";
    import { VuePDF, usePDF } from '@tato30/vue-pdf'
    import router from "../router"
    export default {
    name: "Dashboard",
     components: {
      VuePDF
    },
    props:{
      test: Boolean
    },
    watch: {
      'permissions.lockAspect': {
        handler: function () {
          this.onResize();
        },
        deep: true
      },
      'pdfRet': {
        handler: function () {
          if(this.pdfRet && this.pdfRet.pdf != null){
            this.syncPdfController();
          }
        },
        deep: true
      },
     
    },
    data() {
      return {
        fileWidth: 1000,
        fileHeight: 1000,
        pdfRatio: 1,
        pdfLoad: 0,
        showSpeakerTimer: false,
        aspectRatioIs169: false,
        headerTimeout: null,
        showHeaderTimeout: null,
        showHeader: true,
         showOptions: false,
        languageOptions: [],
        pdfPages: 0,
        loadedRatio: 0,
        numPages: 0,
        showPDF: false,
        pdfPath: "./static/test.pdf",
        pdfRet: {pdf: null, pages:0},
        pdfName: "",
        pdfHash: "",
        pdfPage: 1,
        nextMap: {},
        isNotFullScreen: true,
        slide_transition: "quickfade",
        externalPresentation: false,
        externalURL: "",
        controllerText: "",
        connected: false,
        ratioOptions: {
          1: 9.0 / 16.0,
          2: 5.0 / 8.0,
          3: 3.0 / 4.0,
          4: 4.0 / 5.0
        },
        gradNames: ["linear-gradient(", "linear-gradient(to right,", "radial-gradient("],
        client: {},
        id_string: "",
        current_time: 0,
        variable_map: [],
        sections: [],
        windowWidth: 1000,
        windowHeight: 1000,
        pdfHeight: 1000,
        maxHeightDiv: 'meeting-div',
        meetingStarted: false,
        facts: [],
        mouseTimeout: null,
        mouseIsHidden: false,
        fact_num: 0,
        settings: { edit_ratio: 1, back_type: 1, back_color: "#fff", language: 'en' },
        slides: [{id: 0,
          texts: [], graphics: [], memberDescription: {},
          timer: {delay_time:0, count_direction: 0, disabled: 1, type: 0, x_pos: 0, y_pos: 0, x_px: 0, y_px: 0, width: 5, height: 5, font: "Arial", color: "#000", back_color: "#fff", progressive: 0, num_plays: 1, sound_file: "", max_time: 1, secs: 1, mins: 0 },
          goal: {id: 0, goal_id: 0, disabled: 1,thermo_x:0,thermo_y:0,history_x:0,history_y:0,text_x:0,text_y:0, color: '#000000', height: 5, font: 'arial', description_size: 2, notes_size: 2, heading_colour: "#ffffff", table_colour: "#000000", heading_back_colour: '#1b629c', table_back_colour: '#ffffff'},
          lide_goal: {history: []},
          slide_member: { name: "", company: "", category: "", photo: "", email: "", website: "", phone: "" },
          slide_members: [],
          slide_to: null,
          total_replays: -1,
        }, {
          id: 0,
            texts: [], graphics: [], memberDescription: {},
            timer: { count_direction: 0, disabled: 1, type: 0, x_pos: 0, y_pos: 0, x_px: 0, y_px: 0, width: 5, height: 5, font: "Arial", color: "#000", back_color: "#fff", progressive: 0, num_plays: 1, sound_file: "", max_time: 1, secs: 1, mins: 0 },
            goal: {id: 0, goal_id: 0, disabled: 1,thermo_x:0,thermo_y:0,history_x:0,history_y:0,text_x:0,text_y:0, color: '#000000', height: 5, font: 'arial', description_size: 2, notes_size: 2, heading_colour: "#ffffff", table_colour: "#000000", heading_back_colour: '#1b629c', table_back_colour: '#ffffff'},
            slide_goal: {history: []},
            slide_member: { name: "", company: "", category: "", photo: "", email: "", website: "", phone: "" },
            slide_members: [],
            slide_to: null,
            total_replays: -1,
          }],
       
        factInterval: null,
        externalTab: null,
        checkInterval: null,
        dayParent: null,
        needsfacts: false,
        organization_info: {}, 
        section_force_times: [],
        force_interval: null,
        last_force_time: 0,
        audio_object: null,
        random_members: [-1],
        global_volume: 1.0,
        goal_fields: [
            {
              key: 'day',
              label: this.$t('slide.date_header'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
            { key: 'value',
              label: this.$t('slide.value_header'),
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            }
          ],
        pauseIntervals: false,
        pslide: 1,
        best_weekly: 0
      }
    },
    sockets: {
      timer_end()  {
        this.timerEnd();
      },
      connect()  {
        this.$socket.emit("register", { id_string: this.id_string });
        this.$socket.emit("view_ready", { id_string: this.id_string, ready: true });
        this.resumeTimers();
        this.connected = true;
      },
      disconnect()  {
        this.stallTimers();
        this.connected = false;
      },
      is_controller(data) {
        if (data.found) {
          this.meetingStarted = data.found;
          this.controllerText = "";
          this.startMeeting();
        } else {
          this.controllerText = this.$t('meeting.controller_text');
        }
      },
      fullscreen() {
        document.getElementById("SlidePanel").click();
      },
      set_volume(data) {
        this.global_volume = data.volume;
      },
      stop_timer() {
        if (this.slides[1].timer.interval || this.slides[1].timer.current_audio) {
          this.stopAndSilenceTimer();
        } else {
          this.resumeTimer();
        }

        this.syncTimers();
      },
      show_timer() {
        this.showSpeakerTimer = !this.showSpeakerTimer;
      },
      next_map(data) {
        this.nextMap = data.next_map;
      },
      request_presentation_info() {
        if(this.showPDF){
          this.syncPdfController();
        }
        this.syncTimers();
      },
      
      goto(data) {
        if (data.externalPresentation) {
          if(data.presentation_slide>0){
            //this.goToPSlide(data.presentation_slide);
            this.pdfPage = data.presentation_slide;
            this.$socket.emit("presentation_info", { id_string: this.id_string, maxSlide: null, slide: this.pdfPage, file: this.pdfName,hash: this.pdfHash, showing: true});
            return;
          }

          if (this.externalTab) {
            this.externalTab.close();
            this.externalTab = null;
          }
          
          if (data.link && data.link.length > 0) {
            this.externalURL = data.link; //(data.link.startsWith("http") ? "" : "http://") + 
          } else {
            if(data.type==2){
              this.externalURL = (this.slides[1].slide_member.url.startsWith("http") ? "" : "http://") + this.slides[1].slide_member.url;
            }else{
              this.externalURL = this.slides[1].slide_member.url;//(this.slides[1].slide_member.url.startsWith("http") ? "" : "http://") + 
            }
          }
          if(this.externalURL.includes(".pdf")){
            this.pdfPage = 1;
            this.pdfName = this.externalURL;
            this.pdfHash = data.hash? data.hash : this.slides[1].slide_member.hash;
            this.pdfPath = this.externalURL && this.externalURL.includes("/")? this.externalURL : "pdfs/"+this.externalURL;
            this.pdfLoad = 0;
            this.pdfRet = usePDF(this.pdfPath,{
              onProgress: this.onProgress,
              onError: this.onError
            });
            this.externalPresentation = true;
            this.showPDF = true;
            this.$socket.emit("presentation_info", { id_string: this.id_string, maxSlide: null, slide: this.pdfPage, file: this.pdfName,hash: this.pdfHash, showing: true});
          }else if (this.externalURL.includes(".sharepoint.")) {
            this.showPDF = false;
            this.externalPresentation = true;
          } else if(this.externalURL.includes("youtube.") || this.externalURL.includes("youtu.be")){
            var embedId = this.getYoutubeEmbed(this.externalURL);
            this.externalURL = "https://www.youtube.com/embed/"+embedId+"?autoplay=1";
            this.externalPresentation = true;
            this.showPDF = false;
          } else if(this.externalURL.includes("vimeo.")){
            var embedId = this.getVimeoEmbed(this.externalURL);
            this.externalURL = "https://player.vimeo.com/video/"+embedId+"?autoplay=1";
            this.externalPresentation = true;
            this.showPDF = false;
          }else {
            this.externalPresentation = false;
            this.showPDF = false;
            this.externalURL = (data.link.startsWith("http") ? "" : "http://") + this.externalURL;
            this.externalTab = window.open(this.externalURL, "_blank", 'location=no,status=no,titlebar=no,toolbar=no,menubar=no');
            if(!this.externalTab || this.externalTab.closed || typeof this.externalTab.closed=='undefined'){ 
              this.$bvToast.toast(this.$t('global.popups_blocked'), {
                variant: 'danger',
                solid: true
              });
            }
          }
          if(data.timer_playing == null || data.timer_playing == undefined){
            data.timer_playing = false;
          }
          if(this.slides[1].memberDescription.type == 3){
            this.syncTimers();
          }else if (data.time>0){
            this.syncControllerTimers(data.time, true);
          }
        } else {
          if(this.showPDF){
            this.$socket.emit("presentation_info", { id_string: this.id_string, file: this.pdfName,hash: this.pdfHash, showing: false});
            this.pdfHash = "";
          }
          this.showPDF = false;
          this.getSlide(data.section_id, data.slide_id, data.member_id, data.next_id, data.other_id);
        }
      },
    },
    methods: {
      toggleLockAspect(){
        this.permissions.lockAspect = !this.permissions.lockAspect;
      },
      toggleMeetingFullScreen(){
        this.hideHeader();
        this.toggleFullScreen();
      },
      hideHeaderIfFullSpecial(){
        if(!this.showHeader){
          this.hideHeader();
        }
        
      },
      hideHeaderIfFull(){
        this.hideHeader();
      },
      hideHeader(){
        var self = this;
        if(this.showHeaderTimeout){
          this.showHeaderTimeout.pause();
        }
        this.headerTimeout = new Timer(function(){
            self.showHeader = false;
        }, 2000);
      },
      showHeaderFunc(){
        var self = this;
        if(this.headerTimeout){
          this.headerTimeout.pause();
        }
        this.showHeaderTimeout= new Timer(function(){
            self.showHeader = true;
        }, 200);
      },
      timerStyle(slide){
        if(slide!=null){
          var s = {'border-radius': slide.timer.circular==0? '0%' : '50%',  left: slide.timer.x_pos+'%', top: slide.timer.y_pos+'%', height: slide.timer.height+'%', width: slide.timer.width+'%'}
          var p = slide.timer.direction ==0? (1-(this.current_time/slide.timer.max_time)) : ((this.current_time/slide.timer.max_time));
          var c = (slide.timer.countdown_change ==0? slide.timer.color :  ((slide.timer.direction ==1 && this.current_time<=(slide.timer.alter_time)) || (slide.timer.direction ==0 && this.current_time>=(slide.timer.alter_time))? slide.timer.alter_colour : slide.timer.color))
          if(slide.timer.circular==0){
            s["background"]= 'linear-gradient(to right,'+c+' '+(p*100)+'%,'+slide.timer.back_color+' '+(p*100)+'%)';
          }else{
            s["background"]= 'conic-gradient(from '+slide.timer.circular_start+'deg,'+c+' '+slide.timer.circular_end*p+'deg, '+slide.timer.back_color+' '+slide.timer.circular_end*p+'deg '+slide.timer.circular_end+'deg,rgba(0,0,0,0) '+slide.timer.circular_end+'deg)';
          }
          return s;
        }else{
          return {};
        }
        //'background': (slide.timer.circular==0? 'linear-gradient(to right,'+slide.timer.color+' '+(slide.timer.direction==0? '75%,': '100%,')+slide.timer.back_color+' 25%)' : 'conic-gradient(from '+slide.timer.circular_start+'deg,'+slide.timer.color+' '+slide.timer.circular_end*(timer.direction==0? 0.75 : 1)+'deg, '+slide.timer.back_color+' '+slide.timer.circular_end*(slide.timer.direction==0? 0.75 : 1)+'deg '+slide.timer.circular_end+'deg,rgba(0,0,0,0) '+slide.timer.circular_end+'deg)'),
      },
      multiMemberStyle: function(slide){
        var imageRatio=(slide.memberDescription.circular==1  || slide.memberDescription.type==21? 1 : 0.6157);
        var multiBoxHeight = (this.windowHeight*(slide.memberDescription.height/(100*Math.min(slide.memberDescription.num_rows_y,Math.ceil(slide.slide_members.length/slide.memberDescription.num_rows_x))) )-40);
        var multiImagePart = (slide.memberDescription.multi_vertical==1? slide.memberDescription.graphic_height/100.0: 1.0);
        var multiImagePart = slide.memberDescription.graphic_height/100.0;
        var width = Math.round(multiBoxHeight*multiImagePart*imageRatio);
        var s = {'max-width': width+'px'};
        if(slide.memberDescription.circular==1){
          multiImagePart = slide.memberDescription.graphic_height/100.0;
          var multiBoxWidth = (this.windowWidth*(slide.memberDescription.width/(100*Math.min(slide.memberDescription.num_rows_x, slide.slide_members.length)) )-40);
          s['max-height'] = Math.min(width, multiBoxWidth*multiImagePart)+'px';
        }
        return s;
      },
      backgroundStyleFunc(slide) {
        var s = { "background-color": "#fff" };
        if (slide.back_type == 0) {
          s = { "background-color": "#fff" };
        } else if (slide.back_type == 1) {
          s = { "background-color": slide.back_colour };
        } else if (slide.back_type == 2) {
          s = { "background-image": this.gradNames[slide.back_grad_type] + slide.back_colour_from + "," + slide.back_colour_to + ")" };
        } else if ((slide.back_type == 3)) {
          s = { "background-color": "#fff","background-image": "url(\"" + slide.photo + "\")" };
        }else if(slide.back_type == 4){
          s = { "background-color": "#fff", "background-image": "url(\"" + (slide.photo && slide.photo.includes("assets/backgrounds")? slide.photo : "assets/backgrounds/"+slide.photo)  + "\")" };
        }
        if(this.test){
           s["width"]= "100%";
          s["height"]= "100%";
        }else{
           s["width"]= this.windowWidth+'px';
          s["height"]= this.windowHeight+'px';
        }
      
        return s;
      },

      goToSingleSlide(memberId){
        if(this.slides[1].memberDescription.alternate_slide_id>0){
          this.getSlide(this.slides[1].section_id, this.slides[1].memberDescription.alternate_slide_id, memberId,0);
          return;
        }
      },

      getSlide(sectionId, slideId, memberId, nextId, otherId) {
        if (this.slides[1].slide_to) {
          this.slides[1].slide_to.pause();
          this.slides[1].slide_to = null;
        }
        if (this.slides[0] && this.slides[0].slide_to) {
          this.slides[0].slide_to.pause();
          this.slides[0].slide_to = null;
        }
        //this.slides[1].total_replays = -1;

        if (this.dayParent) {
          this.dayParent.style.width = "fit-content";
          this.dayParent = null;
        }
        this.externalPresentation = false;
        this.stopFacts();

        if (memberId <= 0) {
          memberId = this.random_members;
        }
        if (slideId > 0 && sectionId > 0) {
          this.showSpeakerTimer = false;
          this.$socket.emit("goto_controller", { id_string: this.id_string, section_id: sectionId, slide_id: slideId, member_id: memberId, other_id: otherId });
          this.$http.post("/api/organization/slide/get", {other_id: otherId? otherId: 0, populate: true, id: slideId, section_id: sectionId, member_id: memberId, nextId: nextId, memberDescription: { slide_id: slideId } }).then((response) => {
            var temp_slide = response.data.slide;
            if (response.data.memberDescription && response.data.slide_members){
              if((response.data.memberDescription.type ==5 || response.data.memberDescription.type ==15) && response.data.slide_members.length == 1 && response.data.memberDescription.alternate_slide_id>0){
                this.getSlide(sectionId, response.data.memberDescription.alternate_slide_id, response.data.slide_members[0].id,nextId);
                return;
              }
            }

            var current_slide = this.slides[1];
            if (temp_slide.member_ids && Array.isArray(temp_slide.member_ids)) {
              this.random_members = temp_slide.member_ids;
            }

            this.stopAndSilenceTimer();
            if (response.data.settings) {
              this.settings = response.data.settings;
              temp_slide.settings = response.data.settings;
            }
            if (response.data.timer) {
              temp_slide.timer = response.data.timer;
              temp_slide.timer.mins = Math.floor(temp_slide.timer.max_time / 60);
              temp_slide.timer.secs = temp_slide.timer.max_time % 60;
            } else {
              temp_slide.timer = { continuous: 0, count_direction: 0, disabled: 1, type: 0, x_pos: 0, y_pos: 0, x_px: 0, y_px: 0, width: 5, height: 5, font: "Arial", color: "#000", back_color: "#fff", progressive: 0, num_plays: 1, sound_file: "", max_time: 1, secs: 1, mins: 0 };
            } 
            temp_slide.texts = response.data.texts;
            for(var i=0; i<response.data.variable_map.length; i++){
              for(var j=0; j<this.variable_map.length; j++){
                if(this.variable_map[j].id == response.data.variable_map[i].id){
                  this.variable_map[j].value = response.data.variable_map[i].value;
                }
              }
            }
            this.insertVariables(temp_slide);

            temp_slide.graphics = response.data.graphics;
            if(response.data.goal){
              temp_slide.goal = response.data.goal;
            }else{
              temp_slide.goal = {disabled: 1};
            }
            if(response.data.slide_goal){
              temp_slide.slide_goal = response.data.slide_goal;
            }
            if (response.data.memberDescription) {
              if(response.data.memberDescription.type ==1 && response.data.memberDescription.best_weekly==1){
                this.getBestWeekly();
              }
              temp_slide.memberDescription = response.data.memberDescription;
              if ([1,2,3,4,6,7,9,18,23].indexOf(temp_slide.memberDescription.type) !== -1) {
                if (response.data.slide_member) {
                  temp_slide.slide_member = response.data.slide_member;
                } else {
                  temp_slide.slide_member = { name: "", category: "", company: "", photo: null };
                }
              } else if (temp_slide.memberDescription.type == 5 || temp_slide.memberDescription.type == 15 || response.data.memberDescription.type ==17 || response.data.memberDescription.type ==19 || response.data.memberDescription.type==21 || response.data.memberDescription.type==22) {
                if (response.data.slide_members) {
                  temp_slide.slide_members = response.data.slide_members.slice(0, Math.min(temp_slide.memberDescription.num_rows_x * temp_slide.memberDescription.num_rows_y, response.data.slide_members.length));
                } else {
                  temp_slide.slide_members = [];
                }
              }else if (temp_slide.memberDescription.type >=10) {
                if(temp_slide.memberDescription.type ==14){
                  if (response.data.slide_member) {
                      temp_slide.slide_member = response.data.slide_member;
                    }else {
                      temp_slide.slide_member = { name: "", category: "", company: "", photo: null };
                  }
                }
              }
            if(response.data.special_map && response.data.special_map.length>0 ){
              var self = this;
                temp_slide.special_map = response.data.special_map;
                var ptexts = temp_slide.texts;
                var pgraphics = temp_slide.graphics.filter(v => v.special.length>0);
                for(var k=0; k<ptexts.length;k++){
                  if(ptexts[k].special>0){
                    ptexts[k].var_text = ptexts[k].text;
                  }
                }
                temp_slide.special_map.forEach(function (item) {
                  if(item.type==1){
                    if(item.id=="{Special_Measurement_Day}" && item.value != ""){
                    item.value = self.$d(new Date(item.value), 'day_text');
                  }
                  if(item.id=="{Special_Measurement_Period}" && item.value != ""){
                    item.value = self.$t('general.tracking_option_'+item.value);
                  }
                  for(var k=0; k<ptexts.length;k++){
                    if (ptexts[k].var_text.indexOf(item.id) >= 0){
                     if(item.id.includes("QRCode")){
                          ptexts[k].show_qr = true;
                          ptexts[k].var_text = item.value;
                          ptexts[k].is_linkedin = (item.id == "{Member_Linkedin_QRCode}");
                      }else {
                        ptexts[k].var_text = ptexts[k].var_text.split(item.id).join(item.value);
                      } 
                  }
                  }
                  }else if(item.type==2){
                    for(var b=0; b<pgraphics.length;b++){
                      if(pgraphics[b].special == item.id){
                        pgraphics[b].photo = item.value;
                      }
                    }
                  }
                });
              }
            }

            this.slide_transition = "quickfade";
            switch (temp_slide.trans_in) {
              case 1:
                this.slide_transition = "fade";
                break;
              case 2:
                this.slide_transition = "righttoleft";
                break;
              case 3:
                this.slide_transition = "lefttoright";
                break;
              case 4:
                this.slide_transition = "bottomtotop";
                break;
              case 5:
                this.slide_transition = "toptobottom";
                break;
            }


            this.$set(current_slide, "zIndex", 0);
            this.$set(current_slide, "show", false);
            this.$set(current_slide, "backgroundStyle", this.backgroundStyleFunc(current_slide));
            this.$set(this.slides, 0, current_slide);

            this.$set(temp_slide, "show", true);
            this.$set(temp_slide, "zIndex", 10);
            this.$set(temp_slide, "backgroundStyle",this.backgroundStyleFunc(temp_slide));
            this.$set(this.slides, 1, temp_slide);

            for(var text of this.slides[1].texts){
              if(text.fit==1 && text.var_text.length>0){
                this.fitTextToDiv(text);
              }
            }
            if (current_slide.id == temp_slide.id) {
              temp_slide.total_replays = current_slide.total_replays;
            } 

            if (this.externalTab) {
              this.externalTab.close();
              this.externalTab = null;
            }
            var skip = false;
            if((temp_slide.memberDescription.type == 13 || temp_slide.memberDescription.type == 11) && temp_slide.special_map[0].value === "" && this.sections[temp_slide.section_id].looped){
              skip = true;
            }

           
            this.setTimers(current_slide, temp_slide, skip);

          }).catch((errors) => { console.log(errors);});
        }
      },
      insertVariables(temp_slide) {
        var needsFacts = false;
        var self = this;
        temp_slide.var_name = temp_slide.name? temp_slide.name : "";
        this.variable_map.forEach(function (item) {
          var reg = new RegExp('(<span data-type="mention" class="mention" data-id="'+item.id+'.*?<\/span>)', 'g');
          if (temp_slide.var_name) {
            temp_slide.var_name = temp_slide.var_name.replaceAll(reg, (item.value && item.value.length>0? item.value.replaceAll("$", "$$$$"): ""));
          }
        });
        for (var i = 0; i < temp_slide.texts.length; i++) {
          temp_slide.texts[i].html_id = "text" + temp_slide.texts[i].id;
          temp_slide.texts[i].var_text = temp_slide.texts[i].text;
          this.variable_map.forEach(function (item) {
            if(item.id == "{Current_Time}"){
              item.value = self.$d(new Date(), 'time12') ;
            }else if(item.id == "{Current_Date}"){
              item.value = self.$d(new Date(), 'short') ;
            }else if(item.id == "{Slide_Name}"){
              item.value = temp_slide.var_name;
            }else if(item.id == "{Section_Topic}"){
              item.value = temp_slide.section_topic;
            }
            if (item.id == "{On_This_Day}" && temp_slide.texts[i].var_text.indexOf(item.id) >= 0) {
              needsFacts = true;
            }
            if(item.id.includes("QRCode") && temp_slide.texts[i].var_text.indexOf(item.id) >= 0){
              temp_slide.texts[i].show_qr = true;
              temp_slide.texts[i].var_text = item.value;
            }else{
              var reg = new RegExp('(<span data-type="mention" class="mention" data-id="'+item.id+'.*?<\/span>)', 'g');
              temp_slide.texts[i].var_text  = temp_slide.texts[i].var_text .replaceAll(reg, (item.value && item.value.length>0? item.value.replaceAll("$", "$$$$"): ""));
            }
          });
          const regexpSize = /{Show_QRCode_(.*)}/;
          const match = temp_slide.texts[i].var_text.match(regexpSize);
          if(match && match.length>1){
            temp_slide.texts[i].show_qr = true;
            temp_slide.texts[i].var_text = match[1];
          }
          
        }
         if (needsFacts) {
          this.fact_num =1;
          this.cycleFacts();
        }
        return needsFacts;
      },
      fitTextToDiv(text){
        var self = this;
        setTimeout(function () {
          var d = document.getElementById(text.html_id);
          if(d){
            if(!d.children[0]){
              return;
            }
            var i = parseFloat(d.children[0].style.fontSize.slice(0,-2));
            if(isNaN(i)){
              i = parseFloat(d.style.fontSize.slice(0,-2));
            }
            if(d.children[0].scrollHeight<d.offsetHeight){
              for(var t = 0; t<200;t++){
                if(d.children[0].scrollHeight<d.offsetHeight-16){
                    d.children[0].style.fontSize =  i+"px";
                    d.children[0].style.lineHeight = 100*(text.spacing)+"%";
                    i++;
                }else{
                  break;
                }
              }
              i-=2;
            }else{
              for(var b = 0; b<200;b++){
                if(d.children[0].scrollHeight>d.offsetHeight-8){
                    d.children[0].style.fontSize =  i+"px";
                    d.children[0].style.lineHeight = 100*(text.spacing)+"%";
                    i--;
                }else{
                  break;
                }
              }
              i+=2;
            }
            text.size = (i/self.windowHeight)*100;
            d.children[0].style.fontSize = d.children[0].style.lineHeight = "";
          }else{
            self.fitTextToDiv(text);
          }
        }, document.getElementById(text.html_id)? 0 : 10);
      },
      populateVariables(response) {
        this.variable_map = response.data.variable_map;
      },
      renewVariables() {
        this.$http.post("/api/organization/slide/get/variables", {}).then((response) => {
          if (response.data.success) {
            if (response.data.variables.events) {
              this.facts = response.data.variables.events;
            } else {
              this.facts = [{ event: this.$t('slide.nothing_happened') }];
            }
            this.variable_map = response.data.variable_map;
          }
        }).catch((errors) => {console.log(errors); });
      },
      getTimeString(){
        return ((Math.floor(this.current_time/60)>0? Math.floor(this.current_time/60)+":" : "")+((Math.floor(this.current_time)%60) < 10 && Math.floor(this.current_time/60)>0? "0"+(Math.floor(this.current_time)%60) : (Math.floor(this.current_time)%60)))
      },
      cycleFacts() {
        if (this.checkInterval) {
          clearInterval(this.checkInterval);
          this.checkInterval = null;
        }
        var self = this;
        this.checkInterval = setInterval(function () {
          var dayElements = document.getElementsByClassName("on-this-day")
          if (dayElements.length > 0) {
            for (var i = 0; i < dayElements.length; i++) {
              var dayElement = dayElements[i];
              self.dayParent = dayElement.parentElement.parentElement;
              self.dayParent.style.width = (100 - parseFloat(dayElement.parentElement.parentElement.style.left.replace("%", "")) * 2) + "%";
              dayElement.style.visibility = "visible";
              if (self.facts.length > 0) {
                if (self.factInterval) {
                  clearInterval(self.factInterval);
                  self.factInterval = null;
                }
                self.factInterval = setInterval(() => {
                  var dayElements2 = document.getElementsByClassName("on-this-day")
                  for (var i = 0; i < dayElements2.length; i++) {
                    var dayElement2 = dayElements2[i];
                    if (dayElement2 && self.facts.length > 1) {
                      dayElement2.innerHTML = self.facts[self.fact_num].event;
                      self.fact_num = (self.fact_num + 1) % self.facts.length;
                    }
                  }
                  
                }, 8000);
              }
              if (self.checkInterval) {
                clearInterval(self.checkInterval);
                self.checkInterval = null;
              }
            }
            
          }
        }, 50); 
      },
      stopFacts() {
        if (this.dayParent) {
          this.dayParent.style.width = "fit-content";
          self.dayParent = null;
        }
        if (this.checkInterval) {
          clearInterval(this.checkInterval);
          this.checkInterval = null;
        }
        if (this.factInterval) {
          clearInterval(this.factInterval);
          this.factInterval = null;
        }
      },
      stopAndSilenceTimer() {
        this.slides[1].timer.has_ever_started = true;
        if (this.slides[1].timer && this.slides[1].timer.interval) {
          clearInterval(this.slides[1].timer.interval);
          this.slides[1].timer.interval = null;
        }
        if (this.slides[1].timer && this.slides[1].timer.current_audio) {
          this.slides[1].timer.current_audio.pause();
          if (this.slides[1].timer.timeout) {
            this.slides[1].timer.timeout.pause();
          }
          this.slides[1].timer.current_audio = null;
        }
      },
      resumeTimer() {
        this.slides[1].timer.has_ever_started = true;
        if (this.slides[1].timer && this.current_time>0) {
          this.slides[1].timer.end_time = this.current_time + (new Date()).getTime() / 1000;
          this.slides[1].timer.interval = setInterval(() => {
            var secs = this.slides[1].timer.end_time - (new Date()).getTime() / 1000;
            if (secs > 0) {
              this.current_time = secs;
            } else {
              clearInterval(this.slides[1].timer.interval);
              this.slides[1].timer.interval = null;
              this.current_time = 0;
              this.timerFinished();
            }
          }, 50);
        }else if(this.slides[1].timer && this.current_time == 0){
          this.slides[1].timer.done = true;
          this.slides[1].timer.volume_inc = 1;
          this.slides[1].timer.volume = 1;
          this.slides[1].timer.continuous = 1;
          this.setTimerTimeout();
        }
      },
      setTimers(slide0, slide1, skip) {
        if (slide1.timer && !slide1.timer.disabled && !slide1.timer.end_time) {
          slide1.timer.done = false;
          if(slide1.timer.time_default == 0){
            
            switch(slide1.timer.default_option){
              default:
              case 0:
                slide1.timer.max_time = this.organization_info.weekly_presentation_min * 60 + this.organization_info.weekly_presentation_sec;
                break;
              case 1:
                slide1.timer.max_time = slide1.slide_member && slide1.slide_member.contact_type && slide1.slide_member.contact_type.length>0? this.organization_info.regional_presentation_min * 60 + this.organization_info.regional_presentation_sec : this.organization_info.speaker_presentation_min * 60 + this.organization_info.speaker_presentation_sec;
                break;
              case 2:
                slide1.timer.max_time = this.organization_info.referral_min * 60 + this.organization_info.referral_sec;
                break;
            }
          }
         var self = this;
          this.current_time = slide1.timer.max_time;
          slide1.timer.end_time = slide1.timer.max_time + (new Date()).getTime() / 1000;
          if(slide1.timer.auto_start==1 && slide1.timer.type!=2){
            var self2 = this;
            slide1.timer.has_ever_started = false;
            slide1.timer.auto_start_timeout = new Timer(function(){
              if(!slide1.timer.has_ever_started){
                slide1.timer.end_time = slide1.timer.max_time + (new Date()).getTime() / 1000;
                slide1.timer.interval = setInterval(() => {
                    var secs = slide1.timer.end_time - (new Date()).getTime() / 1000;
                    if (secs > 0) {
                      self2.current_time = secs;
                    } else {
                      clearInterval(slide1.timer.interval);
                      slide1.timer.interval = null;
                      self2.current_time = 0;
                      self2.timerFinished();
                    }
                }, 50);
              }
              self.syncTimers();
            }, slide1.timer.delay_time*1000)
          }
          
        }

        //set slide end timer
        if (this.sections[slide1.section_id] && this.sections[slide1.section_id].slide_data && this.sections[slide1.section_id].slide_data[slide1.id]) {
          var slideData = this.sections[slide1.section_id].slide_data[slide1.id];
          if ((slide1.total_replays <= 0 || !slide1.total_replays) && slideData.replay_times > 0) {
            slide1.total_replays = slideData.replay_times;
          }
          if (slideData.then_action!= 3 && slideData.pauseSeconds > 0) {
            var self = this;
            slide1.slide_to = new Timer(function () {
              if (slideData.then_action == 1 ) {
                slide1.total_replays -= 1;
                if (slide1.total_replays > 0) {
                  self.getSlide(slide1.section_id, slide1.id, -1);
                }
              }
              if (slideData.then_action == 2 || (slideData.then_action == 1 && self.slides[1].total_replays == 0)) {
                for (var k = 0; k < self.sections[slide1.section_id].slides.length; k++) {
                  if (self.sections[slide1.section_id].slides[k].id == self.slides[1].id) {
                    if (k == self.sections[slide1.section_id].slides.length - 1) {
                      if (self.sections[slide1.section_id].looped) {
                        self.getSlide(slide1.section_id,self.sections[self.slides[1].section_id].slides[0].id,-1,0);
                      } else {
                        for (var h = 0; h < self.sections_array.length; h++) {
                          if (self.sections_array[h].section_id == slide1.section_id && h < self.sections_array.length-1) {
                            self.getSlide(self.sections_array[h + 1].section_id, self.sections_array[h + 1].slides[0].id,-1,0);
                            break;
                          }
                        }
                      }
                    } else {
                      self.getSlide(slide1.section_id, self.sections[slide1.section_id].slides[k + 1].id,-1,0);
                    }
                    break;
                  }
                }
              }
            }, skip? 0 : slideData.pauseSeconds*1000);
          }
        }
      },
      timerFinished() {
        var prog = this.slides[1].timer.progressive == 1 && this.slides[1].timer.num_plays > 0;
        this.slides[1].timer.done = true;
        this.slides[1].timer.volume_inc = prog? 2 : 1;
        this.slides[1].timer.volume = prog ? 1 / Math.pow(2, this.slides[1].timer.num_plays) : 1;
        this.stopCurrentTimer();
        this.setTimerTimeout();
      },
      setTimerTimeout() {
        if (this.slides[1].timer && !this.slides[1].timer.disabled && this.slides[1].timer.done && (this.slides[1].timer.num_plays > 0 || this.slides[1].timer.continuous > 0) && this.slides[1].timer.sound_file.length > 0) {
          //this.slides[1].timer.current_audio = this.audio_object;
          //this.slides[1].timer.current_audio.src = this.getImgUrl("sounds/"+this.slides[1].timer.sound_file);
          this.slides[1].timer.current_audio= new Audio(this.getImgUrl("sounds/"+this.slides[1].timer.sound_file));
          this.slides[1].timer.current_audio.load();
          if (this.slides[1].timer.num_plays > 0) {
            this.slides[1].timer.volume *= this.slides[1].timer.volume_inc;
            this.slides[1].timer.current_audio.volume = this.slides[1].timer.volume*this.global_volume;
            this.slides[1].timer.num_plays -= 1;
          }
          
          this.slides[1].timer.current_audio.addEventListener("ended", this.timerEnd);
           var self = this;
           /*self.slides[1].timer.current_audio.play(); console.log(error);*/
          this.slides[1].timer.current_audio.oncanplaythrough = function() {
            //self.$socket.emit("play_audio", { id_string: self.id_string, timer: self.slides[1].timer });
             var promise = self.slides[1].timer.current_audio.play();
              if (promise !== undefined && promise  !== null) {
                promise.catch(function (error) { 
                  console.log(error);
                  self.$socket.emit("play_audio", { id_string: self.id_string, timer: self.slides[1].timer });
                });
              }
          };
        }else{
           this.stopCurrentTimer();
        }
      },
      timerEnd() {
        var self = this;
          self.slides[1].timer.timeout = new Timer(function () {
            if (self.slides[1].timer.current_audio) {
              self.setTimerTimeout();
            }
          }, 500);
      },
      stopCurrentTimer() {
        if (this.slides[1].timer.current_audio) {
          this.slides[1].timer.current_audio.pause();
          this.slides[1].timer.current_audio.removeEventListener("ended", this.timerEnd);
          this.slides[1].timer.current_audio = null;
        }
        if (this.slides[0].timer.current_audio) {
          this.slides[0].timer.current_audio.pause();
          this.slides[0].timer.current_audio.removeEventListener("ended", this.timerEnd);
          this.slides[0].timer.current_audio = null;
        }
      },
      startMeeting() {
        this.getSections();
      },
      cancelMeeting() {
        window.history.length > 1 ? router.go(-1) : router.push("/admin/members");
      },
      focusWindow() {
        var win = document.getElementById('external-iframe').contentWindow;
        var range = document.createRange();
        range.setStart(win.document.body, 0);
        range.setEnd(win.document.body, 0);
        win.document.body.focus();
        win.getSelection().addRange(range);

        /*setTimeout(function () {
          document.getElementById('external-iframe').contentWindow.focus()
        }, 100);*/
      },
      stallTimers() {
        /*if (this.slides[1].slide_to) {
          clearTimeout(this.slides[1].slide_to);
          this.slides[1].slide_to = null;
        }
        this.section_force_times = [];
        if (this.force_interval) {
          clearTimeout(this.force_interval);
        }*/
        if (this.slides[1].slide_to) {
          this.slides[1].slide_to.pause();
        }
        this.pauseIntervals = true;
        this.stopAndSilenceTimer();
      },
      resumeTimers(){
        if (this.slides[1].slide_to) {
          this.slides[1].slide_to.resume();
        }
        this.pauseIntervals = false;
        if (this.slides[1].timer && this.current_time>0) {
          this.slides[1].timer.end_time = this.current_time + (new Date()).getTime() / 1000;
          this.slides[1].timer.interval = setInterval(() => {
            var secs = this.slides[1].timer.end_time - (new Date()).getTime() / 1000;
            if (secs > 0) {
              this.current_time = secs;
            } else {
              clearInterval(this.slides[1].timer.interval);
              this.slides[1].timer.interval = null;
              this.current_time = 0;
              this.timerFinished();
            }
          }, 50);
        }
      },
      syncPdfController(pdfDescrip){
        if(this.pdfRet){
          this.pdfPages = this.pdfRet.pages;
        }
        if(pdfDescrip){
          this.pdfRatio = pdfDescrip.width/pdfDescrip.height;
        }
        
        this.$socket.emit("presentation_info", { id_string: this.id_string, maxSlide: this.pdfPages, slide: this.pdfPage, file: this.pdfName,hash: this.pdfHash, showing:true});
      },
      syncPDFControllerTimer(endTime, isRunning){
        if(this.pdfHash.length>0 && endTime>0){
          this.$socket.emit("presentation_info", { id_string: this.id_string,hash: this.pdfHash, showing: true, timer: {endTime: endTime, isRunning: isRunning,disabled: this.slides[1].timer.disabled}});
        }
      },
      getYoutubeEmbed(url) {
          const regExp = /(?:http:|https:)*?\/\/(?:www\.|)(?:youtube\.com|m\.youtube\.com|youtu\.|youtube-nocookie\.com).*(?:v=|v%3D|v\/|(?:a|p)\/(?:a|u)\/\d.*\/|watch\?|vi(?:=|\/)|\/embed\/|oembed\?|be\/|e\/)([^&?%#\n]*)/;
          const match = url.match(regExp);

          return (match && match[1].length === 11)
            ? match[1]
            : null;
      },
      getVimeoEmbed(url) {
          const regExp = /(?:http:|https:)*?\/\/(?:www\.|)(?:vimeo\.com|player\.vimeo\.com\/video)\/([0-9]*)/;
          const match = url.match(regExp);

          return (match && match[1].length >0) ? match[1] : null;;
      },
      syncTimers(){
        var self = this;
        var timerPlaying = self.slides[1].timer.interval || self.slides[1].timer.current_audio;
        var end_time = this.slides[1] && this.slides[1].timer? this.slides[1].timer.end_time : 0;
        var slide_type = this.slides[1] && this.slides[1].memberDescription? this.slides[1].memberDescription.type: 0;
        this.$socket.emit("sync_timer", { id_string: this.id_string, end_time: end_time, current_time: this.current_time, timer_playing: timerPlaying, hash: this.pdfHash, timer_showing:this.showSpeakerTimer, slide_type: slide_type});
      },
      syncControllerTimers(time, playing){
        var slide_type = this.slides[1] && this.slides[1].memberDescription? this.slides[1].memberDescription.type: 0;
        this.$socket.emit("sync_timer", { id_string: this.id_string, end_time: (new Date()).getTime() / 1000+time, current_time: time, timer_playing: playing, hash: this.pdfHash, timer_showing: false, slide_type: slide_type});
      },
      onProgress({ loaded, total }) {
        this.pdfLoad = loaded/total;
      },

      onError(reason) {
        console.error(`PDF loading error: ${reason}`)
      },
      connectWithId(uid){
        this.id_string = uid;
        this.$socket.emit("register", { id_string: this.id_string });
        this.$socket.emit("view_ready", { id_string: this.id_string , ready: true });
        this.connected = true;
      },

      connect() {
        if (this.id_string.length > 0) {
          this.$socket.emit("register", { id_string: this.id_string });
          this.$socket.emit("view_ready", { id_string: this.id_string, ready: true });
        }
          this.$http.post("/api/client/get", {controller: false}).then((response) => {
            if (response.data.id_string) {
              this.connectWithId(response.data.id_string);
            }
          }).catch((errors) => {
            console.log(errors)
          });
      },
      goToPSlide(n){
        var self = this;
        if(window.frames[0].document.getElementById("pf"+self.pslide)){
          
          window.frames[0].document.getElementById("pf"+self.pslide).style.display = 'none';
          self.pslide = n;
          self.$socket.emit("presentation_info", { id_string: self.id_string, maxSlide: null, slide: n});
          var scale = Math.min(window.innerWidth/960,window.innerHeight/540);
          var height = 540*scale;
          var width = 960*scale;
          var transHeight = (window.innerHeight-height)/2.0;
          var transWidth = (window.innerWidth-width)/2.0;
            
          window.frames[0].document.getElementById("pf"+self.pslide).style.transform= 'translate('+transWidth+'px,'+transHeight+'px) scale('+scale+') ';
          window.frames[0].document.getElementById("pf"+self.pslide).style.display = 'block';
        }
      },
      getSections() {
        this.$http.post("/api/meeting/sections/slides", {}).then((response) => {
          this.sections_array = response.data.sections;
          this.sections = {};
          if (response.data.variables && response.data.variables.events) {
            this.facts = response.data.variables.events;
          } else {
            this.facts = [{ event: "Apparently nothing of note occured." }];
          }
          this.organization_info = response.data.organization;
          
          this.populateVariables(response);

          var day = new Date();
          var cc = day.toLocaleString("en-US", { hour12: false, timetimezone: "EST", day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit" });
          var dsl = cc.slice(6, 10) + "/" + cc.slice(0, 2) + "/" + cc.slice(3, 5);

          for (var i = 0; i < response.data.sections.length; i++) {
            if(response.data.sections[i].slide_data){
              for (var j = 0; j < response.data.sections[i].slide_data.length; j++) {
                this.sections[response.data.sections[i].section_id].slides[response.data.sections[i].slide_data[j].id] = response.data.sections[i].slide_data[j];
              }
            }
            this.sections[response.data.sections[i].section_id] = response.data.sections[i];
            if (this.sections[response.data.sections[i].section_id].force_time != null && this.sections[response.data.sections[i].section_id].slides && this.sections[response.data.sections[i].section_id].slides.length > 0) {
              this.section_force_times.push({ id: response.data.sections[i].section_id, t: new Date(dsl + " " + this.sections[response.data.sections[i].section_id].force_time).getTime() });
            }
          }

          this.last_force_time = new Date().getTime();
          var self = this;
          this.force_interval = setInterval(() => {
            if(!this.pauseIntervals){
              var ct = new Date().getTime();
              var s = null;
              for (var i = 0; i < self.section_force_times.length; i++) {
                if (self.section_force_times[i].t > self.last_force_time && self.section_force_times[i].t <= ct) {
                  s = self.section_force_times[i];
                  self.getSlide(s.id, self.sections[s.id].slides[0].id, -1,0);
                  break;
                }
              }
              if (s != null) {
                var index = self.section_force_times.indexOf(s);
                if (index > -1) {
                  self.section_force_times.splice(index, 1);
                }
              }
            }
          }, 500);

        }).catch((errors) => {
          console.log(errors)
        });
      },

      onResize() {
         const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
        var a = vw/vh;
        this.aspectRatioIs169 = Math.abs(a-16.0/9.0)<0.1;
        if (document.getElementById("meeting-div")) {
          var pWidth = document.getElementById("meeting-div").parentElement.offsetWidth;
          var pHeight = document.getElementById("meeting-div").parentElement.offsetHeight;
          if(this.permissions.lockAspect){
            var r = this.ratioOptions[this.settings.edit_ratio];
            if(pWidth*r > pHeight){
              this.windowHeight = pHeight;
              this.windowWidth = pHeight/r;
            }else{
              this.windowWidth = pWidth;
              this.windowHeight = pWidth*r;
            }
          }else{
            this.windowWidth = pWidth;
              this.windowHeight = pHeight;
          }
        }
        if(document.getElementById("meeting-div")){
          this.fileWidth=document.getElementById("meeting-div").offsetWidth;
          this.fileHeight=document.getElementById("meeting-div").offsetHeight;
        }
        //var self = this;
        /*if(window.frames[0].document.getElementById("pf"+self.pslide)){
          var scale = Math.min(window.innerWidth/960,window.innerHeight/540);
          var height = 540*scale;
          var width = 960*scale;
          var transHeight = (window.innerHeight-height)/2.0;
          var transWidth = (window.innerWidth-width)/2.0;
            
          window.frames[0].document.getElementById("pf"+self.pslide).style.transform= 'translate('+transWidth+'px,'+transHeight+'px) scale('+scale+')';
        }*/
      },
      onBack() {
        if (this.id_string.length > 0) {
          this.$socket.emit("view_ready", { id_string: this.id_string, ready: false });
          //this.$socket.disconnect();
        }
      },
      hideMouse() {
        if (this.mouseTimeout) {
          clearTimeout(this.mouseTimeout);
        }
        var self = this;
        this.mouseTimeout = setTimeout(function () {
          if (!self.mouseIsHidden) {
            document.body.style.cursor = "none";
            self.mouseIsHidden = true;
            //self.showHeader = false;
          }
        }, 3000);
        if (this.mouseIsHidden) {
          document.body.style.cursor = "auto";
          this.mouseIsHidden = false;
        }
      },
     
      auth() {
        var self = this;
        this.authenticate(function(){
           if(!self.test){
            document.title = self.$t('global.document_title_present') +" "+ self.permissions.organization.name;
           }
        });
      },
        back(){
            window.history.length > 1 ? router.go(-1) : router.push("/admin/dashboard");
          },
        fullScreen(){
          this.isNotFullScreen = ((document.fullScreenElement && document.fullScreenElement !== null) ||
          (!document.mozFullScreen && !document.webkitIsFullScreen));
        },
        getLanguages(){
        this.$http.post("/api/organization/languages", {}).then((response) => {
          if (response.data.success === true) {
            this.languageOptions = [];
            for(var i=0; i<response.data.languages.length; i++){
                this.languageOptions.push(response.data.languages[i]);
            }
          }
        }).catch((errors) => {
          console.log(errors)
        });
      },
      getBestWeekly(){
        this.$http.post("/api/organization/newsletter/get/best_weekly", {}).then((response) => {
          if (response.data.success === true) {
            this.best_weekly = response.data.best_weekly;
          }
        }).catch((errors) => {
          console.log(errors)
        });
       },
      logout() {
       window.close();
      },
      refresh(){
        window.location.reload();
      }
    },
    created() {
       if(!this.test){
        document.title = this.$t('global.document_title_present') +" "+ this.permissions.organization.name;
       }
       
      window.addEventListener("resize", this.onResize);
      window.addEventListener("mousemove", this.hideMouse);
      document.body.style.overflow = "hidden";
      this.hideMouse();

       
    },
    destroyed() {
      this.$socket.emit("view_ready", {id_string: this.id_string, ready: false });
      //this.$socket.disconnect();
      if (this.slides[1].slide_to) {
        this.slides[1].slide_to.pause();
        this.slides[1].slide_to = null;
      }
      this.section_force_times = [];
      if (this.force_interval) {
        clearTimeout(this.force_interval);
      }
      this.stopFacts();
      this.stopAndSilenceTimer();
      
      this.meetingStarted = false;
      this.connected = false;
      this.id_string = "";
      
      window.removeEventListener("resize", this.onResize);
      window.removeEventListener("mousemove", this.hideMouse);
      clearTimeout(this.mouseTimeout);
      document.body.style.overflow = "auto";
      document.body.style.cursor = "auto";
    },
    mounted() {
      if(!this.isCorporateConnections){
        (function() {
              var link = document.querySelector("link[rel~='icon']");
              if (!link) {
                  link = document.createElement('link');
                  link.rel = 'icon';
                  document.getElementsByTagName('head')[0].appendChild(link);
              }
              link.href = favicon;
          })();
        }
      if(!this.test){
        this.getLanguages();
      }
      this.getBestWeekly();
      var self = this;
      window.addEventListener("focus", function(event) {
        self.authenticate();
      }, false);
      this.audio_object = document.createElement('audio');
      this.onResize();
      this.auth();
      if (this.id_string.length > 0) {
        this.$socket.emit("view_ready", { id_string: this.id_string, ready: false });
      }
      this.connect();
      
      this.$root.$on('paneSizeChange', () => {
         self.onResize();
      });
      this.hideHeader();
      
    }
  }
</script>
