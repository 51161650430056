<template>
  <div>
    <div id="page-wrapper" class="rel-grid-container">
      <div ></div>
      <Tip :tipNumber="17"></Tip>
      <div v-if="needsDate" id="need-date">{{$t('global.need_date')}}</div>
      <div id="recog-box"  class="fit-content" v-if="!needsDate">
        <div>
          <span class="form-box-title">{{$t('role.network_leaders')}}</span>
          <div class="form-box fit-width recognition-form-box">
            <div class="recognition-inner-box">
                <span class="form-box-name">{{$t('role.add_member')}}</span>
                 <multiselect class="member-select blueselect" v-model="network_leaders" :options="members" :object="true" :placeholder="$t('global.select_members')" label="name" track-by="name" :noOptionsText="$t('global.empty_list')"  mode="tags" valueProp="id" :close-on-select="false" :searchable="true">
                </multiselect>
                 <span class="form-box-name center-text column-span2">{{$t('role.selected_members')}}</span>
                 <table class="table b-table  table-sm table-hover-new column-span2">
                  <thead>
                    <tr>
                      <th >{{$t('role.name_header')}}</th>
                      <th>{{$t('role.note_header')}}</th>
                      <th class="center-text" >{{$t('role.remove_header')}}</th>
                    </tr>
                  </thead>
                  <draggable v-if="permissions.access" tag="tbody" class="column-span2" v-model="network_leaders" item-key="id">
                    <template #item="{element}">
                      <tr >
                        <td class="vert-middle">{{element.name}}</td>
                        <td><b-form-input v-model="element.note" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" maxlength="255"></b-form-input></td>
                        <td class="vert-middle"><span class="trash-recog skinned-image" variant="blue" v-on:click="deleteLeader(element)"></span></td>
                      </tr>
                    </template>
                  </draggable>
                  </table>
            </div>
          </div>
        </div>
        <div>
          <span class="form-box-title">{{$t('role.renewing_members')}}</span>
          <div class="form-box fit-width recognition-form-box">
            <div class="recognition-inner-box">
                <span class="form-box-name">{{$t('role.add_member')}}</span>
                <multiselect class="member-select blueselect" v-model="renewing_members" :options="members" :object="true" :placeholder="$t('global.select_members')" label="name" track-by="name" :noOptionsText="$t('global.empty_list')"  mode="tags" valueProp="id" :close-on-select="false" :searchable="true">
                </multiselect>
                 <span class="form-box-name center-text column-span2">{{$t('role.selected_members')}}</span>
                  <table class="table b-table  table-sm table-hover-new column-span2">
                  <thead>
                    <tr>
                      <th >{{$t('role.name_header')}}</th>
                      <th v-if="allow_renew_date==1">{{$t('role.renewal_date')}}</th>
                      <th class="center-text" >{{$t('role.remove_header')}}</th>
                    </tr>
                  </thead>
                  <draggable v-if="permissions.access" tag="tbody" class="column-span2" v-model="renewing_members" item-key="id">
                    <template #item="{element}">
                      <tr>
                        <td class="vert-middle">{{element.name}}</td>
                        <td v-if="allow_renew_date==1"><b-form-select id="month-options" v-model="element.renew_date" :options="monthOptions" /></td>
                        <td class="vert-middle"><span class="trash-recog skinned-image" variant="blue" v-on:click="deleteRenewing(element)"></span></td>
                      </tr>
                    </template>
                  </draggable>
                  </table>
            </div>
          </div>
        </div>
      </div>
      <div v-if="allow_top_performing==1 && !needsDate" >
        <span class="form-box-title">{{$t('role.top_performing')}}</span>
        <div id="top-performer-box" class="form-box fit-width">
          <div id="period-box">
            <div id="tracking-wrapper" class="fit-width grid-auto-2column auot-margin">
              <span  class="form-box-name">{{$t('role.period_text')}}</span>
              <b-form-select id="tracking-select" v-model="top_performing_period" :options="tracking_nums"  />
            </div>
          </div>
          <div :id="'top-performer'+i" v-for=" i in [1,2,3,4,5,6]" :key="i" >
            <span class="form-box-title">{{$t('role.top_performer'+i)}}</span>
            <div class="performers-wrapper">
                <multiselect class="member-select-small blueselect" v-model="top_performers[i]" :options="recog_members[i]" :object="true" :placeholder="$t('global.select_members')" label="name" track-by="name" :noOptionsText="$t('global.empty_list')"  mode="tags" valueProp="id" :close-on-select="false" :searchable="true">
                </multiselect>
                  <span class="form-box-name center-text column-span2">{{$t('role.selected_members')}}</span>
                  <table class="table b-table table-sm table-hover-new column-span2">
                  <thead>
                    <tr>
                      <th >{{$t('role.name_header')}}</th>
                      <th >{{$t('role.amount_header')}}</th>
                    </tr>
                  </thead>
                  <draggable v-if="permissions.access" tag="tbody" class="column-span2" v-model="top_performers[i]" item-key="id">
                    <template #item="{element}">
                      <tr>
                        <td class="curtail-text .element-name">{{element.name}}</td>
                        <td ><b-form-input v-model="element.amount" class="element-amount" type="number" pattern="[^<>]*" :title="$t('global.pattern_title')" maxlength="255"/></td>
                      </tr>
                    </template>
                  </draggable>
                  </table>
            </div>
          </div>
        </div>
      </div>
      <div id="save-wrapper" class="submit-box-2" v-if="!needsDate">
        <b-button class="action-button" v-on:click="saveRecognition" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
      </div>
      <div class="div-space"></div>
    </div>
  </div>
</template>

<style scoped>
#tracking-wrapper{
  margin: auto;
  margin-top:20px;
}
.performers-wrapper{
  padding: 10px;width: 100%;margin: auto;padding-top: 25px;
}
#tracking-select{
  min-width:200px;
}
.save-wrapper{
  margin-top:50px;
}
.element-amount{
  width: 100px;margin: auto;
}
.element-name{
  vertical-align:middle;font-size:14px;max-width:100px;
}
.month-options{
  max-width:120px;
}
#page-wrapper{
  max-width:100%;
}
#need-date{
  text-align: center;font-size: 20px;
}
.vert-middle{
  vertical-align: middle;
}
.recognition-inner-box{
  display: grid; grid-template-columns: auto 400px;
}
.recognition-form-box{
  margin:10px auto;grid-template-columns:auto;grid-gap:20px;
}
.member-select{
  min-width:400px;
}
.member-select-small{
  min-width:200px;
  font-size: 12px;
}
#recog-box{
  display: grid; grid-template-columns: 1fr 1fr; grid-gap: 30px;margin:auto;
}
#top-performer-box{
  margin: 10px auto;grid-template-columns: repeat(6, 280px);text-align: center;padding: 0;row-gap:10px;
}
 .trash-recog {
      width: 30px;
    height: 30px;
    background-image: url("../assets/button-Close.png");
    border: none;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: block;
    margin: auto;
    text-align: center;
    cursor: pointer;
  }
  #top-performer1,#top-performer2,#top-performer3,#top-performer4,#top-performer5{
    border-right: 1px solid #999;
  }
  #period-box{
    grid-column:1/7;
  }
  
   #recog-box  {
        grid-template-columns: 1fr;
    }
    #top-performer-box{
      grid-template-columns: repeat(3, 270px);
    }
    #top-performer3{
      border: none;
    }
    #period-box{
      grid-column:1/4;
    }
  #top-performer1,#top-performer2,#top-performer3{
    border-right: 1px solid #999;
  }
  #top-performer4{
    border: none;
  }
  #period-box{
    grid-column:1/5;
  }
  @media (max-width: 1150px) {
   #recog-box  {
        grid-template-columns: 1fr;
    }
    #top-performer-box{
      grid-template-columns: repeat(2, 270px);
    }
    #top-performer2,#top-performer4{
      border: none;
    }
    #period-box{
    grid-column:1/3;
  }
  }
</style>
<script>
import router from "../router"
import draggable from "vuedraggable";
    export default {
      name: "Recognition",
      components: {  draggable},
      data() {
        return {
          needsDate: false,
          top_performing_period: 0,
          recognition: [],
          members: [],
          recog_members: {1: [], 2: [], 3: [], 4: [], 5: [], 6: []},
          renewing_members: [],
          network_leaders: [],
          top_performers: {1: [], 2: [], 3: [], 4: [], 5: [], 6: []},
          monthOptions: [{value: 0, text: ''},{value: 1, text: this.$t('global.january')},{value: 2, text: this.$t('global.february')},{value: 3, text: this.$t('global.march')},
          {value: 4, text: this.$t('global.april')},{value: 5, text: this.$t('global.may')},{value: 6, text: this.$t('global.june')},
          {value: 7, text: this.$t('global.july')},{value: 8, text: this.$t('global.august')},{value: 9, text: this.$t('global.september')},
          {value: 10, text: this.$t('global.october')},{value: 11, text: this.$t('global.november')},{value: 12, text: this.$t('global.december')}],
          allow_renew_date: 0,
          allow_top_performing: 0,
          trackingOptions: [
          { value: 1, text: this.$t('general.tracking_option_1') },
          { value: 2, text: this.$t('general.tracking_option_2') },
          { value: 4, text: this.$t('general.tracking_option_4') },
          { value: 8, text: this.$t('general.tracking_option_8') },
          { value: 16, text: this.$t('general.tracking_option_16') },
          { value: 32, text: this.$t('general.tracking_option_32') },
          { value: 64, text: this.$t('general.tracking_option_64') },],
          tracking_nums: []
        }
      },

      watch: {
      '$i18n.locale': {
        handler: function () {
          this.getRecognition();
        }
      }
    },
    methods: {
      addRenewing(index){
        console.log(index);
      },
      deleteLeader(m){
        var index = this.network_leaders.indexOf(m);
        if (index > -1) {
          this.network_leaders.splice(index, 1);
        }
      },
      deleteRenewing(m){
        var index = this.renewing_members.indexOf(m);
            if (index > -1) {
              this.renewing_members.splice(index, 1);
            }
      },
      getRecognition() {
        this.$http.post("/api/organization/recognition", {}).then((response) => {
          if (response.data.success) {
            this.top_performing_period = response.data.top_performing_period;
            this.network_leaders = response.data.network_leaders;
            this.renewing_members = response.data.renewing_members;
            this.top_performers = {1: [], 2: [], 3: [], 4: [], 5: [], 6: []};
            for(var i=0; i<response.data.top_performing.length; i++){
              var h = response.data.top_performing[i];
              if(this.top_performers[h.type] != undefined || this.top_performers[h.type] != null){
                this.top_performers[h.type].push(h);
              }
            }
          }else{
            this.needsDate = true;
          }
        }).catch(() => {
        });
      },
      getMembers() {
        this.$http.post("/api/organization/members/email/select", {}).then((response) => {
          this.recog_members={1: [], 2: [], 3: [], 4: []};
          for(var i=0; i<response.data.members.length;i++){
            response.data.members[i].note = "";
            response.data.members[i].renew_date = 0;
            response.data.members[i].amount = 0;
          }
          this.recog_members[1] = JSON.parse(JSON.stringify(response.data.members));
          this.recog_members[2] = JSON.parse(JSON.stringify(response.data.members));
          this.recog_members[3] = JSON.parse(JSON.stringify(response.data.members));
          this.recog_members[4] = JSON.parse(JSON.stringify(response.data.members));
          this.recog_members[5] = JSON.parse(JSON.stringify(response.data.members));
          this.recog_members[6] = JSON.parse(JSON.stringify(response.data.members));
          this.members = response.data.members;
          
          
        }).catch(() => {});
      },
      getAllowRenewDate() {
        this.$http.post("/api/region/get/allowedrecog", {}).then((response) => {
          this.allow_renew_date = response.data.allow_renew_date;
          this.allow_top_performing = response.data.allow_top_performing;
        }).catch(() => {});
      },
      saveRecognition() {
        var top_performing = [];
        for(var i=1; i<7; i++){
          for(var j=0; j<this.top_performers[i].length;j++){
            var m = JSON.parse(JSON.stringify(this.top_performers[i][j]));
            m.type = i;
            if(m.amount == undefined || m.amount == null || m.amount == ''){
              m.amount = 0;
            }
            top_performing.push(m);
          }
        }
        this.$http.post("/api/organization/recognition/update", {top_performing_period: this.top_performing_period, network_leaders: this.network_leaders, renewing_members: this.renewing_members,top_performing : top_performing}).then((response) => {
          if (response.data.success) {
            this.back();
          }
        }).catch(() => {
        });
      },
      back(){
          router.push("/admin/dashboard");
      },
      getTrackingPeriods(){
        this.$http.post("/api/organization/get/tracking_periods", {}).then((response) => {
          if (response.data.success === true) {
           this.tracking_nums = [];
              for(var i=0; i<this.trackingOptions.length;i++){
                if((response.data.tracking_periods & Math.pow(2,i)) != 0){
                  this.tracking_nums.push(this.trackingOptions[i]);
                }
              }
              this.tracking_nums.unshift({ value: 0, text: this.$t('role.select_period') });
          }
        }).catch((errors) => {
          console.log(errors)
        });
      },
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-recognition"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-recognition"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
      this.getAllowRenewDate();
      this.getRecognition();
      this.getMembers();
      this.getTrackingPeriods();
    }
    }
</script>
