<template>
  <div v-on:dragover="dragHandler" v-on:dragleave="dragLeaveHandler" v-on:dragenter="dragEnterHandler">
    <Header :useruid="this.$route.params.useruid" :title="$t('entry.manage_speaker')" :showDate="false"></Header>
    <div class="rel-grid-container" @dragleave.prevent :style="{'max-width': chapter_id>0? '100%': '75%'}">
      <div ></div>
      <form v-on:submit.prevent="updateEntry" id="speaker-form" class="form-fix">
        <Tip :tipNumber="23"></Tip>
        <div id="date-box">
          <span class="form-box-name">{{$t('entry.date_text')}}</span>
          <b-form-datepicker class="input-vmargins" v-model="entry.day" :locale="$i18n.locale" :date-disabled-fn="dateDisabled" :disabled="entry.id !=0 || (!permissions.organization.official && meeting_days.length==0)"></b-form-datepicker>
        </div>
        <div id="speaker-check" class="fit-content">
              <b-form-radio-group v-model="tempMember" :options="memberStates" :disabled="entry.id !=0">
              </b-form-radio-group>
            </div>
          <div>
        <div>
          <span class="form-box-title">{{$t('entry.speaker_info')}}</span>
            <div v-if="tempMember==0" class="form-box">
              <span class="form-box-name">{{$t('entry.member_text')}}</span>
              <b-form-select required v-model="entry.member_id" :options="members" value-field="id" text-field="name" class="mb-3 form-box-input" :disabled="entry.id !=0"/>
              <span class="form-box-name"  v-if="isCorporateConnections">{{$t('entry.note_text')}}</span><b-form-textarea  v-if="isCorporateConnections" v-model="entry.note" placeholder="" :rows="2" class="form-box-input"/>
            </div>
            <div v-if="tempMember==1" class="form-box">
              <span class="form-box-name">{{$t('entry.contact_text')}}</span>
              <b-form-select required v-model="entry.member_id" :options="contacts" value-field="id" text-field="name" class="mb-3 form-box-input" :disabled="entry.id !=0" />
              <span class="form-box-name"  v-if="isCorporateConnections">{{$t('entry.note_text')}}</span><b-form-textarea  v-if="isCorporateConnections" v-model="entry.note" placeholder="" :rows="2" class="form-box-input"/>
            </div>
            <div v-if="tempMember==2" class="form-box">
              <span required  class="form-box-name">{{$t('entry.name_text')}}</span><b-form-input  v-model="entry.nm_name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input medium-width-input" :disabled="entry.id !=0" required></b-form-input>
            <span class="form-box-name">{{$t('entry.company_text')}}</span><b-form-input  v-model="entry.nm_company" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input>
            <span class="form-box-name">{{$t('entry.title_text')}}</span><b-form-input  v-model="entry.nm_title" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input>
            <span class="form-box-name">{{$t('entry.email_text')}}</span><b-form-input  v-model="entry.nm_email" type="email" class="form-box-input"></b-form-input>
            <span class="form-box-name" v-if="isCorporateConnections">{{$t('entry.note_text')}}</span><b-form-textarea v-if="isCorporateConnections" v-model="entry.note" placeholder="" :rows="2" class="form-box-input"/>
            </div>
            <div v-if="tempMember==3" class="form-box">
              <span class="form-box-name">{{$t('entry.power_team')}}</span>
              <b-form-select required v-model="entry.powerteam_id" :options="powerteams" value-field="id" text-field="name" class="mb-3 form-box-input" :disabled="entry.id !=0"/>
            </div>
          </div>
        </div>
        <div v-if="tempMember ==2">
          <span  class="form-box-title">{{$t('entry.speaker_photo')}}</span>
          <div  class="form-box grid-auto-1column">
            <ImageSelector id="speaker-image-selector" ref="imageSelector" :imageObject="entry" :ratio="isCorporateConnections? 1 : 0.6157" :maxWidth="665" :maxHeight="1080" :clearButton="true" :imageField="'nm_photo'" :smallImageField="'nm_photo_small'" :circular="true"/>
           
          </div>
        </div>
        <div v-if="entry.hash && entry.hash.length>0">
          <span class="form-box-title">{{$t('entry.speaker_setup')}}</span>
          <div class="form-box grid-auto-1column">
            <span v-if="entry.uploaded" class="speaker-info-line">{{$t('entry.last_speaker_update')}} {{$d(new Date(entry.uploaded.split("-").join("/")), 'long')}}</span>
            <span v-if="!entry.uploaded" class="speaker-info-line">{{$t('entry.speaker_submit')}}.</span>
            <div class="speaker-instruction skinned-text">{{$t('presentation.instructions1')}}</div>
          <div class="speaker-instruction skinned-text">{{$t('presentation.instructions2')}}</div>
            <div >
              <div>
                <span class="form-box-title">{{$t('entry.speaker_bio')}}</span>
                <div class="form-box grid-auto-1column">
                  <BaseEditor v-model="entry.bio" :wordLimit="bio.cap_words == 1? bio.max_words : 0" :suggestedChars="bio.recommended_words"/>
                </div>
              </div>
              
            <div>
              <b-form-checkbox class="setting-check" v-model="entry.sharefile" id="share-files">{{$t('presentation.share_presentation')}}<br/>{{$t('presentation.share_presentation2')}}</b-form-checkbox>
              <Fileuploads :obj="entry" page="speaker" :useruid="useruid" :chapter_id="chapter_id"></Fileuploads>
            </div>
            </div>
          </div>
          <span v-if="isCorporateConnections" class="form-box-title">{{$t('presentation.topic_audience')}}</span>
            <div v-if="isCorporateConnections" class="form-box">
              <span class="form-box-name">{{$t('presentation.topic_text')}}</span><b-form-input v-model="entry.topic" max="255" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input>
                <span class="form-box-name">{{$t('presentation.target_audience')}}</span><b-form-textarea id="target-audience" v-model="entry.target_audience" placeholder="" :rows="3" class="form-box-input"/>
                <span class="form-box-name">{{$t('presentation.value_proposition')}}</span><b-form-textarea v-model="entry.value_proposition" placeholder="" :rows="3" class="form-box-input"/>
              </div>
          <div v-if="showPresentationAlert">
            <b-alert variant="danger"
                     dismissible
                     :show="showPresentationAlert"
                     @dismissed="showPresentationAlert=false">
              <span v-html="alertText"></span>
            </b-alert>
          </div>
        </div>
        <div v-if="showDupeAlert" class="speaker-alert">
          <b-alert variant="danger" dismissible :show="showDupeAlert" @dismissed="showDupeAlert=false">
            {{$t('entry.dupe_alert')}}
          </b-alert>
        </div>
        <div v-if="showAlert" class="speaker-alert">
          <b-alert variant="warning" dismissible :show="showAlert" @dismissed="showAlert=false">
            {{$t('performance.error_type1')}}
          </b-alert>
        </div>
        <div v-if="showUpload">
          <b-alert class="alert-line-height" variant="warning" :show="showUpload">
            {{$t('entry.uploading_text')}}
            <b-spinner class="alert-spinner" label="Uploading" variant="warning"></b-spinner>
          </b-alert>
        </div>
        
        <div class="submit-box-2">
          <b-button v-if="entry.id>0" class="action-button" variant="clear-blue" v-on:click="showDelete=true;"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          <b-button class="action-button" v-on:click="back" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="action-button" type="submit" v-if="!showUpload" variant="clear-blue"><div class="save-button"></div><span>{{entry.type==1? $t('global.button_save_upload') : $t('global.button_save')}}</span></b-button>
        </div>
        <b-modal id="modal-center" v-model="showDelete" centered :title="$t('entry.delete_title')">
          <p class="my-4">{{$t('entry.delete_text')}}</p>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="showDelete=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" v-on:click="deleteEntry" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          </div>
        </b-modal>
        <b-modal id="modal-center" v-model="showNoMeetingDates" centered >
          <p class="my-4" v-html="$t('entry.no_meetings_note')"></p>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="closeNoMeetingDates" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_ok')}}</span></b-button>
            <b-button v-if="chapter_id>0" class="action-button" v-on:click="goToMeetingDates" variant="clear-blue"><div class="next-button"></div><span>{{$t('entry.go_to_meetingdates')}}</span></b-button>
          </div>
        </b-modal>
      </form>
      <div class="div-space"></div>
    </div>
    <Footer :useruid="this.$route.params.useruid"></Footer>

  </div>
</template>
<style scoped>
.speaker-alert{
  max-width: 600px;margin: auto;
}
#target-audience{
  margin-bottom: 10px;
}
#share-files{
  margin:10px;
}
.speaker-instruction{
  text-align:center;color: rgb(0, 174, 239);
}
.speaker-info-line{
  font-size:16px;margin-bottom:20px;
}
#speaker-image-selector{
  margin-left:10px;
}
#speaker-form{
  width:800px;
}
#filename-text{
  white-space: nowrap;overflow: hidden;text-overflow: ellipsis;margin: 10px;
}

  #date-box {
    display: grid;
    grid-template-columns: auto auto;
    width: fit-content;
    margin: auto;
  }
  #speaker-check {
    grid-column-start: 2;
    grid-column-end: 4;
    margin: 10px auto;
    color: #999;
  }

</style>
<script>
import Fileuploads from './Fileuploads.vue';
  import router from "../router"
  import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
    export default {
    name: "Entry",
    components: {
      VueCropper,
      Fileuploads
    },
    watch: {
      'entry.type': {
        handler: function (v) {
          if(v == false){
            this.entry.type = 0;
          }
          if (!this.load) {
            this.entry.url = "";
          } else {
            this.load = false;
          }
        },
        deep: true
      },
    },
    props:{
        chapter_id: {type: Number, default: 0},
        speakerObject: {type: Object, default: {id: -1}}
      },
      data() {
        return {
          showNoMeetingDates: false,
          showAlert: false,
          meet_day_of_week: 0,
          showDelete: false,
          showSetup: false,
          num_color: "#999999",
          saveVariant: 'blue',
          members: [],
          entry: { id: 0, powerteam_id: 0, member_id:0, nm_photo: null, nm_photo_small: null, nm_name: "", nm_company: "", nm_title: "", nm_email: "", url: "", hash: "", bio: "", type: -1, uploaded: '', sharefile: 0, day: new Date() , files: [], topic: "", target_audience: "", value_proposition: "", note: ""},
          tempMember: 0,
          load: true,
          showUpload: false,
          showOverMax: false,
          showedOverMax: false,
          bio: {recommended_words: 150, max_words: 200, cap_words: 0},
          memberStates: this.isCorporateConnections? [{ text: this.$t('entry.member_text'), value: 0 },{ text: this.$t('entry.regional_contact'), value: 1 }, { text: this.$t('entry.non_member_text'), value: 2 }] : [{ text: this.$t('entry.member_text'), value: 0 }, { text: this.$t('entry.power_team'), value: 3 },{ text: this.$t('entry.regional_contact'), value: 1 }, { text: this.$t('entry.non_member_text'), value: 2 }],
          contacts: [],
          original_contacts: [],
          showDupeAlert: false,
          showPresentationAlert: false,
          alertText: "",
          useruid: "",
          showDrag: false,
          dragTimeout: null,
          dragging: false,
          meeting_days: [],
          powerteams: []
        }
      },
    methods: {
      closeNoMeetingDates(){
        if(this.chapter_id>0){
          this.showNoMeetingDates = false;
          this.speakerObject.id = -1;
        }else{
          this.showNoMeetingDates = false;
         router.push("/admin/schedule");
        }
      },
      goToMeetingDates(){
        if(this.chapter_id==0){
          this.speakerObject.id = -1;
        }else{
          this.showNoMeetingDates = false;
         router.push("/admin/meetingdates");
        }
      },
      dateDisabled(ymd, date) {
        if(this.meeting_days.length>0){
          return !this.meeting_days.includes(ymd);
        }
        const weekday = date.getDay();
        return weekday !== this.meet_day_of_week;
      },
      getEntry() {
        if(this.chapter_id>0){
          this.entry.id = this.speakerObject.id;
        }else if(this.$route.params.id && this.$route.params.id.includes("-")){
          this.entry.id = 0;
          this.entry.day = this.$route.params.id;
        }else{
          this.entry.id = this.$route.params.id;
          if(this.entry.id==0){
            this.getNextMeetingDate();
          }
        }
        
        if (this.entry.id > 0) {
          this.entry.useruid = this.useruid;
          this.entry.org = this.chapter_id;
          this.$http.post("/api/organization/schedule/get"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""), this.entry).then((response) => {
            if (response.data.success) {
              //response.data.entry.files = [];
              response.data.entry.sharefile = response.data.entry.sharefile==1;
              this.entry = response.data.entry;
              if (!this.entry.bio) {
                this.entry.bio = "";
              }
              for (var i = 0; i < this.entry.files.length; i++) {
                  this.$set(this.entry.files[i], 'file', null);
                  this.$set(this.entry.files[i], 'filename', "");
                }
              if(this.entry.files.length == 0){
                this.entry.files.push({ speaker_id: 0, number: 1, type: 1, link: "", description: "", filename: "", file_path: "", file: null, new: true });
              }
              this.tempMember = this.entry.speaker_type;
            }
            
          }).catch(() => {});
        }
      },
      getPowerteams() {
        this.$http.post("/api/organization/powerteams"+(this.useruid==''? "" : "/speaker/byuseruid"), { useruid: this.useruid}).then((response) => {
          if (response.data.success) {
            for(var powerteam of response.data.powerteams){
              powerteam.name = powerteam.title +": "+(powerteam.manager_name? powerteam.manager_name : this.$t('entry.no_manager'))
            }
            this.powerteams = response.data.powerteams;
            this.powerteams.unshift({id: 0, name: this.$t('entry.select_power')});
          }
        }).catch(() => {});
      },
      getMembers() {
        this.$http.post("/api/organization/members"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""), {org: this.chapter_id,useruid: this.useruid}).then((response) => {
          this.members = response.data.members;
          this.members.unshift({id: 0, name: this.$t('entry.select_member')});
        }).catch(() => {});
      },
      getContacts() {
        this.$http.post("/api/organization/contacts/assigned"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""), {org: this.chapter_id,useruid: this.useruid}).then((response) => {
          for(var i=0; i<response.data.contacts.length;i++){
            response.data.contacts[i].name = response.data.contacts[i].name+ (response.data.contacts[i].contact_name.length>0? " ("+response.data.contacts[i].contact_name+")" : "");
          }
          this.original_contacts = response.data.contacts.slice();
          this.contacts = response.data.contacts;
          for(var j=0; j<this.original_contacts.length; j++){
              this.contacts[j].name = this.original_contacts[j].name;
          }
          this.contacts.unshift({id: 0, name: this.$t('organizations.select_member')});
        }).catch((errors) => {console.log(errors)});
      },
      updateEntry() {
        this.showAlert = false;
        if((this.tempMember==2 && this.entry.nm_name=="") || ((this.tempMember==0 || this.tempMember==1) && this.entry.member_id==0) || (this.tempMember==3 && this.entry.powerteam_id==0)){
          this.showAlert = true;
          return;
        }
        this.showPresentationAlert = false;

        /*if (this.bio.cap_words == 1 && this.getNumWords() > this.bio.max_words) {
          var o = this.editor.getJSON();
          this.recurseBio(o, 0);
          this.editor.commands.setContent(o);
        }*/
            this.showUpload = true;
            if(this.tempMember == 3){
              this.entry.nm_name = "";
              this.entry.nm_company = "";
              this.entry.nm_title = "";
              this.entry.nm_email= "";
              this.entry.nm_photo = null;
              this.entry.nm_photo_small = null;
              this.entry.member_id = 0;
            }else if (this.tempMember != 2) {
              this.entry.nm_name = "";
              this.entry.nm_company = "";
              this.entry.nm_title = "";
              this.entry.nm_email= "";
              this.entry.nm_photo = null;
              this.entry.nm_photo_small = null;
              this.entry.powerteam_id = 0
            } else {
              this.entry.powerteam_id = 0
              this.entry.member_id = 0;
              this.entry.nm_company = this.entry.nm_company ? this.entry.nm_company : "";
            }
           

            let formData = new FormData();
            var presentationUploads = [];
            for (var i = 0; i < this.entry.files.length; i++) {
              if (this.entry.files[i].type == 1 && this.entry.files[i].file && this.entry.files[i].file != null) {
                formData.append("files", this.entry.files[i].file);
                this.entry.files[i].link = "";
                this.entry.files[i].name = this.entry.files[i].file.name;
                this.entry.files[i].file = null;
                presentationUploads.push( this.entry.files[i]);
              }else if (this.entry.files[i].link != ""){
                presentationUploads.push( this.entry.files[i]);
              }
            }
            formData.append("speaker_files", JSON.stringify(presentationUploads));
            formData.append("bio", this.entry.bio);
            formData.append("hash", this.entry.hash);
            formData.append("note", this.entry.note);
            formData.append("topic", this.entry.topic);
            formData.append("target_audience", this.entry.target_audience);
            formData.append("value_proposition", this.entry.value_proposition);
            formData.append("sharefile", this.entry.sharefile);
            formData.append("useruid", this.useruid);
            formData.append("org", this.chapter_id);

            this.$http.post("/api/organization/speaker/update"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""), formData, {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            }).then(() => {
              this.showUpload = false;
              this.entry.useruid = this.useruid;
              this.entry.org = this.chapter_id;
               this.$http.post("/api/organization/schedule/update"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""),this.entry).then((response) => {
                if (response.data.success === true) {
                  this.saveVariant = 'success';
                  this.back();
                } else {
                  if(response.data.error && response.data.error.code == 1){
                    this.showDupeAlert = true;
                    return;
                  }
                  this.saveVariant = 'danger';
                }
            }).catch(() => {this.$bvToast.show('serverError'); });
              }).catch(() => {
                this.showUpload = false;
                this.$bvToast.show('serverError');
            });

          },
          deleteEntry() {
            this.entry.useruid = this.useruid;
            this.entry.org = this.chapter_id;
            this.$http.post("/api/organization/schedule/delete"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""), this.entry).then((response) => {
              if (response.data.success === true) {
                this.back();
              }
            }).catch(() => {});
          },
          back(){
            if(this.permissions.access){
               if(this.chapter_id>0){
                 this.speakerObject.id = -1;
               }else{
                  router.push("/admin/schedule");
               }
             
            }else if(this.useruid.length>0){
              router.push("/schedule/"+this.useruid);
            }
            
          },
      getMeetDay() {
        this.$http.post("/api/organization/get/meet_day_of_week"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""), {org: this.chapter_id,useruid: this.useruid}).then((response) => {
          if (response.data.success === true) {
            this.meet_day_of_week = (response.data.meet_day_of_week + 1) % 7;
            this.meeting_days = response.data.meeting_days;
            if((!response.data.official || this.isCorporateConnections) && this.meeting_days.length==0){
              this.meeting_days = [-1];
              this.showNoMeetingDates = true;
            }
          }
        }).catch(() => { });
      },
      getBioCap() {
        this.$http.post("/api/organization/get/biocap"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""), {org: this.chapter_id,useruid: this.useruid}).then((response) => {
          if (response.data.success === true) {
            this.bio.recommended_words = response.data.bio.recommended_words;
            this.bio.max_words = response.data.bio.max_words;
            this.bio.cap_words = response.data.bio.cap_words;
          }
        }).catch(() => { });
      },
      recurseBio(o, wordcount) {
        var l = 0;
        if (o.content) {
          var self = this;
          o.content = o.content.filter(function (el) {
            var k = self.recurseBio(el, wordcount) ;
            wordcount += k;
            l += k
            return k > 0;
          })
        }
        if (o.text) {
          if (wordcount >= this.bio.max_words) {
            o.text = "";
            return 0;
          } else if (o.text.split(" ").length + l + wordcount > this.bio.max_words) {
            var g = this.bio.max_words - l - wordcount;
            var a = o.text.split(" ").slice(0, g);
            wordcount = this.bio.max_words;
            o.text = a.join(" ");
            return a.length + l;
          } else {
            return o.text.split(" ").length + l;
          }
        } else {
          return l;
        }
      },
      getNextMeetingDate() {
        this.$http.post("/api/organization/dates"+(this.useruid==''? "" : "/byuseruid")+(this.chapter_id>0? "/aschild" : ""), {org: this.chapter_id,useruid: this.useruid}).then((response) => {
          if (response.data.success === true) {
            this.entry.day = response.data.next_formatted;
          }
        }).catch(() => {});
      },
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-speakers"), "active-menu");
      this.removeClass(document.getElementById("menu-meeting"), "active-menu");
    },
    mounted() {
      if(this.$route.params.useruid){
        this.useruid = this.$route.params.useruid;
      }
      this.memberStates = this.isCorporateConnections || this.permissions.organization.allow_powerteams==0? [{ text: this.$t('entry.member_text'), value: 0 },{ text: this.$t('entry.regional_contact'), value: 1 }, { text: this.$t('entry.non_member_text'), value: 2 }] : 
      [{ text: this.$t('entry.member_text'), value: 0 }, { text: this.$t('entry.power_team'), value: 3 },{ text: this.$t('entry.regional_contact'), value: 1 }, { text: this.$t('entry.non_member_text'), value: 2 }]
      this.addClass(document.getElementById("menu-speakers"), "active-menu");
      this.addClass(document.getElementById("menu-meeting"), "active-menu");
      this.getMeetDay();
      this.getMembers();
      this.getContacts();
      this.getEntry();
      this.getBioCap();
      this.getPowerteams();
    }
  }
</script>
