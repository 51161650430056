<template>
  <div>
    <div class="rel-grid-container">
      <div ></div>
      <div>
        <b-table small bordered :items="files" :fields="fields">
            <template v-slot:cell(link)="data" >
                <a :href="data.item.link" target="_blank" >{{$t('files.view_file')}}</a>
            </template>
        </b-table>
      </div>
      <div class="submit-box-2">
        <b-button v-if="fileNum>0" class="action-button previous-button" v-on:click="previousPage" variant="clear-blue"><div class="back-button"></div><span>{{$t('global.button_back')}}</span></b-button>
        <b-button v-if="files.length==50" class="action-button" v-on:click="nextPage" variant="clear-blue"><div class="next-button"></div><span>{{$t('global.button_next')}}</span></b-button>
      </div>
      <div class="div-space"></div>
    </div>
  </div>
</template>
<style scoped>
</style>
<script>
    export default {
    name: "Filelog",
    watch: {
      'orgId': {
        handler: function () {
          this.fileNum = 0;
          this.getFilelogs();
        },
        deep: true
      }
    },
      data() {
        return {
          fields: [
              { key: 'uploadtime',
              label: this.$t('files.uploaded_header'),
              sortable: true,
              thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff", "width": "200px", "text-align": "center" },
              tdClass: "actions-centre"
            },
             { key: 'organization_name',
              label: this.$t('files.organization_header'),
              sortable: true,
              thStyle: { "background-color": process.env.VUE_APP_TABLE_HEADER, "color": "#ffffff" },
            },
             
            { key: 'member_name',
              label: this.$t('files.member_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff"}
            },
             { key: 'type',
              label: this.$t('files.type_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            },
            { key: 'original_name',
              label: this.$t('files.filename_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            },
            { key: 'size',
              label: this.$t('files.size_header') + " (MB)",
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            },
             { key: 'link',
              label: this.$t('files.link_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            },
             { key: 'description',
              label: this.$t('files.description_header'),
              sortable: true,
              thStyle: { "background-color":  process.env.VUE_APP_TABLE_HEADER,"color": "#ffffff" }
            }
          ],
          files: [],
          fileNum: 0,
          orgId: 0,
          organizations: [],
          file_actions : {1: this.$t('files.file_action1'), 2: this.$t('files.file_action2'),
                        3: this.$t('files.file_action3')}
        }
      },
    methods: {
      nextPage() {
        this.fileNum += 50;
        this.getFilelogs();
      },
      previousPage() {
        this.fileNum = Math.max(0, this.fileNum - 50);
        this.getFilelogs();
      },
      getFilelogs() {
        if (this.orgId >= 0) {
          this.$http.post("/api/organization/get/filelogs", { orgId: this.orgId, fileNum: this.fileNum }).then((response) => {
            if (response.data.success) {
               for (var i = 0; i < response.data.files.length; i++) {
                //response.data.files[i].type = this.file_actions[response.data.files[i].type];
                if(response.data.files[i].member_name == null){
                    response.data.files[i].member_name = this.$t('files.from_email');
                }
            }
              this.files = response.data.files;
            }
          }).catch(() => {
          });
        } else {
          this.files = [];
        }
      },
      getOrganizations() {
        this.$http.post("/api/organization/get/children", {}).then((response) => {
          this.organizations = response.data.children;
          if (this.organizations.length > 0) {
            this.fileNum = 0;
            this.orgId = 0;
            this.organizations.unshift({ id: 0, name: this.$t('files.all_chapters') })
          } else {
            this.organizations.unshift({ id: -1, name: this.$t('files.no_chapters') })
          }
          
        }).catch(() => {
        });
      }
    },
     destroyed() {
      this.removeClass(document.getElementById("menu-userlog"), "active-menu");
      this.removeClass(document.getElementById("menu-admin"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-userlog"), "active-menu");
      this.addClass(document.getElementById("menu-admin"), "active-menu");
      this.getFilelogs();
    }
    }
</script>
